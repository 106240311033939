import React, { Component, lazy, Suspense } from 'react';
import { withAppContext } from '../../components/context/AppContext';
const FAQEn = lazy(() => import('./en'));
const FAQAr = lazy(() => import('./ar'));

class FAQ extends Component {
  render() {
    return this.props.locale === 'ar' ? (
      <Suspense fallback={null}>
        <FAQAr />
      </Suspense>
    ) : (
      <Suspense fallback={null}>
        <FAQEn />
      </Suspense>
    );
  }
}

export default withAppContext(FAQ);
