import React, { Component } from 'react';
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import {
  MafpayCardHolderName,
  MafpayCardNumber,
  MafpayCardExpiry,
  MafpayCardCvc,
  MafpayCardSubmit,
  MafpayThreedsComponent
} from '@mafpay/weblib-react';
import styled from 'styled-components';
import axios from 'axios';
import { WhiteButton } from '../buttons';
import { BlueButton } from '../buttons';
import theme from '../../util/theme';
import { Large, Phone } from '../responsive/Breakpoints';
import MobileCheckoutFooterBtn from '../MobileCheckoutFooterBtn';
import { BasicInputField as Input } from './';
import CheckoutOrderDetails from '../CheckoutOrderDetails';
import FooterSpacer from '../NavbarSpacer';
import LoadingSpinner from '../LoadingSpinner';
import DisabledInputField from './DisabledInputField';
import { withAppContext } from '../context/AppContext';
import ShareForm from './ShareForm';
import ShareButton from '../../components/share/Button';


const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.isMobile ? '48px' : '42px')};
`;

const PaymentInfoRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: ${(props) => (props.isMobile ? undefined : 'center')};
  align-items: ${(props) => (props.isMobile ? 'center' : undefined)};
  width: ${(props) => (props.isMobile ? '100%' : 'auto')};
`;

const PaymentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.isMobile ? '100%' : 'auto')};
`;

const InputRow = styled.div`
  display: flex;
  height: 65px;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  input[inputmode='numeric'] {
    direction: ltr;
    [dir='rtl'] & {
      text-align: right;
    }
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? '100%' : '310px')};
  max-width: ${(props) => (props.isMobile ? '360px' : undefined)};
  padding: ${(props) => (props.isMobile ? '0 22px' : undefined)};
  margin-top: ${(props) => (props.isMobile ? '42px' : undefined)};
  box-sizing: ${(props) => (props.isMobile ? 'border-box' : undefined)};
`;

const InputLabel = styled.label`
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-inline-end: ${(props) => (props.flex ? '10px' : undefined)};
  flex: ${(props) => (props.flex ? '1' : undefined)};
  width: ${(props) => (props.width ? props.width : undefined)};
  color: ${(props) => {
    if (props.focused) {
      return props.theme.ds_bright_blue;
    }

    return props.dark ? props.theme.white_80 : props.theme.charcoal_90;
  }};
`;

const InputSpacer = styled.div`
  display: flex;
  width: 14px;
  flex-shrink: 0;
`;

const UserSignoutTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.isMobile ? '14px 0 36px 0' : '24px 0 16px 0')};
`;

const TermsText = styled.span`
  font-size: 0.8125rem;
  color: ${(props) =>
    props.link ? props.theme.ds_dusk_blue : props.theme.charcoal};
  cursor: ${(props) => (props.link ? 'pointer' : undefined)};
`;

const TermsLink = styled.a`
  font-size: 0.8125rem;
  color: ${(props) =>
    props.link ? props.theme.ds_dusk_blue : props.theme.charcoal};
  cursor: ${(props) => (props.link ? 'pointer' : undefined)};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => (props.isMobile ? 'auto' : '100%')};
  height: ${(props) => (props.isMobile ? '68px' : undefined)};
  max-width: ${(props) => (props.isMobile ? '360px' : undefined)};
  padding: ${(props) => (props.isMobile ? '32px 0 22px 0' : '36px 0 22px 0')};
`;

const PromoButtonRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: space-between;
  width: ${(props) => (props.isMobile ? 'auto' : '100%')};
  height: ${(props) => (props.isMobile ? 'auto' : undefined)};
  max-width: ${(props) => (props.isMobile ? '360px' : undefined)};
  padding: ${(props) => (props.isMobile ? '32px 0 22px 0' : '12px 0 22px 0')};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: ${(props) => (props.isMobile ? '360px' : undefined)};
  padding: ${(props) =>
    props.isMobile ? '32px 22px 22px 22px' : '36px 0 22px 0'};
  box-sizing: ${(props) => (props.isMobile ? 'border-box' : undefined)};
`;

const SmallText = styled.div`
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-top: ${(props) => (props.marginTop ? '22px' : undefined)};
  text-align: ${(props) => (props.isMobile ? 'center' : undefined)};
  font-weight: ${(props) => (props.bold ? 'bold' : undefined)};
  color: ${(props) =>
    props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal};
  cursor: ${(props) => (props.blue ? 'pointer' : undefined)};
`;

const inputConfig = {
  base: {
    fontSize: '13px',
    fontFamily: theme.sans_serif,
    color: theme.charcoal,
    width: '100%',
    'box-sizing': 'border-box',
    height: '43px',
    border: `solid 1px ${theme.ds_bright_blue_50}`,
    padding: '14px',
    'font-family': theme.sans_serif,
    'font-size': 'max(0.8125rem,14px)',
    transition: theme.transition,
    'border-radius': '3px',
    outline: 'none',
    '-webkit-appearance': 'none',
    '-webkit-tap-highlight-color': 'transparent',
    'background-color': 'transparent',
    '::placeholder': {
      color: theme.black_30
    },
    ':hover': {
      border: `3px solid ${theme.ds_bright_blue}`,
      padding: '12px'
    },
    ':focus': {
      border: `1px solid ${theme.ds_bright_blue}`,
      padding: '14px'
    },
    ':disabled,:read-only': {
      border: `1px solid ${theme.charcoal_25}`,
      padding: '14px'
    }
  }
};

const CompletePurchaseButton = styled(BlueButton)`
  color: #fff;
  display: block;
  width: ${(props) => (props.mobile ? '100%' : '310px')};
  height: 47px;
  font-family: Brother-1816, Arial, sans-serif;
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border-radius: 3px;
  -webkit-tap-highlight-color: transparent;
  background-color: #2e3191;
  color: white;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 30%);
  border: none;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 1px solid transparent;
`;

const ShareWrapper = styled.div`
  margin: 1em 0;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.isMobile ? '48px' : '42px')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 100%;
`;



class MafPaymentForm extends Component {
  state = {
    inputFocused: {
      cc: false,
      expire: false,
      cvv: false,
      zip: false
    },
    email: '',
    name: '',
    promoCode: '',
    promoCodeFocused: false,
    newsletterOptIn: false,
    cardRequired: true,
    loadingPromoCode: false,
    redeemAmount: 0
  };

  componentDidMount() {
    
    try {
      Frames.init({
        publicKey: 'pk_x2vzxnzoop3acdpo33ly6r2sruh',
        style: inputConfig
      });
    } catch (error) {
   //   this.setState({ errorMessage: 'Failed to initialize Frames. Please check your public key.' });
      console.error(error);
    }
    if (
      this.props.auth0 &&
      this.props.auth0.user &&
      this.props.auth0.user.email
    ) {
      this.setState({
        email: this.props.auth0.user.email
      });
      if (!this.nameRef || !this.nameRef.focus) {
        return;
      }
      return this.nameRef.focus();
    }
  }

  componentDidUpdate() {
    if (this.state.cardRequired && this.props.orderDetails.total === 0) {
      this.setState({ cardRequired: false });
    }
    if (
      !this.state.email &&
      this.props.auth0 &&
      this.props.auth0.user &&
      this.props.auth0.user.email
    ) {
      this.setState({
        email: this.props.auth0.user.email
      });
      if (!this.nameRef || !this.nameRef.focus) {
        return;
      }
      return this.nameRef.focus();
    }
  }
  handleFramesSubmit = () => {
    console.log('frames submit')
    
  };

  handleSubmit = async (ev) => {
    ev.preventDefault();
    this.props.setLoading(true);
    const { orderDetails } = this.props;

    if (this.state.email === '') {
      alert('Email is required');
      this.props.setLoading(false);
      return;
    }
   

    /*if (this.state.name === '') {
      alert('Name is required');
      this.props.setLoading(false);
      return;
    }*/
    // allow user to move with cc info if order total is 0
    if (orderDetails.total <= 0) {
      try {
        const success = await this.props.handleSubmitPayment({
          email: this.state.email,
          name: this.state.name
        });
        if (!success) {
          this.props.setLoading(false);
        }
      } catch (err) {
        console.error('[PaymentForm] -> (handleSubmit) Error Occurred:', err);
        alert(
          "We couldn't process the payment at this time. Please check all fields and try again."
        );
        this.props.setLoading(false);
      }
      return;
    }

    try {
      /*const formData = new FormData(ev.target);
      var data = {};
      formData.forEach(function (value, key) {
        data[key] = value;
      });

      data['merchantId'] = process.env.REACT_APP_MAF_PAY_MERCHANT_ID;
      data['apiKey'] = process.env.REACT_APP_MAF_PAY_API_KEY;

      delete data.redeemDecimal_value;
      delete data.redeem_value;
      const url = process.env.REACT_APP_MAF_PAY_URL;

      const tokenResponse = await axios.post(`${url}/tokenize/sdk`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (
        !tokenResponse.data.responseCode ||
        tokenResponse.data.responseCode !== '000'
      ) {
        console.error(
          '[PaymentForm] -> (handleSubmit) MafPay Error:',
          tokenResponse.data.responseMessage
        );
        this.props.setLoading(false);
        alert(tokenResponse.data.responseMessage);
        return;
      }
      */
      if (Frames.isCardValid()) {
        const tokenResponse =  await Frames.submitCard();
        console.log(tokenResponse)
        const success = await this.props.handleSubmitPayment({
          email: this.state.email,
          name: this.state.name,
          cardToken: tokenResponse.token,
          cardResponse: tokenResponse,
          cardData: tokenResponse
        });
        console.log('MAFPAYFORM',success)
        if (!success) {
          this.props.setLoading(false);
        }
       
        return;
        
         
      }
      /// AUTHORIZE
      /*const success = await this.props.handleSubmitPayment({
        email: this.state.email,
        name: data.cardHolderName,
        cardToken: tokenResponse.data.cardToken,
        cardResponse: tokenResponse.data,
        cardData: data
      });
      if (!success) {
        this.props.setLoading(false);
      }
      return;
      */
    } catch (err) {
      console.error('[PaymentForm] -> (handleSubmit) Error Occurred:', err);
      alert(
        "We couldn't process the payment at this time. Please check all fields and try again."
      );
      this.props.setLoading(false);
      return;
    }
  };

  handleInputFocused(inputFocused) {
    const inputFocusUpdate = {
      [inputFocused]: true
    };
    this.setState({
      inputFocused: { ...this.state.inputFocused, ...inputFocusUpdate }
    });
  }

  handleInputBlur(inputBlurred) {
    const inputFocusUpdate = {
      [inputBlurred]: false
    };
    this.setState({
      inputFocused: { ...this.state.inputFocused, ...inputFocusUpdate }
    });
  }

  handleCheckClicked = (e) => {
    e.preventDefault();
    this.setState({ newsletterOptIn: !this.state.newsletterOptIn });
  };

  handlePromoCode = async (e) => {
    e.preventDefault();
    this.setState({ loadingPromoCode: true });
    await this.props.handlePromoCodeSubmit(this.state.promoCode);
    return this.setState({
      loadingPromoCode: false,
      promoCodeFocused: false,
      promoCode: ''
    });
  };

  enablePromoCode = () => {
    this.setState({ promoCodeFocused: !this.state.promoCodeFocused });
  };

  renderEmailInputArea = () => {
    const { isAuthenticated } = this.props.auth0;
    const { getLabel } = this.props;

    if (!isAuthenticated) {
      // Return the standard, enabled input to enter email with
      return (
        <Input
          autoFocus
          type="email"
          width="100%"
          placeholder={getLabel('checkout_payment_email')}
          label={getLabel('checkout_payment_email') + '*'}
          required
          value={this.state.email}
          style={{ marginBottom: 12 }}
          onChange={(event) => this.setState({ email: event.target.value })}
        />
      );
    }

    // User is logged in so display a disabled input box with disclaimer at the bottom to allow user to signout of the current
    // user account and login OR continue as guest.
    return (
      <React.Fragment>
        <Input
          disabled
          type="email"
          width="100%"
          placeholder={getLabel('checkout_payment_email')}
          label={getLabel('checkout_payment_email') + '*'}
          required
          value={this.state.email}
          style={{ marginBottom: 12 }}
        />
        <UserSignoutTextContainer>
          <TermsText style={{ flex: 1 }}>
            {getLabel('checkout_payment_not_you')}
          </TermsText>
          <TermsText
            style={{ flex: 2 }}
            link
            onClick={this.props.handleUserSignoutAndNavigate}>
            {getLabel('checkout_payment_signin_different')} &gt;
          </TermsText>
        </UserSignoutTextContainer>
      </React.Fragment>
    );
  };

  renderShareForm = () => {
    const { isAuthenticated } = this.props.auth0;
    if (!isAuthenticated) {
      return null;
    }

    if (!this.props.memberDetails) {
      return (
        <div className="spinner" style={{ margin: '10px auto' }}>
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      );
    } else {
      const { LoyaltyMember } = this.props.memberDetails;
      let pointsRemaining = 0;
      if (LoyaltyMember && LoyaltyMember.BalanceList) {
        pointsRemaining = LoyaltyMember.BalanceList[0].PointsRemaining;
      }

      let availableAmountFromShare = pointsRemaining / 10;
      availableAmountFromShare =
        Math.trunc(availableAmountFromShare * 100) / 100;
      const sharePoints =
        availableAmountFromShare >= this.props.orderDetails.total
          ? this.props.orderDetails.total
          : availableAmountFromShare;

      return (
        <ShareWrapper>
          {this.props.memberDetails.isMember ? (
            <ShareForm
              sharePoints={parseFloat(sharePoints).toFixed(2)}
              total={this.props.orderDetails.total}
              addRedeemSubmit={this.props.handleRedeemSubmit}
              deleteRedeemSubmit={this.props.handleRemoveRedeemSubmit}
              isRedeemed={this.props.isRedeemed}
              isRedeemLoading={this.props.isRedeemLoading}
            />
          ) : (
            <ShareButton
              type="button"
              disabled={!this.props.memberDetails}
              width="100%"
              onClick={this.props.handleJoinShare}
            />
          )}
        </ShareWrapper>
      );
    }
  };

  renderPayButton(isMobile) {
    const { orderDetails, getLabel } = this.props;
    let totalCost = orderDetails.totalCost;
    if (
      this.props.remainingAmount !== 0 &&
      this.props.remainingAmount !== null
    ) {
      totalCost = `${this.props.currencySymbol} ${parseFloat(
        this.props.remainingAmount
      ).toFixed(2)}`;
    }

    if (this.props.remainingAmount === 0) {
      const btnContent = this.props.isCompleteLoyaltyLoading ? (
        <LoadingSpinner />
      ) : (
        this.props.getLabel('share_complete_purchase')
      );
      return (
        <CompletePurchaseButton
          type="button"
          width={isMobile ? '100%' : '310px'}
          label={btnContent}
          content={btnContent}
          disabled={this.props.isRedeemLoading}
          onClick={this.props.handleCompleteLoyaltyPayment}>
          {btnContent}
        </CompletePurchaseButton>
      );
    } else {
      const btnContent = `${getLabel('checkout_payment_btn_pay')} ${totalCost}`;
      //cFrames.submit();
      return (
        <BlueButton
        style={{
          display: this.props.shouldShowLoading ? 'none' : 'block'
        }}
          onClick={this.handleFramesSubmit}         
        >
          {btnContent}
        </BlueButton>
      );
    }
  }

  renderGiftPromoSection(isEnabled, isMobile) {
    const { getLabel } = this.props;
    if (this.props.isDiscountApplied) {
      return (
        <Row isMobile={isMobile}>
          <SmallText blue isMobile={isMobile} onClick={this.enablePromoCode}>
            {getLabel('discount_applied')}
          </SmallText>
        </Row>
      );
    }

    if (!isEnabled) {
      return (
        <Row isMobile={isMobile}>
          <SmallText blue isMobile={isMobile} onClick={this.enablePromoCode}>
            {getLabel('enter_promo_code')} &gt;
          </SmallText>
          {/*
          <SmallText
            blue
            marginTop
            isMobile={isMobile}
            onClick={this.enablePromoCode}>
            ENTER GIFT CARD &gt;
          </SmallText> */}
        </Row>
      );
    } else {
      const btnContent = this.state.loadingPromoCode ? (
        <LoadingSpinner />
      ) : (
        getLabel('apply_code')
      );
      return (
        <Column isMobile={isMobile}>
          <Input
            autoFocus
            type="text"
            width="100%"
            label={getLabel('promo_help_text')}
            placeholder={getLabel('promo_placeholder')}
            value={this.state.promoCode}
            style={{ marginBottom: 12 }}
            onInput={(event) =>
              event.target.value = ("" + event.target.value).toUpperCase()}
            onChange={(event) =>
              this.setState({ promoCode: event.target.value })
            }></Input>
          <PromoButtonRow isMobile={isMobile}>
            <BlueButton
              width={isMobile ? '100%' : undefined}
              onClick={this.handlePromoCode}>
              {btnContent}
            </BlueButton>
            {this.state.loadingPromoCode ? null : (
              <WhiteButton
                width={isMobile ? '100%' : undefined}
                style={{ margin: isMobile ? '10px 0 0 0' : '0 0 0 10px' }}
                onClick={this.enablePromoCode}>
                {getLabel('cancel_promo')}
              </WhiteButton>
            )}
          </PromoButtonRow>
        </Column>
      );
    }
  }

  processCompleteHandler(event) {
    if (!event || !event.detail) {
      return;
    }

    if (event.detail.responseCode !== '000') {
      this.props.setLoading(false);
    }

    this.props.handleProcessComplete(event);
  }

  renderThreeDsComponent() {
    if (!this.props.threeDsAuthId) {
      return null;
    }

    return (
      <div>
        {this.props.threeDsAuthId && (
          <MafpayThreedsComponent
            threedsauthid={this.props.threeDsAuthId}
            onProcessComplete={(event) => this.processCompleteHandler(event)}
          />
        )}
      </div>
    );
  }

  render() {
    const { isMobile, orderDetails, titleId, getLabel } = this.props;
    let ccForm = (
      <React.Fragment>
        <Input type="hidden" name="command" value="tokenize" />
        <Input type="hidden" name="source" value="web" />
        <InputRow>
            <CardNumber/>
            
        </InputRow>
        <InputRow>
            <ExpiryDate/>
            <Cvv/>
        </InputRow>
        <Large>
          {this.renderGiftPromoSection(this.state.promoCodeFocused)}
        </Large>
      </React.Fragment>
    );

    if (!this.state.cardRequired) {
      ccForm = (
        <React.Fragment>
          <InputRow>
            <DisabledInputField
              type="text"
              width="100%"
              label={getLabel('checkout_payment_credit_card_number') + '*'}
              placeholder={getLabel('checkout_payment_credit_card_number')}
            />
            <InputSpacer />
            <DisabledInputField
              type="text"
              width={95}
              label={getLabel('checkout_payment_expiry_date') + '*'}
              placeholder="MM/YY"
            />
          </InputRow>
          <InputRow>
            <DisabledInputField
              type="text"
              width={81}
              label="CVV*"
              placeholder="CVV"
            />
            <InputSpacer />
            <DisabledInputField
              type="text"
              width="100%"
              label="Zip Code*"
              placeholder="Zip Code"
            />
          </InputRow>
          <Large>
            {this.renderGiftPromoSection(this.state.promoCodeFocused)}
          </Large>
        </React.Fragment>
      );
    }

    return (
      <StyledForm onSubmit={this.handleSubmit} isMobile={isMobile}>
        <PaymentInfoContainer isMobile={isMobile}>
          <PaymentInfoRow isMobile={isMobile}>
            <CheckoutOrderDetails
              orderDetails={orderDetails}
              shareDiscount={this.props.shareDiscount}
              isMobile={isMobile}
              titleId={titleId}
            />

            <InputGroup isMobile={isMobile} name="payment-form-group">
              {this.renderEmailInputArea()}
              {this.renderShareForm()}
              {this.props.remainingAmount !== 0 && (
                <>
                  <InputRow>
                    <InputLabel flex>
                     
                      <Input
                        width="100%"
                        placeholder={
                          this.state.cardRequired
                            ? getLabel('checkout_payment_name_on_card') + '*'
                            : 'Full Name'
                        }
                        style={inputConfig}
                        onFocus={() => this.handleInputFocused('name')}
                        onBlur={() => this.handleInputBlur('name')}
                        onChange={(event) =>
                          this.setState({ name: event.target.value })
                        }
                        ref={(ref) =>
                          (this.nameRef = ref ? ref.inputRef : null)
                        }
                      />
                    </InputLabel>
                  </InputRow>
                  {ccForm}
                </>
              )}
            </InputGroup>
          </PaymentInfoRow>
        </PaymentInfoContainer>

        {/* Terms of service text here */}
        <Large>
          <TermsContainer>
          <TermsText>
              By continuing to Pay, you agree to  &#32;
                <TermsText link onClick={this.props.linkToTerms}>
                {getLabel('checkout_payment_terms_text')} &#32;
                </TermsText> 
                 .Payments are handled by Checkout.com. Refer to their &#32;
                <TermsLink link href="https://www.checkout.com/legal/privacy-policy">
                &#32; Privacy Policy 
                </TermsLink> 
              </TermsText>  
          </TermsContainer>
        </Large>
        <Phone>
          {this.renderGiftPromoSection(this.state.promoCodeFocused, true)}
          <TermsContainer isMobile>
          <TermsText>
              By continuing to Pay, you agree to  
                <TermsText link onClick={this.props.linkToTerms}>
                {getLabel('checkout_payment_terms_text')}.
                </TermsText> .Payments are handled by Checkout.com. Refer to their 
                <TermsText link onClick={this.props.linkToTerms}>
                  Privacy Policy &gt;
                </TermsText> 
              </TermsText>  
          </TermsContainer>
          <FooterSpacer name="footer-spacer" height={92} />
        </Phone>

        {/* PAYMENT SUBMIT BUTTON */}
        <Large>
          <InputRow>
            <BlueButton
              style={{
                display: this.props.shouldShowLoading ? 'block' : 'none'
              }}
              disabled={this.props.shouldShowLoading}
              width={isMobile ? '100%' : '300px'}>
              <LoadingSpinner />
            </BlueButton>
            {this.renderPayButton(false)}
          </InputRow>
          <FooterSpacer name="footer-spacer" height={57} />
        </Large>

        <Phone>
          <MobileCheckoutFooterBtn>
            <BlueButton
              style={{
                display: this.props.shouldShowLoading ? 'block' : 'none'
              }}
              disabled={this.props.shouldShowLoading}
              width={isMobile ? '100%' : '300px'}>
              <LoadingSpinner />
            </BlueButton>
            {this.renderPayButton(true)}
          </MobileCheckoutFooterBtn>
        </Phone>
        
      </StyledForm>
    );
  }
}

export default withAppContext(MafPaymentForm);
