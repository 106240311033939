import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import AdventureItem from '../components/content/AdventureItem';
import FindUsSection from '../components/FindUsSection';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';
import AnnouncementModal from '../components/AnnouncementModal';
import { sendEcomEvent } from '../util/analytics';
import Breadcrumbs from '../components/breadcrumbs';

const PageContainer = styled.div`
  height: auto;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageTitle = styled.h1`
  height: auto;
  max-width: ${(props) => props.theme.content_max_width};
  text-align: center;
  font-size: ${(props) => props.fontSize || '3.5rem'};
  line-height: ${(props) => props.lineHeight || 'initial'};
  font-weight: bold;
  letter-spacing: ${(props) => props.letterSpacing || '2px'};
  padding: ${(props) => props.padding || '56px 22px 48px 22px'};
`;

const AdventuresSection = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.ds_blue};
`;

const AdventuresListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${(props) => props.theme.content_max_width};
  padding: ${(props) => (props.mobile ? '48px 0 32px 0' : '88px 0 0')};
`;

const AdventureCardRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: ${(props) => props.theme.content_max_width};
  margin: ${(props) => props.margin || '0 22px 55px'};
  /* on desktop set justify-content start so the last row wraps left justified. we are assuming there will be at least 3 adventures (1 full row) */
  justify-content: ${(props) => (props.isMobile ? 'center' : 'start')};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1110px;
  width: 100%;
  z-index: 99999;
  position: relative;
  padding: ${(props) => (props.mobile ? '16px' : 0)};
  top: ${(props) => (props.mobile ? 0 : '2em')};
`;

class AdventuresWithContext extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    /** if adventures not loaded, trigger event after load */
    if (
      prevProps.parentState.adventures !== this.props.parentState.adventures
    ) {
      this.sendViewItemListEvent();
    }
  }
  componentDidMount() {
    /** if adventures loaded, trigger event */
    if (this.props.parentState.adventures.length > 0) {
      this.sendViewItemListEvent();
    }
  }

  sendViewItemListEvent() {
    const { adventures, selectedSite } = this.props.parentState;
    let availableAdventures = adventures;
    if (selectedSite.site_id) {
      availableAdventures = adventures.filter((adventure) => {
        return adventure.isAvailableAtCurrentLocation;
      });
    }
    sendEcomEvent(
      'view_item_list',
      {
        event_non_interaction: true
      },
      {
        siteId: selectedSite.site_id,
        adventures: availableAdventures
      }
    );
  }

  sendSelectItemEvent(adventure) {
    sendEcomEvent('select_item', null, {
      siteId: this.props.parentState.selectedSite.site_id,
      adventures: adventure
    });
  }

  navDetails(adventure, toAvailability, toQuoteBlock) {
    this.props.history.push(`/adventures/details/${adventure.title_id}`, {
      toAvailability,
      toQuoteBlock
    });
  }

  renderAdventures(cols, platform) {
    const rows = [];
    let row = [];
    const isMobile = platform === 'mobile';
    const rowMargin = isMobile ? '16px 0px' : null;
    const itemMargin = isMobile ? null : '0 8px';

    const { adventures, cms, selectedSite } = this.props.parentState;
    const { getLabel } = this.props;

    if (!adventures || !adventures.length) {
      return;
    }
    let availableAdventures = adventures;
    if (selectedSite.site_id) {
      availableAdventures = adventures.filter((adventure) => {
        return adventure.isAvailableAtCurrentLocation;
      });
    }
    availableAdventures.forEach((adventure, itr) => {
      const assets = cms
        ? cms.getAdventureAssets(adventure.title_id, selectedSite.site_id)
        : {};

      const isAvailable = !!adventure.isAvailableAtCurrentLocation;
      if (itr !== 0 && itr % cols === 0) {
        rows.push(
          <AdventureCardRow
            key={adventure.title_id + '_' + itr}
            name="adventure-card-row"
            margin={rowMargin}
            mobile={isMobile}>
            {row}
          </AdventureCardRow>
        );
        row = [];
      }

      row.push(
        <AdventureItem
          isMobile={isMobile}
          key={adventure.title_id + '_final'}
          adventure={adventure}
          assets={assets}
          site={selectedSite}
          onClick={() => {
            this.sendSelectItemEvent(adventure);
            this.navDetails(adventure, isAvailable, !isAvailable);
          }}
          posterOnClick={() => {
            this.sendSelectItemEvent(adventure);
            this.navDetails(adventure);
          }}
          margin={itemMargin}
          buttonText={
            isAvailable
              ? getLabel('adventures_button_get_tickets')
              : getLabel('adventures_button_learn_more')
          }
        />
      );
    });

    if (row.length) {
      rows.push(
        <AdventureCardRow
          margin={rowMargin}
          mobile={isMobile}
          key="final"
          name="adventure-card-row">
          {row}
        </AdventureCardRow>
      );
    }

    return rows;
  }

  render() {
    const { getLabel } = this.props;
    const pageTitleText = getLabel('adventures_title');
    const DesktopPageTitle = <PageTitle>{pageTitleText}</PageTitle>;
    const MobilePageTitle = (
      <PageTitle
        fontSize="1.625rem"
        lineHeight={1.33}
        letterSpacing="normal"
        padding="28px 22px 10px 22px">
        {pageTitleText}
      </PageTitle>
    );

    return (
      <PageContainer name="page-container">
        <Desktop>
          <AnnouncementModal />
        </Desktop>
        <Mobile>
          <AnnouncementModal isMobile={true} />
        </Mobile>

        <Desktop>
          <AdventuresSection name="adventure-section">
            <AdventuresListContainer>
              <Wrapper>
                <Breadcrumbs />
              </Wrapper>
              {DesktopPageTitle}
              {this.renderAdventures(3, 'desktop')}
            </AdventuresListContainer>
          </AdventuresSection>
        </Desktop>
        <Mobile>
          <AdventuresSection name="adventure-section">
            <AdventuresListContainer mobile>
              <Wrapper mobile>
                <Breadcrumbs />
              </Wrapper>
              {MobilePageTitle}
              {this.renderAdventures(1, 'mobile')}
            </AdventuresListContainer>
          </AdventuresSection>
        </Mobile>

        <FindUsSection />
      </PageContainer>
    );
  }
}

const Adventures = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <AdventuresWithContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(Adventures));
