import React from 'react';
import Helmet from 'react-helmet';
import logo from '../../assets/images/img-logo-l.png';

function OrganizationalSchema({ canonicalUrl }) {
  const schema = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Dreamscape KSA',
    url: canonicalUrl,
    logo: logo,
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      telephone: '[+966112154579]'
    },
    sameAs: [
      'https://www.instagram.com/dreamscapeksa/',
      'https://www.facebook.com/Dreamscapeksa',
      'https://twitter.com/dreamscapeksa'
    ]
  });
  return (
    <Helmet>
      <script type="application/ld+json">{schema}</script>
    </Helmet>
  );
}

export default React.memo(OrganizationalSchema);
