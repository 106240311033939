import React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="126"
    height="150"
    viewBox="0 0 117 140"
  >
    <g fill="none" fillRule="evenodd">
      <rect
        width="114"
        height="137"
        x="1.5"
        y="1.5"
        stroke="#2E3191"
        strokeWidth="3"
        rx="5"
      />
      <path
        fill="#2E3191"
        d="M43.752 25.8c.07.457.105.924.105 1.4 0 5.155-4.221 9.333-9.428 9.333C29.22 36.533 25 32.355 25 27.2c0-3.827 2.327-7.116 5.657-8.557V12.8a1 1 0 0 0-1-1H25V9h4.486a4 4 0 0 1 4 4v.667h6.371a4 4 0 0 1 4 4V23h3.924a4 4 0 0 1 3.455 1.985l1.62 2.777a4.3 4.3 0 0 1 .901-.095c2.343 0 4.243 1.88 4.243 4.2 0 2.32-1.9 4.2-4.243 4.2-2.343 0-4.243-1.88-4.243-4.2 0-.996.35-1.91.935-2.63l-1.714-2.94a1 1 0 0 0-.864-.497h-4.12zm-2.723-5.265v-3.068a1 1 0 0 0-1-1h-6.543v1.446c.31-.03.624-.046.943-.046 2.57 0 4.899 1.017 6.6 2.668zm-6.6 13.198c3.645 0 6.6-2.925 6.6-6.533 0-3.608-2.955-6.533-6.6-6.533-3.646 0-6.6 2.925-6.6 6.533 0 3.608 2.954 6.533 6.6 6.533zm19.328-.466c.781 0 1.414-.627 1.414-1.4 0-.774-.633-1.4-1.414-1.4-.78 0-1.414.626-1.414 1.4 0 .773.633 1.4 1.414 1.4zM34.44 29.533c-1.302 0-2.357-1.044-2.357-2.333 0-1.289 1.055-2.333 2.357-2.333 1.302 0 2.357 1.044 2.357 2.333 0 1.289-1.055 2.333-2.357 2.333z"
      />
      <g fill="#2E3191">
        <path d="M66 32.172L88.042 10.13l2.828 2.828L68.828 35z" />
        <path d="M75 10h16v4H75z" />
        <path d="M87 26V10h4v16z" />
      </g>
      <text
        fill="#1B1B1B"
        fillOpacity=".9"
        fontFamily="Brother-1816-Bold, Brother 1816"
        fontSize="16"
        fontWeight="bold"
      >
        <tspan x="33" y="127">
          PEARL
        </tspan>
      </text>
      <text
        fill="#1B1B1B"
        fontFamily="Brother-1816-Bold, Brother 1816"
        fontSize="45"
        fontWeight="bold"
        letterSpacing="-1"
      >
        <tspan x="10.748" y="91">
          MPR
        </tspan>
      </text>
      <path
        fill="#1B1B1B"
        fillOpacity=".25"
        fillRule="nonzero"
        d="M6 106v-1h105v1zM6 44v-1h105v1z"
      />
    </g>
  </svg>
);
