import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { DIApi } from '../util/api';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';
import MafPaymentForm from '../components/forms/MafPaymentForm';
import { withAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from '../components/LoadingSpinner';
import Moment from 'moment-timezone';
import {
  getUserSessionId,
  saveUserSessionId,
  deleteUserSessionId
} from '../util/userSession';

import {
  Desktop,
  Large,
  Mobile,
  Phone
} from '../components/responsive/Breakpoints';
import { calculatePriceAndFees } from '../util/common';
import { sendEcomEvent } from '../util/analytics';
import { CURRENCY_SYMBOL } from '../util/constants';

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  font-size: ${(props) => (props.mobile ? '1.625rem' : '2.75rem')};
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.33' : '1.09')};
  align-self: center;
  color: ${(props) => props.theme.charcoal_90};
  padding: ${(props) =>
    props.mobile ? '30px 48px 0 48px' : '60px 22px 0 22px'};
  text-align: ${(props) => (props.mobile ? 'center' : undefined)};
  letter-spacing: -1px;
`;

// change from 3 -> 18 in order to tolerate a theoretical max latency of 2 minutes
const RETRY_COUNT = 18;
const RETRY_DELAY_MILLIS = 5000;

class CheckoutPaymentConfirm extends Component {
  state = {
    threeDsAuthId: null,
    shouldShowLoading: false,
    member: null,
    remainingAmount: null,
    currencySymbol: 'SAR',
    isRedeemLoading: false,
    isRedeemed: false,
    isCompleteLoyaltyLoading: false,
    shareDiscount: null
  };

  componentDidMount() {
    const { location, history } = this.props;
    this.setState({ continueLoading: true });
    // Assuming you have access to the location object, for example in a React component
    const searchParams = new URLSearchParams(location.search);

    // Check if location.search is a string
    if (typeof location.search === 'string') {
      // Check if it contains 'checkout_status=success'
      if (location.search.includes('checkout_status=success')) {
        console.log('Location search contains checkout_status=success');
        this.setState({ shouldShowLoading: true });
        this.handleProcessComplete();
      } else {
        // Check for 'checkout_status=cancel' or 'checkout_status=failure'
        if (location.search.includes('checkout_status=cancel') || location.search.includes('checkout_status=failure')) {
           // There was a problem with the purchase
      this.props.handleProcessingPurchase(false);
      // TODO: Should display some sort of error here
      alert(
        "We couldn't process the payment at this time. Please check all fields and try again."
      );
      return false;
        } else {
          console.log('Location search does not contain checkout_status=success, checkout_status=cancel, or checkout_status=failure');
        }
      }
    } else {
      console.log('Location search is not a string');
    }

   
    this.getMemberDetails();
    document.body.style.background =
      'radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6) fixed center';
    
    // Retrieve location.state from localStorage if it does not exist
    let locationState = this.props.location.state;
    if (!locationState) {
      const savedState = localStorage.getItem('checkoutState');
      if (savedState) {
        locationState = JSON.parse(savedState);
        // Push the saved state back to the location object
        history.replace({ ...location, state: locationState });
      }
    } else {
      // Save location.state to localStorage if it exists
      localStorage.setItem('checkoutState', JSON.stringify(locationState));
    }

    this.props.updateHeaderText({
      handleOnBackClicked: this.props.auth0.isAuthenticated
        ? null
        : () => this.props.history.replace('/checkout/signin', locationState)
    });

    const env =
      process.env.REACT_APP_MAF_PAY_URL &&
      process.env.REACT_APP_MAF_PAY_URL.includes('sandbox')
        ? 'sandbox'
        : 'production';
    window.MafPay = {
      env,
      lang: locationState.site.locale
    };

    const { site, orderDetails, adventure } = locationState;

    sendEcomEvent(
      'add_payment_info',
      {
        currency: site.currency,
        value: orderDetails.total?.toFixed(2),
        payment_type: 'Credit Card',
        checkout_type: this.props.auth0.isAuthenticated ? 'Member' : 'Guest'
      },
      {
        siteId: site.site_id,
        adventures: adventure,
        orderDetails: orderDetails
      }
    );
  }


  componentWillUnmount() {
    document.body.style.background = 'black';
  }

  generateCodesArray(ticketHold, code) {
    let codes = [];
    ticketHold.codes.forEach((appliedCode) => {
      codes.push({ code: appliedCode.code });
    });
    if (code) {
      codes.push({ code: code });
    }
    return codes;
  }

  setShareDiscountOnState = () => {
    const locationState = this.props.location.state;
    this.props.history.replace(`/checkout/payment`, {
      ...locationState,
      shareDiscount: this.getShareDiscount()
    });
  }

  getShareDiscount = () => {
    if (!this.state.shareDiscount) {
      return null;
    }
    return `${this.state.currencySymbol} ${this.state.shareDiscount.toFixed(
      2
    )}`;
  };

  handleUserSignoutAndNavigate = async () => {
    const { logout } = this.props.auth0;

    // const siteId = this.props.authState.routerBase;
    localStorage.setItem(
      'routeState',
      JSON.stringify(this.props.location.state)
    );
    localStorage.setItem('logoutReturnUrl', `/checkout/signin`);
    logout({
      returnTo: window.location.origin
    });
  };

  linkToTerms = () => {
    window.open(`${window.location.origin}/terms`);
  };

  handleSubmitCodes = async (code) => {
    const userSessionId = getUserSessionId()

    if (!userSessionId) {
      return alert('Ticket is not available');
    }

    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      ignoreCache: false,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access profile'
    });

    // Filter out potential duplicates. `codeExists` array will contain at least one value if the current code
    // submitted matches one contained in the `ticketHold.codes` array (which has been submitted to the API and validated).
    /*const codeExists = ticketHold.codes.filter((ticketCode) => {
      return ticketCode.code.toUpperCase() === code.toUpperCase();
    });

    // If we have at least one record, we can safely assume this is a code that has already been applied.
    if (codeExists.length) {
      return alert('The code entered has already been applied.');
    }

    // Generate the "new" list of codes to submit to the API
    const codes = this.generateCodesArray(ticketHold, code);*/

    try {
      const purchaseData = await DIApi.applyGiftCode(
        code,
        userSessionId,
        token
      );

      if ('errorLabel' in purchaseData) {
        throw (purchaseData.errorLabel)
      }

      const ticketInfo = calculatePriceAndFees(purchaseData);
      const curSymbol = purchaseData.ticketsInfo.currency
        ? CURRENCY_SYMBOL[purchaseData.ticketsInfo.currency] ||
          this.props.getLabel('share_currency_sar')
        : this.props.getLabel('share_currency_sar');
      this.setState({ currencySymbol: curSymbol });
      const { orderDetails } = this.props.location.state;
      const { ticketHold } = this.props.location.state;
      orderDetails.totalCost = `${curSymbol}${ticketInfo.totalPrice.toFixed(
        2
      )}`;
      orderDetails.total = ticketInfo.totalPrice;
      /*orderDetails.giftDeduction = ticketInfo.ticketDeductions.gift
        ? `-${curSymbol}${ticketInfo.ticketDeductions.gift.toFixed(2)}`
        : null;*/
      orderDetails.promoDeduction = ticketInfo.ticketDeductions.promo
        ? `-${curSymbol}${ticketInfo.ticketDeductions.promo.toFixed(2)}`
        : null;
      orderDetails.ticketFees = ticketInfo.ticketFees;
      ticketHold.codes = purchaseData.codes;
      const locationState = this.props.location.state;
      // If the Share Redeem is already applied then update the remainingAmount
      if (this.state.remainingAmount) {
        // If the order total amount is less then the already applied shareDiscount then removed the share redeem
        if (purchaseData.total - this.state.shareDiscount < 0) {
          this.handleRemoveRedeemSubmit()
        } else {
          // Otherwise calculate the new remaining amount after the discount applied
          this.setState({
            remainingAmount: (purchaseData.total - this.state.shareDiscount).toFixed(2)
          })
        }
      }

      this.props.history.replace(`/checkout/payment`, {
        ...locationState,
        ticketHold,
        totalPrice: purchaseData.total,
        orderDetails,
        isDiscountApplied: true
      });
    } catch (error) {
      console.debug(
        '[CheckoutPurchaseConfirm] -> (handleSubmitCodes) error object',
        error
      );

      if (error.errorLabel === 'discount_not_valid') {
        alert(this.props.getLabel('error_promo_code_not_valid'))
      } else if (error.errorLabel === 'discount_not_available') {
        alert(this.props.getLabel('error_discount_not_available'))
      } else if (error.errorLabel === 'discount_already_applied') {
        alert(this.props.getLabel('error_discount_already_applied'))
      } else {
        alert(this.props.getLabel('error_discount_not_available'))
      }
    }
  };

  handleJoinShare = async () => {
    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;
    if (!isAuthenticated) {
      return;
    }
    const token = await getAccessTokenSilently({
      ignoreCache: true,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access profile'
    });
    const res = await DIApi.memberJoinShare(token);
    if (res && res.memberId) {
      const userSessionId = getUserSessionId();
      const token = await getAccessTokenSilently({
        ignoreCache: true,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid offline_access profile'
      });

      const memberDetails = await DIApi.getMember(
        token,
        userSessionId,
        res.memberId
      );
      if (memberDetails.userSessionId) {
        saveUserSessionId(memberDetails.userSessionId);
      }
      this.setState({ member: memberDetails });
    }
  };

  handleRedeemSubmit = async (points) => {
    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;
    if (!isAuthenticated) {
      return;
    }

    if (parseInt(points) === 0) {
      alert(`Redeem value can't enter zero or below`);
      return;
    }
    let totalPoints = 0;
    if (
      this.state.member &&
      this.state.member.LoyaltyMember &&
      this.state.member.LoyaltyMember.BalanceList
    ) {
      totalPoints =
        this.state.member.LoyaltyMember.BalanceList[0].PointsRemaining;
    }

    if (!totalPoints) {
      this.setState({
        isRedeemed: false,
        isRedeemLoading: false,
        shareDiscount: null
      });

      this.setShareDiscountOnState();
      alert(`You don't have enough points to redeem`);
      return;
    }

    const { orderDetails } = this.props.location.state;
    const pointsInCurrency = points / 100;

    const tempShareCurrency = totalPoints / 10;
    const availableShareCurrency = Math.floor(tempShareCurrency * 100) / 100;

    if (tempShareCurrency < pointsInCurrency) {
      this.setState({
        isRedeemed: false,
        isRedeemLoading: false,
        shareDiscount: null
      });
      this.setShareDiscountOnState()
      alert(
        `You only have ${availableShareCurrency} but you have entered ${pointsInCurrency}`
      );
      return;
    }

    if (pointsInCurrency === orderDetails.total) {
      this.setState({
        isRedeemLoading: false,
        isRedeemed: true,
        remainingAmount: 0,
        shareDiscount: pointsInCurrency
      });
      this.setShareDiscountOnState()

      return;
    }

    if (pointsInCurrency > orderDetails.total) {
      this.setState({
        isRedeemed: false,
        isRedeemLoading: false,
        shareDiscount: null
      });
      this.setShareDiscountOnState();
      alert('Redeem amount is greater than the total amount. Please change it!');
      return;
    }

    this.setState({ isRedeemLoading: true });

    const token = await getAccessTokenSilently({
      ignoreCache: true,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access profile'
    });

    const { ticketHold } = this.props.location.state;
    const payload = {
      userSessionId: ticketHold.user_session_id,
      amountInCents: points
    };
    try {
      const data = await DIApi.memberRedeemPoints(payload, token);
      if (!data || data.errorLabel || !data.OrderValueRemainingInCents) {
        this.setState({
          isRedeemed: false,
          isRedeemLoading: false,
          shareDiscount: null
        });
        this.setShareDiscountOnState();
        alert('Redeem Failed');
        return;
      }

      this.setState({
        isRedeemLoading: false,
        isRedeemed: true,
        shareDiscount: pointsInCurrency,
        remainingAmount: data.OrderValueRemainingInCents / 100
      });
      this.setShareDiscountOnState();
    } catch (error) {
      this.setState({
        isRedeemed: false,
        isRedeemLoading: false,
        shareDiscount: null
      });
      this.setShareDiscountOnState();
      alert('Redeem Failed');
      return;
    }
  };

  handleRemoveRedeemSubmit = async () => {
    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;
    if (!isAuthenticated) {
      return;
    }

    if (this.state.remainingAmount === 0) {
      this.setState({
        isRedeemLoading: false,
        isRedeemed: false,
        remainingAmount: null,
        shareDiscount: null
      });
      this.setShareDiscountOnState();
      return;
    }

    this.setState({ isRedeemLoading: true });
    const token = await getAccessTokenSilently({
      ignoreCache: true,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access profile'
    });

    const { ticketHold } = this.props.location.state;
    const userSessionId = ticketHold.user_session_id;

    const data = await DIApi.memberDeleteRedeemPoints(userSessionId, token);

    if (!data) {
      alert('Remove Redeem Failed');
      return;
    }
    this.setState({
      isRedeemLoading: false,
      isRedeemed: false,
      remainingAmount: null,
      shareDiscount: null
    });
    this.setShareDiscountOnState();
  };

  handleSubmitPayment = async ({
    email,
    name,
    cardToken,
    cardData,
    cardResponse
  }) => {
    const { ticketHold } = this.props.location.state;
    // const codes = this.generateCodesArray(ticketHold);
    if (!ticketHold) {
      // @TODO: Display some kind of error here?
      return false;
    }

    const paymentReqBody = {
      userSessionId: ticketHold.user_session_id,
      orderReference: ticketHold.partner_order_id,
      customerName: name,
      customerEmail: email,
      cardToken: cardToken,
      paymentInfo: {
        cardNumber: cardData.bin+'******'+cardData.last4,
        cardType: cardResponse.scheme,
        expiryMonth: cardData.expiry_month.toString(),
        expiryYear: cardData.expiry_year.toString(),
        securityCode: '***'
      }
    };

    this.props.handleProcessingPurchase(true);

    try {
      const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;

      const token = isAuthenticated
        ? await getAccessTokenSilently({
            ignoreCache: true,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid offline_access profile'
          })
        : null;

      this.setState({ shouldShowLoading: true });
      const authorizeResponseData = await DIApi.authorizePayment(
        paymentReqBody,
        token
      );
      console.log('CHECKOUTPAYFORM',authorizeResponseData)
      if (authorizeResponseData.webUrl) {
        // for tabby payment
        window.location.href = authorizeResponseData.webUrl;
      }

      this.setState({
        threeDsAuthId: authorizeResponseData.threeDsAuthId
      });

      if (authorizeResponseData && authorizeResponseData.newOrderExpiry) {
        this.props.resetTimer(authorizeResponseData.newOrderExpiry);
      }

      
    } catch (error) {
      // Check if the request timed out.
      if (error.response && error.response.status === 504) {
        // This should start our order status check
        console.info(
          '[CheckoutPurchaseConfirm] -> (handleSubmitPayment) start order status retry'
        );
        return this.checkOrderStatus(email);
      }

      // There was a problem with the purchase
      this.props.handleProcessingPurchase(false);
      // TODO: Should display some sort of error here
      alert(
        "We couldn't process the payment at this time. Please check all fields and try again."
      );
      return false;
    }
  };

  handleCompleteLoyaltyPayment = async () => {
    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;
    if (!isAuthenticated) {
      return;
    }

    this.setState({ isCompleteLoyaltyLoading: true });
    const token = await getAccessTokenSilently({
      ignoreCache: true,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access profile'
    });

    const { ticketHold } = this.props.location.state;
    const purchaseResponseData = await DIApi.memberCompleteLoyaltyPayment(
      ticketHold.partner_order_id,
      token
    );

    if (!purchaseResponseData) {
      this.setState({ isRedeemed: false, shareDiscount: null });
      this.setShareDiscountOnState();
      alert('Redeem Failed');
      return;
    }
    this.setState({
      isCompleteLoyaltyLoading: false,
      isRedeemed: true,
      remainingAmount: null,
      shareDiscount: this.state.shareDiscount
    });
    this.setShareDiscountOnState();

    this.handleOrderCompletion(purchaseResponseData, isAuthenticated);
  };

  checkOrderStatus = async (email, attempts = 0) => {
    const { ticketHold } = this.props.location.state;
    const { user_session_id } = ticketHold;

    console.info(
      '[CheckoutPurchaseConfirm] -> (checkOrderStatus) attempt:',
      attempts
    );
    try {
      const orderStatus = await DIApi.checkOrderStatus(user_session_id);

      // If http status is 202, the order status is still pending and we should try again.
      console.info(
        '[CheckoutPurchaseConfirm] -> (checkOrderStatus) orderStatus',
        orderStatus
      );
      if (orderStatus.status === 202) {
        const retries = attempts + 1;

        // Check if we have exhausted our retry count
        if (retries >= RETRY_COUNT) {
          // Throw this error which will get locally caught below in the catch block
          throw new Error(
            `After ${RETRY_COUNT} attempts, we still find the order to be pending`
          );
        }

        return new Promise((resolve) => {
          setTimeout(async () => {
            return resolve(this.checkOrderStatus(email, retries));
          }, RETRY_DELAY_MILLIS);
        });
      } else if (orderStatus.status === 400) {
        throw new Error('Order failed');
      }

      // Order http status was not 202 and must be within the 2xx range so must be a 200 response.
      const purchaseResponseData = orderStatus.data;
      purchaseResponseData.email = email;
      const checkoutState = {
        ...this.props.location.state,
        purchaseData: purchaseResponseData,
        customerEmail: email
      };

      this.props.history.replace('/checkout/receipt', checkoutState);
      return true;
    } catch (error) {
      // There was a problem with the purchase
      console.info(
        '[CheckoutPurchaseConfirm] -> (checkOrderStatus) error',
        error
      );
      this.props.handleProcessingPurchase(false);
      return false;
    }
  };

  setLoading(isLoading) {
    this.setState({ shouldShowLoading: isLoading });
  }

  renderPaymentForm(isMobile) {
    // Try to get checkoutState from props.location.state first
    let checkoutState = this.props.location.state;

    // If checkoutState is not available in props.location.state, try to get it from localStorage
    if (!checkoutState) {
        checkoutState = JSON.parse(localStorage.getItem('checkoutState'));
    }

    // If checkoutState is still not available, handle the missing state scenario (optional)
    if (!checkoutState) {
        console.error('Checkout state is missing');
        return null; // Or return some placeholder or error message
    }
    const { orderDetails, adventure } = checkoutState;

    return (
       this.state.shouldShowLoading ?<LoadingSpinner dark />:
      <MafPaymentForm
        currencySymbol={this.state.currencySymbol}
        isMobile={isMobile}
        auth0={this.props.auth0}
        orderDetails={orderDetails}
        handleSubmitPayment={this.handleSubmitPayment}
        handlePromoCodeSubmit={this.handleSubmitCodes}
        checkoutState={checkoutState}
        linkToTerms={this.linkToTerms}
        handleUserSignoutAndNavigate={this.handleUserSignoutAndNavigate}
        titleId={adventure.title_id}
        threeDsAuthId={this.state.threeDsAuthId}
        shouldShowLoading={this.state.shouldShowLoading}
        handleProcessComplete={this.handleProcessComplete.bind(this)}
        setLoading={this.setLoading.bind(this)}
        memberDetails={this.state.member}
        isRedeemed={this.state.isRedeemed}
        isRedeemLoading={this.state.isRedeemLoading}
        isCompleteLoyaltyLoading={this.state.isCompleteLoyaltyLoading}
        remainingAmount={this.state.remainingAmount}
        shareDiscount={this.getShareDiscount()}
        handleJoinShare={this.handleJoinShare}
        handleRedeemSubmit={this.handleRedeemSubmit}
        handleRemoveRedeemSubmit={this.handleRemoveRedeemSubmit}
        handleCompleteLoyaltyPayment={this.handleCompleteLoyaltyPayment}
        isDiscountApplied={this.props.location.state?.isDiscountApplied || false}
      />
    );
  }

  timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async handleProcessComplete() {
    
    //const { cardToken, customerEmail, responseCode } = event.detail;
    try {
    /*  if (responseCode !== '000') {
        alert(
          "We couldn't process the payment at this time. Please check all fields and try again."
        );
        this.setState({
          threeDsAuthId: null
        });
        this.props.handleProcessingPurchase(false);
        this.setState({ shouldShowLoading: false });
        return;
      }
      */
      this.setState({ shouldShowLoading: true });
      await this.timeout(2000);
      const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;

      const token = isAuthenticated
        ? await getAccessTokenSilently({
            ignoreCache: true,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid offline_access profile'
          })
        : null;

      const { ticketHold } = this.props.location.state;
      const purchaseResponseData = await DIApi.capturePayment(
        ticketHold.partner_order_id,
        token
      );

      this.handleOrderCompletion(
        purchaseResponseData,
        isAuthenticated
      );
    } catch (error) {
      this.setState({
        threeDsAuthId: null
      });
      // Check if the request timed out.
      //if (error.response && error.response.status === 504) {
        // This should start our order status check
      //  console.info(
       //   '[CheckoutPurchaseConfirm] -> (handleSubmitPayment) start order status retry'
      //  );
      //  return this.checkOrderStatus(customerEmail);
      //}

      // There was a problem with the purchase
      this.props.handleProcessingPurchase(false);
      this.setState({ shouldShowLoading: false });
      // TODO: Should display some sort of error here
      alert(
        "We couldn't process the payment at this time. Please check all fields and try again."
      );
      return false;
    }
  }

  handleOrderCompletion(
    purchaseResponseData,
    isAuthenticated,
    cardToken = null
  ) {
    const { en } = this.props.location.state.adventure;
    const { adventure, orderDetails, site } = this.props.location.state;

    // Send analytics
    if (purchaseResponseData.errorLabel && purchaseResponseData.isRefund) {
      sendEcomEvent(
        'refund',
        {
          transaction_id: purchaseResponseData.transactionReference
        },
        {
          siteId: site.site_id,
          adventures: adventure,
          orderDetails: orderDetails
        }
      );
      this.setState({
        threeDsAuthId: null
      });
      alert('Complete order failed, refund initiated');

      this.props.history.replace(
        `/adventures/details/${this.props.location.state.adventure.title_id}`,
        {
          site: this.props.location.state.site,
          adventure: this.props.location.state.adventure
        }
      );
      return false;
    } else {
      // google tag manager
      const productInfo = {
        id: this.props.location.state.adventure.title_id,
        name: en.shortname,
        price: purchaseResponseData.amount,
        category: 'Ticket',
        quantity: this.props.location.state.orderDetails.quantity
      };

      /*const slotDatetime = new Moment(orderDetails.showtime.datetime).tz(
        site.tz
      );*/
      const slotDatetime = new Moment(orderDetails.showtime.datetime);
      const slotDate = slotDatetime.format('MM/DD/YYYY');
      const slotTime = slotDatetime.format('h:mmA');
      const slotDay = slotDatetime.format('dddd');
      const isToday = slotDatetime.isSame(new Date(), 'day');

      sendEcomEvent(
        'purchase',
        {
          transaction_id: purchaseResponseData.transactionReference,
          affiliation: 'dreamscapeimmersive.com',
          value: purchaseResponseData.pricing['general-admission'].toFixed(2),
          tax: (
            purchaseResponseData.fees?.reduce?.(
              (acc, fee) => acc + fee.actual_amount,
              0
            ) / 100
          ).toFixed(2),
          currency: purchaseResponseData.currency,
          checkout_type: isAuthenticated ? 'Member' : 'Guest',
          payment_type: 'Credit Card',
          departure_type: adventure.analytics.item_variant,
          departure_date: slotDate,
          departure_week_day: slotDay,
          departure_today: isToday,
          departure_time: slotTime,
          departure_place: site.site_name,
          departure_city: site.city,
          departure_country: site.state,
          number_of_tickets: orderDetails.ticketNum
        },
        {
          siteId: site.site_id,
          adventures: adventure,
          orderDetails: orderDetails
        }
      );
    }

    const checkoutState = {
      ...this.props.location.state,
      purchaseData: purchaseResponseData,
      cardToken,
      customerEmail: purchaseResponseData.email
    };

    this.setState({
      threeDsAuthId: null
    });
    this.props.history.replace('/checkout/receipt', checkoutState);
    this.setState({ shouldShowLoading: false });
    deleteUserSessionId();
    return true;
  }

  async getMemberDetails() {
    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;

    if (!isAuthenticated) {
      return;
    }

    const token = await getAccessTokenSilently({
      ignoreCache: true,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access profile'
    });

    const userSessionId = getUserSessionId();
    const memberDetails = await DIApi.getMember(token, userSessionId);
    if (memberDetails.userSessionId) {
      saveUserSessionId(memberDetails.userSessionId);
    }
    this.setState({ member: memberDetails });
  }

  render() {
    const pageDetails = {
      title: this.props.getLabel('checkout_payment_title')
    };

    return (
      <Page name="checkout-purchase-page">
        <Desktop>
          <SectionTitle>{pageDetails.title}</SectionTitle>
        </Desktop>
        <Mobile>
          <SectionTitle mobile>{pageDetails.title}</SectionTitle>
        </Mobile>

        {/* Desktop and Tablet Devices */}
        <Large>{this.renderPaymentForm(false)}</Large>

        {/* Mobile Phone size displays */}
        <Phone>{this.renderPaymentForm(true)}</Phone>
      </Page>
    );
  }
}

const PaymentConfirm = (props) => (
  <AuthContext.Consumer>
    {(authState) => <CheckoutPaymentConfirm {...props} authState={authState} />}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(withAuth0(PaymentConfirm)));
