import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthenticatorContext';
import { withAppContext } from '../context/AppContext';
import { getBreadcrumsLabel } from './getBreadcrumsLabel';

const Breadcrumb = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap:0.5em
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  color: ${(props) => (props.light ? 'gray' : 'rgba(255, 255, 255, 0.7)')};
  font-size: 1em;
  text-shadow: ${(props) =>
    props.light ? '1px 1px 1px #b2a6a6' : '1px 1px 1px #000'};
  text-transform: capitalize;
  display: inline;
  + :before {
    color: inherit;
    text-shadow: none;
    content: '/';
    padding: 0 0.5em 0 0;
    [dir='rtl'] & {
      padding: 0 0 0 12px;
    }
  }
`;

const Link = styled.a`
  color: rgb(0, 184, 241);
  text-decoration: none;
  text-transform: capitalize;
  &:hover {
    color: rgb(0, 184, 241);
    text-decoration: underline;
  }
`;

// If add Invalid URL path, It's remove form the breadcrums
const removePaths = ['details'];

class BreadcrumbsContext extends Component {
  renderBreadcrumbs() {
    const pathArray = window.location.pathname.split('/').splice(2);
    const lastElement = pathArray.slice(-2);
    let breadcums = [];

    // pathArray = getBreadcrumbs(pathArray);

    pathArray.forEach((path) => {
      if (removePaths.indexOf(path) !== -1) {
        return;
      }
      if (path === 'share') {
        return breadcums.push({ display: path, slug: 'account/share/details' });
      }
      breadcums.push({ display: path, slug: path });
    });

    if (lastElement[1] === 'details') {
      breadcums.push({ display: lastElement[1], slug: lastElement[1] });
    }

    if (
      (pathArray[0] === 'share' && pathArray[1] === 'faq') ||
      pathArray[1] === 'terms' ||
      pathArray[1] === 'how-does-share-work'
    ) {
      breadcums = [];
      breadcums.push({ display: 'share', slug: 'share' });
      breadcums.push({ display: pathArray[1], slug: '' });
    }

    if (breadcums?.length === 0) {
      return;
    }

    return (
      <Breadcrumb>
        <ListItem light={this.props.light}>
          <Link href={`/${this.props.locale}-sa/`}>
            {getBreadcrumsLabel('home', this.props.locale)}
          </Link>
        </ListItem>
        {breadcums?.map(({ display, slug }, i) =>
          breadcums.length !== i + 1 ? (
            <ListItem key={i} light={this.props.light}>
              <Link href={`/${this.props.locale}-sa/${slug}`}>
                {getBreadcrumsLabel(display, this.props.locale)}
              </Link>
            </ListItem>
          ) : (
            <ListItem key={i} light={this.props.light}>
              {getBreadcrumsLabel(display, this.props.locale)}
            </ListItem>
          )
        )}
      </Breadcrumb>
    );
  }

  render() {
    return <React.Fragment>{this.renderBreadcrumbs()}</React.Fragment>;
  }
}

const Breadcrumbs = (props) => (
  <AuthContext.Consumer>
    {(parentState) => {
      return <BreadcrumbsContext {...props} parentState={parentState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(Breadcrumbs));
