import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import ShareWorkflowHeroSection from '../components/share-workflow/ShareWorkflowHeroSection';
import ShareWorkflowDetailsSection from '../components/share-workflow/ShareWorkflowDetailsSection';

import { AuthContext } from '../components/context/AuthenticatorContext';

const Container = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
`;

const en = {
  globalTitle: 'HOW DOES SHARE WORK?',
  list: [
    {
      title: 'To Sign Up for SHARE:',
      description: `You can sign up for the SHARE rewards programme on Dreamscape or VOX Cinemas or Magic
    Planet website/app.`,
      listItems: [
        `If you have a Dreamscape or VOX Cinemas or Magic Planet account: Please login, click on the
    SHARE section under your Profile then select ‘Join SHARE’`,
        `If you are a new customer: Create your account on Dreamscape or VOX Cinemas or Magic
    Planet and sign up for SHARE during the registration process.`
      ]
    },
    {
      title: 'To Use SHARE:',
      subTitle: `If you are making a purchase online at the Dreamscape website:`,
      listItems: [
        `Log into Dreamscape using your SHARE Login.`,
        `Select your preferred adventure.`,
        `Complete your payment.`,
        `Your SHARE points will be automatically added to your SHARE account.`,
        `You can view your SHARE points balance in the Transaction History in your account.`
      ]
    },
    {
      subTitle: `If you are paying in person at VOX Cinemas, Yalla! Bowling, Magic Planet, Dreamscape, Kitchen 35 or Top Bun:`,
      listItems: [
        `Visit the counter/cashier`,
        `Select your preferred adventure or movies or top-up your Magic Planet card.`,
        `Log into your Dreamscape or VOX Cinemas App or Magic Planet website and scan your SHARE ID`,
        `Your SHARE points will be automatically added to your SHARE account.`
      ]
    }
  ]
};
const ar = {
  globalTitle: 'كيف يعمل برنامج شير؟',
  list: [
    {
      title: 'للتسجيل في برنامج شير:',
      description: `يمكنك الاشتراك في برنامج شير للمكافآت من خلال موقع دريمسكيب أو ڤوكس سينما أو ماجيك بلانيت.`,
      listItems: [
        `إذا كان لديك حساب في دريمسكيب أو   ڤوكس سينما أو ماجيك بلانيت، يُرجى تسجيل الدخول، ثم أنقر فوق قسم شير ضمن ملف التعريف الخاص بك ثم حدد "انضم إلى شير".`,
        `إذا كنت عميلاً جديداً: أنشئ حسابك في دريمسكيب أو ڤوكس سينما أو ماجيك بلانيت واشترك في برنامج
        شير أثناء عملية التسجيل.`
      ]
    },
    {
      title: 'طريقة استخدام برنامج شير:',
      subTitle: `إذا كنت تجري عملية شراء عبر الإنترنت في دريمسكيب أو ڤوكس سينما أو ماجيك بلانيت:`,
      listItems: [
        ` قم بتسجيل الدخول إلى دريمسكيب أو ڤوكس سينما أو ماجيك بلانيت مستخدماً حساب شير.`,
        ` حدد الباقة أو التذكرة التي ترغب بشرائها من دريمسكيب.`,
        ` أكمل الدفع لتأكيد طلبك.`,
        ` ستتم إضافة نقاط شير المكتسبة تلقائياً إلى حساب شير الخاص بك.`,
        ` يمكنك الإطلاع على نقاط شير الخاص بك في سجل المعاملات في حسابك`
      ]
    },
    {
      subTitle: `إذا كنت تدفع شخصيًا في دريمسكيب أو ڤوكس سينما، يلا! بولينج أو ماجيك بلانيت أو كيتشن 35 أو توب بان:`,
      listItems: [
        `قم بزيارة الكاونتر / كاشير`,
        `حدد الباقة أو التذكرة التي ترغب بشرائها من دريمسكيب أو ڤوكس سينما أو قم بتعبئة بطاقة ماجيك بلانيت.`,
        `سجّل الدخول إلى تطبيق ڤوكس سينما أو موقع ماجيك بلانيت وأظهر للكاشير معرف شير الخاص بك.`,
        ` إضافة نقاط شير المكتسبة تلقائياً إلى حساب شير الخاص بك.`,
        `يمكنك الإطلاع على نقاط شير الخاص بك في سجل المعاملات في حسابك`
      ]
    }
  ]
};

class ShareWorkflowContainer extends Component {
  render() {
    let data = this.props?.parentState?.locale === 'ar' ? ar : en;
    return (
      <Container id="share-workflow-page">
        <ShareWorkflowHeroSection globalTitle={data?.globalTitle} />
        <ShareWorkflowDetailsSection data={data} />
      </Container>
    );
  }
}

const ShareWorkflow = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <ShareWorkflowContainer {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(ShareWorkflow);
