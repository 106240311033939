import React, { Component } from 'react';
import styled from 'styled-components';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { Large, Phone } from './components/responsive/Breakpoints';
import { AuthContext } from './components/context/AuthenticatorContext';
import AccountViewTickets from './pages/AccountViewTickets';
import AppNavbar from './components/AppNavbar';
import Footer from './components/Footer';
import AccountDetails from './pages/AccountDetails';
import AccountDetailsEdit from './pages/AccountDetailsEdit';
// import ChangePassword from './pages/ChangePassword';
import ForgotPassword from './ForgotPassword';
import AccountSignIn from './pages/AccountSignIn';
// import AccountSignUp from './pages/AccountSignUp';
import ShareDetails from './pages/ShareDetails';
import ShareId from './pages/ShareId';
import ShareTransactions from './pages/ShareTransactions';
import ToasterNotification from './components/ToasterNotification';
import Breadcrumbs from './components/breadcrumbs';

const PageContainer = styled.div`
  margin-top: ${(props) => props.toasterOffset}px;
  width: 100%;
  height: 100%;
`;

const AccountContainer = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  padding: ${(props) => (props.mobile ? '94px 20px 0' : '164px 120px 0')};
  flex-grow: 1;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '1.625rem' : '2.75rem')};
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.31' : '1.09')};
  text-align: center;
  color: ${(props) => props.theme.charcoal};
`;

const Text = styled.div`
  max-width: 830px;
  font-size: 1rem;
  font-weight: ${(props) => (props.dark ? 'bold' : 'normal')};
  font-family: ${(props) => props.theme.sans_serif};
  color: ${(props) =>
    props.dark ? props.theme.charcoal_90 : props.theme.charcoal_60};
  text-align: center;
  padding-top: 5px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: ${(props) => (props.mobile ? 'center' : undefined)};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  max-width: 1110px;
  width: 100%;
  z-index: 99999;
  position: relative;
  top: -2em;
`;

class Account extends Component {
  state = {
    accountTitle: '',
    accountSubTitle: '',
    toasterNotif: null
  };

  componentDidMount() {
    const toasterNotif = document.getElementById('toasterNotification');
    this.setState({ toasterNotif });
  }

  updateHeaderText = (headerInfo) => {
    const { title, subTitle } = headerInfo;
    this.setState({
      accountTitle: title || '',
      accountSubTitle: subTitle || ''
    });
  };

  renderAccountSection(mobile) {
    const toasterNotif = this.state.toasterNotif;
    const toasterOffset = toasterNotif ? toasterNotif.offsetHeight : 0;

    let headerContent = null;
    if (this.state.accountTitle || this.state.accountSubTitle) {
      headerContent = (
        <React.Fragment>
          <Wrapper>
            <Breadcrumbs light />
          </Wrapper>
          <Large>
            <HeaderContainer>
              <Title>{this.state.accountTitle}</Title>
              <Text>{this.state.accountSubTitle}</Text>
            </HeaderContainer>
          </Large>
          <Phone>
            <HeaderContainer mobile>
              <Title mobile>{this.state.accountTitle}</Title>
              <Text>{this.state.accountSubTitle}</Text>
            </HeaderContainer>
          </Phone>
        </React.Fragment>
      );
    }
    return (
      <PageContainer toasterOffset={toasterOffset}>
        <AppNavbar />
        <AccountContainer mobile={mobile}>
          {headerContent}
          <Switch>
            {/* Account Navigation Routes */}
            <Route
              exact
              path="/account"
              render={() => (
                <AccountViewTickets updateHeaderText={this.updateHeaderText} />
              )}
            />
            <Route
              exact
              path="/account/signin"
              render={() => (
                <AccountSignIn updateHeaderText={this.updateHeaderText} />
              )}
            />
            <Route
              exact
              path="/account/details"
              render={() => (
                <AccountDetails updateHeaderText={this.updateHeaderText} />
              )}
            />

            <Route
              exact
              path="/account/details/edit"
              render={() => (
                <AccountDetailsEdit updateHeaderText={this.updateHeaderText} />
              )}
            />

            <Route
              exact
              path="/account/share/details"
              render={() => (
                <ShareDetails updateHeaderText={this.updateHeaderText} />
              )}
            />
            <Route
              exact
              path="/account/share/shareId"
              render={() => (
                <ShareId barcode updateHeaderText={this.updateHeaderText} />
              )}
            />

            <Route
              exact
              path="/account/share/transactions"
              render={() => (
                <ShareTransactions updateHeaderText={this.updateHeaderText} />
              )}
            />
            <Route path="*">
              {document.readyState !== 'complete' ? <Redirect to="/" /> : null}
            </Route>
          </Switch>
          <ForgotPassword path="/account" />
        </AccountContainer>
        <Footer />
      </PageContainer>
    );
  }

  render() {
    return (
      <React.Fragment>
        <ToasterNotification />
        <Large>{this.renderAccountSection(false)}</Large>
        <Phone>{this.renderAccountSection(true)}</Phone>
      </React.Fragment>
    );
  }
}

const AccountController = (props) => (
  <AuthContext.Consumer>
    {(authState) => <Account {...props} authState={authState} />}
  </AuthContext.Consumer>
);

export default withRouter(AccountController);
