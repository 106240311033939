import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile, Large, Phone } from './responsive/Breakpoints';
import styled from 'styled-components';
import { withAppContext } from './context/AppContext';

import imgDuration from '../assets/images/img-duration.svg';
import imgGear from '../assets/images/img-gear.svg';
import imgKeepsakes from '../assets/images/img-keepsakes.svg';
import imgHealthSafety from '../assets/images/img-health-safety.png';

const Container = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${(props) => (props.mobile ? '46px 22px 51px' : '60px 98px 50px')};
  max-width: 1170px;
  margin: 0 auto;
`;

const HeaderBlock = styled.h5`
  padding-bottom: ${(props) => (props.mobile ? '27px' : '36px')};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.33' : '1.09')};
  color: ${(props) => props.theme.charcoal};
  margin: 0;
`;

const SubHeaderBlock = styled.h3`
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.25' : '2.25')}rem;
  font-weight: normal;
  line-height: 1.29;
  color: ${(props) => props.theme.charcoal_90};
  max-width: ${(props) => (props.mobile ? 'unset' : '748px')};
  margin: 0 auto;
`;

const ThingsToKnowTable = styled.table`
  width: 100%;
  text-align: start;
`;

const FlexTr = styled.tr`
  display: flex;
  margin-top: ${(props) => (props.mobile ? '24' : '16')}px;
`;

const SpacerTd = styled.td`
  width: 70px;
`;

const ThingToKnow = styled.td`
  flex: 1;
`;

const TTKHeader = styled.h4`
  color: ${(props) => props.theme.charcoal_90};
  font-size: ${(props) => (props.mobile ? '1.375' : '2.25')}rem;
  font-family: ${(props) => props.theme.serif};
  font-weight: normal;
  margin: 0 0 16px 0;
  padding-top: 18px;
  line-height: ${(props) => (props.mobile ? '1.64' : '1.19')};
  border-top: 1px solid ${(props) => props.theme.charcoal_25};
`;

const TTKBody = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  color: ${(props) => props.theme.charcoal_60};
  line-height: 1.33;
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-position: ${(props) => (props.isRtl ? '100% 50%' : '0 50%')};
`;

class PlanAheadWelcomeBlock extends Component {
  thingToKnowBlock(mobile, header, body, image) {
    const isRtl = this.props.isRtl();
    return (
      <ThingToKnow key={header}>
        <ImageContainer isRtl={isRtl} background={image} />
        <TTKHeader mobile={mobile}>{header}</TTKHeader>
        <TTKBody
          mobile={mobile}
          dangerouslySetInnerHTML={{ __html: body }}></TTKBody>
      </ThingToKnow>
    );
  }

  allocateBlocks(blocks, blocksPerRow, mobile) {
    let blockGroups = blocks;

    if (!mobile) {
      blockGroups = [];
      let temp = [];
      for (let i = 0; i < blocks.length; i++) {
        if (temp.length > 0) {
          temp.push(<SpacerTd key={i} />);
        }
        temp.push(blocks[i]);
        if ((i + 1) % blocksPerRow === 0) {
          blockGroups.push(temp);
          temp = [];
        }
        if (i === blocks.length - 1 && temp.length > 0) {
          for (let j = temp.length; j < blocksPerRow; j++) {
            temp.push(<SpacerTd />);
            temp.push(<div style={{ flex: '1' }} />);
          }
          blockGroups.push(temp);
        }
      }
    }

    return blockGroups;
  }

  tableContent(mobile) {
    const { getLabel } = this.props;
    const blocks = [
      this.thingToKnowBlock(
        mobile,
        getLabel('plan_ahead_welcome_block_title_1'),
        getLabel('plan_ahead_welcome_block_content_1'),
        imgHealthSafety
      ),
      this.thingToKnowBlock(
        mobile,
        getLabel('plan_ahead_welcome_block_title_2'),
        getLabel('plan_ahead_welcome_block_content_2'),
        imgGear
      ),
      this.thingToKnowBlock(
        mobile,
        getLabel('plan_ahead_welcome_block_title_3'),
        getLabel('plan_ahead_welcome_block_content_3'),
        imgDuration
      ),
      this.thingToKnowBlock(
        mobile,
        getLabel('plan_ahead_welcome_block_title_4'),
        getLabel('plan_ahead_welcome_block_content_4'),
        imgKeepsakes
      )
    ];

    const blockGroups = this.allocateBlocks(blocks, 2, mobile);

    return (
      <React.Fragment>
        {blockGroups.map((blockGroup, i) => {
          return (
            <FlexTr key={i} mobile={mobile}>
              {blockGroup}
            </FlexTr>
          );
        })}
      </React.Fragment>
    );
  }

  renderWelcomeBlock(mobile) {
    const { getLabel } = this.props;
    return (
      <React.Fragment>
        <HeaderBlock mobile={mobile}>
          {getLabel('plan_ahead_welcome_title_1')}
        </HeaderBlock>
        <SubHeaderBlock mobile={mobile}>
          {getLabel('plan_ahead_welcome_title_2')}
        </SubHeaderBlock>
        <ThingsToKnowTable>
          <tbody>{this.tableContent(mobile)}</tbody>
        </ThingsToKnowTable>
      </React.Fragment>
    );
  }

  renderContainer(phone) {
    return (
      <TextContainer mobile={phone}>
        <Desktop>{this.renderWelcomeBlock(false)}</Desktop>
        <Mobile>{this.renderWelcomeBlock(true)}</Mobile>
      </TextContainer>
    );
  }

  render() {
    return (
      <Container id={'welcome-to-dreamscape'}>
        <Large>{this.renderContainer(false)}</Large>
        <Phone>{this.renderContainer(true)}</Phone>
      </Container>
    );
  }
}

export default withRouter(withAppContext(PlanAheadWelcomeBlock));
