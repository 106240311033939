import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile, Large, Phone } from './responsive/Breakpoints';
import styled from 'styled-components';
import ImgMission from '../assets/images/img-mission.svg';
import ImgCulture from '../assets/images/img-culture.svg';
import ImgInnovation from '../assets/images/img-innovation.svg';
import BlueButton from './buttons/BlueButton';
import { withAppContext } from './context/AppContext';

const Container = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  position: relative;
  width: 100%;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${(props) => (props.mobile ? '44px 22px 47px' : '61px 98px')};
  max-width: 1170px;
`;

const HeaderBlock = styled.h5`
  margin: ${(props) => (props.mobile ? '0 auto' : '18px auto 0')};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.33' : '1.09')};
  color: ${(props) => props.theme.charcoal};
  padding-bottom: 29px;
`;

const SubHeaderBlock = styled.h3`
  color: ${(props) => props.theme.charcoal_90};
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.25rem' : '2.25rem')};
  font-weight: normal;
  line-height: ${(props) => (props.mobile ? '1.29' : '1.25')};
  margin: 0 auto ${(props) => (props.mobile ? '' : '61px')};
  max-width: 995px;
`;

const WhyWorkReasons = styled.div`
  text-align: start;
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  ${(props) => (props.mobile ? 'padding-top: 48px; padding-bottom: 8px;' : '')}
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
  background: ${(props) => props.theme.charcoal_25};
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-position: ${(props) => (props.isRtl ? '100% 50%' : '0 50%')};
`;

const Reason = styled.div`
  padding-bottom: ${(props) => (props.mobile ? '40' : '50')}px;
  margin: ${(props) => (!props.mobile && props.middle ? '0 60px' : undefined)};
  vertical-align: top;
  flex: 1;
`;

const ReasonHeader = styled.h3`
  color: ${(props) => props.theme.charcoal_90};
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.375' : '2.25')}rem;
  font-weight: normal;
  line-height: ${(props) => (props.mobile ? '1.29' : '1.19')};
  margin: 6px 0 26px 0;
`;

const ReasonBody = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  line-height: ${(props) => (props.mobile ? '1.33' : '1.33')};
  color: ${(props) => props.theme.charcoal_60};
`;

const JobOpeningButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
  z-index: 0;
`;

class CareersWhyWorkBlock extends Component {
  state = {};

  reasonBlock(mobile, header, body, image, isMiddle) {
    const isRtl = this.props.isRtl();
    return (
      <Reason middle={isMiddle} mobile={mobile} key={header}>
        <ImageContainer isRtl={isRtl} background={image} />
        <Divider />
        <ReasonHeader mobile={mobile}>{header}</ReasonHeader>
        <ReasonBody mobile={mobile}>{body}</ReasonBody>
      </Reason>
    );
  }

  handleOpenLink = (url) => {
    window.open(url, '_blank');
  };

  renderWhyWorkBlock(mobile) {
    const { getLabel } = this.props;
    return (
      <React.Fragment>
        <HeaderBlock mobile={mobile}>
          {getLabel('careers_why_block_title_1')}
        </HeaderBlock>
        <SubHeaderBlock mobile={mobile}>
          {getLabel('careers_why_block_title_2')}
        </SubHeaderBlock>
        <WhyWorkReasons mobile={mobile}>
          {this.reasonBlock(
            mobile,
            getLabel('careers_why_reason_title_1'),
            getLabel('careers_why_reason_content_1'),
            ImgMission
          )}
          {this.reasonBlock(
            mobile,
            getLabel('careers_why_reason_title_2'),
            getLabel('careers_why_reason_content_2'),
            ImgCulture,
            true
          )}
          {this.reasonBlock(
            mobile,
            getLabel('careers_why_reason_title_3'),
            getLabel('careers_why_reason_content_3'),
            ImgInnovation
          )}
        </WhyWorkReasons>
        <JobOpeningButtonContainer>
          <BlueButton
            width={'100%'}
            onClick={() =>
              this.handleOpenLink('https://dreamscape-immersive.breezy.hr')
            }>
            {getLabel('careers_why_block_button_text')}
          </BlueButton>
        </JobOpeningButtonContainer>
      </React.Fragment>
    );
  }

  renderContainer(phone) {
    return (
      <TextContainer mobile={phone}>
        <Desktop>{this.renderWhyWorkBlock(false)}</Desktop>
        <Mobile>{this.renderWhyWorkBlock(true)}</Mobile>
      </TextContainer>
    );
  }

  render() {
    return (
      <Container id="why-work-here">
        <Large>{this.renderContainer(false)}</Large>
        <Phone>{this.renderContainer(true)}</Phone>
      </Container>
    );
  }
}

export default withRouter(withAppContext(CareersWhyWorkBlock));
