import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const StyledHubspotForm = styled.div`
  margin-top: 15px;
  color: ${(props) =>
    props.dark ? 'white' : props.theme.charcoal_90} !important;
  position: relative;

  & form {
    display: flex;
    flex-direction: column;
    margin-right: ${(props) => (props.mobile ? undefined : '136px')};
    flex: 1;
  }

  & form fieldset {
    display: ${(props) => (props.mobile ? 'block' : undefined)};
    text-align: start;
  }

  & .hs-form-field {
    margin-bottom: 0 !important;
    box-sizing: border-box;

    & .input {
      margin-right: 0 !important;
    }
  }

  & .hs-form-field label {
    text-align: start;
    font-size: 0.75rem;
    margin-bottom: 2px;
    color: ${(props) =>
      props.dark ? props.theme.white_80 : undefined} !important;
  }

  & .hs-form-field input {
    appearance: unset;
    width: 100% !important;
    height: 46px;
    box-sizing: border-box !important;
    border: solid 1px
      ${(props) =>
        props.dark
          ? props.theme.ds_bright_blue_50
          : props.theme.ds_bright_blue};
    padding: 14px;
    font-size: max(0.8125rem, 16px);
    border-radius: 3px;
    transition: all 0.25s;
    background-color: transparent;
    color: ${(props) => (props.dark ? props.theme.white_90 : undefined)};

    &:hover {
      border: 3px solid
        ${(props) =>
          props.dark
            ? props.theme.ds_bright_blue_50
            : props.theme.ds_bright_blue};
      padding: 12px;
    }
  }

  & .hs-form-field legend {
    display: none !important;
  }

  & .input {
    display: flex;
    box-sizing: border-box;
  }

  & .hs-error-msgs {
    display: flex !important;
  }

  & .actions {
    text-align: ${(props) => (props.mobile ? 'center' : undefined)};
    margin: 0 !important;
    border: none !important;
    padding: 0 !important;
    background: none !important;
  }

  & .hs_submit {
    margin-top: ${(props) => (props.mobile ? undefined : '24px')};
    position: ${(props) => (props.mobile ? undefined : 'absolute')};
    top: 0;
    right: 0;
  }

  & .hs-email {
    width: ${(props) => (props.mobile ? '100% !important' : '60% !important')};
    margin-right: ${(props) => (props.mobile ? undefined : '16px')};
  }

  & .hs-zip {
    width: ${(props) => (props.mobile ? '100% !important' : '34% !important')};
  }

  & .hs_submit input {
    appearance: button;
    margin-top: ${(props) => (props.mobile ? '20px' : undefined)};
    width: ${(props) => (props.mobile ? '100%' : '119px')};
    height: 47px;
    padding: 0;
    border: 1px solid ${(props) => (props.dark ? 'white' : 'transparent')};
    border-radius: 3px;
    font-size: max(0.8125rem, 16px);
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: ${(props) => props.theme.sans_serif};
    background-color: ${(props) =>
      props.dark ? 'transparent' : props.theme.ds_dusk_blue};
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.25s;
    box-shadow: ${(props) =>
      props.dark ? 'none' : `0 10px 15px 0 ${props.theme.black_30}`};
    box-sizing: border-box !important;
    color: white;

    &:focus {
      border: ${(props) =>
        props.dark
          ? 'none'
          : `1px solid  ${props.theme.ds_bright_blue}`} !important;
      background-color: ${(props) =>
        props.dark ? props.theme.white_70 : undefined} !important;
      box-shadow: ${(props) =>
        props.dark ? undefined : `0 5px 5px 0 ${props.theme.black_30}`};
      color: white;
    }

    &:hover {
      border: ${(props) =>
        props.dark ? `3px solid white` : 'none'} !important;
      background-color: ${(props) => (props.dark ? undefined : '#1e205e')};
      box-shadow: ${(props) =>
        props.dark ? undefined : `0 5px 5px 0 ${props.theme.black_30}`};
      color: ${(props) =>
        props.dark ? undefined : props.theme.ds_bright_blue};
    }

    &:active {
      border: none !important;
      background-color: ${(props) =>
        props.dark ? props.theme.ds_bright_blue : 'white'} !important;
      color: ${(props) => (props.dark ? undefined : props.theme.ds_dusk_blue)};
    }
  }

  & .inputs-list {
    list-style: none;
    padding: 0;

    & li {
      height: 20px;
      display: flex;
      align-items: center;

      & label {
        display: flex;
        align-items: center;

        & input {
          margin-right: 10px;
        }
      }
    }
  }
`;

class ComingSoonHubspotForm extends Component {
  componentDidMount() {
    this.setEmailForm();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
  }

  onMessage = (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmit'
    ) {
      console.info('FORM SUBMITTED');
    }
  };

  setEmailForm = () => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: this.props.portalId,
          formId: this.props.formId,
          target: `#${this.props.id || 'hubspotForm'}`,
          translations: {
            en: {
              fieldLabels: {
                email: 'Email'
              }
            }
          }
        });
      }
    });
    window.addEventListener('message', this.onMessage);
  };

  render() {
    return (
      <StyledHubspotForm
        mobile={this.props.mobile}
        dark={this.props.dark}
        id={this.props.id || 'hubspotForm'}
      />
    );
  }
}

export default withRouter(ComingSoonHubspotForm);
