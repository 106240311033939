import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      viewBox="0 0 16 22"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M8 0c4.418 0 8 3.42 8 7.637 0 2.667-2.4 7.21-7.2 13.627a1 1 0 0 1-1.6 0l-.31-.416C2.297 14.65 0 10.246 0 7.637 0 3.419 3.582 0 8 0zm0 4.444a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.334z"
      />
    </svg>
  );
};
