import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  SchedulerNatural,
  SchedulerUndersized
} from '../components/responsive/Breakpoints';
import { withAppContext } from './context/AppContext';
import Checkbox from './forms/Checkbox';
import ADAIcon from './svgs/ADAIcon';
import theme from '../util/theme';

const ShowHandicapHeader = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-weight: bold;
  font-size: 1rem;
  color: ${(props) => props.theme.charcoal_90};
`;

const ShowHandicapBody = styled.div`
  ${(props) => (props.mobile ? '' : 'width: 200px;')}
  margin-inline-start: auto;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
  color: ${(props) => props.theme.charcoal_60};
`;

const HandicapText = styled.div`
  text-align: start;
  display: inline-block;
`;

const AdaContainer = styled.div`
  padding: ${(props) => (props.mobile ? '24px 0 0 0' : '12px 22px 0 22px')};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.mobile ? 'flex-start' : 'flex-end')};
  max-width: ${(props) => (props.mobile ? '312px' : undefined)};
  margin: ${(props) => (props.mobile ? '0 auto' : undefined)};
`;

const AccessibilityIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-inline-end: 16px;
`;

const ADABlock = (props) => {
  const CheckBox = <Checkbox checked={props.ada} onClick={props.toggleAda} />;

  const handicapArea = (mobile) => {
    return (
      <HandicapText>
        <ShowHandicapHeader>
          {props.getLabel('ada_block_title')}
        </ShowHandicapHeader>
        <ShowHandicapBody mobile={mobile}>
          {props.getLabel('ada_block_body')}
        </ShowHandicapBody>
      </HandicapText>
    );
  };

  return (
    <React.Fragment>
      <SchedulerNatural>
        <AdaContainer>
          {CheckBox}
          <AccessibilityIconContainer>
            <ADAIcon size={24} color={theme.charcoal_90} />
          </AccessibilityIconContainer>
          {handicapArea(false)}
        </AdaContainer>
      </SchedulerNatural>
      <SchedulerUndersized>
        <AdaContainer mobile>
          {CheckBox}
          <AccessibilityIconContainer>
            <ADAIcon size={24} color={theme.charcoal_90} />
          </AccessibilityIconContainer>
          {handicapArea(true)}
        </AdaContainer>
      </SchedulerUndersized>
    </React.Fragment>
  );
};

export default withRouter(withAppContext(ADABlock));
