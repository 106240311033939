import React, { Component, lazy, Suspense } from 'react';
import { withAppContext } from '../../components/context/AppContext';

const ReceiptEn = lazy(() => import('./en'));
const ReceiptAr = lazy(() => import('./ar'));

class PrintReceipt extends Component {
  render() {
    return this.props.locale === 'ar' ? (
      <Suspense fallback={null}>
        <ReceiptAr />
      </Suspense>
    ) : (
      <Suspense fallback={null}>
        <ReceiptEn />
      </Suspense>
    );
  }
}

export default withAppContext(PrintReceipt);
