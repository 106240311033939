import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import CareersHeroBlock from '../components/CareersHeroBlock';
import CareersWhyWorkBlock from '../components/CareersWhyWorkBlock';

const PageContainer = styled.div`
  height: auto;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class Careers extends Component {
  render() {
    return (
      <PageContainer name="page-container">
        <CareersHeroBlock />
        <CareersWhyWorkBlock />
      </PageContainer>
    );
  }
}

export default withRouter(Careers);
