import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { AuthContext } from '../components/context/AuthenticatorContext';
import { DIApi } from '../util/api';
import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import Moment from 'moment';
import BlueButton from '../components/buttons/BlueButton';
import { BasicInputField as Input } from '../components/forms';
import LoadingSpinner from '../components/LoadingSpinner';
import BlueOutlineButton from '../components/buttons/BlueOutlineButton';
import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';
import PhoneInputField from '../components/forms/PhoneInputField';
import { withAuth0 } from '@auth0/auth0-react';
import { withAppContext } from '../components/context/AppContext';

const Container = styled.div`
  max-width: 800px;
  margin: ${(props) => (props.mobile ? '17' : '39')}px auto 0;
  padding-bottom: 7px;
`;

const HalfInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
`;

const ButtonContainer = styled.div`
  padding-top: ${(props) => (props.mobile ? '25' : '35')}px;
  padding-bottom: ${(props) => (props.mobile ? '40' : '73')}px;
  text-align: center;
`;

const Button = styled(BlueButton)`
  color: #fff;
  width: ${(props) => (props.mobile ? '100%' : '256px')};
  margin: ${(props) => (props.mobile ? '0 0 16px 0;' : 'auto 0')};
`;

const CancelButton = styled(BlueOutlineButton)`
  color: #000;
  width: ${(props) => (props.mobile ? '100%' : '256px')};
  margin-top: ${(props) => (props.mobile ? '0' : '15')}px;
`;

const FieldContainer = styled.div`
  ${(props) => (props.mobile ? 'width: 100%;' : '')}

  > div {
    width: ${(props) => (props.mobile ? '100%' : '392px')};
  }
`;

class AccountDetails extends Component {
  state = {
    currentUser: null,
    email: '',
    firstName: '',
    lastName: '',
    dob: '',
    phone: '',
    isSaving: false
  };

  isActive = false;
  headerText = null;

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    this.isActive = false;
  }

  componentWillMount() {
    this.handleRedirect();
    this.props.updateHeaderText({
      title: 'ACCOUNT DETAILS',
      subTitle: ''
    });
  }

  componentDidUpdate() {
    this.handleRedirect();
    this.setDataOrRedirect();
  }

  async setDataOrRedirect() {
    if (this.isActive || !this.props.auth0.isAuthenticated || this.props.auth0.isLoading) {
      return;
    }


    const currentUser = this.props.authState.currentUser

    if (this.props.auth0.isAuthenticated && currentUser !== null && currentUser.email) {
      this.isActive = true;
      const phoneNumber = currentUser['https://profile/mobile']
      /*if (
        currentUser['https://profile/mobile'] &&
        currentUser['https://profile/mobile'].startsWith('+')
      ) {
        phoneNumber =
        currentUser['https://profile/mobile'].slice(1);
      }*/
      this.setState({
        currentUser,
        email: currentUser.email,
        firstName: currentUser['https://profile/firstName'],
        lastName: currentUser['https://profile/lastName'],
        dob: currentUser['https://profile/dateOfBirth'],
        phone: new AsYouType('US').input(
          phoneNumber ?? ''
        )
      });
    } else {
      this.navToSignIn();
    }
  }

  handleRedirect() {
    if (
      !this.props.auth0.isLoading &&
      !this.props.auth0.isAuthenticated
    ) {
      this.navToSignIn();
    }
  }

  navToSignIn = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/account/signin', locationState);
  };

  handleSave = async () => {
    const { firstName, lastName, dob, phone } = this.state;

    if (firstName.length <= 0) {
      alert('First name is required.');
      return;
    }

    if (lastName.length <= 0) {
      alert('Last name is required.');
      return;
    }

    if (!dob || dob.length <= 0) {
      alert('Date of birth is required.');
      return;
    }

    if (Moment().diff(dob, 'years') < 13) {
      alert('You must be at least 13 years of age to create an account.');
      return;
    }

    if (Moment().diff(dob, 'years') > 100) {
      alert(
        'An invalid date of birth was entered. Please check the date and try again.'
      );
      return;
    }

    try {
      this.setState({ isSaving: true });

      const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;

      const token = isAuthenticated
        ? await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid offline_access email profile'
          })
      : null;

      const apiArgs = [
        this.state.email,
        this.state.firstName,
        this.state.lastName,
        Moment(this.state.dob).format('YYYY-MM-DD'),
        token
      ];

      if (phone) {
        const phoneNumber = parsePhoneNumber(this.state.phone, 'US');
        if (!phoneNumber.isValid()) {
          throw new Error('Invalid phone number entered.');
        }
        const phone = phoneNumber.format('E.164');
        apiArgs.push(phone);
      }


      await DIApi.userEdit(...apiArgs);
      getAccessTokenSilently({
        ignoreCache: true
      });


      this.props.authState.handleAuthStateChange('signedIn');
      const locationState = this.props.location.state;
      this.props.history.replace('/account/details', locationState);
    } catch (e) {
      this.setState({ isSaving: false });
      if (e.message === 'NOT_A_NUMBER' || e.message === 'TOO_LONG') {
        alert('You must enter a valid phone number format.');
      } else if (typeof e === 'string') {
        alert(e);
      } else {
        alert(e.message);
      }
    }
  };

  renderSaveButton = (mobile) => {
    const buttonContent = this.state.isSaving ? <LoadingSpinner /> : 'SAVE';

    return (
      <Button
        mobile={mobile}
        disabled={this.state.isSaving}
        onClick={this.handleSave}
      >
        {buttonContent}
      </Button>
    );
  };

  accountFields = (mobile) => {
    const rawInputRows = [
      <Input
        autoFocus
        placeholder={this.props.getLabel('account_details_first_name')}
        value={this.state.firstName}
        onChange={(event) => this.setState({ firstName: event.target.value })}
      />,
      <Input
        placeholder={this.props.getLabel('account_details_last_name')}
        value={this.state.lastName}
        onChange={(event) => this.setState({ lastName: event.target.value })}
      />,
      <Input
        placeholder={this.props.getLabel('account_details_dob')}
        type="date"
        value={this.state.dob}
        onChange={(event) => this.setState({ dob: event.target.value })}
      />,
      <PhoneInputField
        placeholder={this.props.getLabel('account_details_phone')}
        value={this.state.phone}
        changeCallback={(newValue) => this.setState({ phone: newValue })}
      />
    ];

    const inputRows = rawInputRows.map((row) => (
      <FieldContainer mobile={mobile}>{row}</FieldContainer>
    ));

    let inputRowGroups = inputRows;

    if (!mobile) {
      inputRowGroups = [
        <React.Fragment>
          {inputRows[0]}
          {inputRows[1]}
        </React.Fragment>,
        <React.Fragment>
          {inputRows[2]}
          {inputRows[3]}
        </React.Fragment>
      ];
    }

    return inputRowGroups.map((inputRow, i) => {
      return <HalfInputContainer key={i}>{inputRow}</HalfInputContainer>;
    });
  };

  renderDetailsPage(mobile) {
    if (!this.state.currentUser) {
      return (
        <div className="spinner" style={{ paddingBottom: '90px' }}>
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      );
    }

    return (
      <Container mobile={mobile}>
        {this.accountFields(mobile)}

        <ButtonContainer mobile={mobile}>
          {this.renderSaveButton(mobile)}
          <br />
          <CancelButton
            mobile={mobile}
            onClick={() => this.props.history.push('/account/details')}>
            {this.props.getLabel('account_details_cancel')}
          </CancelButton>
        </ButtonContainer>
      </Container>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Desktop>{this.renderDetailsPage(false)}</Desktop>
        <Mobile>{this.renderDetailsPage(true)}</Mobile>
      </React.Fragment>
    );
  }
}

const Details = (props) => (
  <AuthContext.Consumer>
    {(authState) => {
      return <AccountDetails {...props} authState={authState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAuth0(withAppContext(Details)));
