import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Desktop,
  Mobile,
  Tablet,
  Phone
} from '../components/responsive/Breakpoints';
import styled from 'styled-components';

import NewsletterForm from './forms/NewsletterForm';
import { WhiteOutlineButton, BlueButton } from './buttons';
import theme from '../util/theme';
import { scroller } from 'react-scroll';
import { TOASTER_NOTIFICATION_HEIGHT } from '../util/constants';
import { withAppContext } from './context/AppContext';

const ImageHero = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: ${(props) => (props.mobile ? '445px' : '570px')};
  align-items: center;
  padding-top: ${(props) => (props.mobile ? '206px' : '237px')};
  text-align: center;
  background-image: url('${(props) => props.image}');
  background-position: center;
  background-size: cover;
`;

const Body = styled.div`
  height: auto;
  width: 100%;
  max-width: 1270px;
  margin: 33px auto 51px auto;
  box-sizing: border-box;
  display: flex;
  ${(props) => (props.mobile ? 'flex-direction: column;' : '')}
  ${(props) => (props.desktop ? 'justify-content: center;' : '')}
  padding: ${(props) => (props.mobile ? '0 22px' : '0 50px')};
`;

const HeaderSmall = styled.h3`
  font-size: ${(props) => (props.mobile ? '0.8125rem' : '1.25rem')};
  letter-spacing: 2px;
  margin: 0;
`;

const HeaderLarge = styled.h1`
  font-size: ${(props) => (props.mobile ? '1.625rem' : '3.5rem')};
  ${(props) => (props.mobile ? 'width: 315px;' : '')}
  letter-spacing: 2px;
  margin: 0;
`;

const HeaderLocation = styled.h1`
  font-size: ${(props) => (props.mobile ? '1.25rem' : '2.75rem')};
  letter-spacing: 2px;
  margin: 0;
`;

const ContentBlockLeft = styled.div`
  border-top: 1px solid ${(props) => props.theme.white_70};
  flex: 1;
  margin-inline-end: 78px;
`;

const ContentBlockRight = styled.div`
  flex: 1;
`;

const ContentHeader = styled.h1`
  font-size: ${(props) => (props.mobile ? '1.375rem' : '2.25rem')};
  font-weight: normal;
  font-family: ${(props) => props.theme.serif};
  margin: 14px 0 16px 0;
`;

const ContentSubHeader = styled.h3`
  margin: ${(props) => (props.mobile ? '8px 0 0 0' : '0')};
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
`;

const HoursList = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  max-width: 336px;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${(props) => props.theme.white_70};
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  margin-top: ${(props) => (props.mobile ? '6px' : '2px')};
`;

const ContentLine = styled.p`
  color: ${(props) => props.theme.white_70};
  margin: 5px 0 0;
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  a {
    [dir='rtl'] & {
      direction: ltr;
      text-align: right;
      unicode-bidi: bidi-override;
    }
  }
`;

const StyledGreyLinkTag = styled(Link)`
  color: ${(props) => props.theme.white_70};
  margin-right: 16px;
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem`')};
  text-decoration: underline;
`;

class LocationDetails extends Component {
  scrollPage = (id, val, offset) => {
    scroller.scrollTo(id, {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset
    });
  };

  handleScroll = (elementId) => {
    const toasterOffset = this.props.hasStoredSite
      ? 0
      : TOASTER_NOTIFICATION_HEIGHT;
    this.scrollPage(
      elementId,
      document.getElementById('location-home').offsetHeight,
      -document.getElementById('navbar').offsetHeight - toasterOffset
    );
  };

  handleNavigation = (page) => {
    return this.props.history.push(page);
  };

  render() {
    const { locationData, site, getLabel } = this.props;

    const copy = locationData.copy;
    const images = locationData.images.landing;
    let address = `${site.street_address}<br/> ${site.city}${
      this.props.locale === 'ar' ? ' ،' : ','
    } ${site.state}`;
    if (site.postal_code) {
      address += ` ${site.postal_code}`;
    }
    // Consolidate static info with the info received from the API
    const siteInfo = {
      address,
      name: copy.display_name.toUpperCase(),
      city: site.city,
      phone: copy.phone,
      directions: copy.directions,
      hours: copy.hours
    };

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <Desktop>
          <ImageHero image={images.desktop.x2}>
            <HeaderSmall>{copy.subHeaderCopy.toUpperCase()}</HeaderSmall>
            <HeaderLarge>{siteInfo.name}</HeaderLarge>
            <HeaderLocation>{`${site.city.toUpperCase()}, ${
              site.state
            }`}</HeaderLocation>
          </ImageHero>
          <Body desktop={true}>
            <ContentBlockLeft>
              <ContentHeader>
                {getLabel('location_details_destination')} {siteInfo.city}
              </ContentHeader>
              <ContentSubHeader>{siteInfo.name}</ContentSubHeader>
              <ContentLine
                dangerouslySetInnerHTML={{
                  __html: siteInfo.address
                }}></ContentLine>
              <ContentLine>
                <a
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  href={`tel:${siteInfo.phone}`}>
                  {siteInfo.phone}
                </a>
              </ContentLine>
              <ContentLine>{siteInfo.directions}</ContentLine>
              <div style={{ marginTop: 20 }}>
                <StyledGreyLinkTag to={'/contact-us'}>
                  {getLabel('location_details_contact_us')}
                </StyledGreyLinkTag>
                <StyledGreyLinkTag to={'/faq'}>
                  {getLabel('location_details_faq')}
                </StyledGreyLinkTag>
              </div>
              <div style={{ display: 'flex', marginTop: 35 }}>
                <div style={{ flex: 1 }}>
                  <BlueButton
                    width={'100%'}
                    onClick={() => this.handleNavigation('/adventures')}>
                    {getLabel('location_details_adventure_btn')}
                  </BlueButton>
                </div>
                <div style={{ flex: 1, marginInlineStart: 20 }}>
                  {/* <WhiteOutlineButton
                    width={'100%'}
                    onClick={() =>
                      this.handleScroll('location-private-events')
                    }>
                    {getLabel('location_details_private_event_btn')}
                  </WhiteOutlineButton> */}
                </div>
              </div>
            </ContentBlockLeft>
            <ContentBlockRight>
              <ContentSubHeader style={{ marginBottom: 4 }}>
                {getLabel('location_details_store_hours')}
              </ContentSubHeader>
              <HoursList>
                {siteInfo.hours.map((item) => (
                  <ListItem key={item.day}>
                    <span>{item.day}</span>
                    <span>{item.time}</span>
                  </ListItem>
                ))}
              </HoursList>
              <ContentSubHeader>
                {getLabel('findus_section_subscribe_header')}
              </ContentSubHeader>
              <ContentLine>
                {getLabel('findus_section_subscribe_content')}
              </ContentLine>
              <br />
              <NewsletterForm dark maxWidth={'337'} widthPercent={'25'} />
            </ContentBlockRight>
          </Body>
        </Desktop>

        <Mobile>
          <ImageHero mobile={true} image={images.mobile.x2}>
            <HeaderSmall mobile={true}>
              {copy.subHeaderCopy.toUpperCase()}
            </HeaderSmall>
            <HeaderLarge mobile={true}>{siteInfo.name}</HeaderLarge>
            <HeaderLocation mobile={true}>{`${site.city.toUpperCase()}, ${
              site.state
            }`}</HeaderLocation>
          </ImageHero>
        </Mobile>

        <Tablet>
          <Body style={{ justifyContent: 'space-between' }}>
            <ContentBlockLeft
              style={{
                marginRight: '44px',
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
              }}>
              <ContentHeader mobile style={{ marginTop: 0, paddingTop: 12 }}>
                {getLabel('location_details_destination')} {siteInfo.city}
              </ContentHeader>
              <ContentSubHeader style={{ marginTop: 16 }} mobile>
                {siteInfo.name}
              </ContentSubHeader>
              <ContentLine
                mobile
                dangerouslySetInnerHTML={{
                  __html: siteInfo.address
                }}></ContentLine>
              <ContentLine mobile>
                <a
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  href={`tel:${siteInfo.phone}`}>
                  {siteInfo.phone}
                </a>
              </ContentLine>
              <ContentLine mobile>{siteInfo.directions}</ContentLine>
              <div style={{ marginTop: 20 }}>
                <StyledGreyLinkTag to={'/contact-us'}>
                  {getLabel('location_details_contact_us')}
                </StyledGreyLinkTag>
                <StyledGreyLinkTag to={'/faq'}>
                  {getLabel('location_details_faq')}
                </StyledGreyLinkTag>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 35
                }}>
                <BlueButton
                  width={'100%'}
                  onClick={() => this.handleNavigation('/adventures')}>
                  {getLabel('location_details_adventure_btn')}
                </BlueButton>
                {/* <WhiteOutlineButton
                  width={'100%'}
                  style={{ marginTop: 16 }}
                  onClick={() => this.handleScroll('location-private-events')}>
                  {getLabel('location_details_private_event_btn')}
                </WhiteOutlineButton> */}
              </div>
            </ContentBlockLeft>
            <ContentBlockRight style={{ flex: 1 }}>
              <ContentSubHeader mobile style={{ margin: '0' }}>
                {getLabel('location_details_store_hours')}
              </ContentSubHeader>
              <HoursList>
                {siteInfo.hours.map((item) => (
                  <ListItem key={item.day} mobile>
                    <span>{item.day}</span>
                    <span>{item.time}</span>
                  </ListItem>
                ))}
              </HoursList>
              <ContentSubHeader mobile>
                {getLabel('findus_section_subscribe_header')}
              </ContentSubHeader>
              <ContentLine mobile>
                {getLabel('findus_section_subscribe_content')}
              </ContentLine>
              <br />
              <NewsletterForm dark mobile />
            </ContentBlockRight>
          </Body>
        </Tablet>

        <Phone>
          <Body mobile={true}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderTop: `1px solid ${theme.white_70}`
              }}>
              <ContentHeader
                mobile={true}
                style={{
                  marginTop: '0',
                  paddingTop: '15px'
                }}>{`${getLabel('location_details_destination')} ${
                siteInfo.city
              }`}</ContentHeader>
              <ContentSubHeader style={{ marginTop: 16 }} mobile={true}>
                {siteInfo.name}
              </ContentSubHeader>
              <ContentLine
                mobile={true}
                dangerouslySetInnerHTML={{
                  __html: siteInfo.address
                }}></ContentLine>
              <ContentLine mobile={true}>
                <a
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  href={`tel:${siteInfo.phone}`}>
                  {siteInfo.phone}
                </a>
              </ContentLine>
              <ContentLine mobile={true}>{siteInfo.directions}</ContentLine>
              <div style={{ marginTop: 20 }}>
                <StyledGreyLinkTag to={'/contact-us'}>
                  {getLabel('location_details_contact_us')}
                </StyledGreyLinkTag>
                <StyledGreyLinkTag to={'/faq'}>
                  {getLabel('location_details_faq')}
                </StyledGreyLinkTag>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 35
                }}>
                <BlueButton
                  width={'100%'}
                  onClick={() => this.handleNavigation('/adventures')}>
                  {getLabel('location_details_adventure_btn')}
                </BlueButton>
                {/* <WhiteOutlineButton
                  width={'100%'}
                  style={{ marginTop: 16 }}
                  onClick={() => this.handleScroll('location-private-events')}>
                  {getLabel('location_details_private_event_btn')}
                </WhiteOutlineButton> */}
              </div>
              <ContentSubHeader style={{ marginTop: 35 }} mobile={true}>
                {getLabel('location_details_store_hours')}
              </ContentSubHeader>
              <HoursList>
                {siteInfo.hours.map((item) => (
                  <ListItem key={item.day} mobile={true}>
                    <span>{item.day}</span>
                    <span>{item.time}</span>
                  </ListItem>
                ))}
              </HoursList>
              <ContentSubHeader mobile={true}>
                {getLabel('findus_section_subscribe_header')}
              </ContentSubHeader>
              <ContentLine mobile={true}>
                {getLabel('findus_section_subscribe_content')}
              </ContentLine>
              <br />
              <NewsletterForm dark mobile />
            </div>
          </Body>
        </Phone>
      </div>
    );
  }
}

export default withRouter(withAppContext(LocationDetails));
