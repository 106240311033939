import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile, Large, Phone } from './responsive/Breakpoints';
import styled from 'styled-components';
import ImgAgeHeight from '../assets/images/img-age-height.svg';
import ImgShoes from '../assets/images/img-attire.svg';
import ImgAttire from '../assets/images/img-attire-clothes.svg';
import ImgPhotography from '../assets/images/img-photography.svg';
import ImgWheelchair from '../assets/images/img-wheelchair.svg';
import ImgLegal from '../assets/images/img-legal.png';
import BlueButton from './buttons/BlueButton';
import { withAppContext } from './context/AppContext';

const Container = styled.div`
  background: #fff;
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${(props) => (props.mobile ? '30px 22px' : '45px 98px 20px')};
  max-width: 1170px;
`;

const HeaderBlock = styled.h5`
  color: ${(props) => props.theme.charcoal};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
  line-height: ${(props) => (props.mobile ? '1.33' : '1.09')};
  padding-bottom: ${(props) => (props.mobile ? '27px' : '36px')};
  margin: 0;
`;

const SubHeaderBlock = styled.h3`
  max-width: 700px;
  margin: 0 auto;
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.25rem' : '2.25rem')};
  font-weight: normal;
  line-height: ${(props) => (props.mobile ? '1.37' : '1.25')};
  color: ${(props) => props.theme.charcoal_90};
`;

const RulesTable = styled.table`
  width: 100%;
  text-align: start;
  table-layout: fixed;
  margin-top: ${(props) => (props.mobile ? '38' : '48')}px;
`;

const SpacerTd = styled.td`
  width: 22px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.charcoal_25};
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-position: ${(props) => (props.isRtl ? '100% 50%' : '0 50%')};
`;

const Rule = styled.td`
  padding-bottom: ${(props) => (props.mobile ? '22px' : undefined)};
  vertical-align: top;
`;

const RuleHeader = styled.h4`
  color: ${(props) => props.theme.charcoal_90};
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.375rem' : '2.25rem')};
  font-weight: normal;
  line-height: ${(props) => (props.mobile ? '1.64' : '1.19')};
  margin: 18px 0 18px 0;
`;

const RuleBody = styled.div`
  color: ${(props) => props.theme.charcoal_60};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  line-height: 1.33;
`;

const FAQButton = styled(BlueButton)`
  margin: ${(props) => (props.mobile ? '57px 0 20px' : '76px 0 59px')};
  ${(props) => (props.mobile ? 'width: 100%;' : '')}
`;

class PlanAheadRulesBlock extends Component {
  state = {};

  ruleBlock(mobile, header, body, image) {
    const isRtl = this.props.isRtl();
    return (
      <Rule key={header} mobile={mobile}>
        <ImageContainer isRtl={isRtl} background={image} />
        <Divider />
        <RuleHeader mobile={mobile}>{header}</RuleHeader>
        <RuleBody
          mobile={mobile}
          dangerouslySetInnerHTML={{ __html: body }}></RuleBody>
      </Rule>
    );
  }

  allocateBlocks(blocks, blocksPerRow, mobile) {
    let blockGroups = blocks;

    if (!mobile) {
      blockGroups = [];
      let temp = [];
      for (let i = 0; i < blocks.length; i++) {
        if (temp.length > 0) {
          temp.push(<SpacerTd key={i} />);
        }
        temp.push(blocks[i]);
        if ((i + 1) % blocksPerRow === 0) {
          blockGroups.push(temp);
          temp = [];
        }
        if (i === blocks.length - 1 && temp.length > 0) {
          for (let j = temp.length; j < blocksPerRow; j++) {
            temp.push(<SpacerTd key={'spacer' + i} />);
            temp.push(<td key={'block' + i} style={{ flex: '1' }} />);
          }
          blockGroups.push(temp);
        }
      }
    }

    return blockGroups;
  }

  tableContent(mobile) {
    const { getLabel } = this.props;
    const blocks = [
      this.ruleBlock(
        mobile,
        getLabel('plan_ahead_rules_block_title_1'),
        getLabel('plan_ahead_rules_block_content_1'),
        ImgAgeHeight
      ),
      this.ruleBlock(
        mobile,
        getLabel('plan_ahead_rules_block_title_2'),
        getLabel('plan_ahead_rules_block_content_2'),
        ImgShoes
      ),
      this.ruleBlock(
        mobile,
        getLabel('plan_ahead_rules_block_title_3'),
        getLabel('plan_ahead_rules_block_content_3'),
        ImgAttire
      ),
      this.ruleBlock(
        mobile,
        getLabel('plan_ahead_rules_block_title_4'),
        getLabel('plan_ahead_rules_block_content_4'),
        ImgPhotography
      ),
      this.ruleBlock(
        mobile,
        getLabel('plan_ahead_rules_block_title_5'),
        getLabel('plan_ahead_rules_block_content_5'),
        ImgWheelchair
      ),
      this.ruleBlock(
        mobile,
        getLabel('plan_ahead_rules_block_title_6'),
        getLabel('plan_ahead_rules_block_content_6'),
        ImgLegal
      )
    ];

    const blockGroups = this.allocateBlocks(blocks, 4, mobile);

    return (
      <React.Fragment>
        {blockGroups.map((blockGroup, i) => {
          return <tr key={i}>{blockGroup}</tr>;
        })}
      </React.Fragment>
    );
  }

  renderRulesBlock(mobile) {
    const { getLabel } = this.props;
    return (
      <React.Fragment>
        <HeaderBlock mobile={mobile}>
          {getLabel('plan_ahead_rules_title_1')}
        </HeaderBlock>
        <SubHeaderBlock mobile={mobile}></SubHeaderBlock>
        <RulesTable mobile={mobile}>
          <tbody>{this.tableContent(mobile)}</tbody>
        </RulesTable>
        <FAQButton
          mobile={mobile}
          onClick={() => this.props.history.push('/faq')}>
          {getLabel('plan_ahead_rules_faq_button_text')}
        </FAQButton>
      </React.Fragment>
    );
  }

  renderContainer(phone) {
    return (
      <TextContainer mobile={phone}>
        <Desktop>{this.renderRulesBlock(false)}</Desktop>
        <Mobile>{this.renderRulesBlock(true)}</Mobile>
      </TextContainer>
    );
  }

  render() {
    return (
      <Container>
        <Large>{this.renderContainer(false)}</Large>
        <Phone>{this.renderContainer(true)}</Phone>
      </Container>
    );
  }
}

export default withRouter(withAppContext(PlanAheadRulesBlock));
