import React from 'react';
import styled from 'styled-components';

import AdventureThumbnail from './AdventureThumbnail';
import { withAppContext } from './context/AppContext';

const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${(props) => (props.isMobile ? '100%' : '317px')};
  max-width: ${(props) => (props.isMobile ? '360px' : undefined)};
  margin-inline-end: ${(props) => (props.isMobile ? undefined : '70px')};
  padding: ${(props) => (props.isMobile ? '0 22px' : undefined)};
  box-sizing: ${(props) => (props.isMobile ? 'border-box' : undefined)};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.charcoal_25};
  margin: 16px 0;
  width: 100%;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsRow = styled.div`
  display: flex;
`;

const DetailLabel = styled.div`
  flex: 1;
  font-size: 1rem;
  letter-spacing: -0.5px;
  color: ${(props) => props.theme.charcoal_60};
`;

const DetailValue = styled.div`
  flex: 1;
  letter-spacing: -0.5px;
  font-size: 1rem;
  margin-inline-start: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.charcoal_90};
  ${(props) =>
    props.isRtl ? 'direction: ltr; text-align:right;margin:0 12px 0 0;' : ''}
`;

function renderTicketFees(ticketFees, isRtl) {
  if (!ticketFees) {
    return null;
  }

  return ticketFees.map((fee, index) => {
    return (
      <DetailsRow key={`fee-type-${index}`}>
        <DetailLabel>{fee.name}</DetailLabel>
        <DetailValue isRtl={isRtl}>{fee.amountLabel}</DetailValue>
      </DetailsRow>
    );
  });
}

const CheckoutOrderDetails = (props) => {
  if (!props.orderDetails) {
    return null;
  }

  const {
    location,
    time,
    ada,
    totalCost,
    ticketCostBreakdown,
    giftDeduction,
    promoDeduction,
    ticketFees
  } = props.orderDetails;

  const shareDiscount = props.shareDiscount || null;

  const isRtl = props.isRtl();

  return (
    <OrderDetailsContainer isMobile={props.isMobile}>
      <AdventureThumbnail ada={ada} titleId={props.titleId} />

      <Divider />

      <DetailsContainer>
        <DetailsRow>
          <DetailLabel>{props.getLabel('checkout_booking_number')}</DetailLabel>
          <DetailValue>{props.shortCode || '-'}</DetailValue>
        </DetailsRow>
        <DetailsRow>
          <DetailLabel>{props.getLabel('checkout_location')}</DetailLabel>
          <DetailValue>{location}</DetailValue>
        </DetailsRow>
        <DetailsRow>
          <DetailLabel>{props.getLabel('checkout_time')}</DetailLabel>
          <DetailValue>{time}</DetailValue>
        </DetailsRow>
        {ada ? (
          <DetailsRow>
            <DetailLabel>{props.getLabel('checkout_accessible')}</DetailLabel>
            <DetailValue>
              {props.getLabel('checkout_accessible_yes')}
            </DetailValue>
          </DetailsRow>
        ) : null}
        <DetailsRow>
          <DetailLabel>{props.getLabel('checkout_tickets')}</DetailLabel>
          <DetailValue isRtl={isRtl}>{ticketCostBreakdown}</DetailValue>
        </DetailsRow>
        {shareDiscount ? (
          <DetailsRow>
            <DetailLabel>{props.getLabel('checkout_share_discount')}</DetailLabel>
            <DetailValue isRtl={isRtl}>{shareDiscount}</DetailValue>
          </DetailsRow>
        ) : null}
        {renderTicketFees(ticketFees, isRtl)}
        {giftDeduction ? (
          <DetailsRow>
            <DetailLabel>{props.getLabel('checkout_gift_card')}</DetailLabel>
            <DetailValue isRtl={isRtl}>{giftDeduction}</DetailValue>
          </DetailsRow>
        ) : null}

        {promoDeduction ? (
          <DetailsRow>
            <DetailLabel>{props.getLabel('checkout_promo_code')}</DetailLabel>
            <DetailValue isRtl={isRtl}>{promoDeduction}</DetailValue>
          </DetailsRow>
        ) : null}
        <DetailsRow>
          <DetailLabel>{props.getLabel('checkout_total')}</DetailLabel>
          <DetailValue isRtl={isRtl}>{totalCost}</DetailValue>
        </DetailsRow>
      </DetailsContainer>
    </OrderDetailsContainer>
  );
};

export default withAppContext(CheckoutOrderDetails);
