import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Desktop, Mobile } from '../responsive/Breakpoints';
import HomeAdventuresCarousel from '../carousels/HomeAdventuresCarousel';
import { MOBILE_ADVENTURE_CARD_WIDTH } from '../../util/constants';
import AdventureItem from '../content/AdventureItem';

import { withAppContext } from '../context/AppContext';

import homeShowPanelLoading from '../../assets/images/home-show-panel-loading.svg';
import homeShowPanelMobileLoading from '../../assets/images/home-show-panel-mobile-loading.svg';

const SectionContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: ${(props) => (props.mobile ? '585px' : '712px')};
  padding: ${(props) => (props.mobile ? '28px 0' : '56px 0')};
  width: 100%;
`;

const AdventureTitleSection = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '1.625rem' : '2.75rem')};
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.31' : '1.09')};
  text-align: center;
  letter-spacing: -1px;
  color: ${(props) =>
    props.isLoading ? props.theme.charcoal_15 : props.theme.charcoal};
  padding: ${(props) =>
    props.mobile ? '0 18px 26px 18px' : '0 22px 56px 22px'};
`;

const MobileAdvItemWrapper = styled.div`
  display: flex !important;
  justify-content: center !important;
`;

class AdventuresSection extends Component {
  navDetails(adventure, toAvailability, toQuoteBlock) {
    this.props.history.push(`/adventures/details/${adventure.title_id}`, {
      toAvailability,
      toQuoteBlock
    });
  }

  renderAdventureItems(isLoading, isMobile) {
    const { adventures, cms, site, getLabel } = this.props;
    if (isLoading) {
      const placeHolderArr = [];
      for (let index = 0; index < 4; index++) {
        const card = isMobile ? (
          <MobileAdvItemWrapper key={index}>
            <img
              alt=""
              style={{ width: '360px' }}
              src={homeShowPanelMobileLoading}
            />
          </MobileAdvItemWrapper>
        ) : (
          <img
            alt=""
            style={{ width: '360px' }}
            key={index + '-' + Date.now()}
            src={homeShowPanelLoading}
          />
        );
        placeHolderArr.push(card);
      }
      return placeHolderArr;
    }
    let availableAdventures = adventures;
    if (site.site_id) {
      availableAdventures = adventures.filter((adventure) => {
        return adventure.isAvailableAtCurrentLocation;
      });
    }

    return availableAdventures.map((adventure) => {
      const isAvailable = !!adventure.isAvailableAtCurrentLocation;
      const adventureAssets = cms
        ? cms.getAdventureAssets(adventure.title_id, site.site_id)
        : {};
      if (isMobile) {
        return (
          <MobileAdvItemWrapper key={adventure.title_id}>
            <AdventureItem
              isMobile={isMobile}
              adventure={adventure}
              assets={adventureAssets}
              site={site}
              isAvailable={isAvailable}
              buttonText={
                isAvailable
                  ? getLabel('adventures_button_get_tickets')
                  : getLabel('adventures_button_learn_more')
              }
              // onClick={ this.navDetails }
              // posterOnClick={ () => this.navDetails(adventure) }
            />
          </MobileAdvItemWrapper>
        );
      }

      return (
        <AdventureItem
          key={adventure.title_id}
          adventure={adventure}
          assets={adventureAssets}
          site={site}
          isAvailable={isAvailable}
          // onClick={ this.navDetails }
          // posterOnClick={ this.navDetails }
          buttonText={
            isAvailable
              ? getLabel('adventures_button_get_tickets')
              : getLabel('adventures_button_learn_more')
          }
        />
      );
    });
  }

  render() {
    const isLoading =
      !this.props.adventures.length ||
      !Object.keys(this.props.cms.assetsMap.titleAssets).length;
    return (
      <React.Fragment>
        <Desktop>
          <SectionContainer id="adventure">
            <AdventureTitleSection isLoading={isLoading}>
              {this.props.heading}
            </AdventureTitleSection>
            <HomeAdventuresCarousel
              items={this.renderAdventureItems(isLoading)}
            />
          </SectionContainer>
        </Desktop>

        <Mobile>
          <SectionContainer mobile id="adventure">
            <AdventureTitleSection isLoading={isLoading} mobile>
              {this.props.heading}
            </AdventureTitleSection>
            <HomeAdventuresCarousel
              isMobile={true}
              items={this.renderAdventureItems(isLoading, true)}
              itemWidth={MOBILE_ADVENTURE_CARD_WIDTH}
            />
          </SectionContainer>
        </Mobile>
      </React.Fragment>
    );
  }
}

export default withRouter(withAppContext(AdventuresSection));
