import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import ContactUsForm from '../components/forms/ContactUsForm';
import { BlueButton, BlueOutlineButton } from '../components/buttons';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';

const PageContainer = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  max-width: ${(props) => (props.mobile ? '424px' : '1214px')};
  width: 100%;
  padding: ${(props) =>
    props.mobile ? '54px 0 27px 0' : '88px 22px 27px 22px'};
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  padding: ${(props) => (props.mobile ? '0 22px' : '0')};
  flex: 1;
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column-reverse' : 'column')};
`;

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.mobile ? 'center' : 'flex-end')};
  margin: ${(props) => (props.mobile ? '16px 22px 0 22px' : '98px 0 0')};
  margin-inline-start: ${(props) => (props.mobile ? '' : '33px')};
`;

const Header = styled.h1`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '1.625' : '2.75')}rem;
  text-align: ${(props) => (props.mobile ? 'center' : undefined)};
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.33' : undefined)};
  color: ${(props) => props.theme.charcoal};
  padding-top: ${(props) => (props.mobile ? '6' : '98')}px;
  margin: 0 0 5px;
`;

const LocationContactHeader = styled.h2`
  padding-top: ${(props) => (props.mobile ? undefined : '0px')};
  margin: ${(props) => (props.mobile ? '22px 0 0 0' : '0')};
  color: ${(props) => props.theme.charcoal_90};
  font-size: ${(props) => (props.mobile ? '1rem' : '1.375rem')};
  font-weight: bold;
`;

const RegularText = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '0.875' : '1')}rem;
  text-align: ${(props) =>
    props.center && props.mobile ? 'center' : undefined};
  color: ${(props) => props.theme.charcoal_60};
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.ds_dusk_blue};
  cursor: pointer;
  text-decoration: none;
  &[href*='tel:'] {
    direction: ltr;
    unicode-bidi: bidi-override;
  }
`;

const SiteLinksSection = styled.div`
  display: flex;
  flex-direction: column
  width: 100%;
  background-color: #FFF;
  align-items: center;
`;

const SiteLinksSectionContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  max-width: 1214px;
  width: ${(props) => (props.mobile ? 'auto' : '100%')};
  margin: ${(props) => (props.mobile ? '0 22px' : undefined)};
  padding: ${(props) => (props.mobile ? '55px 0' : '75px 22px')};
  box-sizing: border-box;
`;

const SiteLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${(props) => {
    if (props.middle && props.mobile) {
      return '60px 0';
    }

    if (props.middle) {
      return '0 60px';
    }
  }};
  border-top: 1px solid ${(props) => props.theme.charcoal_25};
`;

const SiteLinkTitle = styled.h4`
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.375rem' : '2.25rem')};
  font-weight: normal;
  line-height: ${(props) => (props.mobile ? '1.64' : '1.19')};
  color: ${(props) => props.theme.charcoal_90};
  padding-top: ${(props) => (props.mobile ? '12px' : '20px')};
  margin: 0;
`;

const SiteLinkText = styled(RegularText)`
  padding-top: 12px;
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  flex: 1;
`;

const SiteLinkButtonItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.mobile ? 'center' : undefined)};
`;

const SiteLinkButtonContainer = styled.div`
  flex: 1;
  padding-top: 30px;
  max-width: ${(props) => (props.mobile ? '360px' : '325px')};
`;

const LeftContactSeparator = styled.div`
  border-top: 1px solid ${(props) => props.theme.charcoal_25};
  width: 100%;
  margin: ${(props) => (props.mobile ? '22px 0' : '60px 0')};
`;

const LocationInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.mobile ? 'center' : undefined)};
`;

const LocationButtonContainer = styled.div`
  width: 100%;
  max-width: 360px;
  margin-top: ${(props) => (props.mobile ? '15px' : '26px')};
`;

const PHONE_NUMBERS = {
  Riyadh: '+966112154579'
};

class ContactUsWithContext extends Component {
  handleNavLink = (path) => {
    return this.props.history.push(path);
  };

  renderStoreInfoButtons(isMobile) {
    const { sites, cms } = this.props.parentState;

    const contactList = sites
      .filter((site) => {
        const siteAssets = cms.getSiteAssets(site.site_id);
        return (
          !!Object.keys(siteAssets).length && site.locale === this.props.locale
        );
      })
      .map((site) => {
        const siteAssets = cms.getSiteAssets(site.site_id);
        return {
          text: site.city.toUpperCase(),
          path: siteAssets.copy.site_url_path
        };
      });

    return contactList.map((site, index) => (
      <LocationButtonContainer key={index} mobile={isMobile}>
        <BlueOutlineButton
          invert
          width={isMobile ? '100%' : undefined}
          onClick={() => {
            this.props.history.push(site.path);
          }}>
          {site.text}
        </BlueOutlineButton>
      </LocationButtonContainer>
    ));
  }

  renderContactPage(mobile) {
    const { getLabel } = this.props;
    const phoneContactContent = mobile ? (
      <span>
        {getLabel('contact_us_phone_text')}
        <br />
        <StyledLink href={`tel:${PHONE_NUMBERS.Riyadh}`}>
          ({PHONE_NUMBERS.Riyadh})
        </StyledLink>
        .
      </span>
    ) : (
      <span>
        {getLabel('contact_us_phone_text')}{' '}
        <StyledLink href={`tel:${PHONE_NUMBERS.Riyadh}`}>
          ({PHONE_NUMBERS.Riyadh})
        </StyledLink>
      </span>
    );

    return (
      <PageContainer mobile={mobile}>
        <ContactSection mobile={mobile}>
          <TextContainer mobile={mobile}>
            <div>
              <Header mobile={mobile}>{getLabel('contact_title_1')}</Header>
              <RegularText mobile={mobile} center>
                {getLabel('contact_desc_1')}{' '}
                <StyledLink href={'/faq'}>
                  {getLabel('contact_desc_2')}{' '}
                </StyledLink>
                {getLabel('contact_desc_3')}
              </RegularText>
            </div>
            <LeftContactSeparator mobile={mobile} />
            <LocationInformationContainer>
              <LocationContactHeader
                mobile={mobile}
                dangerouslySetInnerHTML={{
                  __html: getLabel('contact_title_2')
                }}></LocationContactHeader>

              {this.renderStoreInfoButtons(mobile)}
              <RegularText style={{ marginTop: 25 }} mobile={mobile}>
                {phoneContactContent}
              </RegularText>
            </LocationInformationContainer>
          </TextContainer>

          <FormContainer mobile={mobile}>
            <ContactUsForm />
          </FormContainer>
        </ContactSection>

        {/* Site links section */}
        <SiteLinksSection>
          <SiteLinksSectionContainer mobile={mobile}>
            <SiteLinkContainer mobile={mobile}>
              <SiteLinkTitle mobile={mobile}>
                {getLabel('contact_block_title_1')}
              </SiteLinkTitle>
              <SiteLinkText mobile={mobile}>
                {getLabel('contact_block_content_1')}
              </SiteLinkText>
              <SiteLinkButtonItem mobile={mobile}>
                <SiteLinkButtonContainer>
                  <BlueButton
                    width={'100%'}
                    onClick={() => this.handleNavLink('/faq')}>
                    {getLabel('contact_block_button_text_1')}
                  </BlueButton>
                </SiteLinkButtonContainer>
              </SiteLinkButtonItem>
            </SiteLinkContainer>
            <SiteLinkContainer middle mobile={mobile}>
              <SiteLinkTitle mobile={mobile}>
                {getLabel('contact_block_title_2')}
              </SiteLinkTitle>
              <SiteLinkText mobile={mobile}>
                {getLabel('contact_block_content_2')}
              </SiteLinkText>
              <SiteLinkButtonItem mobile={mobile}>
                <SiteLinkButtonContainer>
                  <BlueButton
                    width={'100%'}
                    onClick={() => this.handleNavLink('/find-us')}>
                    {getLabel('contact_block_button_text_2')}
                  </BlueButton>
                </SiteLinkButtonContainer>
              </SiteLinkButtonItem>
            </SiteLinkContainer>
            <SiteLinkContainer mobile={mobile}>
              <SiteLinkTitle mobile={mobile}>
                {getLabel('contact_block_title_3')}
              </SiteLinkTitle>
              <SiteLinkText mobile={mobile}>
                {getLabel('contact_block_content_3')}
              </SiteLinkText>
              <SiteLinkButtonItem mobile={mobile}>
                <SiteLinkButtonContainer>
                  <BlueButton
                    width={'100%'}
                    onClick={() => this.handleNavLink('/about-us')}>
                    {getLabel('contact_block_button_text_3')}
                  </BlueButton>
                </SiteLinkButtonContainer>
              </SiteLinkButtonItem>
            </SiteLinkContainer>
          </SiteLinksSectionContainer>
        </SiteLinksSection>
      </PageContainer>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Desktop>{this.renderContactPage(false)}</Desktop>
        <Mobile>{this.renderContactPage(true)}</Mobile>
      </React.Fragment>
    );
  }
}

const ContactUs = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <ContactUsWithContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(ContactUs));
