import React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="126"
    height="150"
    viewBox="0 0 117 140"
  >
    <g fill="none" fillRule="evenodd">
      <rect
        width="114"
        height="137"
        x="1.5"
        y="1.5"
        stroke="#2E3191"
        strokeWidth="3"
        rx="5"
      />
      <g fill="#2E3191">
        <path d="M46 32.172L68.042 10.13l2.828 2.828L48.828 35z" />
        <path d="M55 10h16v4H55z" />
        <path d="M67 26V10h4v16z" />
      </g>
      <path
        fill="#1B1B1B"
        d="M12.024 91L27.38 58.836h.264L43.044 91H35.96l-2.552-5.588H21.572L19.108 91h-7.084zM23.64 80.66h7.744c-1.452-3.3-2.86-6.644-3.828-9.416-.924 2.2-2.596 6.424-3.916 9.416zM43.924 91l15.312-26.004H45.288V59.32h24.244L54.22 85.324h14.828V91H43.924zm28.204-15.84c0-9.108 6.996-16.456 16.588-16.456 9.548 0 16.588 7.304 16.588 16.456 0 9.24-6.996 16.456-16.588 16.456S72.128 84.4 72.128 75.16zm6.82 0c0 5.676 4.048 10.252 9.768 10.252 5.72 0 9.768-4.576 9.768-10.252 0-5.632-4.048-10.252-9.768-10.252-5.72 0-9.768 4.62-9.768 10.252z"
      />
      <path
        fill="#1B1B1B"
        fillOpacity=".9"
        d="M15.196 127l5.584-11.696h.096l5.6 11.696H23.9l-.928-2.032h-4.304L17.772 127h-2.576zm4.224-3.76h2.816c-.528-1.2-1.04-2.416-1.392-3.424-.336.8-.944 2.336-1.424 3.424zM34.54 127h-6.896v-11.52h2.464v9.456h4.432V127zm4 0h-2.464v-11.52h2.464V127zm10.144 0h-7.648v-11.52h7.408v2.064H43.5v2.624h4.192v1.984H43.5v2.784h5.184V127zm11.328.176h-.08l-6.32-6.064-.752-.656V127h-2.416v-11.696h.096l6.304 6.064.736.656v-6.544h2.432v11.696zm5.76-.176l5.568-9.456h-5.072v-2.064h8.816l-5.568 9.456h5.392V127h-9.136zm10.256-5.76c0-3.312 2.544-5.984 6.032-5.984 3.472 0 6.032 2.656 6.032 5.984 0 3.36-2.544 5.984-6.032 5.984s-6.032-2.624-6.032-5.984zm2.48 0c0 2.064 1.472 3.728 3.552 3.728s3.552-1.664 3.552-3.728c0-2.048-1.472-3.728-3.552-3.728s-3.552 1.68-3.552 3.728zm10.624 0c0-3.312 2.544-5.984 6.032-5.984 3.472 0 6.032 2.656 6.032 5.984 0 3.36-2.544 5.984-6.032 5.984s-6.032-2.624-6.032-5.984zm2.48 0c0 2.064 1.472 3.728 3.552 3.728s3.552-1.664 3.552-3.728c0-2.048-1.472-3.728-3.552-3.728s-3.552 1.68-3.552 3.728z"
      />
      <path
        fill="#1B1B1B"
        fillOpacity=".25"
        fillRule="nonzero"
        d="M6 106v-1h105v1zM6 44v-1h105v1z"
      />
    </g>
  </svg>
);
