/**
 * Check local storage is supported
 * @return {Boolean}
 */
const isLocalStorageSupported = () => {
  try {
      return 'localStorage' in window && window['localStorage'] !== null;
  } catch(e) {
      return false;
  }
}

/**
 * Get userSessionId from the local storage
 * @return {String|null}
 */
export function getUserSessionId() {
  if (!isLocalStorageSupported()) {
    return null
  }

  const userSessionId = localStorage.getItem('userSessionId')
  return userSessionId || null
}

/**
 * Save user session id to the local storage
 * @param {String} userSessionId
 */
export function saveUserSessionId(userSessionId) {
  if (!isLocalStorageSupported()) {
    return
  }

  localStorage.setItem('userSessionId', userSessionId)
}

/**
 * Delete user session id from the local storage
 */
export function deleteUserSessionId() {
  if (!isLocalStorageSupported()) {
    return
  }

  localStorage.removeItem('userSessionId')
}
