import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { sendVirtualPVEvent } from '../util/analytics';

class HistoryTracker extends Component {
  prevUrl = null;

  componentDidMount() {
    this.props.history.listen(() => {
      if (this.prevUrl !== window.location.href) {
        this.prevUrl = window.location.href;
        sendVirtualPVEvent();
      }
    });
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(HistoryTracker);
