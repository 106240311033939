import React, { Component } from 'react';
import styled from 'styled-components';
import { Route, Switch, withRouter } from 'react-router-dom';

import { AuthContext } from './components/context/AuthenticatorContext';
import ForgotPasswordRequest from './pages/forgot_password/ForgotPasswordRequest';
import ForgotPasswordSubmitNew from './pages/forgot_password/ForgotPasswordSubmitNew';
import ForgotPasswordConfirmation from './pages/forgot_password/ForgotPasswordConfirmation';
import { DIApi } from './util/api';

const ForgotPasswordPageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  height: ${(props) => (props.isCheckout ? '100vh' : undefined)};
  padding-top: ${(props) => (props.isCheckout ? '42px' : undefined)};
  background-image: ${(props) =>
    props.bgOn
      ? 'radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6)'
      : undefined};
`;

class ForgotPassword extends Component {
  state = {
    email: '',
    visible: false
  };

  handleRequestForgotPasswordCode = async (email) => {
    this.setState({ email });
    await DIApi.requestForgotPasswordVerification(email);
  };

  handleSubmitForgotPasswordCode = async (email, code, newPass) => {
    this.handleResetEmail();
    await DIApi.submitForgotPasswordRequest(email, code, newPass);
  };

  handleResetEmail = () => {
    this.setState({ email: '' });
  };

  componentDidMount() {
    if (this.props.updateHeaderText) {
      this.props.updateHeaderText({
        title: ' '
      });
    }
  }

  componentDidUpdate() {
    if (
      !this.props.location.pathname.match(/forgot-password/) &&
      this.state.email
    ) {
      this.handleResetEmail();
    }

    if (
      !this.state.visible &&
      this.props.location.pathname.match(/forgot-password/)
    ) {
      this.setState({ visible: true });
    }

    if (
      this.state.visible &&
      !this.props.location.pathname.match(/forgot-password/)
    ) {
      this.setState({ visible: false });
    }
  }

  render() {
    return (
      <ForgotPasswordPageContainer
        isCheckout={this.props.isCheckout}
        bgOn={this.props.bgOn}
        name="forgot-password-flow-container"
        style={{ display: this.state.visible ? 'flex' : 'none' }}
      >
        <Switch>
          <Route
            exact
            path={`${this.props.path}/forgot-password/request`}
            render={() => (
              <ForgotPasswordRequest
                path={this.props.path}
                email={this.state.email}
                handleRequestForgotPasswordCode={
                  this.handleRequestForgotPasswordCode
                }
              />
            )}
          />
          <Route
            exact
            path={`${this.props.path}/forgot-password/submit-new`}
            render={() => (
              <ForgotPasswordSubmitNew
                path={this.props.path}
                email={this.state.email}
                handleSubmitForgotPasswordCode={
                  this.handleSubmitForgotPasswordCode
                }
              />
            )}
          />
          <Route
            exact
            path={`${this.props.path}/forgot-password/confirm`}
            render={() => <ForgotPasswordConfirmation path={this.props.path} />}
          />
        </Switch>
      </ForgotPasswordPageContainer>
    );
  }
}

const ForgotPasswordController = (props) => (
  <AuthContext.Consumer>
    {(parentState) => <ForgotPassword {...props} parentState={parentState} />}
  </AuthContext.Consumer>
);

export default withRouter(ForgotPasswordController);
