import React, { Component } from 'react';
import styled from 'styled-components';
import Barcode from 'react-barcode';
import ShareLayout from './Layout';
import { withRouter } from 'react-router-dom';
import { withAppContext } from '../context/AppContext';
import findIcon from '../../assets/images/share/find-out.png';
import historyIcon from '../../assets/images/share/history.png';
import shareIdIcon from '../../assets/images/share/share-id.png';

const Content = styled.div`
  background: ${(props) => props.theme.ds_red};
  color: #fff;
  font-size: 0.9em;
  position: relative;
  padding: 0 0 ${(props) => (props.isViewPoints ? '10em' : '2em')} 0;
  width: 100%;
  height: 20px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
`;

const Title = styled.p`
  font-size: 1.5em;
  text-align: center;
  margin: 0 0.5em 0 0.5em;
  z-index: 1;
`;

const PointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5em;
  [dir='rtl'] & {
    margin-bottom: 6em;
  }
`;

const Points = styled.b`
  margin: 0.15em auto;
  font-size: 3.25em;
  letter-spacing: 1px;
  z-index: 1;
`;

const Currency = styled.small`
  font-size: 1.5em;
  font-weight: 400;
  z-index: 1;
`;

const BarcodeWrapper = styled.div`
  background: #fff;
  border-radius: 0.15em;
  text-align: center;
  position: absolute;
  max-width: 12em;
  left: 0;
  right: 0;
  bottom: -2.5em;
  margin: auto;
  padding: 0.15em 0.15em 0;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
`;

const Cta = styled.a`
  font-size: 1em;
  text-decoration: none;
  color: #b30d4d;
`;

const Icon = styled.img`
  display: block;
  width: 2.25em;
  height: 2.25em;
  object-fit: contain;
  margin-bottom: 0.75em;
  margin-inline: auto;
`;

const CtaWrapper = styled.div`
  border-top: none;
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  transform: translateY(-9em);
  & :nth-child(2) {
    border-right: none;
    border-left: none;
  }
`;

const Td = styled.td`
  text-align: center;
  width: 33.3%;
  border: 2px solid #b30d4d;
  border-top: none;
  flex-grow: 1;
  display: flex;
  align-item: center;
  justify-content: flex-end;
  flex-direction: column;
  min-height: 11em;
  padding: 1.25em 0 1.5em;
  cursor: pointer;
`;

const LinkButton = styled.button`
  background: transparent;
  font-weight: 600;
  width: auto;
  box-shadow: none;
  border: none;
  cursor: pointer;
`;
const options = {
  fontSize: 12,
  textMargin: 0,
  width: 1,
  height: 40,
  format: 'CODE128',
  margin: 0,
  marginTop: 3,
  marginBottom: 0,
  marginLeft: 10,
  marginRight: 10
};

class Details extends Component {
  render() {
    const isViewPoints = this.props.points;
    const { LoyaltyMember } = this.props.memberDetails;
    let pointsRemaining = 0;

    if (LoyaltyMember && LoyaltyMember.BalanceList) {
      pointsRemaining = LoyaltyMember.BalanceList[0].PointsRemaining;
    }

    const tempShareCurrency = pointsRemaining / 10;
    const shareCurrency = Math.floor(tempShareCurrency * 100) / 100;
    return (
      <ShareLayout>
        <Content isViewPoints={isViewPoints}>
          {pointsRemaining >= 0 && isViewPoints && (
            <PointsWrapper>
              <Title>{this.props.getLabel('share_points_balance')}</Title>
              <Points>
                {pointsRemaining.toLocaleString(undefined, {
                  maximumFractionDigits: 2
                })}
              </Points>
              <Currency>
                {shareCurrency.toLocaleString(undefined, {
                  maximumFractionDigits: 2
                })}
                &nbsp;{this.props.getLabel('share_currency_sar')}
              </Currency>
            </PointsWrapper>
          )}
          {this.props.cta && (
            <CtaWrapper>
              <Table>
                <tbody>
                  <Tr>
                    <Td>
                      <LinkButton
                        onClick={() =>
                          this.props.history.push('/account/share/shareId', {
                            ...this.props.location.state,
                            memberDetails: this.props.memberDetails
                          })
                        }>
                        <Icon src={shareIdIcon} />
                        <Cta>{this.props.getLabel('share_id')}</Cta>
                      </LinkButton>
                    </Td>
                    <Td>
                      <LinkButton
                        onClick={() =>
                          this.props.history.push(
                            '/account/share/transactions',
                            {
                              ...this.props.location.state,
                              memberDetails: this.props.memberDetails
                            }
                          )
                        }>
                        <Icon src={historyIcon} />
                        <Cta>{this.props.getLabel('share_history')}</Cta>
                      </LinkButton>
                    </Td>
                    <Td>
                      <LinkButton
                        onClick={() => this.props.history.push('/share')}>
                        <Icon src={findIcon} />
                        <Cta>{this.props.getLabel('share_findout')}</Cta>
                      </LinkButton>
                    </Td>
                  </Tr>
                </tbody>
              </Table>
            </CtaWrapper>
          )}
          {LoyaltyMember && LoyaltyMember.CardNumber && !this.props.cta && (
            <BarcodeWrapper>
              <Barcode value={LoyaltyMember.CardNumber} {...options} />
            </BarcodeWrapper>
          )}
        </Content>
      </ShareLayout>
    );
  }
}

export default withRouter(withAppContext(Details));
