import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import FormInput from '../../components/forms/BasicInputField';
import BlueButton from '../../components/buttons/BlueButton';
import { Desktop, Mobile } from '../../components/responsive/Breakpoints';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  font-size: ${(props) => (props.isMobile ? '1.625rem' : '2.75rem')};
  font-weight: bold;
  line-height: ${(props) => (props.isMobile ? '1.33' : '1.09')};
  color: ${(props) => props.theme.charcoal};
  text-align: center;
`;

const HeaderSubTitle = styled.div`
  display: flex;
  font-size: ${(props) => (props.isMobile ? '0.875' : '1')}rem;
  color: ${(props) => props.theme.charcoal_60};
  max-width: 326px;
  padding-top: 6px;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  align-self: center;
  align-items: center;
  margin: ${(props) => (props.isMobile ? '52px 0 35px' : '44px 0')};
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? '30' : '44')}px;
  align-items: center;
`;

const Text = styled.span`
  font-size: 0.8125rem;
  color: ${(props) =>
    props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal};
  cursor: ${(props) => (props.blue ? 'pointer' : undefined)};
`;

const WrappedFormInput = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const PasswordRules = styled.span`
  padding: 0 0 13px;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 0.81rem;
  font-style: italic;
  color: ${(props) => props.theme.charcoal_60};
`;

class ForgotPasswordSubmitNew extends Component {
  state = {
    code: '',
    newPass: '',
    confirmNewPass: ''
  };

  componentDidMount() {
    console.info(
      '[ForgotPasswordSubmitNew] -> (componentDidMount) email',
      this.props.location.state.email
    );
  }

  handleSubmitRequest = async (e) => {
    e.preventDefault();
    if (!this.props.handleSubmitForgotPasswordCode) {
      return;
    }

    if (this.state.newPass !== this.state.confirmNewPass) {
      return alert('New password and confirm password do not match!');
    }

    await this.props.handleSubmitForgotPasswordCode(
      this.props.location.state.email,
      this.state.code,
      this.state.newPass
    );
    this.props.history.replace(
      `${this.props.path}/forgot-password/confirm`,
      this.props.location.state
    );
  };

  handleInputOnChange = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  renderContent(isMobile) {
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle isMobile={isMobile}>ENTER YOUR NEW PASSWORD</HeaderTitle>
          <HeaderSubTitle isMobile={isMobile}>
            Please check your email for your unique verification code.
          </HeaderSubTitle>
        </HeaderContainer>

        <ContentContainer isMobile={isMobile}>
          <FormContainer
            isMobile={isMobile}
            onSubmit={this.handleSubmitRequest}
          >
            <FormInput
              placeholder="Verification Code"
              width="100%"
              value={this.state.code}
              onChange={(e) => this.handleInputOnChange(e, 'code')}
            />
            <WrappedFormInput>
              <FormInput
                placeholder="New Password"
                type="password"
                width="100%"
                value={this.state.newPass}
                onChange={(e) => this.handleInputOnChange(e, 'newPass')}
              />
              <PasswordRules>
                Use 8 or more characters with an uppercase letter, a lowercase
                letter, and a number.
              </PasswordRules>
            </WrappedFormInput>
            <WrappedFormInput>
              <FormInput
                placeholder="Confirm New Password"
                type="password"
                width="100%"
                value={this.state.confirmNewPass}
                onChange={(e) => this.handleInputOnChange(e, 'confirmNewPass')}
              />
            </WrappedFormInput>
            <BlueButton width="100%" style={{ marginTop: 16 }}>
              SAVE NEW PASSWORD
            </BlueButton>
          </FormContainer>

          <Text>
            Didn’t get the e-mail?{' '}
            <Text blue onClick={() => this.props.history.goBack()}>
              Send a new one. &gt;
            </Text>
          </Text>
        </ContentContainer>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Container name="forgot-password-submit-new-container">
        <Column>
          <Desktop>{this.renderContent()}</Desktop>
          <Mobile>{this.renderContent(true)}</Mobile>
        </Column>
      </Container>
    );
  }
}

export default withRouter(ForgotPasswordSubmitNew);
