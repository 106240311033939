import styled from 'styled-components';

const BaseButton = styled.button`
  width: ${(props) => {
    const { width } = props;
    if (!width) {
      return '310px';
    }

    if (typeof width === 'number') {
      return `${width}px`;
    }

    if (typeof width === 'string') {
      return width;
    }

    // If none of the above; default
    return '310px';
  }};
  height: 43px;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  test-align: center;
  cursor: pointer;
  padding: 0;
  border-radius: 3px;
  -webkit-tap-highlight-color: transparent;
`;

export default BaseButton;
