import React from 'react';
import styled from 'styled-components';

import WhiteButton from '../buttons/WhiteButton';
import ADAIcon2 from '../../assets/images/img-adaw-small.svg';
import CloseCaptionIcon from '../svgs/CloseCaptionIcon';

import theme from '../../util/theme';
import { MOBILE_ADVENTURE_CARD_WIDTH } from '../../util/constants';
import { Desktop, Mobile } from '../responsive/Breakpoints';

import { getAdventureStatus } from '../../util/common';
import { withAppContext } from '../context/AppContext';
const AdventureCardContainer = styled.div`
  padding: ${(props) => (props.minimal ? '40px 0' : undefined)};
`;

const AdventureCard = styled.div`
  width: ${(props) => props.width || 360}px !important;
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
  border-radius: 5px;
  overflow: hidden;
  box-shadow: ${(props) => {
    if (props.minimal) {
      if (props.isMobile) {
        return '0px 10px 30px 8px rgba(0, 184, 241, .25)';
      }
      return '0px 0px 30px 15px rgba(0, 184, 241, .25)';
    }

    return undefined;
  }};
`;

// TODO: Insert background image in Poster;
const Poster = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.isMobile ? '213px' : '330px')};
  width: 100%;
  background-color: gray;
  cursor: ${(props) => (props.minimal ? undefined : 'pointer')};
`;

const PosterImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AdvCardContent = styled.div`
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
  background-color: black;
`;

const AdvCardTitleArea = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.mobile ? '44px' : '54px')};
`;

const AdvCardContentTitle = styled.h3`
  font-size: ${(props) => (props.mobile ? '1rem' : '1.25rem')};
  font-weight: bold;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

const AdvCardContentSubTitle = styled.h4`
  font-size: ${(props) => (props.mobile ? '0.8125rem' : '1rem')};
  font-weight: normal;
  letter-spacing: 1.3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

const AdvCardContentDetails = styled.div`
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  height: ${(props) => (props.height ? props.height : '64px')};
  color: ${(props) => props.theme.white_70};
  padding: ${(props) => props.padding};
  display: flex;
  align-items: center;
  line-height: ${(props) => (props.mobile ? '1.3' : '1.4')};
  unicode-bidi: plaintext;
`;

const StatusBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 28px;
  background-image: linear-gradient(to left, #1b68b8, #254da5);
  display: flex;
  align-items: center;
`;

const StatusMessage = styled.p`
  width: 100%;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
`;

const AdventureItem = (props) => {
  const {
    isMobile,
    posterOnClick,
    onClick,
    margin,
    buttonWidth,
    buttonText,
    assets,
    adventure,
    site
  } = props;

  const cardAssets = assets.card;

  const adventureTitle =
    cardAssets && cardAssets.copy ? cardAssets.copy.title : '';
  const adventureSubTitle =
    cardAssets && cardAssets.copy ? cardAssets.copy.subTitle : '';
  const adventureSynopsis =
    cardAssets && cardAssets.copy ? cardAssets.copy.synopsis : '';

  const renderPosterImage = (isMobile) => {
    if (!cardAssets || !cardAssets.images || !cardAssets.images.poster) {
      return null;
    }

    const imageSource = isMobile
      ? cardAssets.images.poster.mobile
      : cardAssets.images.poster.desktop;

    return (
      <PosterImage
        src={imageSource ? imageSource.x2 : ''}
        srcset={`${imageSource ? imageSource.x2 : ''} 2x, ${
          imageSource ? imageSource.x3 : ''
        } 3x`}
      />
    );
  };

  const renderItem = (mobile) => {
    const statusMessage = assets.status
      ? assets.status[getAdventureStatus(adventure, site)]
      : null;

    return (
      <AdventureCardContainer minimal={props.minimal}>
        <AdventureCard
          minimal={props.minimal}
          width={isMobile ? MOBILE_ADVENTURE_CARD_WIDTH : undefined}
          margin={margin}
          isMobile={isMobile}>
          <Poster
            minimal={props.minimal}
            isMobile={isMobile}
            id={`poster-${adventure.title_id}`}
            className={'adventure-poster'}
            onClick={posterOnClick}>
            {renderPosterImage(mobile)}

            {statusMessage && (
              <StatusBar>
                <StatusMessage>{statusMessage}</StatusMessage>
              </StatusBar>
            )}
          </Poster>

          <AdvCardContent>
            <AdvCardTitleArea mobile={mobile}>
              <AdvCardContentTitle mobile={mobile}>
                {adventureTitle}
              </AdvCardContentTitle>
              <AdvCardContentSubTitle mobile={mobile}>
                {adventureSubTitle}
              </AdvCardContentSubTitle>
            </AdvCardTitleArea>
            <AdvCardContentDetails
              mobile={mobile}
              style={{ paddingBottom: props.minimal ? 10 : 0 }}
              padding={mobile ? '2px 30px' : '8px 35px'}>
              {adventureSynopsis}
            </AdvCardContentDetails>
            <AdvCardContentDetails
              height="auto"
              padding={'14px 35px'}
              style={{ display: props.minimal ? 'none' : undefined }}>
              <CloseCaptionIcon
                color={theme.white_70}
                height={20}
                style={{ marginInlineEnd: 12 }}
              />
              {props.site.isADAEnabled && <img src={ADAIcon2} alt="ADA Icon" />}
            </AdvCardContentDetails>

            <WhiteButton
              id={`cta-${adventure.title_id}`}
              width={buttonWidth || 175}
              style={{
                margin: mobile ? '0 0 8px 0' : '16px 0',
                display: props.minimal ? 'none' : undefined
              }}
              onClick={(adventure, toAvailability, toQuoteBlock) =>
                onClick(adventure, toAvailability, toQuoteBlock)
              }>
              {buttonText || 'GET TICKETS'}
            </WhiteButton>
          </AdvCardContent>
        </AdventureCard>
      </AdventureCardContainer>
    );
  };

  return (
    <React.Fragment>
      <Desktop>{renderItem(false)}</Desktop>
      <Mobile>{renderItem(true)}</Mobile>
    </React.Fragment>
  );
};

export default withAppContext(AdventureItem);
