export const CLOSING_MESSAGE =
  'Leaving this page will cancel the hold on your tickets. Are you sure you want to cancel?';
export const MOBILE_ADVENTURE_CARD_WIDTH = 317;
export const TOASTER_NOTIFICATION_HEIGHT = 70;
export const AVAILABLE_SITES = ['en-sa', 'ar-sa'];
export const LOCATION_RESPONSE_DATA = ['riyadh-park-mall'];
export const CURRENCY_SYMBOL = {
  USD: '$',
  AED: 'AED ',
  SAR: 'SAR '
};
