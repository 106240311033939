import React, { Component, forwardRef } from 'react';
import styled from 'styled-components';

const TextareaContainer = styled.div`
  width: 100%;
  margin-bottom: 0.6em;
  padding: ${(props) => {
    const { padding } = props;
    if (!padding) {
      return;
    }

    if (typeof padding === 'string') {
      return padding;
    }
  }};
  flex: ${(props) => props.flex};
  box-sizing: border-box;
`;

const Textarea = styled.textarea`
  height: 110px;
  border: solid 1px ${(props) => props.theme.ds_bright_blue};
  padding: 14px;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: max(0.8125rem, 16px);
  color: ${(props) => (props.dark ? 'white' : props.theme.charcoal)};
  transition: ${(props) => props.theme.transition};
  border-radius: 3px;
  outline: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  box-sizing: border-box !important;


  &::placeholder {
    color: ${(props) =>
      props.dark ? props.theme.white_70 : props.theme.black_30};
  }

  &:hover {
    border: 3px solid ${(props) => props.theme.ds_bright_blue};
    padding: 12px;
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.ds_bright_blue};
    padding: 14px;
  }

  &:disabled {
    border: 1px solid ${(props) => props.theme.charcoal_25};
    padding: 14px;
  }
  & p {
    height: 20px;
    display: flex;
    align-items: center;

`;

const BasicTextareaLabel = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 12px;
  line-height: 1.6;
  color: ${(props) => {
    return props.dark ? props.theme.white_80 : props.theme.charcoal_90;
  }};
`;

const Error = styled.span`
  font-size: 11px;
  margin: 0 0 0.5em 0;
  color: ${(props) => props.theme.error_color};
`;

class TextareaField extends Component {
  state = {
    focused: false
  };

  handleFocusChange = (isFocused) => {
    this.setState({ focused: isFocused });
  };

  render() {
    const {
      dark,
      padding,
      flex,
      label,
      placeholder,
      data,
      innerRef,
      ...inputProps
    } = this.props;

    return (
      <TextareaContainer padding={padding} flex={flex}>
        <BasicTextareaLabel focused={this.state.focused} dark={dark}>
          {label || placeholder} <br />
          <Textarea
            defaultValue=""
            type="text"
            onBlur={() => {
              this.handleFocusChange(false);
              if (this.props.onBlur) {
                this.props.onBlur();
              }
            }}
            onFocus={() => {
              this.handleFocusChange(true);
              if (this.props.onFocus) {
                this.props.onFocus();
              }
            }}
            ref={innerRef}
            {...inputProps}
          />
          {this.props.error && <Error>{this.props.error}</Error>}
        </BasicTextareaLabel>
      </TextareaContainer>
    );
  }
}

export default forwardRef((props, ref) => (
  <TextareaField {...props} innerRef={ref} />
));
