import React from 'react';
import styled from 'styled-components';

import { withAppContext } from '../context/AppContext';
import { Desktop, Mobile } from '../responsive/Breakpoints';
import Breadcrumbs from '../../components/breadcrumbs';

const ShareWorkflowDetailsSectionContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: black;
  display: flex;
  justify-content: center;
`;

const WorkFlowDetailsContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  max-width: 1170px;
  width: 100%;
  padding: ${(props) => (props.isMobile ? '36px 22px' : '55px 98px')};
`;

const Wrapper = styled.div`
  color: ${(props) => props.theme.white_70};
  margin-bottom: ${(props) => (props.isMobile ? '22px' : '30px')};
  line-height: ${(props) => (props.isMobile ? '1.33' : '1.55')};
`;

const GlobalTitle = styled.h2`
  color: ${(props) => props.theme.white_70};
  text-align: center;
`;

const Title = styled.h3`
  color: #fff;
  font-size: 1.25em;
  font-weight: normal;
`;

const SubTitle = styled.h5`
  color: #fff;
  font-size: 1.15em;
  margin-bottom: 0;
  font-weight: normal;
`;

const Description = styled.p`
  color: ${(props) => props.theme.white_70};
  font-size: 1em;
  margin-bottom: 0.5em;
  line-height: 1.5;
`;
const BreadcrumbsWrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  max-width: 1110px;
  width: 100%;
  z-index: 99999;
  position: relative;
  top: -2em;
  left: 0;
  li:nth-child(2){
    a{
      text-transform: uppercase;
    }
  }
  @media (min-width: 1024px) {
    left: -2.5em
  }
}
`;

const renderDetails = (data) => {
  const { globalTitle, list } = data;
  return (
    <Wrapper>
      <BreadcrumbsWrapper>
        <Breadcrumbs />
      </BreadcrumbsWrapper>
      {globalTitle && <GlobalTitle>{globalTitle}</GlobalTitle>}
      {list?.map(({ title, subTitle, description, listItems }) => (
        <>
          {title && <Title>{title}</Title>}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          {description && <Description>{description}</Description>}
          {listItems && (
            <ul>
              {listItems?.map((i) => (
                <li>{i}</li>
              ))}
            </ul>
          )}
        </>
      ))}
    </Wrapper>
  );
};

const renderDetailsContainer = (data, isMobile) => {
  return (
    <WorkFlowDetailsContainer isMobile={isMobile}>
      {renderDetails(data)}
    </WorkFlowDetailsContainer>
  );
};

const ShareWorkflowDetailsSection = ({ data }) => {
  return (
    <ShareWorkflowDetailsSectionContainer id="share-workflow-details">
      <Desktop>{renderDetailsContainer(data)}</Desktop>
      <Mobile>{renderDetailsContainer(data, true)}</Mobile>
    </ShareWorkflowDetailsSectionContainer>
  );
};

export default withAppContext(ShareWorkflowDetailsSection);
