import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withAppContext } from '../context/AppContext';

import { Desktop, Mobile } from '../responsive/Breakpoints';
import StandardCarousel from '../carousels/StandardCarousel';
import PlanAheadItems from '../content/HomePlanAheadSlides';
import WhiteButton from '../buttons/WhiteButton';

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 768px;
  background-color: gray;
  position: relative;
`;

const PlanAheadButtonContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  margin: 630px auto 0;
  padding: 0 100px;
  max-width: 1170px;
  width: auto;
  right: 0;
  left: 0;
`;

const PlanAheadMobileButtonContainer = styled.div`
  display: flex;
  margin: 477px auto 0;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  flex-direction: column;
  padding: 0 22px;
  max-width: 384px;
`;

const PlanAheadSection = (props) => (
  <React.Fragment>
    <Desktop>
      <SectionContainer>
        <StandardCarousel items={PlanAheadItems} />
        <PlanAheadButtonContainer>
          <WhiteButton
            style={{ pointerEvents: 'all' }}
            onClick={() => props.history.push('/adventures')}>
            {props.getLabel('home_plan_ahead_button_text')}
          </WhiteButton>
        </PlanAheadButtonContainer>
      </SectionContainer>
    </Desktop>

    <Mobile>
      <SectionContainer mobile>
        <StandardCarousel items={PlanAheadItems} mobile />
        <PlanAheadMobileButtonContainer>
          <WhiteButton
            width={'100%'}
            style={{
              pointerEvents: 'all',
              maxWidth: 384,
              alignSelf: 'center',
              marginBottom: 68
            }}
            onClick={() => props.history.push('/adventures')}>
            {props.getLabel('home_plan_ahead_button_text')}
          </WhiteButton>
        </PlanAheadMobileButtonContainer>
      </SectionContainer>
    </Mobile>
  </React.Fragment>
);

export default withRouter(withAppContext(PlanAheadSection));
