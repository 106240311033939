import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  SchedulerNatural,
  SchedulerUndersized
} from './responsive/Breakpoints';
import Scheduler from './Scheduler';
import AdventureThumbnail from './AdventureThumbnail';
import LocationIcon from './svgs/LocationIcon';
// import { CURRENCY_SYMBOL } from '../util/constants';
import { withAppContext } from './context/AppContext';
import { sendEcomEvent } from '../util/analytics';

const Container = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  height: ${(props) => (props.mobile ? 'unset' : '100%')};
  padding-bottom: ${(props) => (props.mobile ? '60px' : 'unset')};
  width: 100%;
  text-align: center;
  min-height: ${(props) => (props.mobile ? '650px' : '750px')};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 24px 0;
`;

const LocationTitle = styled.h2`
  color: ${(props) => props.theme.charcoal};
  font-size: ${(props) =>
    props.platform === 'mobile' ? '1.625rem' : '2.75rem'};
  margin: 0;
  margin-top: 26px;
  padding: 0 22px;
  line-height: 1.09;
  text-align: center;
`;

const NowBoardingStatic = styled.div`
  padding: ${(props) => (props.platform === 'mobile' ? '6px' : '4px 10vh 0')};
  font-size: 1rem;
  text-align: center;
  color: ${(props) => props.theme.charcoal_60};
`;

const LocationSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (props.platform === 'mobile' ? '16px' : '22px')};
  font-size: 0.8125rem;
  font-weight: bold;
`;

const LocationSelectionAction = styled.span`
  color: ${(props) => props.theme.ds_dusk_blue};
  font-weight: normal;
  cursor: pointer;
`;

class SchedulerContainer extends Component {
  titleRef = React.createRef(null);
  componentDidMount() {
    this.props.autoScroll('timeSelector');

    const observer = new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        observer.unobserve(this.titleRef?.current);
        this.sendBeginCheckoutEvent();
      }
    });
    observer.observe(this.titleRef?.current);
  }

  sendBeginCheckoutEvent() {
    sendEcomEvent('begin_checkout', null, {
      siteId: this.props.site.site_id,
      adventures: this.props.adventure
    });
  }

  renderPricing = (isMobile) => {
    const { siteCopyAssets = {} } = this.props;

    // Ruled out that siteCopyAssets exists and contains the data we are looking for so we can
    // safely pluck the cms copy data we want.
    const { customPricingLines, ticketingMinimumDescription } = siteCopyAssets;

    const travelerAgeCopy =
      siteCopyAssets.travelerAgeCopy?.[this.props.adventure.title_id] ||
      siteCopyAssets.travelerAgeCopy?.default;

    if (isMobile) {
      const ageDeclaration = [
        <div key={'price_age_declaraion'}>{travelerAgeCopy}</div>
      ];

      return customPricingLines
        ?.map((priceString, index) => {
          return <div key={`pricing_${index}`}>{priceString}</div>;
        })
        .concat(ageDeclaration, ticketingMinimumDescription);
    }

    return (
      <React.Fragment>
        {customPricingLines?.map((line) => (
          <div key={line}>{line}</div>
        ))}
        <div>{travelerAgeCopy}</div>
        <div>{ticketingMinimumDescription}</div>
      </React.Fragment>
    );
  };

  renderTextContent = (platform) => {
    const { adventure, site, canChangeLocations, getLabel } = this.props;
    const isMobile = platform === 'mobile';
    /*const minAge = adventure.licensed_site
      ? adventure.licensed_site.min_age
      : 10;
    const currencySymbol = adventure.licensed_site
      ? CURRENCY_SYMBOL[adventure.licensed_site.currency] || 'SAR'
      : 'SAR';
    const basePrice = adventure.licensed_site
      ? adventure.licensed_site.base_price.toFixed(2)
      : '20.00';*/
    const ticketingInfo = this.renderPricing(isMobile);
    const location = site.site_legalname
      ? `${site.site_legalname}, ${site.city}, ${site.state}`
      : 'NO LOCATION';

    return (
      <HeaderContainer mobile={isMobile}>
        <AdventureThumbnail titleId={adventure.title_id} />
        <LocationTitle
          ref={this.titleRef}
          platform={platform}
          dangerouslySetInnerHTML={{
            __html: getLabel('scheduler_title')
          }}></LocationTitle>
        <NowBoardingStatic platform={platform}>
          {ticketingInfo}
        </NowBoardingStatic>
        <LocationSelectionContainer platform={platform}>
          <span>{location.toUpperCase()}</span>
          <LocationIcon color={'#000000'} />
          {canChangeLocations ? (
            <LocationSelectionAction
              onClick={() => this.props.handleShowLocationDialog(true)}>
              {getLabel('scheduler_change')}
            </LocationSelectionAction>
          ) : null}
        </LocationSelectionContainer>
      </HeaderContainer>
    );
  };

  render() {
    return (
      <React.Fragment>
        <SchedulerNatural>
          <Container id={'timeSelector'}>
            {this.renderTextContent('desktop')}
            <Scheduler
              key={this.props.adventure.title_id}
              mobile={false}
              maxDaysToDisplay={6}
              site={this.props.site}
              adventure={this.props.adventure}
              isTicketingAvailable={this.props.isTicketingAvailable}
              handleShowLocationDialog={this.props.handleShowLocationDialog}
              canChangeLocations={this.props.canChangeLocations}
            />
          </Container>
        </SchedulerNatural>
        <SchedulerUndersized>
          <Container ref={this.titleRef} id={'timeSelector'} mobile={true}>
            {this.renderTextContent('mobile')}
            <Scheduler
              key={this.props.adventure.title_id}
              mobile={true}
              maxDaysToDisplay={20}
              site={this.props.site}
              adventure={this.props.adventure}
              isTicketingAvailable={this.props.isTicketingAvailable}
              handleShowLocationDialog={this.props.handleShowLocationDialog}
              canChangeLocations={this.props.canChangeLocations}
            />
          </Container>
        </SchedulerUndersized>
      </React.Fragment>
    );
  }
}

export default withRouter(withAppContext(SchedulerContainer));
