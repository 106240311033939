import React, { Component } from 'react';
import styled from 'styled-components';
import { Swiper, Slide } from 'react-dynamic-swiper';
import { withRouter } from 'react-router-dom';
import { sendEcomEvent } from '../../util/analytics';

import AdventureCarouselArrow from '../svgs/AdventureCarouselArrow';
import { AuthContext } from '../context/AuthenticatorContext';
import { withAppContext } from '../context/AppContext';

const CarouselEdgeOverlay = styled.div`
  position: absolute;
  left: ${(props) => (props.isLeft ? 0 : undefined)};
  right: ${(props) => (props.isLeft ? undefined : 0)};
  top: 0;
  height: 100%;
  width: 130px;
  background-image: linear-gradient(
    ${(props) => (props.isLeft ? 'to left' : 'to right')},
    rgba(255, 255, 255, 0),
    white
  );
  z-index: 10;
`;

const ArrowContainer = styled.div`
  display: flex;
  cursor: pointer;
  position: absolute;
  left: ${(props) => (props.isLeft ? '15px' : undefined)};
  right: ${(props) => (props.isLeft ? undefined : '15px')};
  bottom: 55%;
  transform: ${(props) => (props.isLeft ? 'rotate(180deg)' : undefined)};

  & svg {
    border-radius: 32px;
    transition: ${(props) => props.theme.transition};
  }

  &:hover svg {
    background-color: ${(props) => props.theme.ds_bright_blue};
    border-radius: 32px;
  }

  &:hover svg path {
    stroke: white;
  }

  &:active svg {
    background-color: #007599;
    border-radius: 32px;
  }

  &:active svg path {
    stroke: ${(props) => props.theme.charcoal_60};
  }
`;

const StyledCarousel = styled(Swiper)`
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  position: relative;
`;

const CarouselContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const params = {
  swiperOptions: {
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next.di-swiper-button-next',
      prevEl: '.swiper-button-prev.di-swiper-button-prev'
    },
    containerClass: 'di-swiper-container',
    slidesPerView: 'auto',
    spaceBetween: 15,
    centeredSlides: true,
    loopFillGroupWithBlank: false
    // autoplay: {
    //   delay: 10000,
    // },
  },
  pagination: false,
  prevButton: () => (
    <CarouselEdgeOverlay
      className="swiper-button-prev di-swiper-button-prev"
      isLeft>
      <ArrowContainer isLeft>
        <AdventureCarouselArrow />
      </ArrowContainer>
    </CarouselEdgeOverlay>
  ),
  nextButton: () => (
    <CarouselEdgeOverlay className="swiper-button-next di-swiper-button-next">
      <ArrowContainer>
        <AdventureCarouselArrow />
      </ArrowContainer>
    </CarouselEdgeOverlay>
  )
};

class HomeAdventuresCarouselWithContext extends Component {
  navDetails(adventure, toAvailability, toQuoteBlock) {
    this.props.history.push(`/adventures/details/${adventure.title_id}`, {
      toAvailability,
      toQuoteBlock
    });
  }

  sendSelectItemEvent(adventure) {
    sendEcomEvent('select_item', null, {
      siteId: this.props.parentState.selectedSite.site_id,
      adventures: adventure
    });
  }

  componentWillUnmount() {
    const buttons = document.querySelectorAll('.swiper-slide button');
    const posterImages = document.querySelectorAll(
      '.swiper-slide .adventure-poster'
    );

    buttons.forEach((button) => {
      button.removeEventListener('click', () => {});
    });
    posterImages.forEach((poster) => {
      poster.removeEventListener('click', () => {});
    });
  }

  render() {
    params.navigation = !this.props.isMobile;
    const key = JSON.stringify(
      this.props.items.map((item, index) => {
        let props = item.props;
        if (
          !props.adventure &&
          (!props.children || !props.children.props.adventure)
        ) {
          return index;
        }

        if (!props.adventure) {
          props = props.children.props;
        }

        return {
          isMobile: props.isMobile,
          adventure: props.adventure,
          assets: props.assets,
          site: props.site
        };
      })
    );
    return (
      <CarouselContainer name="home-adv-carousel">
        <StyledCarousel
          key={key}
          onInitSwiper={() => {
            const buttons = document.querySelectorAll('.swiper-slide button');
            const posterImages = document.querySelectorAll(
              '.swiper-slide .adventure-poster'
            );
            const adventureItemMap = this.props.items.reduce(
              (acc, adventureItem) => {
                return {
                  ...acc,
                  [adventureItem.key]: adventureItem
                };
              },
              {}
            );

            buttons.forEach((button) => {
              const titleId = button.id.replace('cta-', '');
              const adventureItem = adventureItemMap[titleId];
              const adventureItemProps = adventureItem.props.adventure
                ? adventureItem.props
                : adventureItem.props.children.props;
              const adventure = adventureItemProps.adventure;
              button.addEventListener('click', () => {
                this.sendSelectItemEvent(adventure);
                this.navDetails(
                  adventure,
                  adventureItemProps.isAvailable,
                  !adventureItemProps.isAvailable
                );
              });
            });

            posterImages.forEach((poster) => {
              const titleId = poster.id.replace('poster-', '');
              const adventureItem = adventureItemMap[titleId];
              const adventureItemProps = adventureItem.props.adventure
                ? adventureItem.props
                : adventureItem.props.children.props;
              const adventure = adventureItemProps.adventure;
              // skip registering the event listener if we are rendering a minimal version of these items.
              if (this.props.minimal) {
                return;
              }
              poster.addEventListener('click', () => {
                this.sendSelectItemEvent(adventure);
                this.navDetails(adventure);
              });
            });
          }}
          {...params}>
          {this.props.items.map((item, index) => (
            <Slide style={{ width: 'auto' }} key={index}>
              {item}
            </Slide>
          ))}
        </StyledCarousel>
        {/* These would likely be elements that should sit within and "above" the carousel */}
        {this.props.children}
      </CarouselContainer>
    );
  }
}

const HomeAdventuresCarousel = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <HomeAdventuresCarouselWithContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(HomeAdventuresCarousel));
