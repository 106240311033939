import React from 'react';
import { Desktop, Mobile } from '../responsive/Breakpoints';
import styled from 'styled-components';

const ContentContainer = styled.section`
  ${(props) => (props.mobile ? 'background-position: center;' : '')}
  padding: ${(props) =>
    props.mobile ? '112px 22px 86px' : '104px 101px 67px'};
  height: 100%;
`;

const BoundingContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  align-items: ${(props) => (props.mobile ? 'center' : undefined)};
`;

const TextSection = styled.div`
  ${(props) => (props.mobile ? '' : 'width: 419px')};
`;

const DescriptionHeader = styled.h4`
  color: ${(props) =>
    props.dark ? props.theme.charcoal_90 : props.theme.white_90};
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '22px' : '36px')};
  margin: ${(props) => (props.mobile ? '31px 0 26px' : '11px 0 24px')};
  line-height: 1.19;
  font-weight: normal;
  max-width: 400px;
`;

const DescriptionContent = styled.section`
  color: ${(props) =>
    props.dark ? props.theme.charcoal_60 : props.theme.white_70};
  margin-bottom: ${(props) => (props.mobile ? '77px' : '62px')};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '14' : '16')}px;
  height: 160px;
  max-width: 360px;
`;

const PageBreak = styled.p`
  margin-top: ${(props) => (props.mobile ? '311px' : '309px')};
  padding-top: 1px;
  background-color: ${(props) =>
    props.dark ? '#c6c6c6' : props.theme.white_70};
  max-width: 360px;
  height: 0px !important;
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #e6e6e6;
  z-index: -1;
`;

const BackgroundImage = styled.img`
  ${(props) => (props.contain ? '' : 'min-height: 100%; min-width: 100%;')}
  height: ${(props) => (props.contain ? '100%' : '100%')};
  width: ${(props) => (props.contain ? 'unset' : '100%')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

const Header = styled.h2`
  color: ${(props) => (props.dark ? props.theme.charcoal : 'white')};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '22px' : '40px')};
  line-height: ${(props) => (props.mobile ? '1.33' : '1.09')};
  width: ${(props) => (props.mobile ? '100%' : '420')}px;
  ${(props) => (props.mobile ? 'margin: 0 22px' : '')}
  margin:0;
`;

const HeaderContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 48px;
  max-width: ${(props) => (props.mobile ? '360px' : '1170px')};
  align-self: ${(props) => (props.mobile ? 'center' : undefined)};
  ${(props) => (props.mobile ? '' : 'margin: 0 auto; left: 0; right: 0;')}
  ${(props) => (props.mobile ? '' : 'padding: 0 101px;')}
`;

const renderItem = (item, mobile, dark, contain) => {
  // if no per-slide config, use carousel config
  const darkArg =
    item.config && typeof item.config.textDark === 'boolean'
      ? item.config.textDark
      : dark;
  const containArg =
    item.config && typeof item.config.contain === 'boolean'
      ? item.config.contain
      : contain;

  let background = (
    <BackgroundImage
      contain={containArg}
      src={item.images.background.desktop.x1}
      srcSet={`${item.images.background.desktop.x2} 2x, ${item.images.background.desktop.x3} 3x`}
      alt={'Slide Image'}
    />
  );

  if (mobile) {
    background = (
      <BackgroundImage
        mobile
        contain={containArg}
        src={item.images.background.mobile.x1}
        srcSet={`${item.images.background.mobile.x2} 2x, ${item.images.background.mobile.x3} 3x`}
        alt={'Slide Image'}
      />
    );
  }

  return (
    <ContentContainer contain={containArg} mobile={mobile}>
      <HeaderContainer mobile={mobile}>
        <Header dark={darkArg} mobile={mobile}>
          {item.copy.header}
        </Header>
      </HeaderContainer>
      <ImageContainer>{background}</ImageContainer>
      <BoundingContainer mobile={mobile}>
        <TextSection mobile={mobile}>
          <PageBreak dark={darkArg} mobile={mobile} />
          <DescriptionHeader dark={darkArg} mobile={mobile}>
            {item.copy.title}
          </DescriptionHeader>
          <DescriptionContent dark={darkArg} mobile={mobile}>
            {item.copy.content}
          </DescriptionContent>
        </TextSection>
      </BoundingContainer>
    </ContentContainer>
  );
};

const items = (content, dark, contain) => {
  return content.map((item, itr) => {
    return (
      <div key={itr}>
        <Desktop>{renderItem(item, false, dark, contain)}</Desktop>
        <Mobile>{renderItem(item, true, dark, contain)}</Mobile>
      </div>
    );
  });
};

export default items;
