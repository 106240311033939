import React from 'react';

export const AppContext = React.createContext({
  locale: 'en',
  direction: 'ltr',
  labels: {},
  getLabel: () => {},
  isRtl: () => {}
});

export const withAppContext = (Component) => (props) =>
  (
    <AppContext.Consumer>
      {(context) => (
        <Component
          {...props}
          locale={context.locale}
          direction={context.direction}
          getLabel={context.getLabel}
          isRtl={context.isRtl}
        />
      )}
    </AppContext.Consumer>
  );
