import React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="20"
    viewBox="0 0 28 20"
    style={props.style}
  >
    <g fill="none" fillRule="evenodd">
      <rect
        width="26"
        height="18"
        x="1"
        y="1"
        fillRule="nonzero"
        stroke="#FFF"
        strokeOpacity=".7"
        strokeWidth="2"
        rx="4"
      />
      <path
        fill="#FFF"
        fillOpacity=".7"
        d="M13.545 6.602v1.696a5.14 5.14 0 0 0-2.378-.601c-1.745 0-2.845 1.263-2.845 2.803 0 1.552 1.088 2.803 2.785 2.803 1.111 0 1.96-.324 2.498-.601v1.72c-.442.241-1.422.578-2.63.578-2.593 0-4.494-1.961-4.494-4.5 0-2.527 1.9-4.5 4.554-4.5 1.207 0 2.068.361 2.51.602zm8.395 0v1.696a5.14 5.14 0 0 0-2.378-.601c-1.745 0-2.845 1.263-2.845 2.803 0 1.552 1.088 2.803 2.785 2.803 1.112 0 1.96-.324 2.498-.601v1.72c-.442.241-1.422.578-2.63.578-2.593 0-4.494-1.961-4.494-4.5 0-2.527 1.9-4.5 4.554-4.5 1.207 0 2.068.361 2.51.602z"
      />
    </g>
  </svg>
);
