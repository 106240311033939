import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Desktop, Mobile } from '../responsive/Breakpoints';
import DownArrowIcon from '../svgs/DownArrowIcon';
import theme from '../../util/theme';
import { scroller } from 'react-scroll/modules';

import bgImg_2x from '../../assets/images/bg-about-us@2x.jpg';
import bgImg_3x from '../../assets/images/bg-about-us@3x.jpg';
import bgImgSmall_2x from '../../assets/images/bg-about-us-s@2x.jpg';
import bgImgSmall_3x from '../../assets/images/bg-about-us-s@3x.jpg';
import WhiteButton from '../buttons/WhiteButton';
import { AuthContext } from '../context/AuthenticatorContext';
import { withAppContext } from '../context/AppContext';
import { TOASTER_NOTIFICATION_HEIGHT } from '../../util/constants';

const Container = styled.div`
  height: auto;
  width: 100%;
`;

const AboutUsHeroContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${(props) => props.toasterOffset}px);
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
`;

const AboutUsTitle = styled.h1`
  display: flex;
  font-size: ${(props) => (props.isMobile ? '1.625rem' : '3.5rem')};
  letter-spacing: ${(props) => (props.isMobile ? 'normal' : '2px')};
  line-height: 1.17;
  text-align: center;
  margin-bottom: ${(props) => (props.isMobile ? '20' : '2')}vh;
  margin-top: 0;
  padding: 0 22px;
`;

const BookExperienceButtonContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  margin-bottom: 20vh;
`;

const Anchor = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.white_25};
  border-radius: 3px;
  color: white;
  pointer-events: all;
  cursor: pointer;
  margin-bottom: ${(props) => (props.isMobile ? '10vh' : '10vh')};
  padding: 10px;
  position: absolute;
  bottom: 0;
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

class AboutUsHeroSectionWithContext extends Component {
  state = {
    aboutUsDetails: null,
    navbar: null
  };

  componentDidMount() {
    const aboutUsDetails = document.getElementById('about-us-details');
    const navbar = document.getElementById('navbar');

    this.setState({ aboutUsDetails, navbar });
  }

  navToAdventures = () => {
    this.props.history.push('/adventures');
  };

  renderHeroContainer(isMobile) {
    const { getLabel } = this.props;

    const toasterOffset = this.props.parentState.hasStoredSite
      ? 0
      : TOASTER_NOTIFICATION_HEIGHT;

    let background = (
      <BackgroundImage
        src={bgImg_2x}
        srcSet={`${bgImg_2x} 2x, ${bgImg_3x} 3x`}
        alt="About Us Background"
      />
    );

    if (isMobile) {
      background = (
        <BackgroundImage
          src={bgImgSmall_2x}
          srcSet={`${bgImgSmall_2x} 2x, ${bgImgSmall_3x} 3x`}
          alt="About Us Mobile Background"
        />
      );
    }

    return (
      <AboutUsHeroContainer isMobile={isMobile} toasterOffset={toasterOffset}>
        {background}
        <AboutUsTitle isMobile={isMobile}>
          {getLabel('aboutus_hero_title')}
        </AboutUsTitle>

        <BookExperienceButtonContainer>
          <WhiteButton
            width={isMobile ? 292 : 325}
            onClick={this.navToAdventures}>
            {getLabel('aboutus_hero_button_text')}
          </WhiteButton>
        </BookExperienceButtonContainer>

        <Anchor
          isMobile={isMobile}
          onClick={() => this.handleScrollToDetailsSection(toasterOffset)}>
          <DownArrowIcon color={theme.white_80} />
        </Anchor>
      </AboutUsHeroContainer>
    );
  }

  scrollTo = (val, offset) => {
    scroller.scrollTo('about-us-details', {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset
    });
  };

  handleScrollToDetailsSection = (toasterOffset) => {
    const { aboutUsDetails, navbar } = this.state;
    if (!navbar || !aboutUsDetails) {
      return;
    }
    this.scrollTo(
      aboutUsDetails.offsetHeight,
      -navbar.offsetHeight - toasterOffset
    );
  };

  render() {
    return (
      <Container name="about-us-hero-container">
        <Desktop>{this.renderHeroContainer()}</Desktop>

        <Mobile>{this.renderHeroContainer(true)}</Mobile>
      </Container>
    );
  }
}

const AboutUsHeroSection = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <AboutUsHeroSectionWithContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(AboutUsHeroSection));
