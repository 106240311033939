import { deviceType } from 'mobile-device-detect';
import populateEcomItemData from '../util/populate-ecom-item-data';

export function sendVirtualPVEvent() {
  //get stores user data
  const user = window._user || {};

  window.dataLayer.push({
    event: 'virtual_pv',
    user: {
      id: user.mafid || null,
      voxMemberId: user.voxMemberId || null,
      firstName: user.firstName || null,
      lastName: user.lastName || null,
      dob: user.dateOfBirth || null,
      email: user.email || null,
      mobile: user.mobile || null,
      hashed_email: user.hashed_email || null,
      hashed_phone: user.hashed_phone || null,
      registration_date: null,
      registration_timestamp: null,
      type: user.uuid ? 'member' : 'guest',
      country: null,
      city: null,
      gender: user.title || null,
      nationality: user.nationality || null,
      membership_status: null
    },
    page: {
      path: document.location.pathname,
      url: document.location.href,
      referral: document.referrer,
      location: document.location,
      type: null,
      category: null,
      title: document.title,
      description_length: null,
      breadcrumb: null,
      device: deviceType || 'desktop',
      platform: 'web'
    }
  });
}

export function sendCustomEvent(eventObject) {
  window.dataLayer.push({
    event: 'custom_event',
    event_category: null,
    event_action: null,
    event_label: null,
    event_non_interaction: false,
    ...eventObject
  });
}

export function sendEcomEvent(
  eventAction,
  eventEcommerceData = {},
  { siteId, adventures, orderDetails }
) {
  const items = populateEcomItemData(siteId, adventures, orderDetails);
  const { event_non_interaction = false, ...restEventEcommerceData } =
    eventEcommerceData || {};
  // clear the previous ecommerce object.
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: 'ecom_event',
    event_category: 'ecommerce',
    event_action: eventAction,
    event_label: null,
    event_non_interaction: event_non_interaction ? true : false,
    ecommerce: {
      ...restEventEcommerceData,
      items
    }
  });
}

export function sendMenuEvent(type, cat1 = null, cat2 = null) {
  sendCustomEvent({
    event_category: 'click',
    event_action: type,
    event_label: cat1,
    menu_type: type,
    menu_cat1: cat1,
    menu_cat2: cat2
  });
}

export function sendSocialMediaIconEvent(mediaName) {
  sendCustomEvent({
    event_category: 'click',
    event_action: 'social_media_icon',
    event_label: mediaName,
    social_media_icon_name: mediaName
  });
}

export function sendSetLocation(title, country, city) {
  if (title && country && city)
    sendCustomEvent({
      event_category: 'set_location',
      event_action: title,
      event_label: `${city},${country}`,
      event_non_interaction: false,
      place: title,
      city,
      country
    });
}

export function sendSignInEvent() {
  sendCustomEvent({
    event_category: 'membership',
    event_action: 'login',
    event_label: null
  });
}
