import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'moment-timezone';
import { withAuth0 } from '@auth0/auth0-react';

import {
  Desktop,
  Mobile,
  Large,
  Phone
} from '../components/responsive/Breakpoints';
import { BlueButton, BlueOutlineButton } from '../components/buttons/index';
import { DIApi } from '../util/api';
import { CURRENCY_SYMBOL } from '../util/constants';
import { calculatePriceAndFees } from '../util/common';
import { sendEcomEvent } from '../util/analytics';
import AdventureThumbnail from '../components/AdventureThumbnail';
import LoadingSpinner from '../components/LoadingSpinner';

import checkWhite from '../assets/images/check_white_192x192.svg';
import adaWhite from '../assets/images/img-adaw-small.svg';
import LocationIcon from '../components/svgs/LocationIcon';
import { withAppContext } from '../components/context/AppContext';
import { getUserSessionId, saveUserSessionId } from '../util/userSession'

const Container = styled.div`
  height: 100%;
  margin: 0 ${(props) => (props.mobile ? '20px' : '')} auto;
  text-align: center;
`;

const AdventureThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.mobile ? '24px 0 18px 0' : '52px 0')};
`;

const SelectNumberHeader = styled.div`
  font-size: ${(props) => (props.mobile ? '1.625rem' : '2.75rem')};
  line-height: ${(props) => (props.mobile ? '1.33' : '1.09')};
  font-weight: bold;
  color: ${(props) => props.theme.charcoal};
`;

const SubHeader = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  color: ${(props) => props.theme.charcoal_60};
`;

const TicketNumberHolder = styled.div`
  vertical-align: middle;
  display: inline-block;
  width: 115px;
  font-family: ${(props) => props.theme.serif};
  font-size: 2.25rem;
  line-height: 1.17;
  color: ${(props) => props.theme.charcoal_90};
`;

const SelectionArea = styled.div`
  display: flex;
  ${(props) => (props.mobile ? '' : 'height: 80px')};
  margin: ${(props) => (props.mobile ? '44px auto' : '36px auto')};
  align-items: center;
  justify-content: ${(props) => (props.mobile ? 'space-between' : 'center')};
  ${(props) => (props.mobile ? 'max-width: ' + props.maxWidth + 'px;' : '')}
`;

const SeatContainer = styled.div`
  display: inline-block;
  margin: ${(props) => (props.mobile ? '0' : '13px')};
  width: 28px;
  height: 28px;
  border: solid 1px ${(props) => props.theme.ds_bright_blue};
  cursor: pointer;
  border-radius: 3px;
`;

const Seat = styled.div`
  width: 24px;
  height: 24px;
  margin: 2px;
  background-color: ${(props) =>
    props.checked ? props.theme.ds_dusk_blue : 'transparent'};
  border-radius: 3px;
`;

const TakenSeat = styled.div`
  display: inline-block;
  margin: ${(props) => (props.mobile ? '0' : '13px')};
  width: 28px;
  height: 28px;
  background-color: ${(props) => props.theme.charcoal_60};
  border-radius: 3px;
`;

const ContinueButton = styled(BlueButton)`
  margin-top: ${(props) => (props.mobile ? '0' : '20px')};
  font-family: ${(props) => props.theme.sans_serif};
  width: ${(props) => (props.mobile ? '100%' : '300px')};
  font-size: 0.8rem;
  letter-spacing: 1.5px;
`;

const ExtraTicketingText = styled.div`
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
`;

const NumberContainer = styled.div`
  margin-top: ${(props) => (props.mobile ? '18px' : '25px')};
  line-height: 1.17;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlusMinusContainer = styled(BlueOutlineButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 47px;
  position: relative;
`;

const HorizontalBar = styled.div`
  width: 14px;
  height: 2px;
  background-color: ${(props) => props.theme.charcoal_90};
`;

const VerticalBar = styled.div`
  width: 2px;
  height: 14px;
  background-color: ${(props) => props.theme.charcoal_90};
  position: absolute;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  display: block;
  padding: 2px;
`;

const MobileButtonFooterSpacer = styled.div`
  height: 150px;
`;

const MobileButtonFooter = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
`;

const MobileSubmitBtnContainer = styled.div`
  padding: 22px;
  background-color: ${(props) => props.theme.white_80};
  border-top: 1px solid ${(props) => props.theme.ds_dusk_blue};
`;

const LocationSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (props.mobile ? '9px' : '22px')};
  font-size: 0.8125rem;
  font-weight: bold;
`;

class CheckoutTicketSelector extends Component {
  state = {
    numTicketsSelected: 1,
    continueLoading: false,
    totalPrice: 0,
    estimatedPrice: 0,
    dataLoading: true,
    minOrderAmount: 1,
    orderReference: '',
    currency: 'SAR',
    numOpenSeats: 0,
    packageTickets: []
  };

  componentDidMount() {
    document.body.style.background =
      'radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6) fixed center';

    const { en, tlc } = this.props.location.state.adventure;

    this.props.updateHeaderText({
      title: `${en.shortname.toUpperCase()} (${tlc.toUpperCase()})`
    });
    this.getEstimatedPriceForTicket();
    this.setState({numOpenSeats: this.props.location.state.numOpenSeats});
  }

  componentWillUnmount() {
    document.body.style.background = 'black';
  }

  getEstimatedPriceForTicket = async () => {
    //get estimated price
    const { showtime, ada } = this.props.location.state;
    try {
      const event = await DIApi.getEstimatedPriceForExperience(
        showtime.partner_site_id,
        showtime.partner_experience_id,
        showtime.datetime
      );
      if (event) {
        const stateUpdates = {
          estimatedPrice: ada
            ? event.pricing.ada.toFixed(2)
            : event.pricing['general-admission'].toFixed(2),
          dataLoading: false,
          minOrderAmount: event.order_minimum,
          currency: event.currency
        };

        if (event.packageTickets && event.packageTickets.length) {
          stateUpdates.packageTickets = event.packageTickets;
        }

        stateUpdates.numOpenSeats = event.quantity;

        if (event.order_minimum) {
          stateUpdates.minOrderAmount = event.order_minimum;

          if (this.state.numTicketsSelected < event.order_minimum) {
            stateUpdates.numTicketsSelected = event.order_minimum;
          }
        }

        this.setState(stateUpdates);
      }
    } catch(err) {
      alert('Tickets have been booked, please choose some other time slot');
      this.setState({ continueLoading: false });
      // Redirect back to the showtime page
      this.props.history.replace(
        `/adventures/details/${this.props.location.state.adventure.title_id}`,
        {
          site: this.props.location.state.site,
          adventure: this.props.location.state.adventure
        }
      );
      return
    }
  };

  decrementTickets = () => {
    if (this.state.numTicketsSelected > this.state.minOrderAmount) {
      const numTickets = this.state.numTicketsSelected - 1;
      this.setState({ numTicketsSelected: numTickets });
    }
  };

  incrementTickets = () => {
    let openSeats = this.state.numOpenSeats;
    if (this.state.numTicketsSelected < openSeats) {
      const numTickets = this.state.numTicketsSelected + 1;
      this.setState({ numTicketsSelected: numTickets });
    }
  };

  selectTickets = (index) => {
    let openSeats = this.state.numOpenSeats;
    let ticketCount = index;

    if (ticketCount < this.state.minOrderAmount) {
      ticketCount = this.state.minOrderAmount;
    }

    if (ticketCount <= openSeats) {
      return this.setState({ numTicketsSelected: ticketCount });
    }
  };

  buildOrderDetails(
    site,
    ada,
    showtime,
    ticketHold,
    quantity,
    adaQuantity,
    ticketPriceInfo
  ) {
    const showTimeMoment = new Moment(showtime.datetime);
    /*const time = showTimeMoment
      .tz(site.tz)
      .format('ddd MM/DD/YY [AT] h:mm A')
      .toUpperCase();*/
    const time = showTimeMoment
      .format('ddd MM/DD/YY [AT] h:mm A')
      .toUpperCase();
    const curSymbol = ticketHold.currency
      ? CURRENCY_SYMBOL[ticketHold.currency] || '$'
      : '$';
    const totalCost = `${curSymbol}${ticketPriceInfo.totalPrice.toFixed(2)}`;
    const total = ticketPriceInfo.totalPrice;
    const ticketNum = ticketHold.quantity;
    const ticketCostBreakdown = `${ticketNum} x ${curSymbol}${ticketPriceInfo.generalAdmissionPrice.toFixed(
      2
    )}`;
    const location = site.site_legalname.toUpperCase();

    return {
      location,
      time,
      ticketNum,
      ada,
      totalCost,
      total: total,
      quantity: quantity,
      ada_quantity: adaQuantity,
      ticketCostBreakdown,
      ticketFees: ticketPriceInfo.ticketFees,
      showtime
    };
  }

  async handleContinue(totalQuantity, adaQuantity) {
    this.setState({ continueLoading: true });
    const { ada, adventure, site, showtime, locationAssets } =
      this.props.location.state;
    const { en, tlc } = adventure;

    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;

    const token = isAuthenticated
      ? await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid offline_access email profile'
        })
      : null;

    const userSessionId = getUserSessionId()
    const heldTickets = await DIApi.holdTickets(
      showtime.partner_site_id,
      showtime.partner_experience_id,
      showtime.datetime,
      totalQuantity,
      showtime.ticketed_gearup,
      {
        siteName: site.site_name,
        siteLegalName: site.site_legalname,
        siteShortName: site.site_shortname,
        city: site.city,
        state: site.state,
        shortName: en.shortname,
        tlc,
        titleId: adventure.title_id,
        siteId: site.site_id
      },
      token,
      userSessionId
    );

    if (!heldTickets || !heldTickets.currency) {
      alert('Hold ticket failed!');
      this.setState({ continueLoading: false });
      // Redirect back to the showtime page
      this.props.history.replace(
        `/adventures/details/${this.props.location.state.adventure.title_id}`,
        {
          site: this.props.location.state.site,
          adventure: this.props.location.state.adventure
        }
      );
      return
    }

    saveUserSessionId(heldTickets.user_session_id)

    const ticketPriceInfo = calculatePriceAndFees(heldTickets);
    const orderDetails = this.buildOrderDetails(
      site,
      ada,
      showtime,
      heldTickets,
      totalQuantity,
      adaQuantity,
      ticketPriceInfo
    );

    // Send "Initiate Checkout" analytic event
    // google tag manager
    sendEcomEvent('add_to_cart', null, {
      siteId: site.site_id,
      adventures: [adventure],
      orderDetails
    });

    this.props.history.replace(`/checkout/signin`, {
      ticketHold: heldTickets,
      totalPrice: heldTickets.total,
      ada,
      adventure,
      site,
      showtime,
      orderDetails,
      locationAssets
    });
  }

  generateSeats = (mobile) => {
    let maxSeats = this.props.location.state.maxSeats;
    let takenSeats = maxSeats - this.state.numOpenSeats;
    let selectedSeats = takenSeats + this.state.numTicketsSelected;
    const Seats = [];
    for (let i = 0; i < maxSeats; i++) {
      if (i < takenSeats) {
        Seats.push(<TakenSeat mobile={mobile} key={i} />);
      } else if (i < selectedSeats) {
        let img = (
          <Image
            style={{ width: '16px', margin: '0 auto' }}
            src={checkWhite}
            alt="Check"
          />
        );
        if (i === takenSeats && this.props.location.state.ada) {
          img = <Image src={adaWhite} alt="Check" />;
        }
        Seats.push(
          <SeatContainer
            mobile={mobile}
            key={i}
            onClick={this.selectTickets.bind(this, i - takenSeats + 1)}>
            <Seat checked>{img}</Seat>
          </SeatContainer>
        );
      } else {
        Seats.push(
          <SeatContainer
            mobile={mobile}
            key={i}
            onClick={this.selectTickets.bind(
              this,
              this.state.numTicketsSelected + i - selectedSeats + 1
            )}>
            <Seat />
          </SeatContainer>
        );
      }
    }
    const numSeats = Seats.length;
    const numMargins = numSeats - 1;
    const selectionMaxWidth = 32 * numSeats + 26 * numMargins;
    const seatWidth = 32;
    const minMarginBetween = 3;
    const outerMargins = 20;
    const minimumWidthNeeded =
      seatWidth * numSeats + minMarginBetween * numMargins + outerMargins * 2;
    if (window.innerWidth < minimumWidthNeeded) {
      const splitNumber = Math.ceil(Seats.length / 2);
      const ratio = (Seats.length - splitNumber) / splitNumber;
      const overflowMaxWidth = (window.innerWidth - outerMargins * 2) * ratio;
      return (
        <div>
          <SelectionArea mobile maxWidth={selectionMaxWidth}>
            {Seats.slice(0, splitNumber)}
          </SelectionArea>
          <SelectionArea
            mobile
            style={{ marginTop: '10px' }}
            maxWidth={overflowMaxWidth}>
            {Seats.slice(splitNumber)}
          </SelectionArea>
        </div>
      );
    }
    if (mobile) {
      return (
        <SelectionArea mobile maxWidth={selectionMaxWidth}>
          {Seats}
        </SelectionArea>
      );
    } else {
      return <SelectionArea>{Seats}</SelectionArea>;
    }
  };

  continueButton = (mobile, copy) => {
    const buttonContent = this.state.continueLoading ? (
      <LoadingSpinner />
    ) : (
      copy
    );
    const Continue = (
      <ContinueButton
        mobile={mobile}
        disabled={this.state.continueLoading}
        onClick={() =>
          this.handleContinue(
            this.state.numTicketsSelected,
            this.props.location.state.ada ? 1 : 0
          )
        }>
        {buttonContent}
      </ContinueButton>
    );

    if (mobile) {
      return (
        <MobileButtonFooter>
          <MobileSubmitBtnContainer name="mobile-proceed-btn">
            {Continue}
          </MobileSubmitBtnContainer>
        </MobileButtonFooter>
      );
    }

    return Continue;
  };

  getPackageTicketPrice() {
    if (!this.state.packageTickets.length) {
      return this.state.estimatedPrice > 0 ? this.state.estimatedPrice : '';
    }

    const packageTicket = this.state.packageTickets.find(packageTicket => packageTicket.Quantity === this.state.numTicketsSelected)

    if (!packageTicket) {
      return this.state.estimatedPrice > 0 ? this.state.estimatedPrice : '';
    }

    return ((packageTicket.PriceInCents / 100) / this.state.numTicketsSelected).toFixed(2)
  }

  renderSelectorContents(isMobile) {
    if (this.state.dataLoading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh'
          }}>
          <LoadingSpinner dark />
        </div>
      );
    }
    const { getLabel } = this.props;
    const SeatList = this.generateSeats(isMobile);
    const { adventure, ada, site, locationAssets } = this.props.location.state;

    const basePrice = this.getPackageTicketPrice()
    const minAge = adventure.licensed_site.min_age;
    const currencySymbol = `${this.state.currency} `

    const tagline = locationAssets.copy.customPricingLines[0];
    const travelerInfo =
      locationAssets.copy.travelerAgeCopy[adventure.title_id] ||
      locationAssets.copy.travelerAgeCopy.default;
    const ticketingInfo = (
      <span>
        {tagline}<br/>
        {getLabel('checkout_general_admission')} {currencySymbol}
        {basePrice}
        <br />
        {travelerInfo}
      </span>
    );
    const selectNumTicketsHeader = getLabel(
      'checkout_select_number_of_tickets'
    );
    const location = site.site_legalname
      ? `${site.site_legalname}, ${site.city}, ${site.state}`
      : 'NO LOCATION';

    const extraTicketingDescLines =
      locationAssets.copy.ticketingExtraDescLines &&
      locationAssets.copy.ticketingExtraDescLines.length
        ? locationAssets.copy.ticketingExtraDescLines.map((line, index) => (
            <ExtraTicketingText key={`tix-desc-${index}`} mobile={isMobile}>
              {line}
            </ExtraTicketingText>
          ))
        : null;
    return (
      <React.Fragment>
        <AdventureThumbnailContainer mobile={isMobile}>
          <AdventureThumbnail ada={ada} titleId={adventure.title_id} />
        </AdventureThumbnailContainer>

        <SelectNumberHeader
          mobile={isMobile}
          dangerouslySetInnerHTML={{
            __html: selectNumTicketsHeader
          }}></SelectNumberHeader>
        <SubHeader mobile={isMobile}>{ticketingInfo}</SubHeader>
        <LocationSelectionContainer mobile={isMobile}>
          <span>{location.toUpperCase()}</span>
          <LocationIcon color={'#000000'} />
        </LocationSelectionContainer>

        <NumberContainer mobile={isMobile}>
          <PlusMinusContainer onClick={this.decrementTickets}>
            <HorizontalBar />
          </PlusMinusContainer>
          <TicketNumberHolder>
            {this.state.numTicketsSelected}
          </TicketNumberHolder>
          <PlusMinusContainer onClick={this.incrementTickets}>
            <HorizontalBar />
            <VerticalBar />
          </PlusMinusContainer>
        </NumberContainer>

        {SeatList}

        {extraTicketingDescLines}
        <Large>{this.continueButton(false, getLabel('checkout_next'))}</Large>
        <Phone>
          <MobileButtonFooterSpacer />
          {this.continueButton(true, getLabel('checkout_next'))}
        </Phone>
      </React.Fragment>
    );
  }

  renderSelector(mobile) {
    // disable interaction if loading
    const style = this.state.continueLoading ? { pointerEvents: 'none' } : {};
    return (
      <Container style={style} mobile={mobile}>
        {this.renderSelectorContents(mobile)}
      </Container>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Desktop>{this.renderSelector(false)}</Desktop>
        <Mobile>{this.renderSelector(true)}</Mobile>
      </React.Fragment>
    );
  }
}

export default withRouter(withAppContext(withAuth0(CheckoutTicketSelector)));
