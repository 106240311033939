import React, { Component } from 'react';
import styled from 'styled-components';
import { RedButton, RedOutlineButton } from '../buttons';
import { withRouter } from 'react-router-dom';
import { withAppContext } from '../context/AppContext';
import { BasicInputField as Input } from '../forms/index';
import ShareLogo from '../../assets/images/share/share-logo.png';

const Image = styled.img`
  width: 7em;
  margin-bottom: 0.5em;
  display: block;
  object-fit: contain;
`;

const Label = styled.p`
  margin: 0.25em 0;
  font-size: 0.9em;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;
  gap: 0.5em;
  input {
    border-color: gray;
  }
`;

const Dot = styled.span`
  height: 3px;
  width: 3px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
`;

const ShareWrapper = styled.div``;

const RedeemInput = styled(Input)`
  border: ${(props) =>
    props.isInvalid ? '1px solid red !important' : 'parent'};
`;

class ShareForm extends Component {
  state = {
    redeemValue: 0,
    redeemDecimalValue: 0,
    isValidRedeemPoints: false
  };

  componentDidMount() {
    this.splitDecimalValue(this.props.sharePoints);
  }

  splitDecimalValue(value) {
    let data = value.split('.');

    this.setState({
      redeemValue: data[0],
      redeemDecimalValue: data[1]
    });
  }

  loader(dark) {
    if (dark) {
      return (
        <div className="spinner" style={{ margin: '0 auto', height: '32px' }}>
          <div className="rect1" style={{ backgroundColor: '#B30D4D' }} />
          <div className="rect2" style={{ backgroundColor: '#B30D4D' }} />
          <div className="rect3" style={{ backgroundColor: '#B30D4D' }} />
          <div className="rect4" style={{ backgroundColor: '#B30D4D' }} />
          <div className="rect5" style={{ backgroundColor: '#B30D4D' }} />
        </div>
      );
    } else {
      return (
        <div className="spinner" style={{ margin: '0 auto', height: '32px' }}>
          <div className="rect1" style={{ backgroundColor: '#e6e6e6' }} />
          <div className="rect2" style={{ backgroundColor: '#e6e6e6' }} />
          <div className="rect3" style={{ backgroundColor: '#e6e6e6' }} />
          <div className="rect4" style={{ backgroundColor: '#e6e6e6' }} />
          <div className="rect5" style={{ backgroundColor: '#e6e6e6' }} />
        </div>
      );
    }
  }

  onHandleChange(event) {
    if (event.target.name === 'redeem_value') {
      this.setState({
        redeemValue: event.target.value
      });
    } else {
      this.setState({
        redeemDecimalValue: event.target.value
      });
    }
  }

  handleSubmit = () => {
    const redeemTotal = parseFloat(
      this.state.redeemValue + '.' + this.state.redeemDecimalValue
    );
    let totalInCents = Math.round(redeemTotal * 100);
    totalInCents =
    Math.trunc(totalInCents * 100) / 100;
    this.props.addRedeemSubmit(totalInCents.toString());
  };

  render() {
    const { isRedeemed, isRedeemLoading } = this.props;
    return (
      <ShareWrapper>
        <Image src={ShareLogo} />
        <Label>
          {this.props.getLabel('share_redeem_info_1')}{' '}
          <b>
            {this.props.sharePoints}
            &nbsp;{this.props.getLabel('share_currency_sar')}
          </b>{' '}
          {this.props.getLabel('share_redeem_info_2')}
        </Label>
        <ShareWrapper>
          <InputRow>
            <RedeemInput
              name="redeem_value"
              type="number"
              placeholder="Redeem Value"
              label=" "
              required
              value={this.state.redeemValue}
              flex={1}
              onChange={(event) => this.onHandleChange(event)}
              isInvalid={this.state.isValidRedeemPoints}
              disabled={isRedeemed}
            />
            <Dot />
            <RedeemInput
              name="redeemDecimal_value"
              type="number"
              placeholder="00"
              label=" "
              value={this.state.redeemDecimalValue}
              width={96}
              onChange={(event) => this.onHandleChange(event)}
              isInvalid={this.state.isValidRedeemPoints}
              disabled={isRedeemed}
            />
            <Label>{this.props.getLabel('share_currency_sar')}</Label>
          </InputRow>
          {!isRedeemed ? (
            <RedButton
              type="button"
              width="100%"
              onClick={() => this.handleSubmit()}>
              {!isRedeemLoading
                ? this.props.getLabel('share_redeem')
                : this.loader()}
            </RedButton>
          ) : (
            <RedOutlineButton
              type="button"
              width="100%"
              onClick={this.props.deleteRedeemSubmit}>
              {!isRedeemLoading
                ? this.props.getLabel('share_remove_discount')
                : this.loader(true)}
            </RedOutlineButton>
          )}
        </ShareWrapper>
      </ShareWrapper>
    );
  }
}

export default withRouter(withAppContext(ShareForm));
