import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Large, Phone } from '../components/responsive/Breakpoints';
import { withAppContext } from '../components/context/AppContext';
import styled from 'styled-components';
import NavSpacer from '../components/NavbarSpacer';
import visorLogo from '../assets/images/img-ds-visor-logo-l.svg';
import poweredByOculus from '../assets/images/img-oculus-l.svg';
import poweredByOculusSmall from '../assets/images/img-oculus-s.svg';
import ViconLogo_2x from '../assets/images/img-vicon-logo@2x.png';
import ViconLogo_3x from '../assets/images/img-vicon-logo@3x.png';
import XolaLogo_2x from '../assets/images/img-xola-logo@2x.png';
import XolaLogo_3x from '../assets/images/img-xola-logo@3x.png';
import ZotacLogo_2x from '../assets/images/img-zotac-logo@2x.png';
import ZotacLogo_3x from '../assets/images/img-zotac-logo@3x.png';
import oculusHeadsetSmall from '../assets/images/img-oculus-headset-s.jpg';
import oculusHeadsetSmall_2x from '../assets/images/img-oculus-headset-s@2x.jpg';
import oculusHeadsetSmall_3x from '../assets/images/img-oculus-headset-s@3x.jpg';

const LogoBackground = styled.div`
  background-image: radial-gradient(#fcfcfc, #e6e6e6);
`;

const PartnersBackground = styled.div`
  background: #f6f6f6;
`;

const PartnersFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  margin: 0 auto;
  text-align: center;
  padding: ${(props) => (props.mobile ? '67px 56px' : '107px 68px 141px')};
  max-width: 1170px;
  margin: 0 auto;
  justify-content: center;
`;

const Partner = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 ${(props) => (props.mobile ? '20' : '30')}px;
  ${(props) => (props.mobile ? 'padding-bottom: 20px;' : '')}
  max-width: 250px
`;

const PartnerImageContainer = styled.div`
  height: 31px;
  padding: 25px 0;
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.charcoal_25};
  width: 100%;
  margin-bottom: 20px;
`;

const PartnerHeader = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-weight: bold;
  font-size: 1rem;
  text-align: start;
  color: ${(props) => props.theme.charcoal_90};
`;

const PartnerSubHeader = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
  text-align: start;
  color: ${(props) => props.theme.charcoal_60};
`;

const LogoContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${(props) => (props.mobile ? '6px 22px 99px' : '99px 98px 119px')};
  max-width: 1170px;
  margin: 0 auto;
`;

const OculusContainer = styled.div`
  background: #f6f6f6;
  ${(props) => (props.mobile ? '' : 'height: 350px;')}
`;

const OculusFlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
`;

const PoweredByOculusSection = styled.div`
  flex: 1;
  ${(props) => (props.mobile ? '' : 'flex-grow: 1;')}
  text-align: ${(props) => (props.mobile ? 'center' : 'end')};
  margin: auto 0;
  ${(props) => (props.mobile ? 'padding-top: 36px;' : '')}
`;

const OculusSection = styled.div`
  flex: 1;
  flex-grow: 1;
  ${(props) => (props.mobile ? 'text-align: center;' : 'min-width: 466px')}
`;

const OculusImage = styled.img`
  height: 100%;
  ${(props) => (props.mobile ? 'vertical-align: bottom;' : '')}
  max-width: 100%;
`;

const DILogo = styled.img`
  max-width: ${(props) => (props.mobile ? '230' : '423')}px;
  max-height: ${(props) => (props.mobile ? '75' : '139')}px;
  ${(props) => (props.mobile ? 'padding-top: 50px;' : '')}
`;

const HeaderText = styled.div`
  margin: auto;
  padding-top: ${(props) => (props.mobile ? '20' : '29')}px;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '1.625rem' : '3.5rem')};
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.31' : '1.09')};
  letter-spacing: ${(props) => (props.mobile ? '2px' : 'normal')};
  color: ${(props) => props.theme.charcoal};
  max-width: 582px;
`;

const PartnerLogoImg = styled.img`
  height: 100%;
  width: auto;
`;

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.charcoal_25};
  max-width: ${(props) => (props.mobile ? '317' : '1170')}px;
  margin: 0 auto;
`;

class Partners extends Component {
  renderWelcomeBlock(mobile) {
    const { getLabel } = this.props;
    return (
      <React.Fragment>
        <LogoBackground>
          <LogoContainer mobile={mobile}>
            <NavSpacer />
            <DILogo mobile={mobile} src={visorLogo} alt="DI Logo" />
            <HeaderText
              mobile={mobile}
              dangerouslySetInnerHTML={{
                __html: getLabel('partners_title')
              }}></HeaderText>
          </LogoContainer>
        </LogoBackground>
        <OculusContainer mobile={mobile}>
          <OculusFlexContainer mobile={mobile}>
            <PoweredByOculusSection mobile={mobile}>
              <Large>
                <img src={poweredByOculus} alt="Powered by Oculus" />
              </Large>
              <Phone>
                <img src={poweredByOculusSmall} alt="Powered by Oculus" />
              </Phone>
            </PoweredByOculusSection>
            <OculusSection mobile={mobile}>
              <OculusImage
                mobile={mobile}
                src={oculusHeadsetSmall}
                srcSet={`${oculusHeadsetSmall_2x} 2x, ${oculusHeadsetSmall_3x} 3x`}
              />
            </OculusSection>
          </OculusFlexContainer>
          <DividerLine mobile={mobile} />
        </OculusContainer>

        <PartnersBackground>
          <PartnersFlex mobile={mobile}>
            <Partner mobile={mobile}>
              <PartnerImageContainer>
                <PartnerLogoImg
                  src={XolaLogo_2x}
                  srcSet={`${XolaLogo_2x} 2x, ${XolaLogo_3x} 3x`}
                />
              </PartnerImageContainer>
              <Divider />
              <PartnerHeader>{getLabel('partners_category_1')}</PartnerHeader>
              <PartnerSubHeader>{getLabel('partners_name_1')}</PartnerSubHeader>
            </Partner>
            <Partner mobile={mobile}>
              <PartnerImageContainer>
                <PartnerLogoImg
                  src={ZotacLogo_2x}
                  srcSet={`${ZotacLogo_2x} 2x, ${ZotacLogo_3x} 3x`}
                />
              </PartnerImageContainer>
              <Divider />
              <PartnerHeader>{getLabel('partners_category_2')}</PartnerHeader>
              <PartnerSubHeader>{getLabel('partners_name_2')}</PartnerSubHeader>
            </Partner>
            <Partner mobile={mobile}>
              <PartnerImageContainer>
                <PartnerLogoImg
                  src={ViconLogo_2x}
                  srcSet={`${ViconLogo_2x} 2x, ${ViconLogo_3x} 3x`}
                />
              </PartnerImageContainer>
              <Divider />
              <PartnerHeader>{getLabel('partners_category_3')}</PartnerHeader>
              <PartnerSubHeader>{getLabel('partners_name_3')}</PartnerSubHeader>
            </Partner>
          </PartnersFlex>
        </PartnersBackground>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Large>{this.renderWelcomeBlock(false)}</Large>
        <Phone>{this.renderWelcomeBlock(true)}</Phone>
      </React.Fragment>
    );
  }
}

export default withRouter(withAppContext(Partners));
