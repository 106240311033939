import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { BlueButton } from '../components/buttons';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { DIApi } from '../util/api';
import Moment from 'moment-timezone';
import { Large, Phone } from '../components/responsive/Breakpoints';
import AdventureThumbnail from '../components/AdventureThumbnail';
import { withAuth0 } from '@auth0/auth0-react';
import { withAppContext } from '../components/context/AppContext';
import ShareButton from '../components/share/Button';
import { getUserSessionId, saveUserSessionId } from '../util/userSession';

const Container = styled.div`
  height: auto;
  width: auto;
  margin: 0 auto;
`;

const BoundingContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`;

const BoundingContentContainer = styled.div`
  max-width: 800px;
  margin: ${(props) => (props.mobile ? '61' : '54')}px auto 0;
  padding-bottom: 7px;
`;

const DetailsButton = styled(BlueButton)`
  color: #fff;
  display: block;
  margin: 25px ${(props) => (props.mobile ? '0' : 'auto')};
  border-radius: 3px;
  width: ${(props) => (props.mobile ? '100%' : '191px')};
  height: 47px;
`;

const OrderSubtypeSection = styled.div`
  ${(props) => (props.mobile ? 'padding-bottom: 23px;' : '')}
`;

const OrderSubtypeHeader = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1.875rem;
  font-weight: bold;
  padding-bottom: 29px;
  color: ${(props) => props.theme.charcoal};
`;

const PageBreak = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.charcoal_25};
`;

const TicketContentTable = styled.table`
  display: inline-block;
  padding-bottom: 83px;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
`;

const TicketContentTr = styled.tr`
  vertical-align: top;
`;

const KeyTd = styled.td`
  padding-right: 10px;
  color: ${(props) => props.theme.charcoal_60};
`;

const ValueTd = styled.td`
  font-weight: bold;
  color: ${(props) => props.theme.charcoal_90};
`;

const TicketPreview = styled.div`
  display: ${(props) => (props.mobile ? 'block' : 'inline-block')};
  ${(props) => (props.mobile ? '' : 'float: right;')}
  margin-bottom: ${(props) => (props.mobile ? '16px' : '0px')};
`;

const ShareWrapper = styled.div`
  text-align: center;
  margin: 25px ${(props) => (props.mobile ? '0' : 'auto')};
  > button {
    width: ${(props) => (props.mobile ? '100%' : '191px')};
  }
`;

class AccountViewTickets extends Component {
  state = {
    data: null,
    loadedTitle: false,
    memberDetails: null
  };

  isActive = false;
  isFetching = false;
  async componentDidMount() {
    this.isActive = true;
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    this.isActive = false;
  }

  componentDidUpdate() {
    const { isAuthenticated, user, isLoading } = this.props.auth0;
    this.handleRedirect(isAuthenticated, isLoading);
    if (!this.state.loadedTitle && isAuthenticated && user) {
      this.props.updateHeaderText({
        title: (
          <React.Fragment>
            <Large>
              {user['https://profile/firstName'].toUpperCase()}{' '}
              {user['https://profile/lastName'].toUpperCase()}
            </Large>
            <Phone>
              <span style={{ fontSize: '1.875rem' }}>
                {user['https://profile/firstName'].toUpperCase()}{' '}
                {user['https://profile/lastName'].toUpperCase()}
              </span>
            </Phone>
          </React.Fragment>
        ),
        subTitle: (
          <React.Fragment>
            <Large>{user.email}</Large>
            <Phone>
              <span style={{ fontSize: '0.875rem' }}>{user.email}</span>
            </Phone>
          </React.Fragment>
        )
      });
      if (this.isActive) {
        this.setState({ loadedTitle: true });
      }
    }
    this.setDataOrRedirect();
  }

  async setDataOrRedirect() {
    if (this.state.data !== null || this.props.auth0.isLoading) {
      return;
    }
    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;

    if (isAuthenticated) {
      if (this.isFetching) {
        return;
      }

      try {
        this.isFetching = true;
        const token = isAuthenticated
          ? await getAccessTokenSilently({
              ignoreCache: true,
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: 'openid offline_access profile'
            })
          : null;

        const userSessionId = getUserSessionId();
        const memberDetails = await DIApi.getMember(token, userSessionId);

        if (memberDetails.userSessionId) {
          saveUserSessionId(memberDetails.userSessionId);
        }

        const data = await DIApi.getUserOrders(token);

        if (this.isActive) {
          this.setState({ data, memberDetails });
        }
      } catch (e) {
        alert('Could not load data. Try reloading the page.');
        return;
      }
    } else {
      this.navToSignIn();
    }
  }

  handleShareButton = () => {
    if (!this.state.memberDetails) {
      return;
    }

    if (this.state.memberDetails.isMember) {
      const locationState = this.props.location.state;
      this.props.history.push('/account/share/details', {
        ...locationState,
        memberDetails: this.state.memberDetails
      });
      return;
    }

    this.props.history.push('/account/share/details');
  };

  handleRedirect() {
    if (!this.props.auth0.isLoading && !this.props.auth0.isAuthenticated) {
      this.navToSignIn();
    }
  }

  navToDetails = () => {
    // @TODO: redirect to account details page
    this.props.history.push('/account/details');
  };

  navToSignIn = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/account/signin', locationState);
  };

  ticketPreview(mobile, ada, titleId) {
    return (
      <TicketPreview mobile={mobile}>
        <AdventureThumbnail ada={ada} titleId={titleId} />
      </TicketPreview>
    );
  }

  ticketSection(key, mobile, header, bodyArray) {
    const { getLabel } = this.props;
    return (
      <React.Fragment key={key}>
        <OrderSubtypeSection mobile={mobile}>
          <OrderSubtypeHeader>{header}</OrderSubtypeHeader>
          {bodyArray.map((body, i) => (
            <React.Fragment key={i}>
              <Phone>
                {this.ticketPreview(mobile, body.ada, body.titleId)}
              </Phone>
              <PageBreak />
              <TicketContentTable>
                <tbody>
                  <TicketContentTr>
                    <KeyTd>{getLabel('account_details_order')}</KeyTd>
                    <ValueTd>{body.order}</ValueTd>
                  </TicketContentTr>
                  <TicketContentTr>
                    <KeyTd>{getLabel('account_details_location')}</KeyTd>
                    <ValueTd>{body.location.toUpperCase()}</ValueTd>
                  </TicketContentTr>
                  <TicketContentTr>
                    <KeyTd>{getLabel('account_details_time')}</KeyTd>
                    <ValueTd>{body.time.toUpperCase()}</ValueTd>
                  </TicketContentTr>
                  <TicketContentTr>
                    <KeyTd>{getLabel('account_details_tickets')}</KeyTd>
                    <ValueTd>{body.tickets}</ValueTd>
                  </TicketContentTr>
                  {body.ada && (
                    <TicketContentTr>
                      <KeyTd>{getLabel('account_details_accessible')}</KeyTd>
                      <ValueTd>
                        {getLabel('account_details_accessible_yes')}
                      </ValueTd>
                    </TicketContentTr>
                  )}
                  <TicketContentTr>
                    <KeyTd>{getLabel('account_details_total')}</KeyTd>
                    <ValueTd>{body.totalCost}</ValueTd>
                  </TicketContentTr>
                </tbody>
              </TicketContentTable>
              <Large>
                {this.ticketPreview(mobile, body.ada, body.titleId)}
              </Large>
            </React.Fragment>
          ))}
        </OrderSubtypeSection>
      </React.Fragment>
    );
  }

  ticketBodyObject(row) {
    return {
      order: row.transaction_short_code,
      location: row.site_name,
      time: Moment(row.ticketed_start_time).format(
        'ddd, MM/DD/YYYY [at] h:mmA'
      ),
      //.tz(this.props.authState.site.tz)
      tickets: row.total_ticket_count,
      ada: row.ada_ticket_count > 0,
      totalCost: row.transaction_total,
      titleId: row.ticketed_title_id
    };
  }

  renderShareButton(mobile) {
    if (!this.state.memberDetails) {
      return (
        <div className="spinner">
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      );
    } else {
      return (
        <ShareWrapper mobile={mobile}>
          <ShareButton shadow onClick={this.handleShareButton} />
        </ShareWrapper>
      );
    }
  }

  renderAccountTicketsPage(mobile) {
    let content = null;
    const { getLabel } = this.props;
    if (!this.state.data) {
      content = (
        <div className="spinner">
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      );
    } else {
      content = [];
      const today = Moment();
      const pastTickets = this.state.data.filter((row) =>
        Moment(row.ticketed_start_time).isBefore(today, 'day')
      );
      const todayTickets = this.state.data.filter((row) =>
        Moment(row.ticketed_start_time).isSame(today, 'day')
      );
      const upcomingTickets = this.state.data.filter((row) =>
        Moment(row.ticketed_start_time).isAfter(today, 'day')
      );

      if (todayTickets.length > 0) {
        content.push(
          this.ticketSection(
            1,
            mobile,
            getLabel('account_view_today'),
            todayTickets.map((row) => this.ticketBodyObject(row))
          )
        );
      }
      if (upcomingTickets.length > 0) {
        content.push(
          this.ticketSection(
            2,
            mobile,
            getLabel('account_view_upcoming'),
            upcomingTickets.map((row) => this.ticketBodyObject(row))
          )
        );
      }
      if (pastTickets.length > 0) {
        content.push(
          this.ticketSection(
            3,
            mobile,
            getLabel('account_view_past'),
            pastTickets.map((row) => this.ticketBodyObject(row))
          )
        );
      }
    }
    return (
      <Container>
        <BoundingContainer>
          <DetailsButton
            style={{ display: this.state.loadedTitle ? 'block' : 'none' }}
            mobile={mobile}
            invert
            onClick={this.navToDetails}>
            {this.props.getLabel('account_details_title')}
          </DetailsButton>
          {this.renderShareButton(mobile)}
          <BoundingContentContainer mobile={mobile}>
            {content}
          </BoundingContentContainer>
        </BoundingContainer>
      </Container>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Large>{this.renderAccountTicketsPage(false)}</Large>
        <Phone>{this.renderAccountTicketsPage(true)}</Phone>
      </React.Fragment>
    );
  }
}

const SignIn = (props) => (
  <AuthContext.Consumer>
    {(authState) => {
      return <AccountViewTickets {...props} authState={authState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAuth0(withAppContext(SignIn)));
