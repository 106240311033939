import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withAppContext } from './context/AppContext';

import { Desktop, Mobile } from './responsive/Breakpoints';
import ArrowIcon from './svgs/DownArrowIcon';
import CloseIcon from './svgs/CloseIcon';
import theme from '../util/theme';

const NavRow = styled.div`
  position: fixed;
  width: 100%;
  flex: 1;
  height: 100%;
  max-height: 16px;
  background-color: ${(props) => props.theme.charcoal}
  box-shadow: 0 10px 15px 0 ${(props) => props.theme.black_30};
  padding: 16px 0;
  color: white;
  z-index: 400000000;
`;

const NavContainer = styled.div`
  max-width: 1170px;
  padding: 0 20px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NavButton = styled.div`
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.white_80};
  z-index: 10;
`;

const NavTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  font-size: 0.8125rem;
  position: absolute;
  left: 5em;
  right: 5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

class CheckoutNavBar extends Component {
  handleOnBackPressed = () => {
    if (this.props.onBack) {
      return this.props.onBack();
    }

    return this.props.history.goBack();
  };

  renderCancelNavButton(copy) {
    if (this.props.disableCancel) {
      return null;
    }

    return (
      <React.Fragment>
        <Desktop>
          <NavButton onClick={this.props.onCancel}>{copy}</NavButton>
        </Desktop>
        <Mobile>
          <NavButton onClick={this.props.onCancel}>
            <CloseIcon height={28} width={28} color={theme.white_70} />
          </NavButton>
        </Mobile>
      </React.Fragment>
    );
  }

  render() {
    const { getLabel } = this.props;
    return (
      <NavRow name="checkout-nav-row">
        <NavContainer name="checkout-navbar-container">
          <Desktop>
            <NavButton onClick={this.handleOnBackPressed}>
              &lt; {getLabel('checkout_navbar_back')}
            </NavButton>
          </Desktop>
          <Mobile>
            <NavButton onClick={this.handleOnBackPressed}>
              <ArrowIcon
                height={36}
                width={36}
                color={theme.white_70}
                rotate={90}
              />
            </NavButton>
          </Mobile>

          <NavTitle>{this.props.title || ''}</NavTitle>

          {this.renderCancelNavButton(getLabel('checkout_navbar_cancel'))}
        </NavContainer>
      </NavRow>
    );
  }
}

export default withRouter(withAppContext(CheckoutNavBar));
