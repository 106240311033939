import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import InputField from './BasicInputField';
import Dropdown from './Dropdown';
import TextareaField from './TextareaField';
import { BlueButton } from '../buttons';
import { withAppContext } from '../context/AppContext';

const ContactUsFormWrapper = styled.form`
  width: 100%;
  > div {
    width: 100%;
  }
`;

const Message = styled.p`
  color: ${(props) => (props.dark ? 'white' : props.theme.charcoal)};
  text-align: center;
  margin: 1em 0.2em;
`;

const Error = styled.p`
  color: ${(props) => props.theme.error_color};
  margin: 1em 0.2em;
`;

function ContactUsForm(props) {
  const { getLabel } = props;
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const handleHooksFormSubmit = async (values) => {
    try {
      setSubmitting(true);

      await fetch(`${process.env.REACT_APP_API_URL}/api/contact-requests`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            ...values,
            pagePathname: window.location.pathname
          }
        })
      });

      setSubmitted(true);
    } catch (err) {
      setError(true);
    } finally {
      setSubmitting(false);
    }
  };

  return !submitted ? (
    <ContactUsFormWrapper onSubmit={handleSubmit(handleHooksFormSubmit)}>
      <InputField
        {...register('firstName', {
          required: {
            value: true,
            message: getLabel('contact_form_error_required')
          }
        })}
        label={getLabel('contact_form_label_firstname')}
        error={errors.firstName?.message}
      />
      <InputField
        {...register('lastName', {
          required: {
            value: true,
            message: getLabel('contact_form_error_required')
          }
        })}
        label={getLabel('contact_form_label_lastname')}
        error={errors.lastName?.message}
      />
      <InputField
        {...register('email', {
          required: {
            value: true,
            message: props.getLabel('contact_form_error_required')
          },
          pattern: {
            value: /.+\@.+\..+/,
            message: props.getLabel('contact_form_error_invalid_email')
          }
        })}
        type="email"
        label={getLabel('contact_form_label_email')}
        error={errors.email?.message}
      />
      <Dropdown
        {...register('location', {
          required: {
            value: true,
            message: getLabel('contact_form_error_required_dropdown')
          }
        })}
        label={getLabel('contact_form_label_location')}
        placeholder={getLabel('contact_form_dropdown_placeholder')}
        data={getLabel('contact_form_location_options')}
        error={errors.location?.message}
      />
      <Dropdown
        {...register('enquiry', {
          required: {
            value: true,
            message: getLabel('contact_form_error_required_dropdown')
          }
        })}
        label={getLabel('contact_form_label_enquiry')}
        placeholder={getLabel('contact_form_dropdown_placeholder')}
        data={getLabel('contact_form_enquiry_options')}
        error={errors.enquiry?.message}
      />
      <TextareaField
        {...register('message', {
          required: {
            value: true,
            message: getLabel('contact_form_error_required')
          }
        })}
        label={getLabel('contact_form_label_message')}
        error={errors.message?.message}
      />
      <br />
      <BlueButton type="submit" disabled={submitting} width={'243px'}>
        {getLabel('contact_form_submit_text')}
      </BlueButton>
      {error && <Error>{props.getLabel('contact_form_server_error')}</Error>}
    </ContactUsFormWrapper>
  ) : (
    <Message dark={props.dark}>
      {props.getLabel('contact_form_thankyou_text')}
    </Message>
  );
}

export default withAppContext(ContactUsForm);
