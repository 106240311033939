import React from 'react';
import MediaQuery from 'react-responsive';

const breakpoints = {
  large: '(min-width: 768px)',
  desktop: '(min-width: 1025px)',
  tablet: '(min-width: 768px) and (max-width: 1024px)',
  phone: '(max-width: 767px)',
  mobile: '(max-width: 1024px)'
};

export const Large = (props) => (
  <MediaQuery query={breakpoints.large}>{props.children}</MediaQuery>
);
export const Desktop = (props) => (
  <MediaQuery query={breakpoints.desktop}>{props.children}</MediaQuery>
);
export const Tablet = (props) => (
  <MediaQuery query={breakpoints.tablet}>{props.children}</MediaQuery>
);
export const Phone = (props) => (
  <MediaQuery query={breakpoints.phone}>{props.children}</MediaQuery>
);
export const Mobile = (props) => (
  <MediaQuery query={breakpoints.mobile}>{props.children}</MediaQuery>
);
export const SchedulerNatural = (props) => (
  <MediaQuery query={'(min-width: 1211px)'}>{props.children}</MediaQuery>
);
export const SchedulerUndersized = (props) => (
  <MediaQuery query={'(max-width: 1210px)'}>{props.children}</MediaQuery>
);
