import React from 'react';
import styled from 'styled-components';

import { withAppContext } from '../context/AppContext';
import { Desktop, Mobile } from '../../components/responsive/Breakpoints';

const AboutUsDetailsSectionContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: black;
  display: flex;
  justify-content: center;
`;

const AboutUsDetailsContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  max-width: 1170px;
  padding: ${(props) => (props.isMobile ? '36px 22px' : '55px 98px')};
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.isMobile ? undefined : '1')};
`;

const Paragraph = styled.p`
  font-size: ${(props) => (props.isMobile ? '0.875rem' : '1.25rem')};
  color: ${(props) => props.theme.white_70};
  margin-top: 0;
  margin-bottom: ${(props) => (props.isMobile ? '22px' : '30px')};
  line-height: ${(props) => (props.isMobile ? '1.33' : '1.55')};
`;

const renderDetailsContainer = (labelFn, isMobile) => {
  return (
    <AboutUsDetailsContainer isMobile={isMobile}>
      <DetailsColumn isMobile={isMobile}>
        <Paragraph
          isMobile={isMobile}
          dangerouslySetInnerHTML={{
            __html: labelFn('aboutus_details_text')
          }}></Paragraph>
      </DetailsColumn>
    </AboutUsDetailsContainer>
  );
};

const AboutUsDetailsSection = ({ getLabel }) => {
  return (
    <AboutUsDetailsSectionContainer id="about-us-details">
      <Desktop>{renderDetailsContainer(getLabel)}</Desktop>
      <Mobile>{renderDetailsContainer(getLabel, true)}</Mobile>
    </AboutUsDetailsSectionContainer>
  );
};

export default withAppContext(AboutUsDetailsSection);
