import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import throttle from 'lodash/throttle';

const WrapSlider = styled(Slider)`
  display: flex;
  justify-content: center;
  height: auto;
  flex: 1;

  & li {
    flex: 1 1;
    height: 100%;
    margin: 6px 4px;
    width: 10px;
    border-radius: 5px;
    max-width: 10px;
  }

  & .slick-dots {
    display: flex;
    bottom: 0;
    width: auto;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
  }

  & .slick-slider {
    flex-direction: column;
  }

  & .slick-slide {
    opacity: 0.3;
    display: flex !important;
    flex-direction: column !important;
    flex: 1;
  }

  & .slick-slide > div:first-child {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
  }

  & .slick-slide img {
    width: 100%;
    max-width: 180px;
  }

  & .slick-list {
    height: auto !important;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  & .slick-track {
    height: auto !important;
    flex: 1;
  }

  & .slick-active {
    opacity: 1;
  }

  & .slick-dots > ul {
    width: auto;
    left: 0;
    right: 0;
    margin: 16px auto;
  }

  & .slick-dots .slick-active {
    background-color: #00b8f1;
  }

  @media (max-width: 767px) {
    .slick-dots {
      display: flex;
      bottom: 0;
      width: 100%;
      justify-content: center;
    }
  }
`;

const StyledSlickDots = styled.ul`
  display: flex;
  bottom: 0;
  width: 100%;
  padding: 0;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
`;

const CarouselPosInd = styled.div`
  background-color: ${(props) => props.theme.charcoal_25};
  height: 10px !important;
  width: 10px;
  border-radius: 5px;
`;

export default class HomeNewsCarousel extends Component {
  state = {
    centerPadding: '1px'
  };

  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  componentDidMount() {
    window.addEventListener(
      'resize',
      throttle(this.calculateCenterPadding, 500)
    );
    this.calculateCenterPadding();
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      throttle(this.calculateCenterPadding, 500)
    );
  }

  calculateCenterPadding = () => {
    const viewWidth = window.innerWidth;
    let elementWidth;

    switch (this.props.mobile) {
      case 'tablet':
        elementWidth = 440;
        break;
      case 'phone':
        elementWidth = 226;
        break;
      default:
        elementWidth = 558;
        break;
    }

    const padding = this.props.mobile ? 16 : 209;

    const centerPadding =
      Math.ceil(Math.max(viewWidth - elementWidth - padding, 1) / 2) + 'px';
    this.setState({ centerPadding });
  };

  render() {
    const sliderSettings = {
      adaptiveHeight: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 20000,
      centerMode: true,
      centerPadding: this.state.centerPadding,
      dots: true,
      draggable: true,
      fade: false,
      infinite: true,
      pauseOnHover: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500,
      rtl: true,
      customPaging: () => {
        return <CarouselPosInd />;
      },
      appendDots: (dots) => (
        <div>
          <StyledSlickDots>{dots}</StyledSlickDots>
        </div>
      )
    };

    return (
      <CarouselContainer name="home-news-carousel">
        <WrapSlider ref={(ref) => (this.slider = ref)} {...sliderSettings}>
          {this.props.items}
        </WrapSlider>

        {/* These would likely be elements that should sit within and "above" the carousel */}
        {this.props.children}
      </CarouselContainer>
    );
  }
}
