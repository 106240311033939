import React from 'react';
import Helmet from 'react-helmet';
import metaDatMap from './metaDataMap.json';
import { AVAILABLE_SITES } from '../../util/constants';

function BreadcrumbSchema({ site, pathname }) {
  const defaultSite = AVAILABLE_SITES[0];

  // generate schema only if the pathname is available in the metadata map
  if (metaDatMap[defaultSite][pathname]) {
    const breadcrumbList = pathname.split('/').reduce((acc, path) => {
      acc.pathname = path === '' ? '' : `${acc.pathname}/${path}`;
      const metaData = metaDatMap[site || defaultSite][acc.pathname || '/'];
      if (metaData) {
        acc.position = acc.position + 1 || 1;
        acc.push({
          '@type': 'ListItem',
          position: acc.position,
          name: metaData.name,
          item: origin + (site ? `/${site}` : '') + acc.pathname
        });
      }
      return acc;
    }, []);

    const schema = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbList
    });

    return (
      <Helmet>
        <script type="application/ld+json">{schema}</script>
      </Helmet>
    );
  } else {
    return null;
  }
}

export default React.memo(BreadcrumbSchema);
