import React from 'react';
// import styled from "styled-components";

import AlienZooBadge from './svgs/TicketBadgeAlienZoo';
import AlienZooBadgeADA from './svgs/TicketBadgeADAAlienZoo';
import DeepBluBadge from './svgs/TicketBadgeDeepBlu';
import DeepBluBadgeADA from './svgs/TicketBadgeADADeepBlu';
import MagicProjBadge from './svgs/TicketBadgeMagicProj';
import MagicProjBadgeADA from './svgs/TicketBadgeADAMagicProj';
import DragonsBadge from './svgs/TicketBadgeDragons';
import DragonsBadgeADA from './svgs/TicketBadgeADADragons';
import MIBBadge from './svgs/TicketBadgeMIB';
import MIBBadgeADA from './svgs/TicketBadgeADAMIB';

// const AdventureReceiptThumbnail = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 112px;
//   height: 136px;
//   border: 3px solid ${ props => props.theme.ds_dusk_blue};
//   border-radius: 5px;
//   padding: 4px;
// `;
//
// const IconRow = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex: 1;
// `;
//
// const AbbreviationTitle = styled.div`
//   display: flex;
//   justify-content: center;
//   font-size: 2.875rem;
//   font-weight: bold;
//   padding: 16px 0;
//   margin: 8px 0;
//   line-height: 29px;
//   height: 34px;
//   border: 1px solid ${ props => props.theme.charcoal_25 };
//   border-left-width: 0;
//   border-right-width: 0;
// `;
//
// const AdventureTitle = styled.div`
//   display: flex;
//   justify-content: center;
//   font-size: 1rem;
//   font-weight: bold;
//   line-height: 17px;
//   height: 17px;
//   flex: 1;
// `;
//
// const ArrowImg = styled.img`
//   margin-left: 6px;
// `;

const BADGE_MAP = {
  'alien-zoo': {
    regular: <AlienZooBadge />,
    ada: <AlienZooBadgeADA />
  },
  'deepblu-rescue': {
    regular: <DeepBluBadge />,
    ada: <DeepBluBadgeADA />
  },
  'magic-projector': {
    regular: <MagicProjBadge />,
    ada: <MagicProjBadgeADA />
  },
  'men-in-black': {
    regular: <MIBBadge />,
    ada: <MIBBadgeADA />
  },
  dragons: {
    regular: <DragonsBadge />,
    ada: <DragonsBadgeADA />
  }
};

const AdventureThumbnail = (props) => {
  return (
    <div dir="ltr">
      {props.ada
        ? BADGE_MAP[props.titleId].ada
        : BADGE_MAP[props.titleId].regular}
      {/*<IconRow>*/}
      {/*{props.ada &&*/}
      {/*<ADAIcon color={props.color} />*/}
      {/*}*/}
      {/*<ArrowImg src={ArrowIcon} alt="Arrow"/>*/}
      {/*</IconRow>*/}

      {/*<AbbreviationTitle>AZO</AbbreviationTitle>*/}

      {/*<AdventureTitle>ALIEN ZOO</AdventureTitle>*/}
    </div>
  );
};

export default AdventureThumbnail;
