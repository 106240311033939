import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { BlueButton } from '../components/buttons';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';
import { Large, Phone } from '../components/responsive/Breakpoints';
import LoadingSpinner from '../components/LoadingSpinner';

import { withAuth0 } from '@auth0/auth0-react';
import { sendCustomEvent } from '../util/analytics';

const Headline = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '1.875' : '2.75')}rem;
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.33' : '1.09')};
  text-align: center;
  color: ${(props) => props.theme.charcoal};
  padding-bottom: ${(props) => (props.newAccount ? '0' : '16')}px;
  ${(props) =>
    !props.newAccount && props.mobile ? 'padding-bottom: 46px;' : ''}
`;

const SubHeadline = styled.div`
  text-align: center;
  max-width: 747px;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '0.875' : '1')}rem;
  color: ${(props) => props.theme.charcoal_60};
  margin: 0 auto;
  padding-top: ${(props) => (props.mobile ? '13' : '20')}px;
`;

const CenteredContent = styled.div`
  ${(props) => (props.mobile ? '' : 'width: min-content; max-width: 430px;')}
  margin: 0 auto;
  padding: ${(props) => (props.mobile ? '31px 0' : '46px 0')};
  position: relative;
`;

const SignInButton = styled(BlueButton)`
  color: #fff;
  margin: 40px 0 26px;
  border-radius: 3px;
  ${(props) => (props.mobile ? 'width: 100%;' : '')}
`;

class AccountSignIn extends Component {
  state = {
    signInLoading: false
  };

  handleUserSignIn = async () => {
    sendCustomEvent({
      event_category: 'click',
      event_action: 'membership',
      event_label: 'sign-in',
      membership_icon_name: 'sign-in'
    });

    this.setState({ signInLoading: true });
    const { loginWithRedirect } = this.props.auth0;

    let siteId = this.props.authState.routerBase;
    siteId = siteId === '/' ? '' : siteId;
    loginWithRedirect({
      lang: this.props.locale,
      country: 'KSA',
      appState: { targetUrl: `${siteId}/account` }
    });
  };

  navToAccount = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/account', locationState);
  };

  componentDidMount() {
    this.props.updateHeaderText({});
    if (!this.props.auth0.isAuthenticated) {
      this.handleUserSignIn();
    }
  }

  componentDidUpdate() {
    if (this.props.auth0.isAuthenticated) {
      this.navToAccount();
    }
  }

  render() {
    const { getLabel } = this.props;

    const signInContent = this.state.signInLoading ? (
      <LoadingSpinner />
    ) : (
      getLabel('account_signin_btn_text')
    );

    let headline = (mobile) => (
      <Headline mobile={mobile}>{getLabel('account_signin_title')}</Headline>
    );

    if (this.props.location.state && this.props.location.state.newAccount) {
      headline = (mobile) => {
        return (
          <React.Fragment>
            <Headline mobile={mobile} newAccount>
              WELCOME TO DREAMSCAPE!
            </Headline>
            <SubHeadline mobile={mobile}>
              Thanks for creating a Dreamscape Account!
              <br />
              <br />
              Use it to check in faster, be the first to know about the latest
              adventures, keep track of purchases and get access to special
              events.
            </SubHeadline>
          </React.Fragment>
        );
      };
    }

    return (
      <React.Fragment>
        <Large>
          {headline(false)}
          <CenteredContent>
            <SignInButton
              invert
              width={430}
              disabled={this.state.signInLoading}
              onClick={this.handleUserSignIn}>
              {signInContent}
            </SignInButton>
          </CenteredContent>
        </Large>
        <Phone>
          {headline(true)}
          <CenteredContent mobile>
            <SignInButton
              mobile
              invert
              disabled={this.state.signInLoading}
              onClick={this.handleUserSignIn}>
              {signInContent}
            </SignInButton>
          </CenteredContent>
        </Phone>
      </React.Fragment>
    );
  }
}

const SignIn = (props) => (
  <AuthContext.Consumer>
    {(authState) => {
      return <AccountSignIn {...props} authState={authState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAuth0(withAppContext(SignIn)));
