import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Large, Phone } from '../components/responsive/Breakpoints';

import HomeAdventuresCarousel from './carousels/HomeAdventuresCarousel';
import { withAppContext } from './context/AppContext';
import { AuthContext } from './context/AuthenticatorContext';
import { sendEcomEvent } from '../util/analytics';

import adventure1Img2 from '../assets/images/img-panel-small-az@2x.jpg';
import adventure2Img2 from '../assets/images/img-panel-small-tb@2x.jpg';
import adventure3Img2 from '../assets/images/img-panel-small-mp@2x.jpg';
import adventure4Img2 from '../assets/images/img-panel-small-dg@2x.jpg';
import adventure5Img2 from '../assets/images/img-panel-small-mib@2x.png';

const ADVENTURE_IMAGE_MAP = {
  'alien-zoo': adventure1Img2,
  'deepblu-rescue': adventure2Img2,
  'magic-projector': adventure3Img2,
  'men-in-black': adventure5Img2,
  dragons: adventure4Img2
};

const AdventureCard = styled.div`
  width: ${(props) => props.width || 130}px;
  margin: ${(props) => (props.isMobile ? undefined : '0 19px')};
  overflow: hidden;
`;

const Poster = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 175px;
  width: 100%;
  cursor: pointer;
`;

const PosterImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const AdvCardContent = styled.div`
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 8px 0 12px;
`;

const AdvCardDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.white_25};
  margin-bottom: 5px;
`;

const AdvCardTitleArea = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 54px;
`;

const AdvCardContentTitle = styled.div`
  font-size: 0.5625rem;
  font-weight: bold;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionContainer = styled.div`
  background-color: #141414;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 360px;
  width: 100%;
`;

const AdventureTitleSection = styled.h5`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.09;
  text-align: center;
  letter-spacing: 1.2;
  color: white;
  padding: 35px 22px 27px;
  margin: 0;
`;

const AdventureItemList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const MobileAdvItemWrapper = styled.div`
  display: flex !important;
  justify-content: center !important;
`;

class FooterCarouselWithContext extends Component {
  state = {
    isLoaded: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.isLoaded) {
      const footerPosterImages = document.querySelectorAll(
        '.swiper-slide .adventure-footer-poster'
      );
      const adventureMap = this.props.adventures.reduce((acc, adventure) => {
        return {
          ...acc,
          [adventure.title_id]: adventure
        };
      }, {});

      footerPosterImages.forEach((footerPoster) => {
        const titleId = footerPoster.id.replace('footer-poster-', '');
        const adventure = adventureMap[titleId];
        if (!adventure) {
          return;
        }
        footerPoster.addEventListener('click', () => {
          //this.sendSelectItemEvent(adventure);
          //this.navDetails(adventure);
        });
      });

      if (footerPosterImages.length) {
        this.setState({ isLoaded: true });
      }
    }
  }

  componentWillUnmount() {
    const footerPosterImages = document.querySelectorAll(
      '.swiper-slide .adventure-footer-poster'
    );
    footerPosterImages.forEach((footerPoster) => {
      footerPoster.removeEventListener('click', () => {});
    });
  }

  navDetails(adventure, toAvailability) {
    this.props.history.push(`/adventures/details/${adventure.title_id}`, {
      toAvailability
    });
  }

  sendSelectItemEvent(adventure) {
    sendEcomEvent('select_item', null, {
      siteId: this.props.parentState.selectedSite.site_id,
      adventures: adventure
    });
  }

  renderAdventureItems(isMobile) {
    if (!this.props.adventures.length) {
      return [];
    }

    return this.props.adventures.map((adventure, itr) => {
      let adventureTitle =
        adventure[this.props.locale].shortname?.toUpperCase();

      const posterImageSource = ADVENTURE_IMAGE_MAP[adventure.title_id];

      return (
        <MobileAdvItemWrapper key={adventure.title_id}>
          <AdventureCard
            width={isMobile ? 153 : undefined}
            isMobile={isMobile}
            id={`footer-poster-${adventure.title_id}`}
            className={'adventure-footer-poster'}>
            <Poster
              onClick={() => {
                this.sendSelectItemEvent(adventure);
                this.navDetails(adventure);
              }}>
              <PosterImage src={posterImageSource || ''} />
            </Poster>

            <AdvCardContent>
              <AdvCardDivider />
              <AdvCardTitleArea>
                <AdvCardContentTitle mobile={isMobile}>
                  {adventureTitle}
                </AdvCardContentTitle>
              </AdvCardTitleArea>
            </AdvCardContent>
          </AdventureCard>
        </MobileAdvItemWrapper>
      );
    });
  }

  render() {
    return (
      <SectionContainer id="adventure">
        <AdventureTitleSection>
          {this.props.labelFn('footer_title_adventures')}
        </AdventureTitleSection>
        <Large>
          <AdventureItemList>{this.renderAdventureItems()}</AdventureItemList>
        </Large>
        <Phone>
          <HomeAdventuresCarousel
            isMobile={true}
            items={this.renderAdventureItems(true)}
            itemWidth={153}
          />
        </Phone>
      </SectionContainer>
    );
  }
}

const FooterCarousel = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <FooterCarouselWithContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(FooterCarousel));
