import styled from 'styled-components';

const NavbarSpacer = styled.div`
  width: 100%;
  height: ${(props) => {
    const { height } = props;
    if (!height) {
      return '88px';
    }

    if (typeof height === 'number') {
      return `${height}px`;
    }

    if (typeof height === 'string') {
      return height;
    }

    // if none of the above, default;
    return '98px';
  }};
`;

export default NavbarSpacer;
