import React, { Component } from 'react';
import styled from 'styled-components';
import {
  AsYouType,
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber
} from 'libphonenumber-js';

const BasicInputFieldContainer = styled.div`
  width: ${(props) => {
    const { width } = props;
    if (!width) {
      return '280px';
    }

    if (typeof width === 'number') {
      return `${width}px`;
    }

    if (typeof width === 'string') {
      return width;
    }

    // If none of the above; default
    return '280px';
  }};
  padding: ${(props) => {
    const { padding } = props;
    if (!padding) {
      return;
    }

    if (typeof padding === 'string') {
      return padding;
    }
  }};
  flex: ${(props) => props.flex};
  box-sizing: border-box;
`;

const BasicInputField = styled.input`
  width: auto;
  height: 17px;
  border: solid 1px ${(props) => props.theme.ds_bright_blue};
  padding: 14px;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: max(0.8125rem, 16px);
  color: ${(props) => (props.dark ? 'white' : props.theme.charcoal)};
  transition: ${(props) => props.theme.transition};
  border-radius: 3px;
  outline: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;

  &::placeholder {
    color: ${(props) =>
      props.dark ? props.theme.white_70 : props.theme.black_30};
  }

  &:hover {
    border: 3px solid ${(props) => props.theme.ds_bright_blue};
    padding: 12px;
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.ds_bright_blue};
    padding: 14px;
  }

  &:disabled {
    border: 1px solid ${(props) => props.theme.charcoal_25};
    padding: 14px;
  }
`;

const BasicInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${(props) => {
    if (props.focused) {
      return props.theme.ds_bright_blue;
    }

    return props.dark ? props.theme.white_80 : props.theme.charcoal_90;
  }};
`;

export default class PhoneInputField extends Component {
  state = {
    focused: false
  };

  handleFocusChange = (isFocused) => {
    this.setState({ focused: isFocused });
  };

  render() {
    return (
      <BasicInputFieldContainer
        width={this.props.width}
        padding={this.props.padding}
        flex={this.props.flex}
      >
        <BasicInputLabel focused={this.state.focused} dark={this.props.dark}>
          {this.props.label || this.props.placeholder}
          <BasicInputField
            type="tel"
            onBlur={() => {
              this.handleFocusChange(false);
              if (this.props.onBlur) {
                this.props.onBlur();
              }
            }}
            onFocus={() => {
              this.handleFocusChange(true);
              if (this.props.onFocus) {
                this.props.onFocus();
              }
            }}
            onChange={(event) => {
              let newValue = parseIncompletePhoneNumber(event.target.value);
              const currentValue = parseIncompletePhoneNumber(this.props.value);
              if (newValue === currentValue) {
                if (
                  formatIncompletePhoneNumber(newValue, 'US').indexOf(
                    event.target.value
                  ) === 0
                ) {
                  newValue = newValue.slice(0, -1);
                }
              }

              return this.props.changeCallback(
                new AsYouType('US').input(newValue)
              );
            }}
            {...this.props}
          />
        </BasicInputLabel>
      </BasicInputFieldContainer>
    );
  }
}
