export default function populateEcomItemData(siteId, titles, orderDetails) {
  return [titles].flat().map((title) => {
    const titleAnalytics = title?.analytics || {};
    const siteTitle = title.licensed_sites
      .filter((site) => site.site_id === siteId)
      .pop();
    const siteTitleAnalytics = siteTitle?.analytics || {};

    if (typeof siteTitleAnalytics.price === 'number') {
      siteTitleAnalytics.price = siteTitleAnalytics.price.toFixed(2);
    }

    /** get quantity from order details */
    const orderAnalytics = {};
    if (orderDetails) {
      orderAnalytics.price = (
        orderDetails.total / orderDetails.quantity
      ).toFixed(2);
      orderAnalytics.quantity = orderDetails.quantity;
    }
    /** over title's analytics with site wise title analytics */
    return {
      ...titleAnalytics,
      ...siteTitleAnalytics,
      ...orderAnalytics
    };
  });
}
