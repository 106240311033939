import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import FormInput from '../../components/forms/BasicInputField';
import BlueButton from '../../components/buttons/BlueButton';
import { Desktop, Mobile } from '../../components/responsive/Breakpoints';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  font-size: ${(props) => (props.isMobile ? '1.625rem' : '2.75rem')};
  font-weight: bold;
  line-height: ${(props) => (props.isMobile ? '1.33' : '1.09')};
  padding: 0 22px;
  color: ${(props) => props.theme.charcoal};
  text-align: center;
`;

const HeaderSubTitle = styled.div`
  display: flex;
  font-size: ${(props) => (props.isMobile ? '0.875' : '1')}rem;
  padding: 0 36px;
  color: ${(props) => props.theme.charcoal_60};
  max-width: 326px;
  padding-top: 6px;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  align-self: center;
  align-items: center;
  margin: ${(props) => (props.isMobile ? '66px 0 44px' : '44px 0')};
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 44px;
  align-items: center;
`;

const Text = styled.span`
  font-size: 0.8125rem;
  color: ${(props) =>
    props.blue ? props.theme.ds_dusk_blue : props.theme.charcoal};
  cursor: ${(props) => (props.blue ? 'pointer' : undefined)};
`;

class ForgotPasswordRequest extends Component {
  state = {
    email: this.props.email
  };

  handleOnChangeEmail = (event) => {
    this.setState({ email: event.target.value.toLowerCase() });
  };

  handleSubmitRequest = async (e) => {
    e.preventDefault();
    if (!this.props.handleRequestForgotPasswordCode) {
      return;
    }

    try {
      await this.props.handleRequestForgotPasswordCode(this.state.email);
    } catch (e) {
      // Error is already logged within the DiAPI method.
      return alert('The provided email was not found in our records.');
    }

    this.props.history.push(`${this.props.path}/forgot-password/submit-new`, {
      ...this.props.location.state,
      email: this.state.email
    });
  };

  renderContent(isMobile) {
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle isMobile={isMobile}>NEED A NEW PASSWORD?</HeaderTitle>
          <HeaderSubTitle isMobile={isMobile}>
            Enter your email address. A password reset link will be sent to your
            email.
          </HeaderSubTitle>
        </HeaderContainer>

        <ContentContainer isMobile={isMobile}>
          <FormContainer
            isMobile={isMobile}
            onSubmit={this.handleSubmitRequest}
          >
            <FormInput
              placeholder="E-mail"
              type="email"
              width="100%"
              value={this.state.email}
              onChange={this.handleOnChangeEmail}
            />
            <BlueButton width="100%" style={{ marginTop: 16 }}>
              SEND E-MAIL
            </BlueButton>
          </FormContainer>

          <Text>
            Already have an account?{' '}
            <Text blue onClick={() => this.props.history.goBack()}>
              Sign in &gt;
            </Text>
          </Text>
        </ContentContainer>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Container name="forgot-password-request-container">
        <Column>
          <Desktop>{this.renderContent()}</Desktop>
          <Mobile>{this.renderContent(true)}</Mobile>
        </Column>
      </Container>
    );
  }
}

export default withRouter(ForgotPasswordRequest);
