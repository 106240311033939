import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAuth0 } from '@auth0/auth0-react';
import { withAppContext } from '../components/context/AppContext';
import { DIApi } from '../util/api';
import Details from '../components/share/Details';
import { getUserSessionId, saveUserSessionId } from '../util/userSession';

const Container = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding-top: 1em;
  padding-bottom: 8em;
`;

class ShareId extends Component {
  state = {
    memberDetails: null
  };
  headerText = null;
  componentDidMount() {
    this.handleRedirect();
    this.setDataOrRedirect();
    this.getMemberDetails();
  }

  componentDidUpdate() {
    this.handleRedirect();
    this.setDataOrRedirect();
    const { user } = this.props.auth0;

    const title = this.props.getLabel('account_details_title');
    const username =
      user['https://profile/firstName'].toUpperCase() +
      ' ' +
      user['https://profile/lastName'].toUpperCase();

    if (this.headerText !== title) {
      this.headerText = title;
      this.props.updateHeaderText({
        title: username,
        subTitle: user.email
      });
      this.headerText = title;
    }
  }

  async setDataOrRedirect() {
    if (!this.props.auth0.isAuthenticated || this.props.auth0.isLoading) {
      return;
    }
    if (!this.props.auth0.isAuthenticated) {
      this.navToSignIn();
    }
  }

  handleRedirect() {
    if (!this.props.auth0.isLoading && !this.props.auth0.isAuthenticated) {
      this.navToSignIn();
    }
  }

  navToSignIn = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/account/signin', locationState);
  };

  getMemberDetails = async () => {
    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;
    if (!isAuthenticated) {
      return;
    }

    if (this.props.location?.state?.memberDetails) {
      this.setState({ memberDetails: this.props.location.state.memberDetails });
    } else {
      const token = await getAccessTokenSilently({
        ignoreCache: true,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid offline_access profile'
      });

      const userSessionId = getUserSessionId();
      const member = await DIApi.getMember(token, userSessionId);
      if (member.userSessionId) {
        saveUserSessionId(member.userSessionId);
      }

      if (member && member.isMember) {
        this.setState({ memberDetails: member });
      }
    }
  };

  renderDetailsPage() {
    if (!this.state.memberDetails) {
      return (
        <div className="spinner" style={{ paddingBottom: '90px' }}>
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      );
    }

    if (this.state.memberDetails) {
      return (
        <Container>
          <Details
            memberDetails={this.state.memberDetails}
            cta={false}
            points={true}
          />
        </Container>
      );
    }
  }

  render() {
    return <React.Fragment>{this.renderDetailsPage()}</React.Fragment>;
  }
}

const ShareIdPage = (props) => (
  <AuthContext.Consumer>
    {(authState) => {
      return <ShareId {...props} authState={authState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAuth0(withAppContext(ShareIdPage)));
