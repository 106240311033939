import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withAppContext } from '../context/AppContext';
import layoutBg from '../../assets/images/share/share-layout.png';
import sharelogo from '../../assets/images/share/share-logo-only.png';

const ShareBoundary = styled.div`
font-family: MarselisPro, Almarai, sans-serif;
  color: #fff;
  max-width: 26em;
  border-top-right-radius: 0.25em;
  border-top-left-radius: 0.25em;
  margin: 2em 0;
  margin-inline: auto;
`;
const Wrapper = styled.div`
  color: #fff;
  border: none;
`;
const Imagecontainer = styled.div`
  background: #b30d4d;
  border-top-right-radius: 0.25em;
  border-top-left-radius: 0.25em;
  transform: translateY(1px);
  position: relative;
`;
const BackgroundImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  border-top-right-radius: 0.25em;
  border-top-left-radius: 0.25em;
  [dir='rtl'] & {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
`;

const Logo = styled.img`
  position: absolute;
  display: block;
  width: auto;
  height: 3.25em;
  object-fit: contain;
  top: 0.75em;
  left: 0.85em;
  [dir='rtl'] & {
    left: auto;
    right: 0.85em;
  }
`;

class Layout extends Component {
  render() {
    return (
      <ShareBoundary>
        <Imagecontainer>
          <BackgroundImage src={layoutBg} alt="" />
          <Logo src={sharelogo} alt="" />
        </Imagecontainer>
        <Wrapper>{this.props.children}</Wrapper>
      </ShareBoundary>
    );
  }
}
export default withRouter(withAppContext(Layout));
