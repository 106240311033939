import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import { BlueButton } from '../components/buttons';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';

import bgImg_x2 from '../assets/images/img-find-us-bg-default@2x.jpg';
import bgImgSmall_x3 from '../assets/images/img-find-us-bg-s-default@3x.jpg';

const Container = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
`;

const PageNotFoundHeroContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.isMobile ? '448px' : '656px')};
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
`;

const PageNotTitle = styled.h1`
  display: flex;
  font-size: ${(props) => (props.isMobile ? '1.625em' : '5em')};
  font-weight: bold;
  letter-spacing: ${(props) => (props.isMobile ? '-1px' : '2px')};
  line-height: 1.33;
  text-align: center;
  padding: ${(props) => (props.isMobile ? '0 22px' : '0 60px')};
  max-width: ${(props) => (props.isMobile ? '340px' : undefined)};
  margin: 0;
`;

const SubTitle = styled.h3`
  color: #fff;
  font-size: ${(props) => (props.isMobile ? '1rem' : '1.5em')};
  font-weight: normal;
  line-height: ${(props) => (props.isMobile ? '1.4' : undefined)};
  margin-top: ${(props) => (props.isMobile ? '12px' : '6px')};
  padding: 0 22px;
  text-align: center;
  max-width: ${(props) => (props.isMobile ? '460px' : '736px')};
  margin-bottom: 1.75em;
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const RedirectButton = styled(BlueButton)`
  font-size: 1.15em;
  font-weight: normal;
  text-transform: uppercase;
  width: ${(props) => (props.isMobile ? '180px' : '280px')};
  height: 40px;
  letter-spacing: 1px;
`;

class PageNotFoundContext extends Component {
  componentDidMount() {}

  renderHeroContainer(isMobile) {
    let background = <BackgroundImage src={bgImg_x2} />;
    const { getLabel } = this.props;

    if (isMobile) {
      background = <BackgroundImage src={bgImgSmall_x3} />;
    }

    return (
      <PageNotFoundHeroContainer isMobile={isMobile}>
        {background}
        <PageNotTitle isMobile={isMobile}>404</PageNotTitle>
        <SubTitle isMobile={isMobile}>
          {getLabel('page_not_found_subtitle')}
        </SubTitle>
        <RedirectButton onClick={() => this.props.history.push('/')}>
          {getLabel('page_not_found_btn_return')}
        </RedirectButton>
      </PageNotFoundHeroContainer>
    );
  }

  handleNavigation = (path) => {
    return this.props.history.push(path);
  };

  render() {
    return (
      <Container id="page-not-found">
        <Desktop>{this.renderHeroContainer()}</Desktop>

        <Mobile>{this.renderHeroContainer(true)}</Mobile>
      </Container>
    );
  }
}

const PageNotFound = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <PageNotFoundContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(PageNotFound));
