import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import downArrow from '../assets/images/arrow_down_gray.svg';
import Moment from 'moment';

const Selector = styled.select`
  width: 312px;
  height: 47px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.ds_dusk_blue};
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
  text-align-last: center;
  letter-spacing: 1.5px;
  font-family: ${(props) => props.theme.sans_serif};
  font-weight: bold;
  font-size: 0.8rem;
  appearance: unset;
  text-align: center;
  padding: 0 22px;
`;

const DropDownContainer = styled.div`
  position: relative;
  width: 312px;
  margin: 0 auto;
`;

const DropDownArrow = styled.img`
  margin: 11px;
  position: absolute;
  right: 0;
  pointer-events: none;
  [dir='rtl'] & {
    right: auto;
    left: 0;
  }
`;

const LabelDropDown = (props) => {
  const options = [];
  for (let i = 0; i < props.daysInDropdown; i++) {
    let label = Object.keys(props.availabilityData)[i];
    let labelDate = new Moment(label);
    label = labelDate.format('ddd, MMM D').toUpperCase();
    if (i === 0 && labelDate.isSame(Moment().startOf('day'))) {
      label = 'TODAY - ' + label;
    }
    options.push(
      <option key={i} value={i}>
        {label}
      </option>
    );
  }
  return (
    <DropDownContainer>
      <Selector
        onChange={props.selectDateDropdown}
        name="select"
        id="dateSelector">
        {options}
      </Selector>
      <DropDownArrow src={downArrow} alt="Left Arrow" />
    </DropDownContainer>
  );
};

export default withRouter(LabelDropDown);
