import React, { Component } from 'react';
import styled from 'styled-components';
import { withAppContext } from '../context/AppContext';

import HomeNewsCarousel from '../carousels/HomeNewsCarousel';
import HomeNewsSlides from '../content/HomeNewsSlides';
import { Desktop, Tablet, Phone } from '../responsive/Breakpoints';

const SectionContainer = styled.div`
  background: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: ${(props) => (props.mobile ? '563px' : '613px')};
  width: 100%;
`;

const Title = styled.h1`
  font-size: ${(props) => (props.mobile ? '1.625rem' : '2.75rem')};
  font-weight: normal;
  line-height: 1.33;
  text-align: center;
  padding: ${(props) => (props.mobile ? '50px 56px 0 56px' : '96px 0 0 0')};
  margin: 0;
`;

class NewsSection extends Component {
  render() {
    return (
      <React.Fragment>
        <Desktop>
          <SectionContainer name="news-section">
            <Title>{this.props.getLabel('home_news_title')}</Title>
            <HomeNewsCarousel items={HomeNewsSlides} />
          </SectionContainer>
        </Desktop>
        <Tablet>
          <SectionContainer mobile name="news-section">
            <Title>{this.props.getLabel('home_news_title')}</Title>
            <HomeNewsCarousel mobile="tablet" items={HomeNewsSlides} />
          </SectionContainer>
        </Tablet>
        <Phone>
          <SectionContainer mobile name="news-section">
            <Title mobile>{this.props.getLabel('home_news_title')}</Title>
            <HomeNewsCarousel mobile="phone" items={HomeNewsSlides} />
          </SectionContainer>
        </Phone>
      </React.Fragment>
    );
  }
}

export default withAppContext(NewsSection);
