import Amplify, { Auth } from 'aws-amplify';
import { withOAuth, Authenticator } from 'aws-amplify-react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const SERVICE_ENDPOINTS = {
  SITES: `${process.env.REACT_APP_API_URL}/api/sites`,
  TITLES: `${process.env.REACT_APP_API_URL}/api/titles`,
  HOME_ASSETS: `${process.env.REACT_APP_API_URL}/api/home-asset`,
  TITLE_ASSETS: `${process.env.REACT_APP_API_URL}/api/title-asset`,
  SITE_ASSETS: `${process.env.REACT_APP_API_URL}/api/site-asset`,
  TICKETS: `${process.env.REACT_APP_API_URL}/api/tickets`,
  LABELS: `${process.env.REACT_APP_API_URL}/api/labels?pagination[limit]=-1`,
  VISTA: '/vista',
  SHARE: '/share',
  MAF_PAY: '/pay',
  ACCOUNT: 'https://api.techscapevr.com/account'
};

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_COGNITO_ID_POOL,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_APP_CLIENT

    // oauth: oauth
  },
  API: {
    endpoints: [
      {
        name: 'DevCRMLinkAPI',
        endpoint: process.env.REACT_APP_API_DOMAIN
      }
    ]
  }
});

const apiGet = async ({ path, headers, queryStringParameters }) => {
  let myInit = {
    headers,
    response: true
  };

  if (queryStringParameters) {
    myInit.queryStringParameters = queryStringParameters;
  }

  try {
    const response = await axios.get(path, myInit);
    return response;
  } catch (error) {
    console.debug('[api] -> (apiGet) Error Occurred:', error);
    throw error;
  }
};

const apiPost = async ({ path, headers, queryStringParameters, body }) => {
  let myInit = {
    headers,
    response: true
  };

  if (queryStringParameters) {
    myInit.queryStringParameters = queryStringParameters;
  }

  try {
    const response = await axios.post(path, body, myInit);
    return response;
  } catch (error) {
    console.debug(error);
    throw error;
  }
};

const vistaAPIGet = async ({ path, headers, queryStringParameters }) => {
  const myInit = {
    headers: {
      ...headers,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    },
    response: true
  };

  let url = process.env.REACT_APP_VISTA_API_URL + path;
  if (queryStringParameters) {
    url += '?' + new URLSearchParams(queryStringParameters).toString();
  }

  try {
    const response = await axios.get(url, myInit);
    return response;
  } catch (error) {
    console.debug('[api] -> (vistaApiGet) Error Occurred:', error);
    throw error;
  }
};

const vistaAPIPost = async ({ path, headers, queryStringParameters, body }) => {
  let myInit = {
    headers: {
      ...headers,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    },
    response: true
  };

  if (queryStringParameters) {
    myInit.queryStringParameters = queryStringParameters;
  }

  try {
    const response = await axios.post(
      process.env.REACT_APP_VISTA_API_URL + path,
      body,
      myInit
    );
    return response;
  } catch (error) {
    console.debug(error);
    throw error;
  }
};

class DIApi {
  static async getTitleAssets() {
    const apiConfig = {
      path: SERVICE_ENDPOINTS.TITLE_ASSETS
    };

    try {
      const assetsRes = await apiGet(apiConfig);
      return assetsRes.data.titles;
    } catch (e) {
      return console.error('[api] -> (getTitleAssets) Error Occurred:', e);
    }
  }

  static async getHomeAssets() {
    const apiConfig = {
      path: SERVICE_ENDPOINTS.HOME_ASSETS
    };

    try {
      const assetsRes = await apiGet(apiConfig);
      return assetsRes.data.home;
    } catch (e) {
      return console.error('[api] -> (getHomeAssets) Error Occurred:', e);
    }
  }

  static async getSiteAssets() {
    const apiConfig = {
      path: SERVICE_ENDPOINTS.SITE_ASSETS
    };

    try {
      const assetsRes = await apiGet(apiConfig);
      return assetsRes.data.sites;
    } catch (e) {
      return console.error('[api] -> (getSiteAssets) Error Occurred:', e);
    }
  }

  static async getSites() {
    const apiConfig = {
      path: SERVICE_ENDPOINTS.SITES
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getSites) Error Occurred:', e);
      return;
    }
  }

  static async getTitles() {
    const apiConfig = {
      path: SERVICE_ENDPOINTS.TITLES
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getTitles) Error Occurred:', e);
      return;
    }
  }

  static async getExperiencesBySite(siteId) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.SITES}/${siteId}`
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getExperiencesBySite) Error Occurred:', e);
      return;
    }
  }

  static async getLabels() {
    const apiConfig = {
      path: SERVICE_ENDPOINTS.LABELS
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getLabels) Error Occurred:', e);
      return;
    }
  }

  static async resendReceipt(siteId, orderId) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.MAF_PAY}/resendEmail/${orderId}`
    };

    try {
      const res = await vistaAPIGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (resendReceipt) Error Occurred:', e);
      return;
    }
  }

  static async holdTickets(
    siteId,
    expId,
    expStart,
    totalQuantity,
    screenNumber,
    titleInfo,
    token,
    userSessionId = null
  ) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/holdTicket`
    };

    apiConfig.body = {
      cinemaId: siteId,
      sessionId: expId,
      ticketTypes: {
        qty: totalQuantity,
        screenNumber
      },
      titleInfo,
      ...(userSessionId && {
        userSessionId
      })
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('Found an error: ', e);
      return;
    }
  }

  static async releaseHeldTickets(userSessionId) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/cancelOrder?userSessionId=${userSessionId}`
    };

    try {
      const res = await vistaAPIGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (releaseHeldTickets) Error Occurred:', e);
      return;
    }
  }

  static async applyGiftCode(
    code,
    userSessionId,
    token
  ) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/applyDiscount`
    };

    apiConfig.body = {
      code,
      userSessionId
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      return res.data;
    } catch (err) {
      console.debug('[api] -> (giftCode) Error Occurred:', err);
      throw (err.response.data);
    }
  }

  static async purchaseTickets(purchaseData, token) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.MAF_PAY}/purchaseTickets`,
      body: purchaseData
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (purchaseTickets) Error occurred:', e);
      throw e;
    }
  }

  static async authorizePayment(purchaseData, token) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.MAF_PAY}/authorize`,
      body: purchaseData
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (purchaseTickets) Error occurred:', e);
      throw e;
    }
  }

  static async capturePayment(orderReference, token) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.MAF_PAY}/completeOrder`,
      body: {
        orderReference
      }
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (purchaseTickets) Error occurred:', e);
      throw e;
    }
  }

  static async getOrderDetails(orderId) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/getOrder/?userSessionId=${orderId}`
    };

    try {
      const res = await vistaAPIGet(apiConfig);
      // just return the axios response instead of parsing out the data so we have access to the http status code
      return res;
    } catch (e) {
      console.debug('[api] -> (getOrderDetails) Error occurred:', e);
      throw e;
    }
  }

  static async checkOrderStatus(userSessionId) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/checkOrderStatus/?userSessionId=${userSessionId}`
    };

    try {
      const res = await vistaAPIGet(apiConfig);
      // just return the axios response instead of parsing out the data so we have access to the http status code
      return res;
    } catch (e) {
      console.debug('[api] -> (checkOrderStatus) Error occurred:', e);
      throw e;
    }
  }

  static async getAvailableShowtimesForExperience(
    vistaSiteId,
    filmId,
    expId,
    startDate,
    endDate,
    ada = false
  ) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/getTicketsAvailability/${vistaSiteId}/${filmId}`
    };

    if (startDate && endDate) {
      apiConfig.queryStringParameters = {
        startdate: startDate,
        enddate: endDate
      };
    }

    try {
      const res = await vistaAPIGet(apiConfig);
      return { [expId]: res.data };
    } catch (e) {
      console.debug(
        '[api] -> (getAvailableShowtimesForExperience) Error Occurred:',
        e
      );
      return;
    }
  }

  static async getEstimatedPriceForExperience(siteId, expId, expStart) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/getPrice/${siteId}/${expId}`
    };
    /*apiConfig.body = {
      partner_site_id: siteId,
      partner_experience_id: expId,
      partner_experience_start: expStart
    };*/
    try {
      const res = await vistaAPIGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (pricing) Error occurred:', e);
      throw e;
    }
  }

  static async getEstimatedPriceForAMCExperience(siteId, expId, expStart) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/getPrice/${siteId}/${expId}`,
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate'
      }
    };
    /*apiConfig.body = {
      tickets: 1
    };*/
    try {
      const res = await vistaAPIGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (pricing) Error occurred:', e);
      return;
    }
  }

  // @REMOVE
  static async changePassword(user, oldPassword, newPassword) {
    try {
      return await Auth.changePassword(user, oldPassword, newPassword);
    } catch (e) {
      console.debug('[api] -> (changePassword) Error Occurred:', e);
      throw e;
    }
  }

  static async getUserOrders(token) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/orders/history`,
      headers: {
        Authorization: `Bearer ${token}`,
        token
      }
    };

    try {
      const res = await vistaAPIGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getUserOrders) Error Occurred:', e);
      return;
    }
  }

  // @REMOVE
  static async getUserWaivers() {
    const jwtToken = (await Auth.currentSession()).idToken.jwtToken;
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.ACCOUNT}/waivers/valid`,
      headers: {
        Authorization: jwtToken
      }
    };

    try {
      const res = await apiGet(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getUserOrders) Error Occurred:', e);
      return;
    }
  }

  // @REMOVE
  static async userSignOut() {
    try {
      const signoutData = await Auth.signOut();
      return signoutData;
    } catch (e) {
      console.debug('[api] -> (userSignOut) Error Occurred:', e);
      return;
    }
  }

  // @REMOVE
  static async userSignUp(
    username,
    password,
    firstName,
    lastName,
    birthdate,
    phone_number = null
  ) {
    try {
      const attributesData = {
        given_name: firstName,
        family_name: lastName,
        birthdate
      };
      if (phone_number) {
        attributesData['phone_number'] = phone_number;
      }
      const signupData = await Auth.signUp({
        username,
        password,
        attributes: attributesData
      });
      return signupData;
    } catch (e) {
      console.debug('[api] -> (userSignIn) Error Occurred:', e);
      throw e;
    }
  }

  static async userEdit(
    email,
    firstName,
    lastName,
    birthdate,
    token,
    phone_number = null
  ) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/user/update`
    };

    apiConfig.body = {
      email,
      firstName,
      lastName,
      dateOfBirth: birthdate,
      ...(phone_number && {
        mobile: phone_number
      })
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (userEdit) Error Occurred:', e);
      throw e;
    }
  }

  static async userDeleteRequest(email, token) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.VISTA}/user/deleteRequest`
    };

    apiConfig.body = {
      email
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (userDeleteRequest) Error Occurred:', e);
      throw e;
    }
  }

  static async getCurrentUser() {
    try {
      const { user } = useAuth0();

      return user;
    } catch (e) {
      console.debug('[api] -> (getCurrentUser) Error Occurred:', e);
      return;
    }
  }

  static async requestForgotPasswordVerification(username) {
    try {
      const forgotPasswordResponse = await Auth.forgotPassword(username);
      return forgotPasswordResponse;
    } catch (e) {
      console.debug(
        '[api] -> (requestForgotPasswordVerification) Error Occurred:',
        e
      );
      throw e;
    }
  }

  static async submitForgotPasswordRequest(username, code, newPassword) {
    try {
      const resetPasswordResponse = await Auth.forgotPasswordSubmit(
        username,
        code,
        newPassword
      );
      return resetPasswordResponse;
    } catch (e) {
      console.debug(
        '[api] -> (submitForgotPasswordRequest) Error Occurred:',
        e
      );
      throw e;
    }
  }

  static async getMember(token, userSessionId = null, memberId = null) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.SHARE}/memberDetails`,
      headers: {
        Authorization: `Bearer ${token}`,
        token
      }
    };

    try {
      apiConfig.body = {
        ...(userSessionId && {
          userSessionId
        }),
        ...(memberId && {
          memberId
        })
      }
      const res = await vistaAPIPost(apiConfig);
      return res.data;
    } catch (e) {
      console.debug('[api] -> (getMember) Error Occurred:', e);
      return;
    }
  }

  static async memberJoinShare(token) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.SHARE}/joinShare`
    };

    apiConfig.body = {
      token
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);

      if (!res || !res.data) {
        throw new Error(null);
      }
      return res.data;
    } catch (e) {
      console.debug('[api] -> (memberJoinShare) Error Occurred:', e);
      throw e;
    }
  }

  static async memberRedeemPoints({ userSessionId, amountInCents }, token) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.SHARE}/redeemPoints`
    };

    apiConfig.body = {
      userSessionId,
      amountInCents
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      if (!res || !res.data) {
        throw new Error(null);
      }

      return res.data;
    } catch (e) {
      console.debug('[api] -> (memberRedeemPoints) Error Occurred:', e);
      throw e;
    }
  }

  static async memberDeleteRedeemPoints(userSessionId, token) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.SHARE}/removeRedeemPoints`
    };

    apiConfig.body = {
      userSessionId
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      if (!res || !res.data) {
        throw new Error(null);
      }
      return res.data;
    } catch (e) {
      console.debug('[api] -> (memberDeleteRedeemPoints) Error Occurred:', e);
      throw e;
    }
  }

  static async memberCompleteLoyaltyPayment(orderReference, token) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.MAF_PAY}/completeLoyaltyPayment`
    };

    apiConfig.body = {
      orderReference
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      if (!res || !res.data) {
        throw new Error(null);
      }
      return res.data;
    } catch (e) {
      console.debug('[api] -> (completeLoyaltyPayment) Error Occurred:', e);
      throw e;
    }
  }

  static async memberShareTractions(token, cursor = null) {
    const apiConfig = {
      path: `${SERVICE_ENDPOINTS.SHARE}/transactions`
    };

    apiConfig.body = {
      ...(cursor && {
        cursor
      })
    };

    // If the user signed then send the authorization token in the header
    if (token) {
      apiConfig.headers = {
        Authorization: `Bearer ${token}`,
        token
      };
    }

    try {
      const res = await vistaAPIPost(apiConfig);
      if (!res || !res.data) {
        throw new Error(null);
      }
      return res.data;
    } catch (e) {
      console.debug('[api] -> (memberShareTractions) Error Occurred:', e);
      throw e;
    }
  }
}

export { DIApi, Authenticator, Auth, withOAuth };
