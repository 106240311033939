import React, { Component } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { withRouter } from 'react-router-dom';
import Styled from 'styled-components';
import moment from 'moment';

import WhiteOutlineButton from './buttons/WhiteOutlineButton';
import BlueOutlineButton from './buttons/BlueOutlineButton';
import { AuthContext } from './context/AuthenticatorContext';

const OverlayBackground = Styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500000000;
  background-color: ${(props) => props.theme.white_25};
  backdrop-filter: blur(10px);
`;

const DialogContainer = Styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AnnouncementDialog = Styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 582px;
  max-height: 444px;
  padding: 22px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.mode === 'dark' ? props.theme.charcoal : props.theme.white_90};
  background-image: url(${(props) =>
    props.background ? props.background : null});
  color: ${(props) =>
    props.mode === 'dark' ? props.theme.white_70 : props.theme.ds_blue};
  
  @media (max-width: 500px) { 
    max-width: 300px;
    max-height: 520px;
  }
  
  @media (min-width: 501px) and (max-width: 640px) { 
    max-width: 450px;
    max-height: 500px;
  }
`;

const ModalContent = Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
`;

const AnnouncementTitle = Styled.div`
    font-size: 20px;
    font-family: ${(props) => props.theme.sans_serif};
    text-transform: uppercase;
    color: ${(props) =>
      props.mode === 'dark' ? props.theme.white_90 : props.theme.ds_blue};
    text-align: center;
    font-weight: bold;
    
    @media (max-width: 500px) {
        font-weight: normal;
    }
`;

const DescriptionContainer = Styled.div`
    padding: 6px 0;
`;

const AnnouncementDescription = Styled.div`
    font-size: 16px;
    font-family: ${(props) => props.theme.sans_serif};
    width: 100%;
    
    @media (max-width: 500px) { 
        font-size: 14px;
        padding: 2px 0;
        font-weight: light;
    }
`;

const ActionButtons = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const DarkActionBtn = Styled(WhiteOutlineButton)`
    margin: 0 10px;
    width: 152px;
    height: 47px;
    color: ${(props) => props.theme.white_70};
    border-color: ${(props) => props.theme.white_25};
    text-transform: uppercase;
    
    @media (max-width: 500px) {
        width: 110px;
        height: 38px;
        font-size: 11px;
    }
`;

const LightActionBtn = Styled(BlueOutlineButton)`
    margin: 0 10px;
    width: 152px;
    height: 47px;
    text-transform: uppercase;
    color: ${(props) => props.theme.ds_blue};
    border-color: ${(props) => props.theme.ds_blue};
    
    @media (max-width: 500px) {
        width: 110px;
        height: 38px;
        font-size: 11px;
    }
    
    &:hover {
        border: 3px solid ${(props) => props.theme.ds_blue};
    }
`;

const HeaderImg = Styled.img`
    width: 90px;
    height: 90px;
`;

class AnnouncementModalWithContext extends Component {
  targetElement = null;

  componentDidMount() {
    const { cms, selectedSite, dismissedAnnouncementDialog } =
      this.props.parentState;

    if (!selectedSite) {
      return;
    }

    const assets = cms.getHomeAnnouncementAssets(selectedSite.site_id);
    const show = !dismissedAnnouncementDialog;

    if (!this.isEmpty(assets)) {
      if (show && assets.config.visible) {
        disableBodyScroll(this.targetElement);
      } else {
        clearAllBodyScrollLocks();
      }
    } else {
      clearAllBodyScrollLocks();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { cms, selectedSite, dismissedAnnouncementDialog } =
      this.props.parentState;

    if (!selectedSite) {
      return;
    }

    const assets = cms.getHomeAnnouncementAssets(selectedSite.site_id);
    const show = !dismissedAnnouncementDialog;
    const prevShow = !prevProps.parentState.dismissedAnnouncementDialog;

    if (!this.isEmpty(assets) && show && !prevShow) {
      if (assets.config.visible) {
        disableBodyScroll(this.targetElement);
      } else {
        clearAllBodyScrollLocks();
      }
    } else {
      clearAllBodyScrollLocks();
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  isExternal(link) {
    return link.includes('.');
  }

  getImg(type) {
    const { cms, selectedSite } = this.props.parentState;
    const assets = cms.getHomeAnnouncementAssets(selectedSite.site_id);
    const { images } = assets;
    if (this.props.isMobile) {
      return images[type].mobile.x1;
    }
    return images[type].desktop.x1;
  }

  onPressActionBtn = (link) => {
    const { handleDismissAnnouncementDialog } = this.props.parentState;
    handleDismissAnnouncementDialog(true);
    if (link) {
      if (this.isExternal(link)) {
        window.open(link, '_blank').focus();
      } else {
        this.props.history.push(link);
      }
    }
  };

  render() {
    const {
      cms,
      selectedSite,
      dismissedAnnouncementDialog,
      announcementLastViewed
    } = this.props.parentState;

    if (!selectedSite) {
      return null;
    }

    const assets = cms.getHomeAnnouncementAssets(selectedSite.site_id);
    const show = !dismissedAnnouncementDialog;

    if (this.isEmpty(assets) || !show) {
      return null;
    }
    const { copy, config, images } = assets;

    const lastViewed = announcementLastViewed
      ? moment(announcementLastViewed)
      : null;
    const announcementLastUpdated = config.lastUpdated
      ? moment(config.lastUpdated)
      : null;
    const displayDialog =
      show &&
      config.visible &&
      (!lastViewed || lastViewed.isBefore(announcementLastUpdated));

    if (!displayDialog) {
      return null;
    }

    return (
      <OverlayBackground>
        <DialogContainer>
          <AnnouncementDialog
            mode={config.mode ? config.mode : 'light'}
            background={
              config.background && images.background
                ? this.getImg('background')
                : null
            }
          >
            <ModalContent>
              {config.icon && images.icon && (
                <HeaderImg src={this.getImg('icon')} />
              )}
              <div>
                <AnnouncementTitle mode={config.mode}>
                  {copy.title ? copy.title : 'Announcement'}
                </AnnouncementTitle>
              </div>
              {copy.description.length > 0 && (
                <DescriptionContainer>
                  {copy.description.map((description, index) => {
                    return (
                      <AnnouncementDescription key={index}>
                        {description}
                      </AnnouncementDescription>
                    );
                  })}
                </DescriptionContainer>
              )}
              <ActionButtons>
                {copy.buttons.map((button, index) => {
                  if (config.mode === 'dark') {
                    return (
                      <DarkActionBtn
                        key={index}
                        onClick={() => this.onPressActionBtn(button.link)}
                      >
                        {button.label}
                      </DarkActionBtn>
                    );
                  } else {
                    return (
                      <LightActionBtn
                        key={index}
                        onClick={() => this.onPressActionBtn(button.link)}
                      >
                        {button.label}
                      </LightActionBtn>
                    );
                  }
                })}
              </ActionButtons>
            </ModalContent>
          </AnnouncementDialog>
        </DialogContainer>
      </OverlayBackground>
    );
  }
}

const AnnouncementModal = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <AnnouncementModalWithContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(AnnouncementModal);
