import React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ? props.size : '30'}
    height={props.size ? props.size : '30'}
    viewBox="0 0 23 20"
  >
    <path
      fill={props.color ? props.color : 'rgba(27, 27, 27, 0.9)'}
      fillRule="evenodd"
      d="M13.07 11.829A6.571 6.571 0 1 1 3.942 6.79V2.97a1 1 0 0 0-1-1H0V0h3.914a2 2 0 0 1 2 2v1.286h5.229a2 2 0 0 1 2 2v4.571h3.181a2 2 0 0 1 1.732 1l1.358 2.353a2.957 2.957 0 1 1-1.677 1.038l-1.108-1.92a1 1 0 0 0-.866-.5H13.07zM11.17 8.12V6.257a1 1 0 0 0-1-1H5.914v1.018a6.55 6.55 0 0 1 5.257 1.846zm-4.6 9.293a4.6 4.6 0 1 0 0-9.2 4.6 4.6 0 0 0 0 9.2zm13.472-.328a.986.986 0 1 0 0-1.972.986.986 0 0 0 0 1.972zM6.579 14.457a1.643 1.643 0 1 1 0-3.286 1.643 1.643 0 0 1 0 3.286z"
    />
  </svg>
);
