import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAppContext } from '../context/AppContext';
import { Large, Phone } from '../responsive/Breakpoints';
import styled from 'styled-components';
import BlueButton from '../buttons/BlueButton';

const Container = styled.div`
  background: #fff;
  position: relative;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${(props) => (props.mobile ? '52px 22px 47px' : '80px 98px 128px')};
  max-width: 1170px;
  margin: 0 auto;
`;

const ThingsToKnowTable = styled.table`
  width: 100%;
  text-align: start;
`;

const FlexTr = styled.tr`
  display: flex;
`;

const SpacerTd = styled.td`
  width: 70px;
`;

const ThingToKnow = styled.td`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.charcoal_25};
`;

const TTKHeader = styled.h4`
  color: ${(props) => props.theme.charcoal_90};
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.375' : '2.25')}rem;
  font-weight: normal;
  line-height: ${(props) => (props.mobile ? '1.29' : '1.19')};
  margin: ${(props) => (props.mobile ? '8px 0 14px' : '11px 0 23px')};
`;

const TTKBody = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '0.875' : '1')}rem;
  color: ${(props) => props.theme.charcoal_60};
  min-height: 72px;
  margin-bottom: 40px;
`;

const FlexButtonContainer = styled.div`
  flex: 1;
  display: flex;
`;

const TTKButton = styled(BlueButton)`
  align-self: flex-end;
  ${(props) => (props.mobile ? '' : 'max-width: 310px;')}
`;

class AboutUsLinkBlock extends Component {
  thingToKnowBlock(mobile, header, body, buttonContent, buttonOnClick) {
    return (
      <ThingToKnow key={header}>
        <TTKHeader mobile={mobile}>{header}</TTKHeader>
        <TTKBody mobile={mobile}>{body}</TTKBody>
        <FlexButtonContainer>
          <TTKButton width={'100%'} mobile={mobile} onClick={buttonOnClick}>
            {buttonContent}
          </TTKButton>
        </FlexButtonContainer>
      </ThingToKnow>
    );
  }

  allocateBlocks(blocks, blocksPerRow, mobile) {
    let blockGroups = blocks;

    if (!mobile) {
      blockGroups = [];
      let temp = [];
      for (let i = 0; i < blocks.length; i++) {
        if (temp.length > 0) {
          temp.push(<SpacerTd key={i} />);
        }
        temp.push(blocks[i]);
        if ((i + 1) % blocksPerRow === 0) {
          blockGroups.push(temp);
          temp = [];
        }
        if (i === blocks.length - 1 && temp.length > 0) {
          for (let j = temp.length; j < blocksPerRow; j++) {
            temp.push(<SpacerTd />);
            temp.push(<div style={{ flex: '1' }} />);
          }
          blockGroups.push(temp);
        }
      }
    }

    return blockGroups;
  }

  tableContent(labelFn, mobile) {
    const blocks = [
      this.thingToKnowBlock(
        mobile,
        labelFn('aboutus_block_title_1'),
        labelFn('aboutus_block_desc_1'),
        labelFn('aboutus_block_button_text_1'),
        () => window.open('https://dreamscapeimmersive.com/find-us')
      ),
      this.thingToKnowBlock(
        mobile,
        labelFn('aboutus_block_title_2'),
        labelFn('aboutus_block_desc_2'),
        labelFn('aboutus_block_button_text_2'),
        () => window.location.assign('https://dreamscape-immersive.breezy.hr')
      ),
      this.thingToKnowBlock(
        mobile,
        labelFn('aboutus_block_title_3'),
        labelFn('aboutus_block_desc_3'),
        labelFn('aboutus_block_button_text_3'),
        () => this.props.history.push('/press-kit')
      ),
      this.thingToKnowBlock(
        mobile,
        labelFn('aboutus_block_title_4'),
        labelFn('aboutus_block_desc_4'),
        labelFn('aboutus_block_button_text_4'),
        () => this.props.history.push('/contact-us')
      )
    ];

    const blockGroups = this.allocateBlocks(blocks, 2, mobile);

    return (
      <React.Fragment>
        {blockGroups.map((blockGroup, i) => {
          let style = {};
          if (i > 0) {
            style = { marginTop: mobile ? '66px' : '79px' };
          }
          return (
            <FlexTr key={i} mobile={mobile} style={style}>
              {blockGroup}
            </FlexTr>
          );
        })}
      </React.Fragment>
    );
  }

  renderLinkBlock(labelFn, mobile) {
    return (
      <Container>
        <TextContainer mobile={mobile}>
          <ThingsToKnowTable>
            <tbody>{this.tableContent(labelFn, mobile)}</tbody>
          </ThingsToKnowTable>
        </TextContainer>
      </Container>
    );
  }

  render() {
    const { getLabel } = this.props;
    return (
      <React.Fragment>
        <Large>{this.renderLinkBlock(getLabel, false)}</Large>
        <Phone>{this.renderLinkBlock(getLabel, true)}</Phone>
      </React.Fragment>
    );
  }
}

export default withRouter(withAppContext(AboutUsLinkBlock));
