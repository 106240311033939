import merge from 'lodash/merge';

export default class CMS {
  assetsMap = {
    titleAssets: {},
    homeAssets: {},
    siteAssets: {},
    labels: {}
  };

  constructor({
    titleAssets = {},
    homeAssets = {},
    siteAssets = {},
    labels = {},
    locale = 'en'
  }) {
    this.assetsMap.titleAssets = titleAssets;
    this.assetsMap.homeAssets = homeAssets;
    this.assetsMap.siteAssets = siteAssets;
    this.assetsMap.labels = labels;
    this.locale = locale;
  }

  getAdventureAssets(adventureId, siteId) {
    if (!adventureId) {
      return {};
    }

    const assets = this.assetsMap.titleAssets[adventureId];
    if (!assets) {
      // No assets found in the CMS for the provided adventureId
      return {};
    }

    const defaultAssets = assets[this.locale];
    const siteAdventureAssets = assets[siteId];

    // If we have assets based on siteId, merge them into the default asset set where the site specific properties
    // would overwrite the default set if there is any overlap.
    return siteAdventureAssets
      ? merge(defaultAssets, siteAdventureAssets)
      : defaultAssets;
  }

  updateLocale(locale) {
    if (this.locale !== locale) {
      this.locale = locale;
    }
  }

  getHomeAnnouncementAssets(siteId) {
    if (!siteId || !Object.keys(this.assetsMap.homeAssets).length) {
      return {};
    }
    return this.assetsMap.homeAssets.dialog[siteId] || {};
  }

  getHomeHeroAssets(siteId) {
    if (!Object.keys(this.assetsMap.homeAssets).length) {
      return {};
    }

    const slideAssets = this.assetsMap.homeAssets.hero.slides;

    if (!siteId) {
      return slideAssets.default;
    }

    const defaultSlideAssets = slideAssets.default;
    const siteSlideAssets = slideAssets[siteId];

    // If we have assets based on siteId, merge them into the default asset set where the site specific properties
    // would overwrite the default set if there is any overlap.
    return siteSlideAssets
      ? merge(defaultSlideAssets, siteSlideAssets)
      : defaultSlideAssets;
  }

  getSiteAssets(siteId) {
    if (!siteId) {
      return {};
    }

    const siteAssets = this.assetsMap.siteAssets[siteId];

    return siteAssets || {};
  }

  getAdventureCardAssets(adventureId, siteId) {
    const adventureAssets = this.getAdventureAssets(adventureId, siteId);
    return adventureAssets.card;
  }

  getAdventureDetailsAssets(adventureId, siteId) {
    const adventureAssets = this.getAdventureAssets(adventureId, siteId);
    return adventureAssets.details;
  }
}
