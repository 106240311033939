import React, { Component } from 'react';
import styled from 'styled-components';

const BasicInputFieldContainer = styled.div`
  width: ${(props) => {
    const { width } = props;
    if (!width) {
      return '280px';
    }

    if (typeof width === 'number') {
      return `${width}px`;
    }

    if (typeof width === 'string') {
      return width;
    }

    // If none of the above; default
    return '280px';
  }};
  padding: ${(props) => {
    const { padding } = props;
    if (!padding) {
      return;
    }

    if (typeof padding === 'string') {
      return padding;
    }
  }};
  flex: ${(props) => props.flex};
  box-sizing: border-box;
`;

const BasicInputField = styled.input`
  width: auto;
  height: 15px;
  border: solid 1px ${(props) => props.theme.charcoal_25};
  padding: 14px;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: max(0.8125rem, 16px);
  color: ${(props) => (props.dark ? 'white' : props.theme.charcoal)};
  transition: ${(props) => props.theme.transition};
  border-radius: 3px;
  outline: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;

  &::placeholder {
    color: ${(props) =>
      props.dark ? props.theme.white_70 : props.theme.black_30};
  }
`;

const BasicInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  opacity: 0.25;
  color: ${(props) => {
    return props.dark ? props.theme.white_80 : props.theme.charcoal_90;
  }};
`;

export default class DisabledInputField extends Component {
  render() {
    return (
      <BasicInputFieldContainer
        width={this.props.width}
        padding={this.props.padding}
        flex={this.props.flex}
      >
        <BasicInputLabel disabled dark={this.props.dark}>
          {this.props.label || this.props.placeholder}
          <BasicInputField disabled {...this.props} />
        </BasicInputLabel>
      </BasicInputFieldContainer>
    );
  }
}
