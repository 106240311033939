import React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="117"
    height="140"
    viewBox="0 0 117 140"
  >
    <g fill="none" fill-rule="evenodd">
      <rect
        width="114"
        height="137"
        x="1.5"
        y="1.5"
        stroke="#2E3191"
        stroke-width="3"
        rx="5"
      />
      <g fill="#2E3191">
        <path d="M46 32.172L68.042 10.13l2.828 2.828L48.828 35z" />
        <path d="M55 10h16v4H55z" />
        <path d="M67 26V10h4v16z" />
      </g>
      <text
        fill="#1B1B1B"
        fill-opacity=".9"
        font-family="Brother-1816-Bold, Brother 1816"
        font-size="16"
        font-weight="bold"
      >
        <tspan x="19.592" y="127">
          DRAGONS
        </tspan>
      </text>
      <text
        fill="#1B1B1B"
        font-family="Brother-1816-Bold, Brother 1816"
        font-size="45"
        font-weight="bold"
        letter-spacing="-1"
      >
        <tspan x="10.86" y="91">
          DRG
        </tspan>
      </text>
      <path
        fill="#1B1B1B"
        fill-opacity=".25"
        fill-rule="nonzero"
        d="M6 106v-1h105v1zM6 44v-1h105v1z"
      />
    </g>
  </svg>
);
