import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';

import { Desktop, Mobile } from '../responsive/Breakpoints';

import img1_2x from '../../assets/images/img-info-slide-1@2x.jpg';
import img1_3x from '../../assets/images/img-info-slide-1@3x.jpg';
import img1Small_2x from '../../assets/images/img-info-slide-1-s@2x.jpg';
import img1Small_3x from '../../assets/images/img-info-slide-1-s@3x.jpg';
import img2_2x from '../../assets/images/img-info-slide-2@2x.jpg';
import img2Small_2x from '../../assets/images/img-info-slide-2-s@2x.jpg';
import img2Small_3x from '../../assets/images/img-info-slide-2-s@3x.jpg';
import img3_2x from '../../assets/images/img-info-slide-3-4-l@2x.png';
import img3Small_2x from '../../assets/images/img-info-slide-3-4-s@2x.png';
import img3Small_3x from '../../assets/images/img-info-slide-3-4-s@3x.png';
import img4_2x from '../../assets/images/img-info-slide-3-3-l@2x.jpg';
import img4Small_2x from '../../assets/images/img-info-slide-3-3-s@2x.jpg';
import img4Small_3x from '../../assets/images/img-info-slide-3-3-s@3x.jpg';

const StyledCarouselItem = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  [dir='rtl'] & {
    direction: rtl;
  }
`;

const NarrativeContainer = styled.section`
  max-width: ${(props) => (props.mobile ? '384px' : '450px')};
  border-top: 1px solid ${(props) => props.theme.white_70};
  display: ${(props) => (props.mobile ? 'block' : 'inline-block')};
  margin: ${(props) => (props.mobile ? '358px auto 0 auto' : '360px 0 0 0')};
`;

const NarrativeWrapper = styled.section`
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  max-width: 1170px;
`;

const NarrativeHeader = styled.section`
  font-size: ${(props) => (props.mobile ? '1.75rem' : '2.25rem')};
  line-height: 1.19;
  color: rgba(255, 255, 255, 0.9);
  font-family: ${(props) => props.theme.serif};
  padding: 18px 0 16px;
`;

const NarrativeText = styled.div`
  font-size: ${(props) => (props.mobile ? '0.875' : '1')}rem;
  color: ${(props) => props.theme.white_70};
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PlanAheadTitle = styled.div`
  pointer-events: none;
  font-size: ${(props) => (props.mobile ? '2.25rem' : '2.75rem')};
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.33' : '1.09')};
  color: white;
  margin: ${(props) => (props.mobile ? '56px auto 0 auto' : '111px 0 0 0')};
  max-width: ${(props) => (props.mobile ? '384px' : '1170px')};
  ${(props) => (props.mobile ? 'justify-content: center;' : '')}
`;

const CarouselItemWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-content: center;
`;

const CarouselMobileItemWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  align-content: center;
  max-width: 384px;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 0 22px;
`;

const TitleButtonGroup = styled.div`
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1170px;
  margin: 0 auto;
`;

const PaddingWrapper = styled.div`
  padding: 0 ${(props) => (props.mobile ? '22' : '100')}px;
  max-width: ${(props) => (props.mobile ? '384' : '1170')}px;
  margin: 0 auto;
`;

const content = [
  {
    background: 'black',
    title: {
      label: 'home_plan_ahead_slide_title_1'
    },
    header: {
      label: 'home_plan_ahead_slide_header_1'
    },
    narrative: {
      label: 'home_plan_ahead_slide_narrative_1'
    },
    imageSources: {
      src: img1_2x,
      src2x: img1_2x,
      src3x: img1_3x
    },
    imageSourcesSmall: {
      src: img1Small_2x,
      src2x: img1Small_2x,
      src3x: img1Small_3x
    }
  },
  {
    background: 'gray',
    title: {
      label: 'home_plan_ahead_slide_title_2'
    },
    header: {
      label: 'home_plan_ahead_slide_header_2'
    },
    narrative: {
      label: 'home_plan_ahead_slide_narrative_2'
    },
    imageSources: {
      src: img2_2x,
      src2x: img2_2x,
      src3x: img2_2x
    },
    imageSourcesSmall: {
      src: img2Small_2x,
      src2x: img2Small_2x,
      src3x: img2Small_3x
    }
  },
  {
    background: 'lightgray',
    title: {
      label: 'home_plan_ahead_slide_title_3'
    },
    header: {
      label: 'home_plan_ahead_slide_header_3'
    },
    narrative: {
      label: 'home_plan_ahead_slide_narrative_3'
    },
    imageSources: {
      src: img3_2x,
      src2x: img3_2x,
      src3x: img3_2x
    },
    imageSourcesSmall: {
      src: img3Small_2x,
      src2x: img3Small_2x,
      src3x: img3Small_3x
    }
  },
  {
    background: 'lightgray',
    title: {
      label: 'home_plan_ahead_slide_title_4'
    },
    header: {
      label: 'home_plan_ahead_slide_header_4'
    },
    narrative: {
      label: 'home_plan_ahead_slide_narrative_4'
    },
    imageSources: {
      src: img4_2x,
      src2x: img4_2x,
      src3x: img4_2x
    },
    imageSourcesSmall: {
      src: img4Small_2x,
      src2x: img4Small_2x,
      src3x: img4Small_3x
    }
  }
];

const items = content.map((item, itr) => {
  return (
    <StyledCarouselItem background={item.background} key={itr}>
      <AppContext.Consumer>
        {({ getLabel, isRtl }) => (
          <React.Fragment>
            <Desktop>
              <BackgroundImage
                /* for arabic flip the image */
                style={{ transform: isRtl() ? 'scaleX(-1)' : '' }}
                src={item.imageSources.src}
                srcset={`${item.imageSources.src2x} 2x, ${item.imageSources.src3x} 3x`}
              />
              <PaddingWrapper>
                <CarouselItemWrapper>
                  <TitleButtonGroup>
                    <PlanAheadTitle>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getLabel(item.title.label)
                        }}
                      />
                    </PlanAheadTitle>
                  </TitleButtonGroup>
                </CarouselItemWrapper>
                <NarrativeWrapper>
                  <NarrativeContainer>
                    <NarrativeHeader>
                      {getLabel(item.header.label)}
                    </NarrativeHeader>
                    <NarrativeText>
                      {getLabel(item.narrative.label)}
                    </NarrativeText>
                  </NarrativeContainer>
                </NarrativeWrapper>
              </PaddingWrapper>
            </Desktop>

            <Mobile>
              <BackgroundImage
                src={item.imageSourcesSmall.src}
                srcset={`${item.imageSourcesSmall.src2x} 2x, ${item.imageSourcesSmall.src3x} 3x`}
              />
              <PaddingWrapper mobile>
                <CarouselMobileItemWrapper>
                  <PlanAheadTitle mobile>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getLabel(item.title.label)
                      }}
                    />
                  </PlanAheadTitle>
                </CarouselMobileItemWrapper>
                <NarrativeWrapper mobile>
                  <NarrativeContainer mobile>
                    <NarrativeHeader mobile>
                      {getLabel(item.header.label)}
                    </NarrativeHeader>
                    <NarrativeText mobile>
                      {getLabel(item.narrative.label)}
                    </NarrativeText>
                  </NarrativeContainer>
                </NarrativeWrapper>
              </PaddingWrapper>
            </Mobile>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    </StyledCarouselItem>
  );
});

export default items;
