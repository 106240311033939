import styled from 'styled-components';
import BaseButton from './BaseButton';

const RedButton = styled(BaseButton)`
  color: white;
  background-color: ${(props) => props.theme.ds_red};
  border: none;
  box-shadow: ${(props) =>
    props.shadow ? `0 10px 15px 0 rgb(0 0 0 / 30%)` : 'none'};

  &:focus {
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export default RedButton;
