import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile } from './responsive/Breakpoints';
import styled from 'styled-components';
import { withAppContext } from './context/AppContext';

const ContainerBound = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  min-height: 485px;
`;

const Container = styled.div`
  background: #fff;
  padding: ${(props) =>
    props.mobile ? '36px 21px 49px' : '84px 100px 60px 100px'};
`;

const Header = styled.h2`
  color: ${(props) => props.theme.charcoal};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '1.5em' : '2.5em')};
  line-height: 1.09;
  padding-bottom: 68px;
  margin: 0;
`;

const ContentBlocks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DestinationBlock = styled.div`
  flex: 5;
  padding-inline-end: ${(props) => (props.mobile ? undefined : '80px')};
`;

const BlockHeader = styled.h4`
  color: ${(props) => props.theme.charcoal_90};
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.375rem' : '2.25rem')};
  padding-top: ${(props) => (props.mobile ? '10px' : '0')};
  line-height: 1.19;
  font-weight: normal;
  padding-bottom: 18px;
  margin: 0;
`;

const DestinationDescription = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  color: ${(props) => props.theme.charcoal_60};
`;

const TripDetailsBlock = styled.div`
  padding-top: ${(props) => (props.mobile ? '40px' : undefined)};
  flex: 4;
`;

const TripDetailsTextContainer = styled.div`
  padding-bottom: 18px;
`;
const HeaderText = styled.h5`
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  color: ${(props) => props.theme.charcoal_90};
  margin: 0;
`;

const SubText = styled.div`
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  color: ${(props) => props.theme.charcoal_60};
`;

const PageBreak = styled.div`
  width: 100%;
  margin: 0 auto 18px;
  border-top: 1px solid ${(props) => props.theme.charcoal_25};
`;

const TicketingDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 40px;
`;

const Para = styled.p`
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  color: ${(props) => props.theme.charcoal_60};
  margin: 16px 0 0 0;
`;

class ItineraryBlock extends Component {
  state = {};

  checkForContent = () => {
    return this.props.contentData ? this.props.contentData.itinerary : null;
  };

  checkForSiteContent = () => {
    return this.props.siteCopy ? this.props.siteCopy.ticketingDetails : null;
  };

  renderTripDetails = (itineraryList, mobile) => {
    return itineraryList.map((listItem, idx) => (
      <TripDetailsTextContainer key={idx}>
        <HeaderText mobile={mobile}>{listItem.title}</HeaderText>
        <SubText mobile={mobile}>{listItem.body}</SubText>
      </TripDetailsTextContainer>
    ));
  };

  renderTicketingDetails = (ticketingCopy, mobile) => {
    if (!ticketingCopy) {
      return null;
    }

    const header = ticketingCopy.header
      ? ticketingCopy.header.toUpperCase()
      : 'NEW TICKETING APPROACH';
    const lines = ticketingCopy.lines.map((ticketingDetailsLine, index) => (
      <Para key={'tx-detail-' + index} mobile={mobile}>
        {ticketingDetailsLine}
      </Para>
    ));

    return (
      <TicketingDetails>
        <PageBreak />
        <HeaderText mobile={mobile}>{header}</HeaderText>
        {lines}
      </TicketingDetails>
    );
  };

  renderContent(mobile) {
    const adventureContent = this.checkForContent();
    const ticketingDetailsCopy = this.checkForSiteContent();
    const { getLabel } = this.props;

    const content = (
      <React.Fragment>
        <DestinationBlock mobile={mobile}>
          <PageBreak />
          <BlockHeader mobile={mobile}>
            {adventureContent.copy.destination}
          </BlockHeader>
          <DestinationDescription mobile={mobile}>
            {adventureContent.copy.description}
          </DestinationDescription>
          {this.renderTicketingDetails(ticketingDetailsCopy, mobile)}
        </DestinationBlock>
        <TripDetailsBlock mobile={mobile}>
          <PageBreak />
          <BlockHeader mobile={mobile}>
            {getLabel('itinerary_adventure_details')}
          </BlockHeader>
          {this.renderTripDetails(adventureContent.copy.itineraryList, mobile)}
        </TripDetailsBlock>
      </React.Fragment>
    );

    return (
      <Container mobile={mobile}>
        <ContainerBound>
          <Header mobile={mobile}>
            {getLabel('itinerary_experience_title')}
          </Header>
          <Desktop>
            <ContentBlocks>{content}</ContentBlocks>
          </Desktop>
          <Mobile>{content}</Mobile>
        </ContainerBound>
      </Container>
    );
  }

  render() {
    return (
      <div>
        <Desktop>{this.renderContent(false)}</Desktop>
        <Mobile>{this.renderContent(true)}</Mobile>
      </div>
    );
  }
}

export default withRouter(withAppContext(ItineraryBlock));
