import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import AboutUsHeroSection from '../components/aboutus/AboutUsHeroSection';
import AboutUsDetailsSection from '../components/aboutus/AboutUsDetailsSection';
import AboutUsLinkBlock from '../components/aboutus/AboutUsLinkBlock';

const Container = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
`;

class AboutUs extends Component {
  render() {
    return (
      <Container id="about-us-page">
        <AboutUsHeroSection />
        <AboutUsDetailsSection />
        <AboutUsLinkBlock />
      </Container>
    );
  }
}

export default withRouter(AboutUs);
