import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Desktop, Mobile } from './responsive/Breakpoints';
import theme from '../util/theme';
import LeftNavDrawer from './LeftNavDrawer';
import LogoText from '../components/svgs/LogoText';
import LogoTextStacked from '../components/svgs/LogoTextStacked';
import LogoTextStackedWHover from '../components/svgs/LogoTextStackedWHover';
import MenuAnimatedButton from '../components/buttons/MenuAnimatedButton';
import navbarDropDownArrowActive from '../assets/images/img-dropdown-active.svg';
import navbarDropDownArrowInactive from '../assets/images/img-dropdown-inactive.svg';
import { AuthContext } from './context/AuthenticatorContext';
import { withAppContext } from './context/AppContext';
import { sendMenuEvent } from '../util/analytics';
import { withAuth0 } from '@auth0/auth0-react';

import LocationIcon from '../components/svgs/LocationIcon';

const MyNavLink = (props) => (
  <NavLink activeStyle={{ color: theme.ds_bright_blue }} {...props} />
);

const NavRow = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : 62)}px;
  z-index: 400000000;
`;

const NavContainer = styled.div`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '1110px')};
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.charcoal_90};
  box-shadow: 0 10px 15px 0 ${(props) => props.theme.black_30};
  color: white;
  margin: 0 auto;
  padding: ${(props) => (props.padding ? props.padding : '13px 30px')};
  font-family: ${(props) => props.theme.sans_serif};
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  [dir='rtl'] & {
    font-size: 0.85rem;
  }
`;

const StyledNavLink = styled(MyNavLink)`
  height: 100%;
  margin: 0 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  color: ${(props) => props.theme.white_80};
  text-decoration: none;

  &:hover {
    color: ${(props) => (props.disable ? props.theme.white_80 : '#fff')};
    cursor: ${(props) => (props.disable ? 'auto' : 'pointer')};
  }
`;

const StyledLocationLink = styled.div`
  height: 100%;
  margin: 0 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  color: ${(props) => props.theme.white_80};
  text-decoration: none;

  &:hover {
    color: ${(props) => (props.disable ? props.theme.white_80 : '#fff')};
    cursor: ${(props) => (props.disable ? 'auto' : 'pointer')};
  }
`;

const StyledChangeLabel = styled.span`
  margin-left: 4px;
  font-weight: bold;
  color: ${(props) => props.theme.ds_bright_blue};
`;

const StyledNavDropDown = styled.div`
  font-weight: bold;
  letter-spacing: 2px;
`;

const FlexSpacer = styled.div`
  flex: 1;
`;

const MobileTitleWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 120px;
  align-items: center;
`;

const MobileTitle = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  font-size: 1.125rem;
`;

const MenuToggle = styled.div`
  margin: 0;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 4px;
`;

const DropDownArrow = styled.img`
  transform: none;
  padding: 0 0 2px 3px;
`;

const DropDownDiv = styled.div`
  background: ${(props) => props.theme.charcoal};
  width: 195px;
  margin: ${(props) => props.marginTop}px 6px 0;
  justify-content: space-evenly;
  text-align: center;
  cursor: auto;
  transition: 0.5s all;
`;

const DropDownTouchContainer = styled.div`
  position: relative;
  top: ${(props) => props.top}px;
  height: ${(props) => props.height}px;
  float: ${(props) => (props.isRtl ? 'left' : 'right')};
`;

const DropDownContainer = styled.div`
  left: 0;
  right: 0;
  max-width: 1170px;
  margin: 0 auto;
  cursor: auto;
`;

const DropDownLink = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
  color: ${(props) =>
    props.active ? props.theme.ds_bright_blue : props.theme.white_70};
  font-weight: normal;
  cursor: pointer;
  width: max-content;
  margin: 0 auto;
  transition: 1s all;
`;

NavContainer.defaultProps = {
  theme: {
    ...theme
  }
};

class AppNavBar extends Component {
  state = {
    isDrawerOpen: false,
    linkMouseOver: false,
    dropDownMouseOver: false,
    navBarHeight: null,
    navBarPaddingBottom: null,
    DILogoHovering: false
  };

  toggleLeftDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };

  handleDrawerOnChange = (open) => {
    this.setState({ isDrawerOpen: open });
  };

  linkMouseOut = () => {
    this.setState({ linkMouseOver: false });
  };

  linkMouseOver = () => {
    this.setState({ linkMouseOver: true });
  };

  dropDownMouseOut = () => {
    this.setState({ dropDownMouseOver: false });
  };

  dropDownMouseOver = () => {
    this.setState({ dropDownMouseOver: true });
  };

  componentDidUpdate = () => {
    if (this.state.navBarHeight || this.state.navBarPaddingBottom) {
      return;
    }
    const navBar = document.getElementById('navbar');
    const navBarHeight = navBar.offsetHeight;
    const paddingBottomString = getComputedStyle(navBar).paddingBottom;
    const navBarPaddingBottom = parseInt(paddingBottomString.slice(0, -2), 10);
    this.setState({
      navBarHeight,
      navBarPaddingBottom
    });
  };

  handleUserSignOut = async () => {
    if (this.props.location && this.props.location.state) {
      localStorage.setItem(
        'routeState',
        JSON.stringify(this.props.location.state)
      );
    }
    localStorage.setItem('logoutReturnUrl', '/');
    this.props.auth0.logout({ returnTo: window.location.origin });
    this.props.authState.handleAuthStateChange('signedOut');
  };

  handleSetLocationPressed = (event) => {
    // prevent the NavLink "navigation" effect so we can use the same styling component but handle different functionality
    event.preventDefault();

    // Prevent opening the dialog if we only have one site to select
    if (this.props.authState.sites.length <= 1) {
      return;
    }

    this.props.authState.handleShowLocationDialog(true);
  };

  hoverDILogo = () => {
    this.setState({ DILogoHovering: true });
  };

  regularDILogo = () => {
    this.setState({ DILogoHovering: false });
  };

  render() {
    const { getLabel } = this.props;
    let accountLink = (
      <StyledNavLink
        id={'my-account'}
        style={{ marginRight: 0 }}
        to="/account"
        onClick={() => sendMenuEvent('header_menu', 'account')}>
        <span>{getLabel('navbar_menu_my_account')}</span>
      </StyledNavLink>
    );

    let shouldDropDown =
      this.props.authState.isSignedIn &&
      (this.state.linkMouseOver || this.state.dropDownMouseOver);

    if (this.props.authState.isSignedIn) {
      let dropDownArrow = navbarDropDownArrowInactive;
      if (this.props.location.pathname.startsWith('/account')) {
        dropDownArrow = navbarDropDownArrowActive;
      }
      accountLink = (
        <StyledNavLink
          id={'my-account'}
          style={{ marginRight: 0 }}
          to="/account">
          <span onMouseOut={this.linkMouseOut} onMouseOver={this.linkMouseOver}>
            {getLabel('navbar_menu_my_account')}
            <DropDownArrow src={dropDownArrow} alt="DropDownArrow" />
          </span>
        </StyledNavLink>
      );
    }

    const marginBottom = this.state.navBarPaddingBottom;
    const height = this.state.navBarHeight;
    const fullHeight = marginBottom + height;

    let DILogo = <LogoTextStacked />;

    if (this.state.DILogoHovering) {
      DILogo = <LogoTextStackedWHover />;
    }

    const locationName =
      this.props.authState.selectedSite.site_legalname ||
      getLabel('navbar_set_location');
    const locationAbreviatedName = this.props.authState.selectedSite.tlc || '';
    const locationSet = !!this.props.authState.selectedSite.site_legalname;
    const disableLocationSelector = this.props.authState.sites.length <= 1;

    return (
      <React.Fragment>
        {/* Desktop Experience */}
        <Desktop>
          <NavRow>
            <NavContainer id="navbar">
              <StyledNavLink
                onMouseEnter={this.hoverDILogo}
                onMouseLeave={this.regularDILogo}
                style={{ marginLeft: 0, color: 'white' }}
                activeStyle={{ color: 'inherit' }}
                exact
                to="/">
                <div>{DILogo}</div>
              </StyledNavLink>
              <StyledLocationLink
                disable={disableLocationSelector}
                onClick={(e) => this.handleSetLocationPressed(e)}>
                <span>{locationName.toUpperCase()}</span>
                <LocationIcon active={locationSet} />
                {locationSet ? (
                  <StyledChangeLabel>
                    {getLabel('navbar_change_text')}
                  </StyledChangeLabel>
                ) : null}
              </StyledLocationLink>

              <FlexSpacer>{/* Spacer */}</FlexSpacer>

              <StyledNavLink
                to="/adventures"
                onClick={() => sendMenuEvent('header_menu', 'adventures')}>
                <span>{getLabel('navbar_menu_adventures')}</span>
              </StyledNavLink>
              <StyledNavLink
                to="/find-us"
                onClick={() => sendMenuEvent('header_menu', 'find-us')}>
                <span>{getLabel('navbar_menu_find_us')}</span>
              </StyledNavLink>
              <StyledNavLink
                to="/plan-ahead"
                onClick={() => sendMenuEvent('header_menu', 'plan-ahead')}>
                <span>{getLabel('navbar_menu_plan_ahead')}</span>
              </StyledNavLink>
              {accountLink}
            </NavContainer>
            <StyledNavDropDown>
              <DropDownContainer>
                <DropDownTouchContainer
                  isRtl={this.props.isRtl()}
                  top={-1 * marginBottom}
                  height={
                    shouldDropDown ? `${fullHeight}px` : `${marginBottom}px`
                  }
                  onMouseOver={this.dropDownMouseOver}
                  onMouseOut={this.dropDownMouseOut}>
                  <DropDownDiv
                    marginTop={marginBottom}
                    style={{
                      overflowY: 'hidden',
                      height: shouldDropDown ? `${height}px` : '0',
                      WebkitTransform: 'translate3d(0, 0, 0)'
                    }}>
                    <DropDownLink
                      active={this.props.location.pathname.startsWith(
                        '/account/details'
                      )}
                      style={{
                        opacity: shouldDropDown ? '100' : '0',
                        marginTop: '12px',
                        marginBottom: '6px'
                      }}
                      onClick={() => {
                        sendMenuEvent('header_menu', 'account-details');
                        this.props.history.push('/account/details');
                      }}>
                      {getLabel('navbar_menu_account_details')}
                    </DropDownLink>
                    <DropDownLink
                      style={{
                        opacity: shouldDropDown ? '100' : '0',
                        marginTop: '12px',
                        marginBottom: '12px'
                      }}
                      onClick={() => {
                        sendMenuEvent('header_menu', 'sign-out');
                        this.handleUserSignOut();
                      }}>
                      {getLabel('navbar_menu_sign_out')}
                    </DropDownLink>
                  </DropDownDiv>
                </DropDownTouchContainer>
              </DropDownContainer>
            </StyledNavDropDown>
          </NavRow>
        </Desktop>

        {/* Mobile Experience */}
        <Mobile>
          <NavRow maxHeight={16}>
            <NavContainer
              maxWidth="auto"
              padding="16px"
              id="navbar"
              backgroundColor={theme.charcoal}
              style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <MenuToggle style={{ zIndex: 10 }}>
                <MenuAnimatedButton
                  isOpen={this.state.isDrawerOpen}
                  menuClicked={this.toggleLeftDrawer}
                  height={18}
                  width={24}
                  strokeWidth={2}
                  color={theme.white_70}
                  borderRadius={0}
                />
              </MenuToggle>

              <MobileTitleWrapper>
                <MobileTitle onClick={() => this.props.history.push('/')}>
                  <LogoText />
                </MobileTitle>
              </MobileTitleWrapper>

              <div
                onClick={(e) => this.handleSetLocationPressed(e)}
                style={{
                  zIndex: 10,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}>
                <span style={{ fontSize: '0.625rem', lineHeight: 10 }}>
                  {locationAbreviatedName}
                </span>
                <LocationIcon active={locationSet} />
              </div>
            </NavContainer>
          </NavRow>

          {/* Left Nav Drawer */}
          <LeftNavDrawer
            fadeOut
            width={300}
            signOut={this.handleUserSignOut}
            open={this.state.isDrawerOpen}
            onChange={this.handleDrawerOnChange}
          />
        </Mobile>
      </React.Fragment>
    );
  }
}

const AuthenticatedNavBar = (props) => (
  <AuthContext.Consumer>
    {(authState) => {
      return <AppNavBar {...props} authState={authState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(withAuth0(AuthenticatedNavBar)));
