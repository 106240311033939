import React, { Component, lazy, Suspense } from 'react';
import { withAppContext } from '../../components/context/AppContext';

const TermsEn = lazy(() => import('./en'));
const TermsAr = lazy(() => import('./ar'));

class Terms extends Component {
  render() {
    return this.props.locale === 'ar' ? (
      <Suspense fallback={null}>
        <TermsAr />
      </Suspense>
    ) : (
      <Suspense fallback={null}>
        <TermsEn />
      </Suspense>
    );
  }
}

export default withAppContext(Terms);
