import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as Routes from '../routes';
import { LOCATION_RESPONSE_DATA } from '../util/constants';

export default class Navigation extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            sensitive
            path="/:slug1*:slug2([A-Z]):slug3*/"
            render={(props) => (
              <Redirect to={`${props.location.pathname.toLowerCase()}`} />
            )}
          />
          <Route exact path="/" component={Routes.HOME} />
          <Route
            path={`/adventures`.toLowerCase()}
            exact
            component={Routes.ADVENTURES}
          />
          <Route exact path="/find-us" component={Routes.FIND_US} />
          <Route
            exact
            path="/find-us/:location"
            render={({ match }) => {
              if (LOCATION_RESPONSE_DATA.indexOf(match.params.location) < 0) {
                return <Redirect to="/find-us" />;
              } else {
                return <Routes.LOCATION_LANDING />;
              }
            }}
          />
          <Route exact path="/plan-ahead" component={Routes.PLAN_AHEAD} />
          <Route exact path="/about-us" component={Routes.ABOUT_US} />
          <Route
            exact
            path="/adventures/details/:title_id"
            component={Routes.SINGLE_ADVENTURE}
          />
          <Route exact path="/terms" component={Routes.TERMS} />
          <Route exact path="/terms" component={Routes.TERMS} />
          <Route exact path="/terms" component={Routes.TERMS} />
          <Route exact path="/privacy" component={Routes.PRIVACY} />
          <Route exact path="/careers" component={Routes.CAREERS} />
          <Route exact path="/contact-us" component={Routes.CONTACT_US} />
          <Route exact path="/faq" component={Routes.FAQ} />
          <Route exact path="/share" component={Routes.Share_Landing_Page} />
          <Route exact path="/share/faq" component={Routes.Share_FAQ} />
          <Route exact path="/share/terms" component={Routes.Share_Terms} />
          <Route
            exact
            path="/share/how-does-share-work"
            component={Routes.Share_Workflow}
          />
          <Route exact path="/press-kit" component={Routes.PRESS_KIT} />
          <Route path="*" component={Routes.PAGENOTFOUND} />
          {/*
          <Route path="*">
            {document.readyState !== 'complete' ? <Redirect to="/" /> : null}
          </Route> */}

          {/* <Route
            render={({ location }) => {
              if (!location || !location.search) {
                return <Redirect to="/" />;
              }

              const queryParams = qs.parse(location.search.replace('?', ''));
              if (queryParams.amc_error) {
                alert(queryParams.amc_error);
              }
              return <Redirect to="/" />;
            }}
          /> */}
        </Switch>
      </React.Fragment>
    );
  }
}
