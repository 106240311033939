import React from 'react';
// import PropTypes from "prop-types";
import { Motion, spring } from 'react-motion';
import Hammer from 'react-hammerjs';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';
import theme from '../util/theme';
import { BlueOutlineButton, WhiteOutlineButton } from './buttons';

import { sendMenuEvent } from '../util/analytics';

const MyNavLink = (props) => (
  <NavLink activeStyle={{ color: theme.ds_bright_blue }} {...props} />
);

const Overlay = styled(Hammer).attrs({
  style: (props) => ({
    opacity: props.x / props.drawerwidth,
    transform: `translate3d(${props.drawerwidth}px, 0, 0)`
  })
})`
  z-index: -2;
  position: fixed;
  width: ${(props) => props.clientwidth}px;
  height: 100%;
  background: ${(props) => props.overlaycolor};
  top: 0;
  left: 0;
  margin: 0;
`;

const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: black;
`;

const DrawerNavBar = styled.div`
  height: ${(props) => (props.toasterOffset ? 32 + props.toasterOffset : 32)}px;
  padding: 11px;
  flex-direction: column;
  flex-shrink: 0;
`;

const StyledNavLink = styled(MyNavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 19px 0;
  margin: 0 20px;
  border-bottom: 1px solid ${(props) => props.theme.white_70};
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: ${(props) => props.theme.white_80};
  text-decoration: none;
  flex-shrink: 0;
`;

const AdventureSubLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.white_70};
  flex-shrink: 0;
`;

const SubLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;

const StyledNavSubLink = styled(MyNavLink)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: ${(props) => props.theme.white_80};
  text-decoration: none;
`;

const StyledNavSubDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: ${(props) => props.theme.white_80};
  text-decoration: none;
  cursor: pointer;
`;

const LinkText = styled.div`
  display: flex;
  margin-inline-end: 8px;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: ${(props) => props.theme.white_80};
`;

const LocationContainer = styled.div`
  margin: 30px 20px 20px 20px;
  padding: ${(props) => (props.locationSelected ? undefined : '20px')};
  border: ${(props) =>
    props.locationSelected ? undefined : '1px solid white'};
  border-radius: ${(props) => (props.locationSelected ? undefined : '5px')};
  color: white;
  text-align: ${(props) => (props.locationSelected ? undefined : 'center')};
`;

const LocationContainerTitle = styled.div`
  font-weight: bold;
  font-size: ${(props) => (props.locationSelected ? '0.75rem' : '1rem')};
  color: ${(props) =>
    props.locationSelected ? props.theme.white_80 : 'white'};
  letter-spacing: ${(props) => (props.locationSelected ? '2px' : undefined)};
  text-align: ${(props) => (props.locationSelected ? undefined : 'center')};
`;

const LocationContainerDesc = styled.div`
  color: ${(props) => props.theme.white_80};
  font-size: 0.875rem;
  margin-top: 10px;
`;

const LocationButtonContainer = styled.div`
  margin-top: 21px;
`;

const styles = function (val, props) {
  const { zIndex, height, fadeOut, offset, isRtl } = props;

  let width = props.width;
  if (/\D/.test(width)) {
    width = document.body.clientWidth * (width.match(/\d*/) / 100);
  }

  const opacity = (val - offset) / width;
  const stepX = (val - width) * (isRtl() ? -1 : 1);

  const transform = {
    boxSizing: 'content-box',
    pointer: 'cursor',
    position: 'fixed',
    display: 'block',
    zIndex: zIndex,
    width: width,
    maxWidth: width,
    height: height,
    top: 0,
    margin: 0,
    transform: `translate3d(${stepX}px, 0, 0)`,
    WebkitTransform: `translate3d(${stepX}px, 0, 0)`,
    opacity: fadeOut ? opacity : 1,
    backgroundColor: 'white'
  };
  if (isRtl()) {
    transform.right = 0;
  } else {
    transform.left = 0;
  }

  return { transform };
};

class LeftNavDrawerWithContext extends React.Component {
  static defaultProps = {
    zIndex: 10000,
    noTouchClose: false,
    onChange: () => {},
    overlayColor: 'rgba(0, 0, 0, 0.4)',
    config: { stiffness: 350, damping: 40 },
    open: false,
    width: 300,
    height: '100%',
    panTolerance: 50,
    fadeOut: false,
    offset: 0
  };

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    const { open: nextOpen } = nextProps;

    if (nextOpen !== open) {
      if (nextOpen) {
        return this.open();
      }
      return this.close();
    }
  }

  state = {
    currentState: 'CLOSED',
    toasterNotif: null
  };

  componentDidMount() {
    const toasterNotif = document.getElementById('toasterNotification');
    this.setState({ toasterNotif });
  }

  isState = (s) => s === this.state.currentState;
  isClosed = () => this.isState('CLOSED');
  isOpen = () => this.isState('OPEN');
  isOpening = () => this.isState('IS_OPENING');
  isClosing = () => this.isState('IS_CLOSING');

  close() {
    this.props.onChange(false);
    return this.setState({ currentState: 'CLOSED', x: 0 });
  }

  open() {
    this.props.onChange(true);

    return this.setState({ currentState: 'OPEN', x: this.calculateWidth() });
  }

  handleUserSignOut = async () => {
    if (this.props.location && this.props.location.state) {
      localStorage.setItem(
        'routeState',
        JSON.stringify(this.props.location.state)
      );
    }
    localStorage.setItem('logoutReturnUrl', '/');
    this.props.signOut({ returnTo: window.location.origin });
    this.props.authState.handleAuthStateChange('signedOut');
  };

  isClosingDirection(direction) {
    return direction === 2;
  }

  closingOrOpening(direction) {
    return this.isClosingDirection(direction) ? 'IS_CLOSING' : 'IS_OPENING';
  }

  inPanTolerance(deltaX) {
    const { currentState } = this.state;
    const { panTolerance } = this.props;

    return Math.abs(deltaX) <= panTolerance && currentState === 'OPEN';
  }

  onPressUp = (e) => {
    if (this.props.noTouchClose) {
      return;
    }
    // e.preventDefault();
    this.close();
  };

  onPan = (e) => {
    if (this.isClosed()) {
      return;
    }
    e.preventDefault();

    const { isFinal, pointers, direction, deltaX } = e;
    if (this.inPanTolerance(deltaX)) {
      return;
    }

    if (isFinal) {
      if (this.isOpening()) {
        return this.open();
      }

      if (this.isClosing()) {
        return this.close();
      }

      return;
    }

    const { currentState } = this.state;
    const { clientX } = pointers[0];

    const closingOrOpening = this.closingOrOpening(direction);
    const nextState = {
      PEAK: closingOrOpening,
      IS_OPENING: closingOrOpening,
      IS_CLOSING: closingOrOpening,
      OPEN: 'IS_CLOSING',
      CLOSED: 'PEAK'
    };

    this.setState({
      currentState: nextState[currentState],
      x: clientX
    });
  };

  onOverlayTap = (e) => {
    e.preventDefault();
    if (this.isOpen()) {
      this.close();
    }
  };

  calculateWidth = () => {
    const width = this.props.width;
    return /%/.test(width)
      ? document.body.clientWidth * (width.match(/\d*/) / 100)
      : width;
  };

  getClientWidth() {
    return document.body.clientWidth;
  }

  renderOverlay(stepX) {
    if (this.isClosed()) {
      return;
    }

    return (
      <Overlay
        clientwidth={this.getClientWidth()}
        overlaycolor={this.props.overlayColor}
        x={stepX}
        drawerwidth={this.calculateWidth()}
        onTap={this.onOverlayTap}>
        <span />
      </Overlay>
    );
  }

  renderAdventureLinks() {
    const { adventures } = this.props.parentState;
    const adventureComponents = adventures.map((adventure) => {
      const adventureDetailsUrl = `/adventures/details/${adventure.title_id}`;
      let displayName = adventure[this.props.locale].shortname?.toUpperCase();

      return (
        <SubLinkContainer key={adventure.title_id}>
          <LinkText>/</LinkText>
          <StyledNavSubLink
            to={adventureDetailsUrl}
            onClick={() => {
              this.onPressUp();
              sendMenuEvent('hamburger_menu', 'adventures', adventure.title_id);
            }}>
            {displayName}
          </StyledNavSubLink>
          .
        </SubLinkContainer>
      );
    });

    return (
      <AdventureSubLinkContainer>
        {adventureComponents}
      </AdventureSubLinkContainer>
    );
  }

  handleOnLocationDialog = () => {
    this.props.parentState.handleShowLocationDialog(true);
  };

  renderLocationContainer() {
    const { getLabel } = this.props;
    if (
      this.props.parentState.selectedSite &&
      this.props.parentState.selectedSite.site_legalname
    ) {
      return (
        <LocationContainer locationSelected>
          <LocationContainerTitle locationSelected>
            {this.props.parentState.selectedSite.site_legalname.toUpperCase()}
          </LocationContainerTitle>
          <LocationButtonContainer>
            <WhiteOutlineButton
              onClick={this.handleOnLocationDialog}
              width={'100%'}>
              {getLabel('navbar_change_location')}
            </WhiteOutlineButton>
          </LocationButtonContainer>
        </LocationContainer>
      );
    }

    return (
      <LocationContainer>
        <LocationContainerTitle>
          {getLabel('navbar_no_location_title')}
        </LocationContainerTitle>
        <LocationContainerDesc>
          {getLabel('navbar_no_location_desc')}
        </LocationContainerDesc>
        <LocationButtonContainer>
          <BlueOutlineButton
            onClick={this.handleOnLocationDialog}
            width={'100%'}>
            {getLabel('navbar_set_location')}
          </BlueOutlineButton>
        </LocationButtonContainer>
      </LocationContainer>
    );
  }

  render() {
    let signedInLinks = null;
    const { getLabel } = this.props;

    if (this.props.parentState.isSignedIn) {
      signedInLinks = (
        <AdventureSubLinkContainer>
          <SubLinkContainer>
            <LinkText>/</LinkText>
            <StyledNavSubLink
              to="/account/details"
              onClick={() => {
                sendMenuEvent('hamburger_menu', 'account-details');
                this.onPressUp();
              }}>
              <span>{getLabel('navbar_menu_account_details')}</span>
            </StyledNavSubLink>
          </SubLinkContainer>
          <SubLinkContainer>
            <LinkText>/</LinkText>
            <StyledNavSubDiv
              onClick={() => {
                sendMenuEvent('hamburger_menu', 'sign-out');
                this.handleUserSignOut();
                this.onPressUp();
              }}>
              <span>{getLabel('navbar_menu_sign_out')}</span>
            </StyledNavSubDiv>
          </SubLinkContainer>
        </AdventureSubLinkContainer>
      );
    }

    const toasterOffset = this.state.toasterNotif
      ? this.state.toasterNotif.offsetHeight
      : 0;

    return (
      <Motion
        style={{
          myProp: spring(
            Math.min(this.state.x || 0, this.calculateWidth()),
            this.props.config
          )
        }}>
        {(interpolated) => {
          const { transform } = styles(interpolated.myProp, this.props);

          return (
            <Hammer
              onPressUp={this.onPressUp}
              onPan={this.onPan}
              direction={Hammer.DIRECTION_HORIZONTAL}>
              <div style={transform}>
                <Drawer width={this.calculateWidth()}>
                  {/* Insert Drawer items here */}
                  <DrawerNavBar toasterOffset={toasterOffset}>
                    {/* Spacer */}
                  </DrawerNavBar>

                  {this.renderLocationContainer()}

                  <StyledNavLink
                    to="/"
                    exact
                    onClick={() => {
                      this.onPressUp();
                      sendMenuEvent('hamburger_menu', 'home');
                    }}>
                    <span>{getLabel('navbar_menu_home')}</span>
                  </StyledNavLink>
                  <StyledNavLink
                    to="/adventures"
                    onClick={() => {
                      this.onPressUp();
                      sendMenuEvent('hamburger_menu', 'adventures');
                    }}>
                    <span>{getLabel('navbar_menu_adventures')}</span>
                  </StyledNavLink>
                  {this.renderAdventureLinks()}
                  <StyledNavLink
                    to="/find-us"
                    onClick={() => {
                      this.onPressUp();
                      sendMenuEvent('hamburger_menu', 'find-us');
                    }}>
                    <span>{getLabel('navbar_menu_find_us')}</span>
                  </StyledNavLink>
                  <StyledNavLink
                    to="/plan-ahead"
                    onClick={() => {
                      this.onPressUp();
                      sendMenuEvent('hamburger_menu', 'plan-ahead');
                    }}>
                    <span>{getLabel('navbar_menu_plan_ahead')}</span>
                  </StyledNavLink>
                  <StyledNavLink
                    to="/account"
                    onClick={() => {
                      this.onPressUp();
                      sendMenuEvent('hamburger_menu', 'account');
                    }}>
                    <span>{getLabel('navbar_menu_my_account')}</span>
                  </StyledNavLink>
                  {signedInLinks}
                  <DrawerNavBar>{/* Spacer */}</DrawerNavBar>

                  {this.renderOverlay(interpolated.myProp)}
                </Drawer>
              </div>
            </Hammer>
          );
        }}
      </Motion>
    );
  }
}

const LeftNavDrawer = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <LeftNavDrawerWithContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withAppContext(LeftNavDrawer);
