import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Large, Phone } from '../components/responsive/Breakpoints';
import imgFacebook from '../assets/images/img-facebook.svg';
import imgInstagram from '../assets/images/img-instagram.svg';
import imgTwitter from '../assets/images/img-twitter.svg';
import imgTiktok from '../assets/images/img-tiktok.svg';
import imgShare from '../assets/images/share/share.png';
import FooterCarousel from './FooterCarousel';
import { AuthContext } from './context/AuthenticatorContext';
import { withAppContext } from './context/AppContext';
import { sendMenuEvent, sendSocialMediaIconEvent } from '../util/analytics';

const MainFooter = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.charcoal};
  color: ${(props) => props.theme.white_80};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 0.8rem;
  padding: ${(props) => (props.mobile ? '24px 0px' : '16px 0px')};
`;

const ColumnHeader = styled.h5`
  font-size: 0.8rem;
  font-weight: 800;
  color: #fff;
  margin: 0;
`;

const ColumnEntry = styled(Link)`
  line-height: 1.62;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.white_80};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  padding-inline-end: 14px;
  width: 27.4px;
  height: 27.4px;
  cursor: pointer;
`;

const Copyright = styled.div`
  margin: auto;
  padding: ${(props) => (props.mobile ? '20px 22px 0' : '12px 22px 0')};
  color: rgba(255, 255, 255, 0.5);
  font-size: ${(props) => (props.mobile ? '0.5625rem' : '0.6875rem')};
  text-align: center;
  unicode-bidi: plaintext;
`;

const PrivacyTermsArea = styled.div`
  padding-top: 10px;
`;

const PrivacyTermsLink = styled(Link)`
  margin: auto 10px;
  cursor: pointer;
  color: ${(props) => props.theme.white_80};
  text-decoration: none;
  white-space: nowrap;
`;

const PrivacyTermsHref = styled.a`
  margin: auto 10px;
  cursor: pointer;
  color: ${(props) => props.theme.white_80};
  text-decoration: none;
  white-space: nowrap;
`;

const PrivacyTermsDivider = styled.span`
  margin: auto 10px;
`;

const ShareIcon = styled.img`
  width: 60px;
  height: 27px;
  object-fit: contain;
  filter: brightness(0.8);
  cursor: pointer;
`;

const DreamscapeDiv = (props) => (
  <Column {...props}>
    <ColumnHeader>{props.labelFn('footer_title_1')}</ColumnHeader>
    <Row>
      <Large>
        <Column style={{ marginInlineEnd: 16 }}>
          <ColumnEntry
            to={'/adventures'}
            onClick={() => sendMenuEvent('footer_menu', 'adventures')}>
            {props.labelFn('footer_link_adventures')}
          </ColumnEntry>
          <ColumnEntry
            to={'/plan-ahead'}
            onClick={() => sendMenuEvent('footer_menu', 'plan-ahead')}>
            {props.labelFn('footer_link_plan_ahead')}
          </ColumnEntry>
          <ColumnEntry
            to={'/account'}
            onClick={() => sendMenuEvent('footer_menu', 'account')}>
            {props.labelFn('footer_link_my_account')}
          </ColumnEntry>
          <ColumnEntry
            to={{
              pathname: '/faq',
              state: {
                resetPage: true,
                fromHealthFooter: false,
                stamp: Date.now()
              }
            }}
            onClick={() => sendMenuEvent('footer_menu', 'faq')}>
            {props.labelFn('footer_link_faq')}
          </ColumnEntry>
        </Column>
        <Column>
          <ColumnEntry
            to={'/find-us'}
            onClick={() => sendMenuEvent('footer_menu', 'find-us')}>
            {props.labelFn('footer_link_my_find_us')}
          </ColumnEntry>
          <ColumnEntry
            to={{
              pathname: '/faq',
              state: {
                fromHealthFooter: true,
                resetPage: false,
                stamp: Date.now()
              }
            }}
            onClick={() => sendMenuEvent('footer_menu', 'health-safety')}>
            {props.labelFn('footer_link_health_safety')}
          </ColumnEntry>
          <ColumnEntry
            to={{
              pathname: '/share',
              state: {
                fromShareFooter: true,
                resetPage: false,
                stamp: Date.now()
              }
            }}
            onClick={() => sendMenuEvent('footer_menu', 'share')}>
            <ShareIcon src={imgShare} alt="SHARE" />
          </ColumnEntry>
        </Column>
      </Large>
      <Phone>
        <Column style={{ marginInlineEnd: 16 }}>
          <ColumnEntry
            to={'/adventures'}
            onClick={() => sendMenuEvent('footer_menu', 'adventures')}>
            {' '}
            {props.labelFn('footer_link_adventures')}
          </ColumnEntry>
          <ColumnEntry
            to={'/plan-ahead'}
            onClick={() => sendMenuEvent('footer_menu', 'plan-ahead')}>
            {props.labelFn('footer_link_plan_ahead')}
          </ColumnEntry>
          <ColumnEntry
            to={'/account'}
            onClick={() => sendMenuEvent('footer_menu', 'account')}>
            {props.labelFn('footer_link_my_account')}
          </ColumnEntry>
          <ColumnEntry
            to={{
              pathname: '/faq',
              state: {
                resetPage: true,
                fromHealthFooter: false,
                stamp: Date.now()
              }
            }}
            onClick={() => sendMenuEvent('footer_menu', 'faq')}>
            {props.labelFn('footer_link_faq')}
          </ColumnEntry>
          <ColumnEntry
            to={'/partners'}
            onClick={() => sendMenuEvent('footer_menu', 'partners')}>
            {props.labelFn('footer_link_my_partners')}
          </ColumnEntry>
          <ColumnEntry
            to={'/find-us'}
            onClick={() => sendMenuEvent('footer_menu', 'find-us')}>
            {props.labelFn('footer_link_my_find_us')}
          </ColumnEntry>
          <ColumnEntry
            to={{
              pathname: '/faq',
              state: {
                fromHealthFooter: true,
                resetPage: false,
                stamp: Date.now()
              }
            }}
            onClick={() => sendMenuEvent('footer_menu', 'health-safety')}>
            {props.labelFn('footer_link_health_safety')}
          </ColumnEntry>
          <ColumnEntry
            to={{
              pathname: '/share',
              state: {
                fromShareFooter: true,
                resetPage: false,
                stamp: Date.now()
              }
            }}
            onClick={() => sendMenuEvent('footer_menu', 'share')}>
            <ShareIcon src={imgShare} alt="SHARE" />
          </ColumnEntry>
        </Column>
      </Phone>
    </Row>
  </Column>
);

const CopyrightDiv = (props) => (
  <Column {...props}>
    <Copyright
      mobile={props.mobile}
      dangerouslySetInnerHTML={{
        __html: props.labelFn('footer_copyright_text')
      }}></Copyright>
    <PrivacyTermsArea>
      <PrivacyTermsHref
        target="_blank"
        href={`https://privacy-center.majidalfuttaim.com/client/xD0k7coKl4VeyT6rIB4RE16nX4g8dvOC?lang=${props?.locale}#/`}
        onClick={() => sendMenuEvent('footer_menu', 'privacy-policy')}>
        {' '}
        {props.labelFn('footer_link_privacy_policy')}
      </PrivacyTermsHref>
      <PrivacyTermsDivider> / </PrivacyTermsDivider>
      <PrivacyTermsLink
        to={'/terms'}
        onClick={() => sendMenuEvent('footer_menu', 'terms-conditions')}>
        {' '}
        {props.labelFn('footer_link_terms_conditions')}
      </PrivacyTermsLink>
      {/* <PrivacyTermsDivider> / </PrivacyTermsDivider> */}
      {/* <PrivacyTermsLink
        to={{
          pathname: '/privacy',
          state: { willScroll: true, scrollId: 'section11' }
        }}
        onClick={() => sendMenuEvent('footer_menu', 'privacy-rights')}>
        {' '}
        {props.labelFn('footer_link_privacy_rights')}
      </PrivacyTermsLink> */}
      <PrivacyTermsDivider> / </PrivacyTermsDivider>
      <PrivacyTermsLink
        style={{ marginInlineEnd: 0 }}
        to={{
          pathname: '/privacy',
          state: { willScroll: true, scrollId: 'section9' }
        }}
        onClick={() => sendMenuEvent('footer_menu', 'ad-choices')}>
        {' '}
        {props.labelFn('footer_link_ad_choices')}
      </PrivacyTermsLink>
    </PrivacyTermsArea>
  </Column>
);

const StayInTouchDiv = (props) => (
  <Column {...props}>
    <ColumnHeader>{props.labelFn('footer_title_2')}</ColumnHeader>
    <ColumnEntry
      to={'/careers'}
      onClick={() => sendMenuEvent('footer_menu', 'careers')}>
      {props.labelFn('footer_link_careers')}
    </ColumnEntry>
    <ColumnEntry
      to={'/contact-us'}
      onClick={() => sendMenuEvent('footer_menu', 'contact-us')}>
      {props.labelFn('footer_link_contact_us')}
    </ColumnEntry>
    <ColumnEntry
      to={'/about-us'}
      onClick={() => sendMenuEvent('footer_menu', 'about-us')}>
      {props.labelFn('footer_link_about_us')}
    </ColumnEntry>
    <ColumnEntry
      to={'/press-kit'}
      onClick={() => sendMenuEvent('footer_menu', 'press-kit')}>
      {props.labelFn('footer_link_press')}
    </ColumnEntry>
  </Column>
);

const LocationsColumn = (props) => {
  if (!props.sites || !props.sites.length) {
    return null;
  }

  const locationEntries = props.sites.map((site) => {
    const siteAssets = props.cms.getSiteAssets(site.site_id);
    if (
      !site.site_id.includes(props.cms.locale) ||
      !Object.keys(siteAssets).length
    ) {
      return null;
    }

    const urlPath = siteAssets.copy.site_url_path;
    const city = site.city;
    return (
      <ColumnEntry
        to={urlPath}
        key={`footer_site_link_${site.site_id}`}
        onClick={() => sendMenuEvent('footer_menu', city)}>
        {city}
      </ColumnEntry>
    );
  });

  return (
    <Column {...props}>
      <ColumnHeader>{props.labelFn('footer_title_3')}</ColumnHeader>
      {locationEntries}
    </Column>
  );
};

const SocialMediaDiv = (props) => (
  <Column {...props}>
    <ColumnHeader>{props.labelFn('footer_title_4')}</ColumnHeader>
    <Row style={{ justifyContent: 'start', paddingTop: 6 }}>
      <a
        target={'_blank'}
        href={'https://www.instagram.com/dreamscapeksa'}
        onClick={() => sendSocialMediaIconEvent('instagram')}>
        <Image src={imgInstagram} alt="Instagram" />
      </a>
      <a
        target={'_blank'}
        href={'https://www.facebook.com/Dreamscapeksa'}
        onClick={() => sendSocialMediaIconEvent('facebook')}>
        <Image src={imgFacebook} alt="Facebook" />
      </a>
      <a
        target={'_blank'}
        href={'https://www.tiktok.com/@dreamscapeksa'}
        onClick={() => sendSocialMediaIconEvent('tiktok')}>
        <Image src={imgTiktok} alt="Tiktok" />
      </a>
      <a
        target={'_blank'}
        href={'https://twitter.com/dreamscapeksa'}
        onClick={() => sendSocialMediaIconEvent('twitter')}>
        <Image src={imgTwitter} alt="Twitter" />
      </a>
    </Row>
  </Column>
);

class FooterWithContext extends Component {
  state = {
    isDrawerOpen: false
  };

  handleNavigation = (path, opts) => {
    return this.props.history.push(path, opts);
  };

  render() {
    const { sites, cms } = this.props.parentState;
    const { getLabel } = this.props;
    return (
      <React.Fragment>
        <FooterCarousel
          key={'one'}
          labelFn={getLabel}
          adventures={this.props.parentState.adventures}
        />

        <Large>
          <MainFooter>
            <Row>
              <DreamscapeDiv
                labelFn={getLabel}
                navigate={this.handleNavigation}
              />
              <StayInTouchDiv
                labelFn={getLabel}
                style={{ marginInlineStart: 36 }}
                navigate={this.handleNavigation}
              />
              <LocationsColumn
                labelFn={getLabel}
                sites={sites}
                cms={cms}
                style={{ marginInlineStart: 36 }}
                navigate={this.handleNavigation}
              />
              <SocialMediaDiv
                labelFn={getLabel}
                style={{ marginInlineStart: 36 }}
              />
            </Row>
            <Row>
              <CopyrightDiv
                labelFn={getLabel}
                navigate={this.handleNavigation}
                locale={this.props.locale}
              />
            </Row>
          </MainFooter>
        </Large>

        <Phone>
          <MainFooter mobile={true}>
            <Row style={{ padding: '0 22px' }}>
              <Column style={{ marginInlineEnd: 24 }}>
                <DreamscapeDiv
                  labelFn={getLabel}
                  navigate={this.handleNavigation}
                />
                <LocationsColumn
                  labelFn={getLabel}
                  sites={sites}
                  cms={cms}
                  style={{ paddingTop: 22 }}
                  navigate={this.handleNavigation}
                />
              </Column>
              <Column>
                <StayInTouchDiv
                  labelFn={getLabel}
                  navigate={this.handleNavigation}
                />
                <SocialMediaDiv labelFn={getLabel} style={{ paddingTop: 22 }} />
              </Column>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <CopyrightDiv
                labelFn={getLabel}
                mobile
                navigate={this.handleNavigation}
                locale={this.props.locale}
              />
            </div>
          </MainFooter>
        </Phone>
      </React.Fragment>
    );
  }
}

const Footer = (props) => (
  <AuthContext.Consumer>
    {(parentState) => {
      return <FooterWithContext {...props} parentState={parentState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(Footer));
