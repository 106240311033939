import React from 'react';
import styled from 'styled-components';
import { RedButton } from '../buttons';
import ShareButtonIcon from '../../assets/images/share/share-button.png';

const Image = styled.img`
width: 6em
display: block;
object-fit: contain;
margin-inline: auto;

`;

function Button(props) {
  return (
    <RedButton {...props}>
      <Image src={ShareButtonIcon} />
    </RedButton>
  );
}

export default Button;
