import Moment from 'moment-timezone';

import { CURRENCY_SYMBOL } from './constants';

export function calculatePriceAndFees(ticketData) {
  const ticketPriceInfo = {
    totalPrice: 0,
    generalAdmissionPrice: 0,
    adaAdmissionPrice: 0,
    ticketDeductions: {},
    totalPaid: 0,
    ticketFees: []
  };

  if (!ticketData) {
    return ticketPriceInfo
  }

  const curSymbol = ticketData.currency
    ? CURRENCY_SYMBOL[ticketData.currency] || '$'
    : '$';

  // If the `ticketData` is an object from "holding tickets", then it will contain the simple `total` key with the
  // amount value we need to assign as the `totalPrice`.
  if (ticketData.total) {
    ticketPriceInfo.totalPrice = ticketData.total;
  }

  // If the ticketData is from a purchase or status call, it will not contain the `total` key but instead will contain
  // a `balance` key that contains an object with the amount of the total.
  if (ticketData.balance && Object.keys(ticketData.balance).length) {
    ticketPriceInfo.totalPrice = ticketData.balance.amount;
  }

  // Reduce the fees array down to the Convenience Fee object
  ticketPriceInfo.ticketFees = ticketData.fees.map((item) => {
    // Conciously using type correction here to more easily check if `item.actual_amount` exists or not. If the value
    // is 0, we still want to display this value over the fallback variable `item.amount`.
    const amount =
      item.actual_amount != null ? item.actual_amount : item.amount;
    const amountLabel = `${curSymbol}${amount.toFixed(2)}`;
    return {
      name: item.name.toUpperCase(),
      amount,
      amountLabel
    };
  });

  // Parse out the pricing for ticketing options
  if (ticketData.pricing && Object.keys(ticketData.pricing).length) {
    ticketPriceInfo.generalAdmissionPrice =
      ticketData.pricing['general-admission'];
    ticketPriceInfo.adaAdmissionPrice = ticketData.pricing.ada;
  }

  // Calculate total amounts applied to transaction from "codes" (gift card, promo, etc).
  if (ticketData.codes && Object.keys(ticketData.codes).length) {
    const ticketDeductions = ticketData.codes.reduce((acc, item) => {
      return {
        ...acc,
        [item.type]: acc[item.type]
          ? Math.abs(acc[item.type] + Math.abs(item.amount))
          : Math.abs(item.amount)
      };
    }, {});
    // TODO: We might end up plucking out specific types so we are assigning to ticketPriceInfo to it's own variable for now.
    ticketPriceInfo.ticketDeductions = ticketDeductions;
  }

  // Get the total amount paid by card (excluding gift cards, etc).
  if (ticketData.transactions && Object.keys(ticketData.transactions).length) {
    const creditTransactionAmount = ticketData.transactions.reduce(
      (acc, item) => {
        if (item.method === 'cc') {
          return acc + Math.abs(item.amount);
        }

        return acc;
      },
      0
    );

    ticketPriceInfo.totalPaid = creditTransactionAmount;
  }

  return ticketPriceInfo;
}

export function checkIsTicketingAvailableForSiteAdventure(adventure, site) {
  const now = Moment() // .tz(site.tz);
  const { ticketing_active_time: ticketingTime, end_date: endTime } =
    adventure.licensed_site;

  /*const start = Moment.tz(ticketingTime, site.tz);
  const end = Moment.tz(endTime || undefined, site.tz).endOf('day');*/
  const start = Moment(ticketingTime);
  const end = Moment(endTime || undefined).endOf('day');

  // If endTime is "null", pass undefined in Moment() so it creates a timestamp of now, sames as `Moment()`
  return now.isBetween(start, end, null, '[]');
}

export function getAdventureStatus(adventure, site) {
  if (!adventure || !Object.keys(site).length) {
    return null;
  }

  if (!adventure.licensed_site) {
    return 'notAvailable';
  }

  const now = Moment() // .tz(site.tz);
  /*const ticketingStart = Moment.tz(
    adventure.licensed_site.ticketing_active_time,
    site.tz
  );*/
  const ticketingStart = Moment(
    adventure.licensed_site.ticketing_active_time
  );

  if (now.isBefore(ticketingStart)) {
    return 'comingSoon';
  }

  if (adventure.licensed_site.end_date) {
    /*const endTime = Moment.tz(adventure.licensed_site.end_date, site.tz).endOf(
      'day'
    );
    const sunsetTime = Moment.tz(adventure.licensed_site.sunset_date, site.tz);*/
    const endTime = Moment(adventure.licensed_site.end_date).endOf(
      'day'
    );
    const sunsetTime = Moment(adventure.licensed_site.sunset_date);
    if (now.isAfter(endTime)) {
      return 'expired';
    }
    if (now.isAfter(sunsetTime)) {
      return 'endingSoon';
    }
  }

  return null;
}

export function getCybersourceProfileId(hostname) {
  const cybsHostToProfileId = {
    'dreamscapeimmersive.com': process.env.REACT_APP_CYBS_PROFILE_ID,
    'dreamscapemena.com': process.env.REACT_APP_CYBS_DUBAI_PROFILE_ID
  };

  return cybsHostToProfileId[hostname] || process.env.REACT_APP_CYBS_PROFILE_ID;
}

export function getCybersourceAccessKeyId(hostname) {
  const cybsHostToAccessKey = {
    'dreamscapeimmersive.com': process.env.REACT_APP_CYBS_ACCESS_KEY,
    'dreamscapemena.com': process.env.REACT_APP_CYBS_DUBAI_ACCESS_KEY
  };

  return cybsHostToAccessKey[hostname] || process.env.REACT_APP_CYBS_ACCESS_KEY;
}

export function getCybersourceCredentials(hostname) {
  const domainToCredsMap = {
    'dreamscapeimmersive.com': {
      profile_id: process.env.REACT_APP_CYBS_PROFILE_ID,
      access_key: process.env.REACT_APP_CYBS_ACCESS_KEY
    },
    'dreamscapemena.com': {
      profile_id: process.env.REACT_APP_CYBS_DUBAI_PROFILE_ID,
      access_key: process.env.REACT_APP_CYBS_DUBAI_ACCESS_KEY
    }
  };

  return (
    domainToCredsMap[hostname] || {
      profile_id: process.env.REACT_APP_CYBS_PROFILE_ID,
      access_key: process.env.REACT_APP_CYBS_ACCESS_KEY
    }
  );
}
