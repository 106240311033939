import styled from 'styled-components';

import BaseButton from './BaseButton';

const RedOutlineButton = styled(BaseButton)`
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.ds_red};
  color: ${(props) => props.theme.ds_red};
  transition: ${(props) => props.theme.transition};
  box-shadow: ${(props) =>
    props.shadow ? `0 10px 15px 0 rgb(0 0 0 / 30%)` : 'none'};

  &:focus {
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
`;

export default RedOutlineButton;
