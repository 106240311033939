import styled from 'styled-components';

import BaseButton from './BaseButton';

const WhiteOutlineButton = styled(BaseButton)`
  background-color: ${(props) =>
    props.background ? props.background : 'transparent'};
  border: 1px solid white;
  color: ${(props) => (props.color ? props.color : 'white')};
  transition: ${(props) => props.theme.transition};

  &:focus {
    background-color: ${(props) => props.theme.white_70};
  }

  &:hover {
    border: 3px solid white;
  }

  &:active {
    background-color: ${(props) => props.theme.ds_bright_blue};
    border: none;
  }
`;

export default WhiteOutlineButton;
