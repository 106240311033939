import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import { WhiteOutlineButton } from '../components/buttons';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';
import Breadcrumbs from '../components/breadcrumbs';
import RedButton from '../components/buttons/RedButton';
import banner_desktop from '../assets/images/share/share-banner.jpg';
import banner_mobile from '../assets/images/share/share-banner-mobile.jpg';
import share_find_us_en from '../assets/images/share/share_find_us_en.jpg';
import share_find_us_ar from '../assets/images/share/share_find_us_ar.jpg';

const Container = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
`;

const RedirectButton = styled(RedButton)`
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase;
  height: 40px;
  border-radius: 3px;
  letter-spacing: 1px;
  color: white;
  background-color: #b30d4d;
  text-transform: inherit;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
  margin-bottom: 2em;
  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`;

const ShareFindUsHeroContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.isMobile ? '600px' : '1000px')};
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: center;
  flex: 1;
  width: 100%;
  color: white;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column-reverse' : 'row')};
  max-width: 1214px;
  flex-wrap: wrap;
  align-items: ${(props) => (props.isMobile ? 'center' : undefined)};
  width: 100%;
  padding: ${(props) => (props.isMobile ? undefined : '50px 22px')};
  box-sizing: border-box;
`;

const ShareFindUsDetails = styled.div`
  max-width: 1214px;
  width: 100%;
  padding: ${(props) => (props.isMobile ? '0 22px' : '10px 22px 0 22px')};
  box-sizing: border-box;
`;

const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: ${(props) => (props.isMobile ? '375px' : undefined)};
  border-top: ${(props) => (props.isMobile ? undefined : '1px solid white')};
  margin-inline-end: ${(props) => (props.isMobile ? undefined : '133px')};
  padding: ${(props) => (props.isMobile ? '0 22px' : undefined)};
`;

const InfoContainer = styled.div`
  text-align: justify;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: ${(props) => (props.isMobile ? undefined : '1px solid white')};
  margin-inline-end: ${(props) => (props.isMobile ? undefined : '133px')};
  padding: ${(props) => (props.isMobile ? '0 22px' : undefined)};
  h4 {
    margin: 0.5em 0;
  }
  strong {
    font-weight: 600;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? '100%' : '550px')};
  height: 100%;
`;

const StoreHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.isMobile ? '24px 0 30px 0' : '30px 0 47px 0')};
`;

const StoreHoursLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${(props) => (props.isMobile ? '0.875rem' : '1rem')};
  max-width: 360px;
`;

const TitleText = styled.div`
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.isMobile ? '1.375rem' : '2.25rem')};
  color: ${(props) => props.theme.white_90};
  line-height: 1.19;
  margin: ${(props) => (props.isMobile ? '36px 0 0 0' : '16px 0 0 0')};
`;

const RegularText = styled.div`
  font-size: ${(props) => (props.isMobile ? '0.875rem' : '1rem')};
  color: ${(props) => props.theme.white_70};
  margin: ${(props) => (props.isMobile ? '0 0 10px 0' : '0 0 4px 0')};
  flex: ${(props) => (props.flex ? 1 : undefined)};
  text-align: ${(props) => (props.rightAlign ? 'right' : undefined)};
  [dir='rtl'] [href]& {
    direction: ltr;
    text-align: right;
  }
`;
const BannerTitle = styled.h3`
  margin-bottom: 0.5em;
  font-weight: normal;
`;

const BreadcrumbWrapper = styled.div`
  margin: 0 auto;
  max-width: 1110px;
  width: 100%;
  z-index: 99999;
  position: relative;
  padding: ${(props) => (props.isMobile ? '16px' : 0)};
  top: -37%;
  li:last-child {
    text-transform: uppercase;
  }
`;

const en = {
  banner_title:
    'As a SHARE Member, you can earn points for all your adventures with Dreamscape.',
  banner_desc: `SHARE is our new reward programme that’s created to provide you joy and fun to the fullest, with the freedom to
earn points at our brands and use them for unlimited entertainment.`,
  content_markdown: `
  <h4>PLAY MORE, WATCH MORE, EARN MORE!</h4>

    <p><strong>Escaping reality at Dreamscape?</strong> Watching the latest
    blockbuster in <strong>VOX Cinemas?</strong> Challenging your friend
    at a bowling game in <strong>Yalla! Bowling?</strong> Taking the kids
    for a day of fun at <strong>Magic Planet?</strong>
    </p>
    <p>
    The SHARE rewards programme works in all these
    locations and more!
    </p>
    <p>
    As a SHARE Programme member, you can earn cash
    back in points for all your purchases at Dreamscape.
    </p>
    <p>
    You can then redeem your SHARE Programme points
    for purchases at all participating Majid Al Futtaim
    brands in Saudi Arabia. Arabia.
    </p>
`,
  share_how_does_work_cta: 'HOW DOES IT WORK?',
  share_faq_cta: 'SHARE FAQs',
  share_terms_cta: 'SHARE Terms & Conditions'
};
const ar = {
  banner_title:
    'بصفتك عضوًا في شير ، يمكنك كسب نقاط لجميع مغامراتك في دريمسكيب',
  banner_desc: `شير هو برنامج المكافآت الجديد الذي تم إنشاؤه ليوفر لك المتعة والمتعة على أكمل وجه ، مع حرية كسب النقاط في علاماتنا التجارية واستخدامها
  للترفيه غير المحدود.`,
  content_markdown: `
<p><strong> سواءً كنت تتابع أحدث الأفلام الرائجة في ڤوكس سينما؟</strong> أو تتحدى أصحابك في لعبة البولينج في<strong> يلا! بولينج؟ </strong> 
    يوم من المرح مع الأطفال في <strong> ماجيك بلانيت؟</strong> أو تهرب من
    الواقع في  <strong> دريمسكيب؟</strong>  برنامج شير للمكافآت يعمل في جميع هذه الأماكن وأكثر ! 
    </p>

 <p>
   بصفتك عضوًا في شير، يمكنك كسب النقاط لجميع مشترياتك 
في ڤوكس سينما، دريمسكيب، يلا بولينج، ماجيك بلانيت، 
كيتشن 35 وتوب بن!
    </p>

    <p>
   بمجرد حصولك على نقاط كافية، يمكنك استخدامها لدفع ثمن 
    مشترياتك في جميع العلامات التجارية  المشاركة في المملكة 
   العربية السعودية
    </p>
`,
  share_how_does_work_cta: 'طريقة استخدام برنامج شير؟',
  share_faq_cta: 'الأسئلة الشائعة',
  share_terms_cta: 'شروط و أحكام شير'
};

class ShareFindUsWithContext extends Component {
  componentDidMount() {}

  renderHeroContainer(isMobile) {
    let background = (
      <BackgroundImage src={isMobile ? banner_mobile : banner_desktop} />
    );

    return (
      <ShareFindUsHeroContainer isMobile={isMobile}>
        <BreadcrumbWrapper isMobile={isMobile}>
          <Breadcrumbs />
        </BreadcrumbWrapper>
        {background}
      </ShareFindUsHeroContainer>
    );
  }

  handleNavigation = (path) => {
    return this.props.history.push(path);
  };

  renderStoreHours(hours, isMobile) {
    const { getLabel } = this.props;
    const storeHourLines = hours.map((storeHourData, index) => {
      return (
        <StoreHoursLineContainer
          key={`store_hour_${index}`}
          isMobile={isMobile}>
          <RegularText isMobile={isMobile} flex>
            {storeHourData.day}
          </RegularText>
          <RegularText isMobile={isMobile} flex rightAlign>
            {storeHourData.time}
          </RegularText>
        </StoreHoursLineContainer>
      );
    });
    return (
      <StoreHoursContainer isMobile={isMobile}>
        <StoreHoursLineContainer
          isMobile={isMobile}
          style={{ fontWeight: 'bold', marginBottom: isMobile ? 10 : 0 }}>
          {getLabel('findus_store_hours_title')}
        </StoreHoursLineContainer>
        {storeHourLines}
      </StoreHoursContainer>
    );
  }

  renderShareFindUsContainer = (isMobile) => {
    let data = this.props.locale === 'ar' ? ar : en;
    let shareImage =
      this.props.locale === 'ar' ? share_find_us_ar : share_find_us_en;
    let { share_how_does_work_cta, share_faq_cta, share_terms_cta } = data;
    return (
      <SubContainer isMobile={isMobile}>
        <ShareFindUsDetails isMobile={isMobile}>
          {data?.banner_title && (
            <BannerTitle>{data?.banner_title}</BannerTitle>
          )}
          {data?.banner_desc && <RegularText>{data?.banner_desc}</RegularText>}
        </ShareFindUsDetails>

        <Wrapper isMobile={isMobile}>
          <InfoContainer
            isMobile={isMobile}
            dangerouslySetInnerHTML={{
              __html: data?.content_markdown
            }}></InfoContainer>
          <ImageContainer isMobile={isMobile}>
            <img width={'100%'} src={shareImage} alt="" />
          </ImageContainer>
        </Wrapper>

        <ShareFindUsDetails isMobile={isMobile}>
          <Flex>
            <RedirectButton
              onClick={() =>
                this.props.history.push('/share/how-does-share-work')
              }>
              {share_how_does_work_cta}
            </RedirectButton>
            <RedirectButton
              onClick={() => this.props.history.push('/share/faq')}>
              {share_faq_cta}
            </RedirectButton>
            <RedirectButton
              onClick={() => this.props.history.push('/share/terms')}>
              {share_terms_cta}
            </RedirectButton>
          </Flex>
        </ShareFindUsDetails>
      </SubContainer>
    );
  };

  renderLocations(isMobile) {
    const { sites, cms } = this.props.parentState;
    const { getLabel } = this.props;

    const locations = sites.map((site) => {
      const siteAssets = cms.getSiteAssets(site.site_id);
      if (
        /** do not list other locale sites */
        this.props.locale !== site.locale ||
        !Object.keys(siteAssets).length
      ) {
        return null;
      }

      const copy = siteAssets.copy;
      const imageAssets = siteAssets.images.findus;

      const locationName = copy.display_name;
      let locationAddress = `${site.street_address}<br/> ${site.city}${
        this.props.locale === 'ar' ? ' ،' : ','
      } ${site.state}`;
      if (site.postal_code) {
        locationAddress += ` ${site.postal_code}`;
      }
      const locationPhone = copy.phone;
      const locationDirections = copy.directions;
      const images = isMobile ? imageAssets.mobile.x3 : imageAssets.desktop.x2;
      const navPath = copy.site_url_path;
      const storeHours = copy.hours;

      return (
        <Wrapper isMobile={isMobile} key={site.site_id}>
          <LocationsContainer isMobile={isMobile}>
            <TitleText isMobile={isMobile}>{locationName}</TitleText>
            <RegularText
              isMobile={isMobile}
              dangerouslySetInnerHTML={{
                __html: locationAddress
              }}></RegularText>
            <RegularText
              as="a"
              style={{ textDecoration: 'none' }}
              href={`tel:${locationPhone}`}
              isMobile={isMobile}>
              {locationPhone}
            </RegularText>
            <RegularText isMobile={isMobile}>{locationDirections}</RegularText>
            <div style={{ marginTop: isMobile ? 16 : 30, maxWidth: 316 }}>
              <WhiteOutlineButton
                width={'100%'}
                onClick={() => this.handleNavigation(navPath)}>
                {getLabel('findus_adventure_button_text')}
              </WhiteOutlineButton>
            </div>
            {this.renderStoreHours(storeHours, isMobile)}
          </LocationsContainer>
          <ImageContainer isMobile={isMobile}>
            <img width={'100%'} src={images} alt="Find Us BG" />
          </ImageContainer>
        </Wrapper>
      );
    });

    return locations;
  }

  render() {
    return (
      <Container id="share-find-us-page">
        <Desktop>
          {this.renderHeroContainer()}
          {this.renderShareFindUsContainer()}
          {/* <SubContainer>{this.renderLocations()}</SubContainer> */}
        </Desktop>

        <Mobile>
          {this.renderHeroContainer(true)}
          {this.renderShareFindUsContainer(true)}
          {/* <SubContainer>{this.renderLocations(true)}</SubContainer> */}
        </Mobile>
      </Container>
    );
  }
}

const ShareLandingPage = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <ShareFindUsWithContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(ShareLandingPage));
