import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Large, Phone } from '../components/responsive/Breakpoints';
import styled from 'styled-components';
import { withAppContext } from './context/AppContext';

const Container = styled.div`
  width: 100%;
  background-image: radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6);
`;

const Body = styled.div`
  height: auto;
  padding: ${(props) => (props.mobile ? '43px 22px 0' : '71px 50px')};
  width: 100%;
  max-width: 1270px;
  box-sizing: border-box;
  margin: 0 auto;
  color: ${(props) => props.theme.charcoal_90};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headline = styled.h3`
  margin: 0;
  font-size: 1rem;
  text-align: center;
  max-width: ${(props) => (props.mobile ? '300px' : undefined)};
`;

const HeroText = styled.p`
  font-size: ${(props) => (props.mobile ? '1.25rem' : '2.25rem')};
  font-family: ${(props) => props.theme.serif};
  line-height: ${(props) => (props.mobile ? '1.25' : '1.25')};
  max-width: ${(props) => (props.mobile ? undefined : '918px')};
  width: 100%;
  text-align: center;
  margin-bottom: ${(props) => (props.mobile ? '50px' : '100px')};
`;

const SuggestionRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const CopyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:first-child {
    border-top: 1px solid ${(props) => props.theme.charcoal_25};
  }
  ${(props) => (props.mobile ? 'flex-direction: column;' : 'flex-wrap: wrap;')}

  ${(props) => {
    // Target each SuggestionBox child element
    if (props.mobile) {
      return `
        & > * {
          border-top: 1px solid ${props.theme.charcoal_25};
          max-width: none !important;
        }
      `;
    }

    // Non-mobile layout places SuggestionBoxes in a list of rows
    return `
      & > :first-child > * {
        border-top: 1px solid ${props.theme.charcoal_25};
      }

      & > ${SuggestionRow} > :first-child {
        margin-inline-end: 80px;
      }
    `;
  }}
`;

const CopyHeader = styled.h3`
  margin-top: 24px;
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  margin-bottom: 0;
`;

const CopyBody = styled.p`
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  color: ${(props) => props.theme.charcoal_60};
  margin-top: 0;
`;

const SuggestionBox = styled.div`
  flex: 1;
  max-width: 503px;
  margin-bottom: ${(props) => (props.mobile ? '42px' : '0')};
`;

const NUM_COLUMNS = 2;

class NearbyRecommendations extends Component {
  renderContent(isMobile) {
    const { locationData, site, getLabel } = this.props;

    // Don't render the content until we have location details from the CMS
    if (
      !locationData ||
      !locationData.copy ||
      !locationData.copy.nearby ||
      !locationData.copy.nearby.suggestions ||
      !locationData.copy.nearby.herotext
    ) {
      return null;
    }

    const siteInfo = {
      headline: `${getLabel(
        'plan_ahead_nearby_rec_title_1'
      )} ${site.site_legalname.toUpperCase()}`,
      heroText: locationData.copy.nearby.herotext,
      suggestions: locationData.copy.nearby.suggestions
    };

    // Mobile is a flat list so use the suggestions list as is
    if (isMobile) {
      const suggestions = siteInfo.suggestions.map((suggestion) => (
        <SuggestionBox mobile key={suggestion.title}>
          <CopyHeader mobile>{suggestion.title}</CopyHeader>
          <CopyBody mobile>{suggestion.text}</CopyBody>
        </SuggestionBox>
      ));

      return (
        <Body mobile>
          <Headline mobile>{siteInfo.headline}</Headline>
          <HeroText mobile>{siteInfo.heroText}</HeroText>
          <CopyContainer mobile>{suggestions}</CopyContainer>
        </Body>
      );
    }

    // Desktop layout is more of a Grid pattern so iterate over suggestions to
    const suggestionsArray = siteInfo.suggestions.reduce(
      (acc, suggestion, idx) => {
        const suggestionComp = (
          <SuggestionBox key={suggestion.title}>
            <CopyHeader>{suggestion.title}</CopyHeader>
            <CopyBody>{suggestion.text}</CopyBody>
          </SuggestionBox>
        );
        // Find index based on the premise of displaying "NUM_COLUMNS" per row
        const index = Math.floor(idx / NUM_COLUMNS);
        acc[index] = acc[index]
          ? [...acc[index], suggestionComp]
          : [suggestionComp];
        return acc;
      },
      []
    );

    const suggestionRows = suggestionsArray.map((suggestions, index) => {
      return (
        <SuggestionRow key={`suggestion_${index}`}>{suggestions}</SuggestionRow>
      );
    });

    return (
      <Body>
        <Headline>{siteInfo.headline}</Headline>
        <HeroText>{siteInfo.heroText}</HeroText>
        <CopyContainer>{suggestionRows}</CopyContainer>
      </Body>
    );
  }

  render() {
    return (
      <Container>
        <Large>{this.renderContent()}</Large>

        <Phone>{this.renderContent(true)}</Phone>
      </Container>
    );
  }
}

export default withRouter(withAppContext(NearbyRecommendations));
