export default {
  content_max_width: '1170px',
  ds_bright_blue: '#00b8f1',
  ds_bright_blue_50: 'rgba(0, 184, 241, 0.5)',
  ds_dusk_blue: '#2e3191',
  ds_blue: '#25265c',
  charcoal: '#1b1b1b',
  charcoal_90: 'rgba(27, 27, 27, 0.9)',
  charcoal_70: 'rgba(27, 27, 27, 0.7)',
  charcoal_60: 'rgba(27, 27, 27, 0.6)',
  charcoal_25: 'rgba(27, 27, 27, 0.25)',
  charcoal_15: 'rgba(27, 27, 27, 0.15)',
  black_30: 'rgba(0, 0, 0, 0.3)',
  black_75: 'rgba(0, 0, 0, 0.75)',
  white_90: 'rgba(255, 255, 255, 0.9)',
  white_80: 'rgba(255, 255, 255, 0.8)',
  white_70: 'rgba(255, 255, 255, 0.7)',
  white_25: 'rgba(255, 255, 255, 0.25)',
  ds_red: '#B30D4D',
  serif: 'BellMTStd, Almarai, Georgia, serif',
  sans_serif: 'Brother-1816, Almarai, Arial, sans-serif',
  transition: 'all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1)',
  error_color: '#CC0000'
};
