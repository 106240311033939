import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Desktop, Mobile } from '../responsive/Breakpoints';
import DownArrowIcon from '../svgs/DownArrowIcon';
import theme from '../../util/theme';
import { scroller } from 'react-scroll/modules';

import { AuthContext } from '../../components/context/AuthenticatorContext';
import { withAppContext } from '../context/AppContext';

import banner_desktop from '../../assets/images/share/share-banner.jpg';
import banner_mobile from '../../assets/images/share/share-banner-mobile.jpg';
import { TOASTER_NOTIFICATION_HEIGHT } from '../../util/constants';

const Container = styled.div`
  height: auto;
  width: 100%;
`;

const ShareWorkflowHeroContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.isMobile ? '600px' : '1000px')};
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
`;

const Anchor = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.white_25};
  border-radius: 3px;
  color: white;
  pointer-events: all;
  cursor: pointer;
  margin-bottom: ${(props) => (props.isMobile ? '10vh' : '10vh')};
  padding: 10px;
  position: absolute;
  bottom: 0;
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

class ShareWorkflowHeroSectionWithContext extends Component {
  state = {
    aboutUsDetails: null,
    navbar: null
  };

  componentDidMount() {
    const aboutUsDetails = document.getElementById('share-workflow-details');
    const navbar = document.getElementById('navbar');

    this.setState({ aboutUsDetails, navbar });
  }

  navToAdventures = () => {
    this.props.history.push('/adventures');
  };

  renderHeroContainer(isMobile) {
    const toasterOffset = this.props.parentState.hasStoredSite
      ? 0
      : TOASTER_NOTIFICATION_HEIGHT;

    let background = (
      <BackgroundImage src={isMobile ? banner_mobile : banner_desktop} />
    );
    return (
      <ShareWorkflowHeroContainer
        isMobile={isMobile}
        toasterOffset={toasterOffset}>
        {background}

        <Anchor
          isMobile={isMobile}
          onClick={() => this.handleScrollToDetailsSection(toasterOffset)}>
          <DownArrowIcon color={theme.white_80} />
        </Anchor>
      </ShareWorkflowHeroContainer>
    );
  }

  scrollTo = (val, offset) => {
    scroller.scrollTo('share-workflow-details', {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset
    });
  };

  handleScrollToDetailsSection = (toasterOffset) => {
    const { aboutUsDetails, navbar } = this.state;
    if (!navbar || !aboutUsDetails) {
      return;
    }
    this.scrollTo(
      aboutUsDetails.offsetHeight,
      -navbar.offsetHeight - toasterOffset
    );
  };

  render() {
    return (
      <Container name="share-how-does-it-work-hero-container">
        <Desktop>{this.renderHeroContainer()}</Desktop>

        <Mobile>{this.renderHeroContainer(true)}</Mobile>
      </Container>
    );
  }
}

const ShareWorkflowHeroSection = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <ShareWorkflowHeroSectionWithContext
        {...props}
        parentState={parentState}
      />
    )}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(ShareWorkflowHeroSection));
