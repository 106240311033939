import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { withAppContext } from '../context/AppContext';
import InputField from './BasicInputField';
import { WhiteOutlineButton } from '../buttons';
import { sendCustomEvent } from '../../util/analytics';

const FormWrapper = styled.form`
  text-align: start;
  display: flex;
  flex-wrap: ${(props) => (props.mobile ? 'wrap' : 'no-wrap')};
  & div:first-child {
    width: ${(props) => (props.mobile ? '' : '80%')};
  }
  & div,
  button {
    width: ${(props) => (props.mobile ? '100%' : 'auto')};
    margin: 0.5em;
    margin-bottom: 0;
  }
  & button {
    margin-top: 1.95em;
    min-width: 125px;
    height: 47px;
  }
`;

const Message = styled.p`
  color: ${(props) => (props.dark ? 'white' : props.theme.charcoal)};
  margin: 1em 0.2em;
`;

const Error = styled.p`
  color: ${(props) => props.theme.error_color};
  margin: 1em 0.2em;
`;

function NewsletterForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const handleHooksFormSubmit = async (values) => {
    try {
      setSubmitting(true);

      await fetch(`${process.env.REACT_APP_API_URL}/api/newsletter-requests`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            ...values,
            pagePathname: window.location.pathname
          }
        })
      });

      sendCustomEvent({ event_category: 'get_updates' });

      setSubmitted(true);
    } catch (err) {
      setError(true);
    } finally {
      setSubmitting(false);
    }
  };

  return !submitted ? (
    <React.Fragment>
      <FormWrapper
        mobile={props.mobile}
        onSubmit={handleSubmit(handleHooksFormSubmit)}>
        <InputField
          {...register('email', {
            required: {
              value: true,
              message: props.getLabel('newsletter_form_error_required_email')
            },
            pattern: {
              value: /.+\@.+\..+/,
              message: props.getLabel('newsletter_form_error_invalid_email')
            }
          })}
          type="email"
          placeholder={props.getLabel('newsletter_form_placeholder_email')}
          label={props.getLabel('newsletter_form_label_email')}
          error={errors.email?.message}
          dark={props.dark}
        />
        <InputField
          {...register('zipCode')}
          label={props.getLabel('newsletter_form_label_zipcode')}
          placeholder={props.getLabel('newsletter_form_placeholder_zipcode')}
          dark={props.dark}
        />
        <WhiteOutlineButton type="submit" disabled={submitting}>
          {props.getLabel('newsletter_form_submit_text')}
        </WhiteOutlineButton>
      </FormWrapper>
      {error && <Error>{props.getLabel('newsletter_form_server_error')}</Error>}
    </React.Fragment>
  ) : (
    <Message dark={props.dark}>
      {props.getLabel('newsletter_form_thankyou_text')}
    </Message>
  );
}

export default withAppContext(NewsletterForm);
