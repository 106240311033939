import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { parsePhoneNumber } from 'libphonenumber-js';
import Moment from 'moment';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import BlueOutlineButton from '../components/buttons/BlueOutlineButton';
import rightArrow from '../assets/images/btn-arrow.svg';
import exclamationCircle from '../assets/images/img-checkmark-gradient-l-new.svg';
import { withAuth0 } from '@auth0/auth0-react';
import { DIApi } from '../util/api';
import { withAppContext } from '../components/context/AppContext';

const Container = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding: ${(props) => (props.mobile ? '20px 0 56px 0' : '56px 0 86px 0')};
`;

const Headline = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '0.875' : '1')}rem;
  font-weight: bold;
  color: ${(props) => props.theme.charcoal_90};
`;

const SubHeadline = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '0.875' : '1')}rem;
  color: ${(props) => props.theme.charcoal_60};
  padding-bottom: ${(props) => (props.mobile ? '14px' : '10px')};
`;

const HalfInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${(props) => (props.mobile ? '10px 0 7px 0' : '10px 0 12px 0')};
`;

const StyledHalfInput = styled.div`
  width: ${(props) => (props.mobile ? 'unset' : '362px')};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1.125rem;
  color: ${(props) => (props.dark ? 'white' : props.theme.charcoal)};
  border-radius: 3px;
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
`;

const InputLabel = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 0.625rem;
  color: ${(props) => props.theme.charcoal_90};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const WaiverList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: ${(props) => (props.mobile ? '22px' : 'unset')};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  justify-content: space-between;
  padding-top: 25px;
`;

const Button = styled(BlueOutlineButton)`
  color: #000;
  width: ${(props) => {
    if (props.mobile) {
      return '100%';
    } else if (props.small) {
      return '119px';
    } else {
      return '256px';
    }
  }};
  margin: ${(props) => (props.mobile ? '0 0 16px 0;' : 'auto 0')};
`;

const NextArrow = styled.div`
  background: url(${rightArrow});
  background-size: contain;
  background-repeat: no-repeat;
  height: 22px;
  width: 11px;
  margin: ${(props) => (props.isMobile ? '16px 0 0 0' : 'auto 0')};
  flex-shrink: 0;
`;

const ExclamationCircle = styled.div`
  background: url(${exclamationCircle});
  height: 40px;
  width: 40px;
  margin: ${(props) => (props.isMobile ? '4px 12px 0 0' : 'auto 29px auto 0')};
`;

const WaiverStatus = styled.div`
  font-family: ${(props) => props.theme.serif};
  font-size: 1.375rem;
  line-height: 1.29;
  margin-right: 24px;
  color: ${(props) => props.theme.charcoal_90};
`;

const LastUpdated = styled.span`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) => props.theme.charcoal_90};
`;

const LastUpdatedDate = styled.span`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: 1rem;
  color: ${(props) => props.theme.charcoal_60};
`;

const FieldContainer = styled.div`
  ${(props) => (props.mobile ? 'width 100%;' : '')}
`;

class AccountDetails extends Component {
  state = {
    userWaivers: []
  };

  headerText = null;

  componentDidMount() {
    this.handleRedirect();
    this.setDataOrRedirect();
  }

  componentDidUpdate() {
    this.handleRedirect();
    this.setDataOrRedirect();
    const { user } = this.props.auth0;
    const title = this.props.getLabel('account_details_title');
    let username =
      user['https://profile/firstName'].toUpperCase() +
      ' ' +
      user['https://profile/lastName'].toUpperCase();

    if (this.headerText !== title) {
      this.headerText = title;
      this.props.updateHeaderText({
        title: username,
        subTitle: user.email
      });
      this.headerText = title;
    }
  }

  async setDataOrRedirect() {
    if (!this.props.auth0.isAuthenticated || this.props.auth0.isLoading) {
      return;
    }
    if (!this.props.auth0.isAuthenticated) {
      this.navToSignIn();
    }
  }

  handleRedirect() {
    if (!this.props.auth0.isLoading && !this.props.auth0.isAuthenticated) {
      this.navToSignIn();
    }
  }

  handleUserSignOut = async () => {
    const { logout } = this.props.auth0;
    // const siteId = this.props.authState.routerBase

    if (this.props.location && this.props.location.state) {
      localStorage.setItem(
        'routeState',
        JSON.stringify(this.props.location.state)
      );
    }
    localStorage.setItem('logoutReturnUrl', `/`);

    logout({ returnTo: window.location.origin });
  };

  handleDeleteAccount = async () => {
    const { getAccessTokenSilently, isAuthenticated, user } = this.props.auth0;
    const token = isAuthenticated
      ? await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid offline_access email profile'
        })
      : null;
    await DIApi.userDeleteRequest(user.email, token);
    alert('Delete account request raised');
    return;
  };

  navToSignIn = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/account/signin', locationState);
  };

  navToDetailsEdit = () => {
    this.props.history.push('/account/details/edit');
  };

  accountFields = (mobile) => {
    const { user } = this.props.auth0;
    const given_name = user['https://profile/firstName'];
    const family_name = user['https://profile/lastName'];
    const birthdate = user['https://profile/dateOfBirth'];
    const phone_number = user['https://profile/mobile'];

    const formattedPhoneNumber = phone_number
      ? parsePhoneNumber(phone_number).formatNational()
      : '';

    const { getLabel } = this.props;

    const inputRows = [
      <FieldContainer mobile={mobile}>
        <InputLabel>{getLabel('account_details_first_name')}</InputLabel>
        <StyledHalfInput mobile={mobile}>{given_name}</StyledHalfInput>
      </FieldContainer>,
      <FieldContainer mobile={mobile}>
        <InputLabel>{getLabel('account_details_last_name')}</InputLabel>
        <StyledHalfInput mobile={mobile}>{family_name}</StyledHalfInput>
      </FieldContainer>,
      <FieldContainer mobile={mobile}>
        <InputLabel>{getLabel('account_details_dob')}</InputLabel>
        <StyledHalfInput mobile={mobile}>
          {birthdate ? Moment(birthdate).format('MM/DD/YYYY') : 'mm/dd/yyyy'}
        </StyledHalfInput>
      </FieldContainer>,
      <FieldContainer mobile={mobile}>
        <InputLabel>{getLabel('account_details_phone')}</InputLabel>
        <StyledHalfInput mobile={mobile}>
          {formattedPhoneNumber || '(###) ### - ####'}
        </StyledHalfInput>
      </FieldContainer>
    ];

    let inputRowGroups = inputRows;

    if (!mobile) {
      inputRowGroups = [
        <React.Fragment>
          {inputRows[0]}
          {inputRows[1]}
        </React.Fragment>,
        <React.Fragment>
          {inputRows[2]}
          {inputRows[3]}
        </React.Fragment>
      ];
    }

    return inputRowGroups.map((inputRow, i) => {
      return (
        <HalfInputContainer key={i} mobile={mobile} labelWidth={392}>
          {inputRow}
        </HalfInputContainer>
      );
    });
  };

  renderWaiversSection(isMobile) {
    if (!this.state.userWaivers.length) {
      return;
    }

    const waiverItems = this.state.userWaivers.map((waiver, index) => {
      const displayExpiryDate = Moment(waiver.waiver_expiry).format('MM/DD/YY');
      const waiverLocationName = this.props.authState.sites.reduce(
        (acc, site) => {
          if (site.site_id === waiver.waiver_site_id) {
            acc = site.site_legalname;
          }
          return acc;
        },
        ''
      );
      return (
        <Row key={`waiver_${index}`}>
          <ExclamationCircle isMobile={isMobile} />
          <div style={{ flex: 1 }}>
            <WaiverStatus>{waiverLocationName} - Waiver Signed</WaiverStatus>
            <div>
              <LastUpdated>EXPIRES:</LastUpdated>
              &nbsp;<LastUpdatedDate>{displayExpiryDate}</LastUpdatedDate>
            </div>
          </div>
          <NextArrow isMobile={isMobile} />
        </Row>
      );
    });

    return (
      <div style={{ marginTop: 30 }}>
        <Headline mobile={isMobile}>WAIVER DETAILS</Headline>
        <WaiverList mobile={isMobile}>{waiverItems}</WaiverList>
      </div>
    );
  }

  renderDetailsPage(mobile) {
    const { getLabel } = this.props;
    const { user } = this.props.auth0;
    if (!user) {
      return (
        <div className="spinner" style={{ paddingBottom: '90px' }}>
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      );
    }

    return (
      <Container mobile={mobile}>
        <Headline mobile={mobile}>{getLabel('account_details_email')}</Headline>
        <SubHeadline mobile={mobile}>{user.email}</SubHeadline>

        {this.accountFields(mobile)}

        <ButtonContainer mobile={mobile}>
         
          <Button mobile={mobile} onClick={this.handleDeleteAccount}>
            {getLabel('account_details_delete_account')}
          </Button>
          <Button mobile={mobile} onClick={this.handleUserSignOut}>
            {getLabel('account_details_sign_out')}
          </Button>
        </ButtonContainer>

        {/*this.renderWaiversSection(mobile)*/}

        {/*<Headline mobile={mobile}>EMAIL SUBSCRIPTIONS</Headline>*/}

        {/*<DisclaimerText>*/}
        {/*I understand and agree that by checking the email customization option(s) above and clicking "SAVE", I*/}
        {/*agree to receive occasional email communications from Dreamscape and consent to Dreamscape’s&nbsp;*/}
        {/*<PrivacyTermsLink onClick={() => this.props.history.push('/privacy')}>Privacy Policy</PrivacyTermsLink>*/}
        {/*&nbsp;and&nbsp;*/}
        {/*<PrivacyTermsLink onClick={() => this.props.history.push('/terms')}>Terms and Policies</PrivacyTermsLink>.*/}
        {/*</DisclaimerText>*/}
      </Container>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Desktop>{this.renderDetailsPage(false)}</Desktop>
        <Mobile>{this.renderDetailsPage(true)}</Mobile>
      </React.Fragment>
    );
  }
}

const Details = (props) => (
  <AuthContext.Consumer>
    {(authState) => {
      return <AccountDetails {...props} authState={authState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAuth0(withAppContext(Details)));
