import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withAppContext } from '../context/AppContext';
import ShareLayout from './Layout';
import { RedOutlineButton, RedButton } from '../buttons';

const Content = styled.div`
  background: #fff;
  color: #000;
  font-size: 0.9em;
  border: 2px solid #b30d4d;
  padding: 0.75em 0.5em;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;

  & button {
    font-family: MarselisPro, Almarai, sans-serif;
    font-size: 1em;
    letter-spacing: inherit;
    margin: 0.25em 0;
    height: 32px;
    & :nth-child(1) {
      margin-bottom: 0.5em;
    }
  }
  & p {
    font-size: 0.9em;
  }
  [dir='rtl'] & {
    direction: rtl;
  }
`;

const Title = styled.p`
  background-color: #b30d4d;
  color: #fff;
  font-size: 0.9em;
  padding: 0 0.5em 0.25em 0.5em;
  margin: 0;
`;

const Paragraph = styled.p`
  margin: 0 0 1em;
`;

class ShareJoin extends Component {
  render() {
    return (
      <ShareLayout>
        <Title> {this.props.getLabel('share_join_title')}</Title>
        <Content>
          <Paragraph>{this.props.getLabel('share_join_description')}</Paragraph>
          <RedButton onClick={this.props.handleJoinShare} invert width="100%">
            {this.props.getLabel('share_join')}
          </RedButton>
          <br />
          <RedOutlineButton
            onClick={() => this.props.history.push('/share')}
            invert
            width="100%">
            {this.props.getLabel('share_findout')}
          </RedOutlineButton>
        </Content>
      </ShareLayout>
    );
  }
}

export default withRouter(withAppContext(ShareJoin));
