import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import TagManager from 'react-gtm-module';
import { defineCustomElements } from '@mafpay/weblib';

import 'react-dynamic-swiper/lib/styles.css';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@mafpay/weblib/dist/mafpay/mafpay.css';
import { Auth0Provider } from '@auth0/auth0-react';

import registerServiceWorker from './registerServiceWorker';
import { sendSignInEvent } from './util/analytics';

import Root from './Root';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_CONTAINER_ID
});

const onRedirectCallback = (appState) => {
  sendSignInEvent();
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const storage = process.env.REACT_APP_AUTH0_STORAGE;

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    audience={audience}
    clientId={clientId}
    redirectUri={window.origin}
    responseType="token"
    scope="openid offline_access profile"
    cacheLocation={storage}
    useCookiesForTransactions={true}
    useRefreshTokens={true}
    onRedirectCallback={onRedirectCallback}>
    <CookiesProvider>
      <Root />
    </CookiesProvider>
  </Auth0Provider>,
  document.getElementById('root')
);
registerServiceWorker();
defineCustomElements();
