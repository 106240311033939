import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import 'moment/locale/ar-ly';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import md5 from 'md5';
import { DIApi } from './util/api';
import { checkIsTicketingAvailableForSiteAdventure } from './util/common';
import LocationSelectionDialog from './components/dialogs/LocationSelectionDialog';
import theme from './util/theme';
import { AuthContext } from './components/context/AuthenticatorContext';
import { AppContext } from './components/context/AppContext';
import ScrollToTop from './components/ScrollToTop';
import Account from './Account';
import CheckoutAMC from './CheckoutAMC';
import Checkout from './Checkout';
import PrintReceipt from './pages/PrintReceipt';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import App from './App';
import CMS from './util/cms';
import CacheBuster from './CacheBuster';
import DubaiLandingPage from './pages/DubaiLandingPage';
import { withAuth0 } from '@auth0/auth0-react';
import HistoryTracker from './components/HistoryTracker';
import { sendSetLocation } from './util/analytics';
import { AVAILABLE_SITES } from './util/constants';

const COOKIE_NAME = 'di_site_id';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

class Root extends Component {
  state = {
    isSignedIn: false,
    currentUser: null,
    isLoadingUser: false,
    setReleaseTickets: null,
    adventures: [],
    allTitles: [],
    sites: [],
    site: {},
    selectedSite: {},
    hasStoredSite: false,
    showLocationDialog: false,
    dismissedAnnouncementDialog: false,
    announcementLastViewed: null,
    cms: new CMS({}),
    locale: 'en',
    direction: 'ltr',
    routerBase: '/',
    setLocale: () => {},
    getLabel: () => {},
    isRtl: () => {},
    handleAuthStateChange: () => {},
    releaseTickets: () => {},
    //handleShowLocationDialog: () => {},
    handleDismissAnnouncementDialog: () => {},
    handleSaveLocation: () => {}
  };

  handleAuthStateChange = async (state) => {
    switch (state) {
      case 'signedIn':
        const user = this.props.auth0.user;
        this.setState({
          isSignedIn: true,
          currentUser: user,
          isLoadingUser: false
        });
        break;
      case 'signedOut':
        this.setState({
          isSignedIn: false,
          currentUser: null,
          isLoadingUser: false
        });
        break;
      default:
        this.setState({
          isLoadingUser: false
        });
        break;
    }
  };

  handleShowLocationDialog = (show) => {
    this.setState({ showLocationDialog: show });
  };

  handleDismissAnnouncementDialog = (show) => {
    const cookieConfig = {
      path: '/',
      expires: moment().add(1, 'days').toDate()
    };
    const cookieName = `${this.state.selectedSite.site_id}_dialog`;
    const now = moment().format();

    this.props.cookies.set(cookieName, now, cookieConfig);
    this.setState({
      dismissedAnnouncementDialog: show,
      announcementLastViewed: now
    });
  };

  handleSaveLocation = async (site) => {
    const analytics = site.analytics;
    this.props.cookies.set(COOKIE_NAME, site.site_id, {
      path: '/',
      expires: moment().add(3, 'months').toDate()
    });
    this.setState({ hasStoredSite: true });
    this.setRouterBase(site.site_id);
    sendSetLocation(analytics.name, analytics.state, analytics.city);
    await this.setSelectedSiteAndAdventures(site);
  };

  async setSelectedSiteAndAdventures(site) {
    const adventures = await this.reconcileAdventures(
      site,
      this.state.allTitles
    );
    const announcementLastViewed = this.getLastAnnouncementDate(site.site_id);
    this.setState({
      site,
      selectedSite: site,
      adventures,
      dismissedAnnouncementDialog: false,
      announcementLastViewed
    });
  }

  setReleaseTickets = (releaseTicketFunc) => {
    this.setState({ releaseTickets: releaseTicketFunc });
  };

  findLocationBySiteId(siteId) {
    let foundSite = {};
    if (!siteId) {
      return foundSite;
    }

    this.state.sites.some((site) => {
      if (site.site_id.toUpperCase() === siteId.toUpperCase()) {
        foundSite = site;
        return true;
      }
      return false;
    });

    return foundSite;
  }

  getLastAnnouncementDate(siteId = 'default') {
    return this.props.cookies.get(`${siteId}_dialog`);
  }

  getSiteIdAvailableInBrowser() {
    return this.getSiteFromURL() || this.props.cookies.get(COOKIE_NAME);
  }

  getLocallyAvailableSite() {
    const localSiteId = this.getSiteIdAvailableInBrowser();
    if (localSiteId) {
      this.setState({ hasStoredSite: true });
    }
    return this.findLocationBySiteId(localSiteId);
  }

  async getCMSAssets() {
    const [titleAssets, homeAssets, siteAssets, labels] = await Promise.all([
      DIApi.getTitleAssets(),
      DIApi.getHomeAssets(),
      DIApi.getSiteAssets(),
      DIApi.getLabels()
    ]);

    const cms = new CMS({
      titleAssets,
      homeAssets,
      siteAssets,
      labels,
      locale: this.state.locale
    });
    this.setState({ cms });
  }

  setLocale(locale = 'en') {
    const direction = locale === 'ar' ? 'rtl' : 'ltr';
    this.state.cms.updateLocale(locale);

    /** set locale for moment & moment-timezone */
    moment.locale(locale === 'ar' ? 'ar-ly' : locale);
    momentTZ.defineLocale(locale, moment.localeData()._config);
    momentTZ.locale(locale);

    this.setState({
      locale,
      direction
    });
    document.documentElement.setAttribute('lang', locale);
    document.documentElement.setAttribute('dir', direction);
  }

  getLabel(labelName) {
    const locale = this.state.locale,
      { labels } = this.state.cms.assetsMap;
    return labels && labels[labelName]
      ? labels[labelName][locale] || labelName
      : labelName;
  }

  isRtl() {
    return this.state.direction === 'rtl';
  }

  setRouterBase(site_id) {
    if (site_id) {
      this.setState({
        routerBase: `/${site_id}`
      });
      this.setLocale(site_id.substring(0, 2));
    }
  }

  getSiteFromURL() {
    const siteId = `${window.location.pathname.split('/')[1]}`;
    if (AVAILABLE_SITES.indexOf(siteId) >= 0) {
      return siteId;
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.sites && this.state.sites.length && !prevState.sites.length) {
      // The first time sites are populated in state, check if site id exists for auto-setting location
      // If there is only one site, bypass multiple location logic
      if (this.state.sites.length === 1) {
        const siteId = this.state.sites[0].site_id;
        const location = this.findLocationBySiteId(siteId);
        return this.handleSaveLocation(location);
      } else {
        // Otherwise check url, then our cookie for the local site
        const localSelectedSite = this.getLocallyAvailableSite();
        if (localSelectedSite?.site_id) {
          this.handleSaveLocation(localSelectedSite);
        } else {
          this.setRouterBase(localSelectedSite.site_id);
          this.setSelectedSiteAndAdventures(localSelectedSite);
        }
      }
    }

    // store user details in global object for analytics purpose
    if (prevProps.auth0.user !== this.props.auth0.user) {
      if (this.props.auth0.user) {
        window._user = Object.entries(this.props.auth0.user).reduce(
          (user, [key, value]) => {
            user[key.split('/').pop()] = value;
            return user;
          },
          {}
        );
        window._user.hashed_email = md5(window._user.email);
        window._user.hashed_phone = md5(window._user.mobile);
        this.handleAuthStateChange('signedIn');
      } else {
        window._user = null;
      }
    }
  }

  componentWillMount() {
    const localSiteId = this.getSiteIdAvailableInBrowser();
    if (localSiteId) {
      this.setRouterBase(localSiteId);
      this.setState({
        hasStoredSite: true
      });
    }
  }

  async componentDidMount() {
    this.getCMSAssets();
    const state = {
      setLocale: this.setLocale.bind(this),
      getLabel: this.getLabel.bind(this),
      isRtl: this.isRtl.bind(this),
      handleAuthStateChange: this.handleAuthStateChange,
      setReleaseTickets: this.setReleaseTickets,
      handleShowLocationDialog: this.handleShowLocationDialog,
      handleDismissAnnouncementDialog: this.handleDismissAnnouncementDialog,
      handleSaveLocation: this.handleSaveLocation
    };

    this.setState(state);

    const [sites, allTitles] = await Promise.all([
      DIApi.getSites(),
      DIApi.getTitles()
    ]);

    this.setState({
      sites,
      allTitles
    });
  }

  checkAuth() {
    const { user, isAuthenticated, isLoading } = this.props.auth0;

    const state = {};
    state.isSignedIn = isAuthenticated;
    state.isLoadingUser = isLoading;

    if (user) {
      state.currentUser = user;
    }

    this.setState(state);
  }

  async reconcileAdventures(site, titles) {
    let adventures = [];

    if (!titles || !titles.length) {
      return adventures;
    }

    if (!site || !site.site_id) {
      adventures = titles;
    } else {
      const siteAdventures = await DIApi.getExperiencesBySite(site.site_id);
      const siteAdventuresMap = siteAdventures.reduce((acc, adventure) => {
        return {
          ...acc,
          [adventure.title_id]: adventure
        };
      }, {});

      adventures = titles.map((title) => {
        const adventure = { ...title };
        const siteAdventure = siteAdventuresMap[adventure.title_id];
        if (siteAdventure) {
          adventure.licensed_site = siteAdventure.licensed_site;

          adventure.isAvailableAtCurrentLocation =
            checkIsTicketingAvailableForSiteAdventure(adventure, site);
          return adventure;
        }

        adventure.isAvailableAtCurrentLocation = false;
        return adventure;
      });
    }

    return adventures;
  }

  renderLocationDialog = () => {
    return (
      <LocationSelectionDialog
        show={this.state.showLocationDialog}
        sites={this.state.sites}
        selectedSite={this.state.selectedSite}
        onDismiss={() => this.handleShowLocationDialog(false)}
        onSave={this.handleSaveLocation}
      />
    );
  };

  renderRedirect = () => {
    // check if sites data is loaded
    if (this.state.sites.length > 0) {
      /* eslint-disable-next-line no-useless-escape */
      const regexPathname = new RegExp(`\/(${AVAILABLE_SITES.join('|')})`);
      const pathname = window.location.pathname.replace(regexPathname, '');
      // don't redirect checkout urls
      return !window.location.search.includes('paymentRedirect') &&
        pathname.includes('/checkout') ? null : (
        <Redirect to={{ pathname, state: null }} />
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) {
            return null;
          }

          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <ThemeProvider theme={theme}>
              <AuthContext.Provider value={this.state}>
                <AppContext.Provider value={this.state}>
                  <StyledDiv>
                    <BrowserRouter
                      key={`router-${this.state.routerBase}`}
                      basename={this.state.routerBase}
                      getUserConfirmation={(message, cb) => {
                        const allowTransition = window.confirm(message);
                        // Intercept the user's decision an if true, release any held tickets the user may have had.
                        if (allowTransition) {
                          this.state.releaseTickets();
                        }
                        cb(allowTransition);
                      }}>
                      <ScrollToTop>
                        <HistoryTracker>
                          {this.renderLocationDialog()}
                          {this.renderRedirect()}
                          <Switch>
                            {/* List routes here that should not render like the main site */}
                            {/* <Route exact path='/' component={Landing}/> */}
                            {/* We otherwise leave the rest to the main application */}
                            <Route
                              path="/purchase/:siteId/:orderId/print-receipt"
                              component={PrintReceipt}
                            />
                            <Route
                              sensitive
                              path="/:slug1*:slug2([A-Z]):slug3*/"
                              render={(props) => (
                                <Redirect
                                  to={props.location.pathname.toLowerCase()}
                                />
                              )}
                            />

                            <Route path="/account" component={Account} />
                            <Route
                              path="/checkout/amc"
                              component={CheckoutAMC}
                            />
                            <Route path="/checkout" component={Checkout} />
                            <Route
                              path="/DubaiComingSoon"
                              component={DubaiLandingPage}
                            />

                            <Route
                              path="/terms-kiosk"
                              render={() => <Terms disableLinks={true} />}
                            />
                            <Route
                              path="/privacy-kiosk"
                              render={() => <Privacy disableLinks={true} />}
                            />

                            <Route path="/" component={App} />
                          </Switch>
                        </HistoryTracker>
                      </ScrollToTop>
                    </BrowserRouter>
                  </StyledDiv>
                </AppContext.Provider>
              </AuthContext.Provider>
            </ThemeProvider>
          );
        }}
      </CacheBuster>
    );
  }
}

export default withCookies(withAuth0(Root));
