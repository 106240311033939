import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAuth0 } from '@auth0/auth0-react';
import { withAppContext } from '../components/context/AppContext';
import Join from '../components/share/Join';
import Details from '../components/share/Details';
import { DIApi } from '../util/api';
import { getUserSessionId, saveUserSessionId } from '../util/userSession';

const Container = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding-top: 1em;
  padding-bottom: 8em;
`;

let isLoading = false;
function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

class ShareDetails extends Component {
  state = {
    memberDetails: null,
    transactions: null,
    isLoading: false
  };

  headerText = null;
  componentDidMount() {
    this.handleRedirect();
    this.setDataOrRedirect();
  }

  componentDidUpdate() {
    this.handleRedirect();
    this.setDataOrRedirect();
    const { user } = this.props.auth0;

    if (!user) {
      return;
    }

    const title = this.props.getLabel('account_details_title');
    const username =
      user['https://profile/firstName'].toUpperCase() +
      ' ' +
      user['https://profile/lastName'].toUpperCase();

    if (this.headerText !== title) {
      this.headerText = title;
      this.props.updateHeaderText({
        title: username,
        subTitle: user.email
      });
      this.headerText = title;
    }
  }

  async setDataOrRedirect() {
    if (!this.props.auth0.isAuthenticated || this.props.auth0.isLoading) {
      return;
    }
    if (!this.props.auth0.isAuthenticated) {
      this.navToSignIn();
    }
  }

  handleRedirect() {
    if (!this.props.auth0.isLoading && !this.props.auth0.isAuthenticated) {
      this.navToSignIn();
    }
  }

  navToSignIn = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/account/signin', locationState);
  };

  getMemberDetails = async () => {
    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;

    if (!isAuthenticated || isLoading) {
      return;
    }

    const token = await getAccessTokenSilently({
      ignoreCache: true,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access profile'
    });
    isLoading = true;
    const userSessionId = getUserSessionId();
    const memberDetails = await DIApi.getMember(token, userSessionId);
    if (memberDetails.userSessionId) {
      saveUserSessionId(memberDetails.userSessionId);
    }
    this.setState({ memberDetails: memberDetails });
    isLoading = false;
  };

  handleJoinShare = async () => {
    const { getAccessTokenSilently, isAuthenticated } = this.props.auth0;
    if (!isAuthenticated) {
      return;
    }
    const token = await getAccessTokenSilently({
      ignoreCache: true,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid offline_access profile'
    });
    const res = await DIApi.memberJoinShare(token);
    if (res && res.memberId) {
      const userSessionId = getUserSessionId();
      const token = await getAccessTokenSilently({
        ignoreCache: true,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid offline_access profile'
      });
      const memberDetails = await DIApi.getMember(
        token,
        userSessionId,
        res.memberId
      );
      if (memberDetails.userSessionId) {
        saveUserSessionId(memberDetails.userSessionId);
      }
      this.setState({ memberDetails: memberDetails });
      const locationState = this.props.location.state;
      this.props.history.push('/account/share/details', {
        ...locationState,
        memberDetails: this.state.memberDetails
      });
    }
  };

  renderDetails() {
    if (!this.state.memberDetails) {
      return (
        <div className="spinner" style={{ paddingBottom: '90px' }}>
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      );
    }

    if (this.state.memberDetails.isMember) {
      return (
        <Container>
          <Details
            memberDetails={this.state.memberDetails}
            cta={true}
            points={true}
          />
        </Container>
      );
    } else {
      return (
        <Container>
          <Join handleJoinShare={this.handleJoinShare} />
        </Container>
      );
    }
  }
  renderSharePage() {
    const { user } = this.props.auth0;

    if (!user) {
      return (
        <div className="spinner" style={{ paddingBottom: '90px' }}>
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      );
    }

    if (this.state.memberDetails) {
      return this.renderDetails();
    }

    if (this.props.location.state && this.props.location.state.memberDetails) {
      this.setState({
        memberDetails: this.props.location.state.memberDetails
      });
      return this.renderDetails();
    } else {
      this.debouncedFunction();
      return this.renderDetails();
    }
  }

  render() {
    if (!this.debouncedFunction) {
      this.debouncedFunction = debounce(this.getMemberDetails, 1000);
    }
    return <React.Fragment>{this.renderSharePage()}</React.Fragment>;
  }
}

const SharePage = (props) => (
  <AuthContext.Consumer>
    {(authState) => {
      return <ShareDetails {...props} authState={authState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAuth0(withAppContext(SharePage)));
