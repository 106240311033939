import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { scroller } from 'react-scroll/modules';

import { BlueButton, WhiteButton } from './buttons';
import { Desktop, Mobile } from './responsive/Breakpoints';
import Overlay from './Overlay';
import playVideoBtn from '../assets/images/play-arrow.svg';
import { withAppContext } from './context/AppContext';
import Breadcrumbs from '../components/breadcrumbs';
import { TOASTER_NOTIFICATION_HEIGHT } from '../util/constants';

const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
  align-items: center;
  text-align: center;
`;

const GetTicketsButtonContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  margin-bottom: ${(props) => (props.mobile ? '77px' : '125px')};
`;

const WrappedButtonsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  width: ${(props) => (props.mobile ? '214px' : '100%')};
  align-items: center;
  justify-content: center;
`;

const GetTicketsButton = styled(BlueButton)`
  max-height: 47px;
  margin: ${(props) => (props.mobile ? '0 0 10px 0' : undefined)};
  width: ${(props) => (props.mobile ? '214px' : '175px')};
  height: 47px;
`;

const WatchTrailerButton = styled(WhiteButton)`
  max-height: 47px;
  margin-inline-start: ${(props) => (props.mobile ? 0 : '16px')};
  width: 214px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  height: calc(100vh - ${(props) => props.toasterOffset}px);
  position: relative;
  background: url(${(props) => props.background});
  background-size: cover;
  background-position-x: center;
  overflow: hidden;
`;

const OverlayVideo = styled.video`
  max-width: ${(props) => (props.mobile ? '100' : '55')}%;
  max-height: ${(props) => (props.mobile ? '100' : '55')}%;
  min-height: 25%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props) => (props.shouldDisplay ? 'block' : 'none')};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1110px;
  width: 100%;
  z-index: 99999;
  position: relative;
  padding: ${(props) => (props.mobile ? '16px' : 0)};
  top: ${(props) => (props.mobile ? '3em' : '7.5em')};
`;

/*const OverlayImage = styled.img`
  max-width: ${(props) => (props.mobile ? '100' : '55')}%;
  max-height: ${(props) => (props.mobile ? '100' : '55')}%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props) => (props.shouldDisplay ? 'block' : 'none')};
`;*/

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

const StatusBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  background-image: linear-gradient(to left, #1b68b8, #254da5);
  display: flex;
  align-items: center;
`;

const StatusMessage = styled.p`
  width: 100%;
  font-size: 0.9375rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
`;

class AdventureHeroBlock extends Component {
  state = {
    displayOverlayMedias: false,
    startingIndex: 0,
    reloading: false
  };

  videos = [];

  scrollPage = (target, val, offset) => {
    scroller.scrollTo(target, {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset
    });
  };

  handleScrollToAdvSection = () => {
    const toasterOffset = this.props.hasStoredSite
      ? 0
      : TOASTER_NOTIFICATION_HEIGHT;
    const targetSection = this.props.isTicketingAvailable
      ? 'timeSelector'
      : 'quoteBlock';
    this.scrollPage(
      targetSection,
      document.getElementById('home').offsetHeight,
      -document.getElementById('navbar').offsetHeight - toasterOffset
    );
  };

  renderOverlay(index) {
    this.setState({ displayOverlayMedias: true, startingIndex: index });
  }

  tearDown = () => {
    this.setState({ displayOverlayMedias: false, reloading: true }, () => {
      this.setState({ reloading: false });
    });
  };

  pushVideo(index, video) {
    if (!video) {
      return;
    }
    for (let i = 0; i < this.videos.length; i++) {
      if (this.videos[i].index === index) {
        return;
      }
    }
    this.videos.push({ index: index, video: video });
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  mediaItems = (mobile) => {
    if (this.state.reloading) {
      this.videos = [];
      return null;
    }
    const videoURL = this.props.contentData.hero.images.video;
    return (
      <ImageContainer>
        <OverlayVideo
          mobile={mobile}
          shouldDisplay={this.state.displayOverlayMedias}
          className={'video'}
          controls
          playsInline
          ref={(video) => this.pushVideo(0, video)}
          onClick={(e) => {
            this.stopEvent(e);
          }}>
          <source src={videoURL} />
        </OverlayVideo>
      </ImageContainer>
    );
  };

  renderHeroBlock(mobile) {
    const { getLabel } = this.props;
    const ticketButtonLabel = !this.props.isTicketingAvailable
      ? 'LEARN MORE'
      : getLabel('adventures_button_get_tickets');
    const logos = mobile
      ? this.props.contentData.hero.images.logo.mobile
      : this.props.contentData.hero.images.logo.desktop;
    return (
      <React.Fragment>
        <ContentContainer mobile={mobile}>
          <img
            src={logos.x1}
            srcSet={`${logos.x2} 2x, ${logos.x3} 3x`}
            alt={this.props.adventure.shortname}
          />
          {this.props.statusMessage && (
            <StatusBar>
              <StatusMessage>{this.props.statusMessage}</StatusMessage>
            </StatusBar>
          )}
        </ContentContainer>

        <GetTicketsButtonContainer mobile={mobile}>
          <WrappedButtonsContainer mobile={mobile}>
            <GetTicketsButton
              mobile={mobile}
              onClick={() => this.handleScrollToAdvSection()}>
              {ticketButtonLabel}
            </GetTicketsButton>
            <WatchTrailerButton
              mobile={mobile}
              onClick={() => this.renderOverlay()}>
              <img
                style={{ marginRight: 11 }}
                src={playVideoBtn}
                alt={getLabel('adventures_button_watch_trailer')}
              />
              {getLabel('adventures_button_watch_trailer')}
            </WatchTrailerButton>
          </WrappedButtonsContainer>
        </GetTicketsButtonContainer>

        <Overlay
          title_id={this.props.adventure.title_id}
          startingIndex={this.state.startingIndex}
          display={this.state.displayOverlayMedias}
          tearDown={this.tearDown}
          items={this.mediaItems(mobile)}
          videos={this.videos}
        />
      </React.Fragment>
    );
  }

  render() {
    const toasterOffset = this.props.hasStoredSite
      ? 0
      : TOASTER_NOTIFICATION_HEIGHT;
    const heroImages = this.props.contentData.hero.images;

    return (
      <React.Fragment>
        <Desktop>
          <Container id="home" toasterOffset={toasterOffset}>
            <Wrapper>
              <Breadcrumbs />
            </Wrapper>
            <BackgroundImage
              src={heroImages.background.desktop.x1}
              srcSet={`${heroImages.background.desktop.x2} 2x, ${heroImages.background.desktop.x3} 3x`}
              alt={'AZBG'}
            />
            {this.renderHeroBlock(false)}
          </Container>
        </Desktop>
        <Mobile>
          <Container id="home" toasterOffset={toasterOffset}>
            <Wrapper mobile>
              <Breadcrumbs />
            </Wrapper>
            <BackgroundImage
              src={heroImages.background.mobile.x1}
              srcSet={`${heroImages.background.mobile.x2} 2x, ${heroImages.background.mobile.x3} 3x`}
              alt={'AZBG_Mobile'}
            />
            {this.renderHeroBlock(true)}
          </Container>
        </Mobile>
      </React.Fragment>
    );
  }
}

export default withRouter(withAppContext(AdventureHeroBlock));
