import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withAppContext } from '../context/AppContext';
import moment from 'moment';

const TransactionsWrapper = styled.div`
  font-family: MarselisPro, Almarai, sans-serif;
  font-weight: 400;
  margin: 1em auto;
  padding: 1em 0;
  max-width: 26em;
`;
const Title = styled.h2`
  color: #000;
  margin: 0;
  margin-top: 0.5em;
  text-transform: uppercase;
`;
const List = styled.ul`
  color: #fff;
  font-size: 1em;
  padding: 0;
  list-item-type: none;
  position: relative;
  border-radius: none;
`;
const Item = styled.li`
  display: flex;
  flex-direction: column;
  padding: 0.25em 0;
  color: #000;
  & :nth-child(1) {
    margin-bottom: 0.75em;
  }
`;

const DateTime = styled.time`
  background: #b30d4d;
  color: #fff;
  padding: 0.5em 0.25em;
  @media (max-width: 500px) {
    margin: 0 -20px;
    padding-left: 20px;
  }
`;
const Label = styled.label`
  font-size: 1em;
  font-weight: 600;
`;

const Data = styled.data`
  color: ${(props) =>
    props.green ? '#3BBF2A' : props.red ? '#b30d4d' : '#000'}
  font-weight: 400;
`;

class Transactions extends Component {
  render() {
    const validTransactions =
      (this.props.transactions &&
        this.props.transactions.filter((t) => {
          if (!t.amount_spent && !t.points_redeemed && !t.points_earned) {
            return false;
          }
          return true;
        })) ||
      [];
    return (
      <TransactionsWrapper>
        <Title> {this.props.getLabel('share_transaction_history')}</Title>
        <List>
          {validTransactions.length ? (
            validTransactions.map(
              ({
                bit_date,
                country_code,
                location_name,
                amount_spent,
                points_earned,
                points_redeemed
              }) => {
                return (
                  <Item key={bit_date}>
                    <DateTime date-time={bit_date}>
                      {moment(bit_date).format('dddd, Do MMMM  YYYY')}
                    </DateTime>

                    {country_code && (
                      <Label>
                        {this.props.getLabel('share_country')}:{' '}
                        <Data>{country_code}</Data>
                      </Label>
                    )}

                    {location_name && (
                      <Label>
                        {this.props.getLabel('share_location')}
                        {': '}
                        <Data>{location_name}</Data>
                      </Label>
                    )}

                    {amount_spent && amount_spent > 0 ? (
                      <Label>
                        {this.props.getLabel('share_amount_spend')}
                        {': '}
                        <Data>
                          {amount_spent}{' '}
                          {this.props.getLabel('share_currency_sar')}
                        </Data>
                      </Label>
                    ) : null}

                    <Label>
                      {this.props.getLabel('share_points_earned')}
                      {': '}
                      {points_earned && Math.round(points_earned) > 0 ? (
                        <Data green>
                          {'+'}
                          {points_earned.toLocaleString()}
                        </Data>
                      ) : (
                        <Data green>{points_earned.toLocaleString()}</Data>
                      )}
                    </Label>

                    <Label>
                      {this.props.getLabel('share_points_redeemed')}
                      {': '}
                      {points_redeemed && Math.round(points_redeemed) > 0 ? (
                        <Data red>
                          {'-'} {points_redeemed.toLocaleString()}
                        </Data>
                      ) : (
                        <Data red>{points_redeemed.toLocaleString()}</Data>
                      )}
                    </Label>
                  </Item>
                );
              }
            )
          ) : (
            <Item>
              <Label> {this.props.getLabel('share_no_transactions')}</Label>
            </Item>
          )}
        </List>
      </TransactionsWrapper>
    );
  }
}

export default withRouter(withAppContext(Transactions));
