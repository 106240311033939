import React, { Component, lazy, Suspense } from 'react';
import { withAppContext } from '../../components/context/AppContext';

const ShareTermsEn = lazy(() => import('./en'));
const ShareTermsAr = lazy(() => import('./ar'));

class ShareTerms extends Component {
  render() {
    return this.props.locale === 'ar' ? (
      <Suspense fallback={null}>
        <ShareTermsAr />
      </Suspense>
    ) : (
      <Suspense fallback={null}>
        <ShareTermsEn />
      </Suspense>
    );
  }
}

export default withAppContext(ShareTerms);
