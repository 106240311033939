import React, { Component, forwardRef } from 'react';
import styled from 'styled-components';

const BasicInputFieldContainer = styled.div`
  display: flex;
  margin-bottom: 0.6em;
  width: ${(props) => {
    const { width } = props;
    if (!width) {
      return '280px';
    }

    if (typeof width === 'number') {
      return `${width}px`;
    }

    if (typeof width === 'string') {
      return width;
    }

    // If none of the above; default
    return '280px';
  }};
  padding: ${(props) => {
    const { padding } = props;
    if (!padding) {
      return;
    }

    if (typeof padding === 'string') {
      return padding;
    }
  }};
  margin: ${(props) => {
    const { margin } = props;
    if (!margin) {
      return;
    }

    if (typeof margin === 'string') {
      return margin;
    }
  }};
  flex: ${(props) => props.flex};
  box-sizing: border-box;
`;

const BasicInputField = styled.input`
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
  height: 47px;
  border: solid 1px
    ${(props) =>
      props.dark ? props.theme.ds_bright_blue_50 : props.theme.ds_bright_blue};
  padding: 14px;
  font-size: max(0.8125rem, 16px);
  color: ${(props) => (props.dark ? 'white' : props.theme.charcoal)};
  transition: ${(props) => props.theme.transition};
  border-radius: 3px;
  outline: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  line-height: 1.5;

  [dir='rtl'] & {
    text-align: right;
  }

  &::placeholder {
    color: ${(props) => props.theme.white_30};
  }

  &:hover {
    border-width: 3px;
    padding: 12px;
  }

  &:focus {
    border-width: 1px;
    padding: 14px;
  }

  &:disabled,
  :read-only {
    border: 1px solid ${(props) => props.theme.charcoal_25};
    padding: 14px;
  }
`;

const BasicInputLabel = styled.label`
  display: block;
  flex: 1;
  font-size: 12px;
  line-height: 1.6;
  color: ${(props) => {
    return props.dark ? props.theme.white_80 : props.theme.charcoal_90;
  }};
`;

const Error = styled.span`
  font-size: 11px;
  margin: 0 0 0.5em 0;
  color: ${(props) => props.theme.error_color};
`;

class InputField extends Component {
  state = {
    focused: false
  };

  handleFocusChange = (isFocused) => {
    this.setState({ focused: isFocused });
  };

  render() {
    const {
      width,
      height,
      flex,
      margin,
      padding,
      label,
      placeholder,
      innerRef,
      ...inputProps
    } = this.props;

    const containerProps = {
      width,
      height,
      flex,
      margin,
      padding
    };

    return (
      <BasicInputFieldContainer {...containerProps}>
        <BasicInputLabel focused={this.state.focused} dark={this.props.dark}>
          {this.props.label || this.props.placeholder}
          <BasicInputField
            onBlur={() => {
              this.handleFocusChange(false);
              if (this.props.onBlur) {
                this.props.onBlur();
              }
            }}
            onFocus={() => {
              this.handleFocusChange(true);
              if (this.props.onFocus) {
                this.props.onFocus();
              }
            }}
            placeholder={this.props.placeholder}
            ref={innerRef}
            {...inputProps}
          />
          {this.props.error && <Error>{this.props.error}</Error>}
        </BasicInputLabel>
      </BasicInputFieldContainer>
    );
  }
}

export default forwardRef((props, ref) => (
  <InputField {...props} innerRef={ref} />
));
