import React, { Component, forwardRef } from 'react';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  margin-bottom: 0.6em;
`;

const DropdownLabel = styled.label`
  position: relative;
  margin: 0.5em 0;
  display: block;
  font-size: 12px;
  line-height: 1.6;
  color: ${(props) => {
    if (props.focused) {
      return props.theme.ds_bright_blue;
    }

    return props.dark ? props.theme.white_80 : props.theme.charcoal_90;
  }};
`;

const Selector = styled.select`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 47px;
  border-radius: 3px;
  font-size: max(0.8125rem, 16px);
  text-align: start;
  line-height: 1.4;
  padding: 0 14px;
  background: transparent;
  color: ${(props) => (props.dark ? '#fff' : 'rgba(27,27,27,0.9)')};
  font-family: ${(props) => props.theme.sans_serif};
  border: solid 1px ${(props) => props.theme.ds_bright_blue};
  transition: ${(props) => props.theme.transition};
  &:hover {
    border-width: 3px;
    padding: 12px;
  }
`;

const Error = styled.span`
  font-size: 11px;
  margin: 0 0 0.5em 0;
  color: ${(props) => props.theme.error_color};
`;

class DropDown extends Component {
  state = {
    focused: false
  };

  getOptions(data) {
    const options = [
      <option key={1} value={''} disabled>
        {this.props.placeholder}
      </option>
    ];

    /*** if its a line separated string split it */
    if (typeof data === 'string') {
      data.split(/\n/).forEach((value) => {
        options.push(
          <option key={value} value={value}>
            {value}
          </option>
        );
      });
    } else {
      data.forEach(({ label, value }) => {
        options.push(
          <option key={value} value={value}>
            {label}
          </option>
        );
      });
    }

    return options;
  }

  render() {
    const { dark, label, placeholder, data, innerRef, ...inputProps } =
      this.props;

    return (
      <DropdownContainer>
        <DropdownLabel dark={dark}>
          {label || placeholder} <br />
          <Selector
            placeholder="Please Select"
            defaultValue={''}
            name="select"
            dark={dark}
            id="dateSelector"
            ref={innerRef}
            {...inputProps}>
            {this.getOptions(data)}
          </Selector>
          {this.props.error && <Error>{this.props.error}</Error>}
        </DropdownLabel>
      </DropdownContainer>
    );
  }
}

export default forwardRef((props, ref) => (
  <DropDown {...props} innerRef={ref} />
));
