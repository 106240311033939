import React from 'react';
import { Desktop, Mobile } from '../responsive/Breakpoints';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';

import img2_2x from '../../assets/images/img-we-like-la-logo@2x.png';
import img2_3x from '../../assets/images/img-we-like-la-logo@3x.png';
import img3 from '../../assets/images/img-forbes-logo.svg';
import img5_2x from '../../assets/images/img-vr-scout-logo@2x.png';
import img5_3x from '../../assets/images/img-vr-scout-logo@3x.png';
import img6_2x from '../../assets/images/img-thrillist-logo@2x.png';
import img6_3x from '../../assets/images/img-thrillist-logo@3x.png';

import imgFortume2x from '../../assets/images/img-fortune-logo@2x.png';
import imgFortume3x from '../../assets/images/img-fortune-logo@3x.png';

const StyledCarouselItem = styled.div`
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  // margin-top: ${(props) => (props.mobile ? '12px' : '12px')};
  margin-bottom: ${(props) => (props.mobile ? '102px' : '124px')};
  margin-top: 12px;
`;

const ContentContainer = styled.section`
  padding: 0 11px;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.mobile ? '226px' : '650px')};
  flex: 1;
`;

const StyledQuote = styled.div`
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.25rem' : '2.25rem')};
  [dir='rtl'] & {
    font-size: ${(props) => (props.mobile ? '1.25rem' : '2rem')};
  }
  font-weight: normal;
  line-height: ${(props) => (props.mobile ? '1.37' : '1.17')};
  color: ${(props) => props.theme.charcoal_90};
  flex: 1;
  display: flex;
  align-items: center;
  padding: 32px 0;
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // max-height: 40px;
`;

const content = [
  {
    quote: {
      label: 'home_news_slide_quote_1'
    },
    sourceIcon: <img src={img3} alt="brand source" />
  },
  {
    quote: {
      label: 'home_news_slide_quote_2'
    },
    sourceIcon: (
      <img
        src={img2_2x}
        srcSet={`${img2_2x} 2x, ${img2_3x} 3x`}
        alt="brand source"
      />
    )
  },
  {
    quote: {
      label: 'home_news_slide_quote_3'
    },
    sourceIcon: (
      <img
        src={imgFortume2x}
        srcSet={`${imgFortume2x} 2x, ${imgFortume3x} 3x`}
        alt="brand source"
      />
    )
  },
  {
    quote: {
      label: 'home_news_slide_quote_4'
    },
    sourceIcon: (
      <img
        src={img5_2x}
        srcSet={`${img5_2x} 2x, ${img5_3x} 3x`}
        alt="brand source"
      />
    )
  },
  {
    quote: {
      label: 'home_news_slide_quote_5'
    },
    sourceIcon: (
      <img
        src={img6_2x}
        srcSet={`${img6_2x} 2x, ${img6_3x} 3x`}
        alt="brand source"
      />
    )
  }
];

const items = content.map((item, itr) => {
  return (
    <AppContext.Consumer key={itr}>
      {({ getLabel }) => (
        <React.Fragment key={itr}>
          <Desktop>
            <StyledCarouselItem key={itr}>
              <ContentContainer>
                <StyledQuote>{getLabel(item.quote.label)}</StyledQuote>
                <StyledIcon>{item.sourceIcon}</StyledIcon>
              </ContentContainer>
            </StyledCarouselItem>
          </Desktop>
          <Mobile>
            <StyledCarouselItem mobile key={itr}>
              <ContentContainer mobile>
                <StyledQuote mobile>{getLabel(item.quote.label)}</StyledQuote>
                <StyledIcon mobile>{item.sourceIcon}</StyledIcon>
              </ContentContainer>
            </StyledCarouselItem>
          </Mobile>
        </React.Fragment>
      )}
    </AppContext.Consumer>
  );
});

export default items;
