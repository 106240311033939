import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { withAppContext } from '../context/AppContext';
import BreadcrumbSchema from './BreadcrumbSchema';
import OrganizationalSchema from './OrganizationalSchema';
import metaDatMap from './metaDataMap.json';
import { AVAILABLE_SITES } from '../../util/constants';

function SEOTags(props) {
  const seo = useMemo(() => {
    const defaultSite = AVAILABLE_SITES[0];
    const site = AVAILABLE_SITES.find((site) =>
      window.location.pathname.startsWith(`/${site}`)
    );
    const origin = window.location.origin;
    const pathname = props.location.pathname.replace(/\/$/, '');
    const canonicalUrl = origin + (site ? `/${site}` : '') + pathname;
    const alternateUrls = AVAILABLE_SITES.map((site) => ({
      hreflang: site,
      href: origin + `/${site}` + pathname
    }));
    const { title, description } =
      metaDatMap[site || defaultSite][pathname || '/'] || {};

    return {
      title,
      description,
      canonicalUrl,
      alternateUrls,
      site,
      pathname
    };
  }, [window.location.pathname]);

  return (
    <>
      <Helmet>
        {seo.title && <title>{seo.title}</title>}
        {seo.title && <meta name="title" content={seo.title} />}
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
        <link rel="canonical" href={seo.canonicalUrl} />
        {seo.alternateUrls.map((alternateUrl) => (
          <link
            rel="alternate"
            hreflang={alternateUrl.hreflang}
            href={alternateUrl.href}
            key={alternateUrl.href}
          />
        ))}
      </Helmet>
      <BreadcrumbSchema pathname={seo.pathname} site={seo.site} />
      {/* Only show this schema in homepage */}
      {seo.pathname === '' && (
        <OrganizationalSchema canonicalUrl={seo.canonicalUrl} />
      )}
    </>
  );
}

export default withRouter(withAppContext(SEOTags));
