import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';

// Galaxy Images
import galaxyFindUsBG_x2 from '../assets/images/img-find-us-bg-default@2x.jpg';
import galaxyFindUsBG_x3 from '../assets/images/img-find-us-bg-default@3x.jpg';
import galaxyFindUsBGSmall_x2 from '../assets/images/img-find-us-bg-s-default@2x.jpg';
import galaxyFindUsBGSmall_x3 from '../assets/images/img-find-us-bg-s-default@3x.jpg';

import { Desktop, Mobile } from './responsive/Breakpoints';
import NewsletterForm from './forms/NewsletterForm';
import { WhiteOutlineButton, WhiteButton } from './buttons';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';
import LoadingSpinner from '../components/LoadingSpinner';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
`;

const GalaxySection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${(props) => (props.mobile ? '325px' : '533px')};
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 60px 22px;
`;

const LocationFormWrapper = styled.div`
  width: 100%;
  background-color: black;
`;

const LocationFormContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  padding: ${(props) => (props.mobile ? '32px 22px' : '48px 22px 40px 22px')};
  justify-content: ${(props) => (!props.center ? 'center' : 'space-between')};
  align-items: ${(props) => (props.mobile ? 'center' : undefined)};
  max-width: 1214px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: ${(props) => (props.mobile ? '370px' : '560px')};
  margin-bottom: ${(props) => (props.mobile ? '30px' : undefined)};
  margin-right: ${(props) => (props.mobile ? undefined : '32px')};
  width: ${(props) => (props.mobile ? '100%;' : undefined)};
`;

const ContainerHeader = styled.div`
  font-family: ${(props) => props.theme.serif};
  font-size: ${(props) => (props.mobile ? '1.375rem' : '2.25rem')};
  padding-bottom: ${(props) => (props.mobile ? '6px' : undefined)};
  line-height: 1.19;
  color: ${(props) => props.theme.white_90};
`;

const SubscribeHeader = styled.div`
  font-size: ${(props) => (props.mobile ? '0.825rem' : '1rem')};
  padding-bottom: ${(props) => (props.mobile ? '6px' : undefined)};
  line-height: 1.19;
  color: white;
`;

const ContainerText = styled.div`
  font-size: ${(props) => (props.mobile ? '0.825rem' : '1rem')};
  color: ${(props) => props.theme.white_70};
`;

const StoreHoursButton = styled(WhiteOutlineButton)`
  max-width: 370px;
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

const MultipleLocationsTitle = styled.div`
  color: white;
  font-size: ${(props) => (props.mobile ? '1.625rem' : '3.5rem')};
  font-weight: bold;
  letter-spacing: ${(props) => (props.mobile ? 'normal' : '2px')};
  line-height: 1.35;
  text-align: center;
`;

const MultipleLocationsDescription = styled.div`
  color: ${(props) => props.theme.white_70};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '.875rem' : '1rem')};
  max-width: 692px;
  text-align: center;
  margin-top: 10px;

  .locations-bold {
    font-weight: 500;
    color: white;
  }
`;

const MultipleLocationsButtonContainer = styled.div`
  margin-top: ${(props) => (props.mobile ? '35px' : '40px')};
`;

const StoreInfoButtons = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
`;

const ContentLine = styled.p`
  margin: 0;
  color: ${(props) => props.theme.white_70};
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem`')};
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: underline;
`;

const StyledPhoneLink = styled.a`
  color: ${(props) => props.theme.white_70};
  text-decoration: none;
  [dir='rtl'] & {
    direction: ltr;
    text-align: right;
    unicode-bidi: bidi-override;
  }
`;

class FindUsSectionWithContext extends Component {
  state = {
    subEmail: '',
    hasSelectedSite: false,
    siteName: '',
    siteAddress: '',
    sitePhone: '',
    siteImages: {
      mobile: {
        x1: galaxyFindUsBGSmall_x2,
        x2: galaxyFindUsBGSmall_x2,
        x3: galaxyFindUsBGSmall_x3
      },
      desktop: {
        x1: galaxyFindUsBG_x2,
        x2: galaxyFindUsBG_x2,
        x3: galaxyFindUsBG_x3
      }
    },
    siteLocationPath: ''
  };

  componentDidMount() {
    const { selectedSite } = this.props.parentState;
    this.setSiteInfo(selectedSite);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedSite, cms } = this.props.parentState;
    const siteAssets = cms.getSiteAssets(selectedSite.site_id);
    if (
      (!prevProps.parentState.selectedSite.site_id && selectedSite.site_id) ||
      prevProps.parentState.selectedSite.site_id !== selectedSite.site_id ||
      (!prevState.siteName && Object.keys(siteAssets).length)
    ) {
      return this.setSiteInfo(selectedSite);
    }
  }

  setSiteInfo(site) {
    const { cms } = this.props.parentState;
    const siteAssets = cms.getSiteAssets(site.site_id);
    const state = {
      hasSelectedSite: false,
      siteName: '',
      siteAddress: '',
      sitePhone: '',
      siteImages: {
        mobile: {
          x1: galaxyFindUsBGSmall_x2,
          x2: galaxyFindUsBGSmall_x2,
          x3: galaxyFindUsBGSmall_x3
        },
        desktop: {
          x1: galaxyFindUsBG_x2,
          x2: galaxyFindUsBG_x2,
          x3: galaxyFindUsBG_x3
        }
      }
    };

    if (
      !site ||
      !site.site_id ||
      !siteAssets ||
      !Object.keys(siteAssets).length
    ) {
      return this.setState(state);
    }

    const copy = siteAssets.copy;
    const images = siteAssets.images.section;
    let siteAddress = `${site.street_address}<br/> ${site.city}${
      this.props.locale === 'ar' ? ' ،' : ','
    } ${site.state}`;
    if (site.postal_code) {
      siteAddress += ` ${site.postal_code}`;
    }
    return this.setState({
      siteAddress,
      hasSelectedSite: true,
      siteName: copy.display_name,
      sitePhone: copy.phone,
      siteDirections: copy.directions,
      siteImages: images,
      siteLocationPath: copy.site_url_path
    });
  }

  onSubmitSubscriptionEmail = () => {
    this.setState({ subEmail: '' });
  };

  handleNavToPath = (path) => {
    return this.props.history.push(path);
  };

  renderImage(isMobile) {
    if (this.props.noImage) {
      return null;
    }

    const { getLabel } = this.props;
    const images = isMobile
      ? this.state.siteImages.mobile.x3
      : this.state.siteImages.desktop.x3;
    const canChangeLocations = this.props.parentState.sites.length > 1;
    const multipleLocationsComponent = (
      <React.Fragment>
        <MultipleLocationsTitle
          mobile={isMobile}
          dangerouslySetInnerHTML={{
            __html: getLabel('findus_section_multi_locations_title')
          }}></MultipleLocationsTitle>
        <MultipleLocationsDescription mobile={isMobile}>
          {getLabel('findus_section_multi_locations_desc_1')}
          <StyledLink to={'/account/signup'}>
            {' '}
            {getLabel('findus_section_multi_locations_desc_2')}
          </StyledLink>{' '}
          {getLabel('findus_section_multi_locations_desc_3')}
        </MultipleLocationsDescription>
        <MultipleLocationsButtonContainer mobile={isMobile}>
          {canChangeLocations ? (
            <WhiteButton
              onClick={this.props.parentState.handleShowLocationDialog}>
              {getLabel('findus_section_multi_locations_button_text')}
            </WhiteButton>
          ) : (
            <LoadingSpinner />
          )}
        </MultipleLocationsButtonContainer>
      </React.Fragment>
    );

    return (
      <GalaxySection mobile={isMobile}>
        <BackgroundImage src={images} alt="Find Us BG" />
        {!this.state.hasSelectedSite ? multipleLocationsComponent : null}
      </GalaxySection>
    );
  }

  renderStoreInfo(mobile) {
    if (!this.state.hasSelectedSite) {
      return null;
    }
    const { getLabel } = this.props;

    return (
      <InfoContainer mobile={mobile}>
        <ContainerHeader mobile={mobile}>{this.state.siteName}</ContainerHeader>
        <ContainerText
          mobile={mobile}
          style={{ paddingBottom: '5px' }}
          dangerouslySetInnerHTML={{
            __html: this.state.siteAddress
          }}></ContainerText>
        <ContainerText mobile={mobile} style={{ paddingBottom: '5px' }}>
          <StyledPhoneLink href={`tel:${this.state.sitePhone}`}>
            {this.state.sitePhone}
          </StyledPhoneLink>
        </ContainerText>
        <ContainerText mobile={mobile} style={{ paddingBottom: '20px' }}>
          {this.state.siteDirections}
        </ContainerText>
        <StoreInfoButtons mobile={mobile}>
          <StoreHoursButton
            width={mobile ? '100%' : '251px'}
            onClick={() => this.handleNavToPath('/find-us')}>
            {getLabel('findus_section_store_button_text_1')}
          </StoreHoursButton>
          <StoreHoursButton
            style={{
              marginTop: mobile ? '20px' : '0',
              marginInlineStart: mobile ? '0' : '20px'
            }}
            width={mobile ? '100%' : '175px'}
            onClick={() => this.handleNavToPath(this.state.siteLocationPath)}>
            {getLabel('findus_section_store_button_text_2')}
          </StoreHoursButton>
        </StoreInfoButtons>
      </InfoContainer>
    );
  }

  render() {
    const { getLabel } = this.props;
    return (
      <React.Fragment>
        <Desktop>
          <SectionContainer name="find-us-section">
            {this.renderImage()}
            <LocationFormWrapper>
              <LocationFormContainer center={this.state.hasSelectedSite}>
                {this.renderStoreInfo()}

                <InfoContainer
                  style={{
                    textAlign: this.state.hasSelectedSite ? undefined : 'center'
                  }}>
                  <SubscribeHeader>
                    {getLabel('findus_section_subscribe_header')}
                  </SubscribeHeader>
                  <ContentLine>
                    {getLabel('findus_section_subscribe_content')}
                  </ContentLine>
                  <NewsletterForm dark />
                </InfoContainer>
              </LocationFormContainer>
            </LocationFormWrapper>
          </SectionContainer>
        </Desktop>

        <Mobile>
          <SectionContainer name="find-us-section">
            {this.renderImage(true)}
            <LocationFormWrapper>
              <LocationFormContainer mobile>
                {this.renderStoreInfo(true)}

                <InfoContainer mobile style={{ marginBottom: 0 }}>
                  <SubscribeHeader mobile>
                    {getLabel('findus_section_subscribe_header')}
                  </SubscribeHeader>
                  <ContentLine mobile>
                    {getLabel('findus_section_subscribe_content')}
                  </ContentLine>
                  <NewsletterForm dark mobile />
                </InfoContainer>
              </LocationFormContainer>
            </LocationFormWrapper>
          </SectionContainer>
        </Mobile>
      </React.Fragment>
    );
  }
}

const FindUsSection = (props) => (
  <AuthContext.Consumer>
    {(parentState) => (
      <FindUsSectionWithContext {...props} parentState={parentState} />
    )}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(FindUsSection));
