import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Import sections
import HeroSection from '../components/home/HeroSection';
import AdventuresSection from '../components/home/AdventuresSection';
import PlanAheadSection from '../components/home/PlanAheadSection';
import NewsSection from '../components/home/NewsSection';
import FindUsSection from '../components/FindUsSection';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';
import AnnouncementModal from '../components/AnnouncementModal';
import { Desktop, Mobile } from '../components/responsive/Breakpoints';

const Container = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: inherit;
  cursor: pointer;
  margin: 0;
`;

class HomeWithContext extends Component {
  componentDidMount() {
    // Handle logout redirect
    const logoutReturnUrl = localStorage.getItem('logoutReturnUrl');

    if (logoutReturnUrl) {
      localStorage.removeItem('logoutReturnUrl');

      let routeState = localStorage.getItem('routeState');
      if (routeState) {
        routeState = JSON.parse(routeState);
        localStorage.removeItem('routeState');
      }

      return this.props.history.replace({
        pathname: logoutReturnUrl,
        state: routeState
      });
    }
  }
  render() {
    const { adventures, cms, selectedSite, hasStoredSite } =
      this.props.parentState;

    return (
      <Container id="home">
        <Desktop>
          <AnnouncementModal />
        </Desktop>
        <Mobile>
          <AnnouncementModal isMobile={true} />
        </Mobile>

        <HeroSection
          cms={cms}
          selectedSite={selectedSite}
          hasStoredSite={hasStoredSite}
        />
        <AdventuresSection
          adventures={adventures}
          cms={cms}
          site={selectedSite}
          heading={
            <Title onClick={() => this.props.history.push('/adventures')}>
              {this.props.getLabel('home_adventures_title')}
            </Title>
          }
        />
        <PlanAheadSection />
        <NewsSection />
        <FindUsSection />
      </Container>
    );
  }
}

const Home = (props) => (
  <AuthContext.Consumer>
    {(parentState) => <HomeWithContext {...props} parentState={parentState} />}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(Home));
