import React, { Component, lazy, Suspense } from 'react';
import { withAppContext } from '../../components/context/AppContext';
const ShareFAQEn = lazy(() => import('./en'));
const ShareFAQAr = lazy(() => import('./ar'));

class ShareFAQ extends Component {
  render() {
    return this.props.locale === 'ar' ? (
      <Suspense fallback={null}>
        <ShareFAQAr />
      </Suspense>
    ) : (
      <Suspense fallback={null}>
        <ShareFAQEn />
      </Suspense>
    );
  }
}

export default withAppContext(ShareFAQ);
