import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile } from './responsive/Breakpoints';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  min-height: 532px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: ${(props) => (props.mobile ? '30px 22px' : '80px 225px')};
  max-width: 1170px;
  margin: 0 auto;
  min-height: ${(props) =>
    props.extraSpace && props.mobile ? '803px' : undefined};
`;

const HeaderBlock = styled.h1`
  color: ${(props) => (props.light ? 'white' : props.theme.charcoal)};
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '26px' : '44px')};
  padding-bottom: ${(props) => (props.mobile ? '18px' : '35px')};
  line-height: ${(props) => (props.mobile ? '1.17' : '1.09')};
  margin: 0;
`;

const BodyBlock = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => (props.mobile ? '14px' : '22px')};
  line-height: 1.54;
  color: ${(props) =>
    props.light ? props.theme.white_70 : props.theme.charcoal_60};
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

class QuoteBlock extends Component {
  state = {};

  componentDidMount() {
    this.props.autoScroll();
  }

  checkForContent = () => {
    return this.props.contentData.story;
  };

  renderQuoteBlock(mobile) {
    const adventureContent = this.checkForContent();

    let background = (
      <BackgroundImage
        src={adventureContent.images.background.desktop.x1}
        srcSet={`${adventureContent.images.background.desktop.x2} 2x, ${adventureContent.images.background.desktop.x3} 3x`}
      />
    );

    if (mobile) {
      background = (
        <BackgroundImage
          src={adventureContent.images.background.mobile.x1}
          srcSet={`${adventureContent.images.background.mobile.x2} 2x, ${adventureContent.images.background.mobile.x3} 3x`}
        />
      );
    }

    return (
      <Container id={'quoteBlock'}>
        {background}
        <TextContainer
          extraSpace={adventureContent.copy.extraSpace}
          mobile={mobile}>
          <HeaderBlock light={adventureContent.copy.textLight} mobile={mobile}>
            {adventureContent.copy.headerText}
          </HeaderBlock>
          <BodyBlock light={adventureContent.copy.textLight} mobile={mobile}>
            {adventureContent.copy.bodyText}
          </BodyBlock>
        </TextContainer>
      </Container>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Desktop>{this.renderQuoteBlock(false)}</Desktop>
        <Mobile>{this.renderQuoteBlock(true)}</Mobile>
      </React.Fragment>
    );
  }
}

export default withRouter(QuoteBlock);
