import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { BlueButton } from '../components/buttons';
import CheckoutOrderDetails from '../components/CheckoutOrderDetails';
import { AuthContext } from '../components/context/AuthenticatorContext';
import { withAppContext } from '../components/context/AppContext';
import { Desktop, Mobile } from '../components/responsive/Breakpoints';
import LoadingSpinner from '../components/LoadingSpinner';
import { withAuth0 } from '@auth0/auth0-react';
import { sendCustomEvent, sendEcomEvent } from '../util/analytics';

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.mobile ? '20px' : '38px')};
`;

const SignInForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.mobile ? '16px 22px 0 22px' : undefined)};
  max-width: ${(props) => (props.mobile ? '360px' : '300px')};
  box-sizing: border-box;
  width: 100%;
`;

const SectionTitle = styled.div`
  font-size: ${(props) => (props.mobile ? '1.625rem' : '2.75rem')};
  font-weight: bold;
  line-height: ${(props) => (props.mobile ? '1.33' : '1.09')};
  align-self: center;
  color: ${(props) => props.theme.charcoal_90};
  padding: ${(props) =>
    props.mobile ? '46px 22px 0 22px' : '60px 22px 0 22px'};
  text-align: ${(props) => (props.mobile ? 'center' : undefined)};
  letter-spacing: -1px;
`;

const SectionSubTitle = styled.div`
  font-size: ${(props) => (props.mobile ? '0.875rem' : '1rem')};
  align-self: center;
  max-width: ${(props) => (props.mobile ? '360px' : '428px')};
  padding: 4px 22px 0 22px;
  text-align: center;
  color: ${(props) => props.theme.charcoal_60};
  box-sizing: border-box;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  box-sizing: border-box;
  max-width: ${(props) => (props.mobile ? '360px' : '300px')};
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 21px 0;
`;

const BtnDividerText = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.charcoal_60};
  padding: 3px 12px;
`;

const BtnDividerLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${(props) => props.theme.charcoal_60};
`;

class CheckoutSignIn extends Component {
  state = {
    email: '',
    password: '',
    orderDetails: null,
    isSigningIn: false
  };

  componentDidMount() {
    this.handleRedirect();
    document.body.style.background =
      'radial-gradient(circle at 50% 50%, #fcfcfc, #e6e6e6) fixed center';
    // This will return the header information back to its default if it has been changed.
    this.props.updateHeaderText({});
  }

  componentDidUpdate() {
    this.handleRedirect();
  }

  componentWillUnmount() {
    document.body.style.background = 'black';
  }

  handleRedirect() {
    if (this.props.auth0.isAuthenticated) {
      this.navToPayment(true);
    }
  }

  handleInputChange = (element) => {
    const { name, value } = element.target;
    this.setState({
      [name]: value
    });
  };

  handleUserSignIn = async (e) => {
    e.preventDefault();
    console.info('[CheckoutSignIn] -> (handleUserSignIn) Sign In Clicked');

    sendCustomEvent({
      event_category: 'click',
      event_action: 'membership',
      event_label: 'sign-in',
      membership_icon_name: 'sign-in'
    });

    await this.setState({ isSigningIn: true });

    this.setState({ signInLoading: true });
    const { loginWithRedirect } = this.props.auth0;

    const siteId = this.props.authState.routerBase;

    localStorage.setItem(
      'routeState',
      JSON.stringify(this.props.location.state)
    );

    loginWithRedirect({
      lang: this.props.locale,
      appState: {
        targetUrl: `${siteId}/checkout/signin?paymentRedirect=1`
      }
    });
    return;
  };

  navToPayment = (shouldReplace) => {
    const locationState = this.getLocationState(true);

    if (shouldReplace) {
      return this.props.history.replace('/checkout/payment', locationState);
    }

    return this.props.history.push('/checkout/payment', locationState);
  };

  handleNavToPayment = (e) => {
    e.preventDefault();
    this.navToPayment();

    const { site, orderDetails, adventure } = this.props.location.state;

    sendEcomEvent('add_user_info', null, {
      siteId: site.site_id,
      adventures: adventure,
      orderDetails: orderDetails
    });
  };

  handleNavToForgotPassword = () => {
    this.props.history.push(
      '/checkout/signin/forgot-password/request',
      this.props.location.state
    );
  };

  navToSignup = () => {
    const locationState = this.props.location.state;
    this.props.history.replace('/checkout/signup', locationState);
  };

  renderSignInButton(isMobile) {
    const buttonContent = this.state.isSigningIn ? (
      <LoadingSpinner />
    ) : (
      this.props.getLabel('checkout_signin_btn_text')
    );

    return (
      <BlueButton
        width={isMobile ? '100%' : 300}
        type="submit"
        disabled={this.state.isSigningIn}>
        {buttonContent}
      </BlueButton>
    );
  }

  getLocationState(shouldRemoveFromStorage = false) {
    if (this.props.location.state) {
      return this.props.location.state;
    }
    let routeState = localStorage.getItem('routeState');
    if (routeState) {
      routeState = JSON.parse(routeState);

      if (!this.props.auth0.isAuthenticated || shouldRemoveFromStorage) {
        localStorage.removeItem('routeState');
      }
    }

    return routeState || this.props.location.state;
  }

  render() {
    const { getLabel } = this.props;
    const locationState = this.getLocationState();
    const { orderDetails, adventure } = locationState;
    const pageDetails = {
      title: getLabel('checkout_signin_title'),
      subTitle: getLabel('checkout_signin_sub_title')
    };

    return (
      <Page>
        <Desktop>
          <SectionTitle>{pageDetails.title}</SectionTitle>
          <SectionSubTitle>{pageDetails.subTitle}</SectionSubTitle>
        </Desktop>
        <Mobile>
          <SectionTitle mobile>{pageDetails.title}</SectionTitle>
          <SectionSubTitle mobile>{pageDetails.subTitle}</SectionSubTitle>
        </Mobile>

        <Desktop>
          <Container>
            <CheckoutOrderDetails
              orderDetails={orderDetails}
              titleId={adventure.title_id}
            />

            <SignInForm onSubmit={this.handleUserSignIn}>
              <ButtonGroup>
                {this.renderSignInButton()}

                <Row>
                  <BtnDividerLine />
                  <BtnDividerText>
                    {getLabel('checkout_or_text')}
                  </BtnDividerText>
                  <BtnDividerLine />
                </Row>

                <BlueButton width={300} onClick={this.handleNavToPayment}>
                  {getLabel('checkout_guest_btn_text')}
                </BlueButton>
              </ButtonGroup>
            </SignInForm>
          </Container>
        </Desktop>

        {/* MOBILE STYLING */}
        <Mobile>
          <Container mobile>
            <SignInForm mobile onSubmit={this.handleUserSignIn}>
              <ButtonGroup mobile>
                {this.renderSignInButton(true)}

                <Row>
                  <BtnDividerLine />
                  <BtnDividerText>
                    {getLabel('checkout_or_text')}
                  </BtnDividerText>
                  <BtnDividerLine />
                </Row>

                <BlueButton width={'100%'} onClick={this.handleNavToPayment}>
                  {getLabel('checkout_guest_btn_text')}
                </BlueButton>
              </ButtonGroup>
            </SignInForm>
          </Container>
        </Mobile>
      </Page>
    );
  }
}

const SignIn = (props) => (
  <AuthContext.Consumer>
    {(authState) => {
      return <CheckoutSignIn {...props} authState={authState} />;
    }}
  </AuthContext.Consumer>
);

export default withRouter(withAppContext(withAuth0(SignIn)));
