import React from 'react';
import styled from 'styled-components';
import WhiteCheckMark from '../../assets/images/check_white_192x192.svg';

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  background-color: transparent;
  cursor: pointer;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 1;
  left: 0;
`;

const CheckboxContainer = styled.div`
  position: relative;
  ${(props) =>
    props.margin ? 'margin: ' + props.margin : 'margin-inline-end: 11px'};
  width: 30px;
  height: 30px;
  border: solid 1px ${(props) => props.theme.ds_bright_blue};
  border-radius: 3px;
  cursor: pointer;
  padding: 0;
  flex-shrink: 0;

  &:hover {
    background-color: rgba(27, 27, 27, 0.1);
  }
`;

const CheckboxImgDiv = styled.div`
  position: absolute;
  background-color: ${(props) =>
    props.checked ? props.theme.ds_dusk_blue : 'transparent'};
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  border-radius: 2px;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  margin: 3px;
`;

const CheckboxImg = styled.img`
  display: ${(props) => (props.checked ? 'block' : 'none')};
  width: 16px;
  margin: 4px auto;
`;

const CheckboxComp = (props) => {
  return (
    <CheckboxContainer margin={props.margin}>
      <Checkbox onClick={props.onClick} type="checkbox" />
      <CheckboxImgDiv checked={props.checked} alt="Check">
        <CheckboxImg checked={props.checked} src={WhiteCheckMark} />
      </CheckboxImgDiv>
    </CheckboxContainer>
  );
};

export default CheckboxComp;
