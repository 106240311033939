import React, { Component } from 'react';
import styled from 'styled-components';

import StandardCarousel from '../carousels/StandardCarousel';
import { Desktop, Mobile, Phone, Tablet } from '../responsive/Breakpoints';
import DownArrowIcon from '../svgs/DownArrowIcon';
import theme from '../../util/theme';
import { withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll/modules';
import WhiteButton from '../buttons/WhiteButton';
import { TOASTER_NOTIFICATION_HEIGHT } from '../../util/constants';
import { withAppContext } from '../context/AppContext';

import imgLogoLoadingLarge from '../../assets/images/img-logo-loading-l.svg';

const StyledCarouselItem = styled.div`
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url('${(props) => props.imgSrc}');
  background-size: cover;
  background-position: center;
`;

const ContentContainer = styled.section`
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.mobile ? '80px' : 0)};
  max-width: 1214px;
  box-sizing: border-box;
`;

const StyledEyebrow = styled.div`
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => props.fontSize || '1.25rem'};
  font-weight: bold;
  letter-spacing: ${(props) => props.letterSpacing || '2px'};
  color: white;
  ${(props) => (props.mobile ? 'position: absolute' : '')}
  ${(props) => (props.mobile ? 'top: 12px; left: 0px; right: 0px' : '')}
`;

const StyledNarrative = styled.div`
  color: white;
  font-family: ${(props) => props.theme.sans_serif};
  font-size: ${(props) => props.fontSize || '3.5rem'};
  font-weight: bold;
  letter-spacing: 2px;
  line-height: ${(props) => props.lineHeight || 'initial'};
  margin-top: ${(props) => props.marginTop || 'initial'};
  max-width: ${(props) => props.maxWidth || undefined};
`;

const BookTripButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 10;
  pointer-events: none;
`;

const SectionContainer = styled.div`
  height: calc(100vh - ${(props) => props.toasterHeight}px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // #dedede equivalent to charcoal at 10% opacity on white background
  background-color: ${(props) => (props.isLoading ? '#dedede' : 'black')};
`;

const LogoPlaceHolder = styled.img`
  width: ${(props) => (props.mobile ? '230px' : '350px')};
`;

const AnchorNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  position: absolute;
  z-index: 10;
  pointer-events: none;
`;

const Anchor = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.white_25};
  border-radius: 3px;
  color: white;
  pointer-events: all;
  cursor: pointer;
  margin-bottom: ${(props) => props.marginTop || '11vh'};
  padding: 10px;
`;

class HeroSection extends Component {
  state = {
    assets: {},
    selectedSite: {}
  };

  navLink = null;
  callToAction = 'BOOK YOUR ADVENTURE';

  async componentDidMount() {
    const assets = this.props.cms.getHomeHeroAssets(
      this.props.selectedSite.site_id
    );
    if (Object.keys(assets).length) {
      await this.setState({ assets });
      this.captureIndex(0);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const assets = this.props.cms.getHomeHeroAssets(
      this.props.selectedSite.site_id
    );
    if (
      // If assets has loaded in
      (!Object.keys(prevState.assets).length && Object.keys(assets).length) ||
      // The selected site was changed
      (Object.keys(assets).length &&
        prevProps.selectedSite.site_id !== this.props.selectedSite.site_id)
    ) {
      await this.setState({ assets });
      this.captureIndex(0);
    }
  }

  navToAdventures = () => {
    if (!this.navLink) {
      return;
    }
    this.props.history.push(this.navLink);
  };

  captureIndex = (newIndex) => {
    const { config, ...slides } = this.state.assets;
    const slideName = config.slideList[newIndex];
    this.callToAction = slides[slideName].copy.default.callToAction;
    this.navLink = slides[slideName].copy.default.callToActionPath;
  };

  scrollTo = (val, offset) => {
    scroller.scrollTo('adventure', {
      duration: 750,
      smooth: 'easeInOutQuint',
      offset
    });
  };

  handleScrollToAdvSection = () => {
    const toasterHeight = this.props.hasStoredSite
      ? 0
      : TOASTER_NOTIFICATION_HEIGHT;
    this.scrollTo(
      document.getElementById('home').offsetHeight,
      -document.getElementById('navbar').offsetHeight - toasterHeight
    );
  };

  renderCarouselContent() {
    return (
      <AnchorNav onClick={this.handleScrollToAdvSection}>
        {/* Absolute positioned Button to animate scroll down to the next section */}
        <Desktop>
          <Anchor>
            <DownArrowIcon color={theme.white_80} />
          </Anchor>
        </Desktop>
        <Tablet>
          <Anchor>
            <DownArrowIcon color={theme.white_80} />
          </Anchor>
        </Tablet>
        <Phone>
          <Anchor>
            <DownArrowIcon color={theme.white_80} />
          </Anchor>
        </Phone>
      </AnchorNav>
    );
  }

  render() {
    const { config, ...slides } = this.state.assets;
    const toasterHeight = this.props.hasStoredSite
      ? 0
      : TOASTER_NOTIFICATION_HEIGHT;
    const isLoading = !config;
    if (isLoading) {
      return (
        <SectionContainer isLoading={isLoading} toasterHeight={toasterHeight}>
          <Desktop>
            <LogoPlaceHolder src={imgLogoLoadingLarge} />
          </Desktop>
          <Mobile>
            <LogoPlaceHolder src={imgLogoLoadingLarge} mobile />
          </Mobile>
        </SectionContainer>
      );
    }

    const items = config.slideList.map((slideName, itr) => {
      const item = slides[slideName];
      //TODO: Setup check for "Coming Soon" if needed
      const desktopImages = item.images.default.desktop;
      const mobileImages = item.images.default.mobile;
      const copy = item.copy.default;

      return (
        <React.Fragment key={itr}>
          <Desktop>
            <StyledCarouselItem imgSrc={desktopImages.x3}>
              <ContentContainer>
                <StyledEyebrow>{copy.headline}</StyledEyebrow>
                <StyledNarrative
                  fontSize={copy.subCopyFontSize}
                  maxWidth={copy.subCopyMaxWidth}>
                  {copy.subCopy}
                </StyledNarrative>
              </ContentContainer>
            </StyledCarouselItem>
          </Desktop>
          <Mobile>
            <StyledCarouselItem imgSrc={mobileImages.x3}>
              <ContentContainer mobile>
                <StyledEyebrow
                  mobile
                  fontSize={'0.8125rem'}
                  letterSpacing={'1.5px'}>
                  {copy.headline}
                </StyledEyebrow>
                <StyledNarrative
                  fontSize={'1.625rem'}
                  lineHeight={'1.31'}
                  marginTop={'12px'}
                  maxWidth={'414px'}>
                  {copy.subCopy}
                </StyledNarrative>
              </ContentContainer>
            </StyledCarouselItem>
          </Mobile>
        </React.Fragment>
      );
    });

    return (
      <SectionContainer isLoading={isLoading} toasterHeight={toasterHeight}>
        <Desktop>
          <BookTripButtonContainer>
            <WhiteButton
              width={292}
              style={{
                pointerEvents: 'all',
                marginTop: '50vh',
                display: 'none'
              }}
              onClick={() => this.navToAdventures()}>
              {this.callToAction}
            </WhiteButton>
          </BookTripButtonContainer>
          <StandardCarousel
            mobile
            captureIndex={(index) => this.captureIndex(index)}
            items={items}
            displayLeftArrow={false}
            displayRightArrow={false}>
            {this.renderCarouselContent()}
          </StandardCarousel>
        </Desktop>
        <Mobile>
          <BookTripButtonContainer>
            <WhiteButton
              width={292}
              style={{
                pointerEvents: 'all',
                marginTop: '45vh',
                display: 'none'
              }}
              onClick={() => this.navToAdventures()}>
              {this.callToAction}
            </WhiteButton>
          </BookTripButtonContainer>
          <StandardCarousel
            mobile
            captureIndex={this.captureIndex}
            items={items}
            top={true}>
            {this.renderCarouselContent()}
          </StandardCarousel>
        </Mobile>
      </SectionContainer>
    );
  }
}

export default withRouter(withAppContext(HeroSection));
